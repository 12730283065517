import { CompanyFactory, CompanyFactoryData } from 'models/entities/company';
import { Headquarters, HeadquartersData } from 'models/entities/headquarters';
import { Franchisor, FranchisorData } from 'models/entities/franchisor';
import { Franchisee, FranchiseeData } from 'models/entities/franchisee';
import { Store, StoreData } from 'models/entities/store';
import { CentralKitchen, CentralKitchenData } from 'models/entities/central-kitchen';

import { Organization } from './organization';

type OrganizationData = CompanyFactoryData | HeadquartersData | FranchisorData | FranchiseeData | StoreData | CentralKitchenData;

class OrganizationFactory {

  static create(data: OrganizationData): Organization {
    switch (data.type) {
      case 'Company': return CompanyFactory.create(data as CompanyFactoryData);
      case 'Headquarters': return new Headquarters(data as HeadquartersData);
      case 'Franchisor': return new Franchisor(data as FranchisorData);
      case 'Franchisee': return new Franchisee(data as FranchiseeData);
      case 'Store': return new Store(data as StoreData);
      case 'CentralKitchen': return new CentralKitchen(data as CentralKitchenData);
      default: throw new Error('invalid type');
    }
  }

}

export { OrganizationFactory };
export type { OrganizationData };
