export const OrderedDishItemFieldsQuery = `
  posItemId
  name
  quantity
  timezone
  orderedAt
`;

export const OrderedDishItemCollectionQuery = `
  documents {
    ${OrderedDishItemFieldsQuery}
  }
`;

export const ReadOrderedDishItemsQuery = `
  readOrderedDishItems(businessCode: $businessCode, input: $input) {
    ${OrderedDishItemCollectionQuery}
  }
`;
