import { Supplier, SupplierData } from 'models/entities/supplier';

import { IngredientItem, IngredientItemData } from '../ingredient-item';

type Data = IngredientItemData & {
  supplier: SupplierData | Supplier;
};

abstract class PurchasedIngredientItem extends IngredientItem {

  readonly supplier: Supplier;

  protected constructor(data: Data) {
    super(data);
    this.supplier = data.supplier instanceof Supplier ? data.supplier : new Supplier(data.supplier);
  }

  toJSON(): Data {
    const data = super.toJSON();
    const supplier = this.supplier?.toJSON();
    return { ...data, supplier };
  }

}

export { PurchasedIngredientItem };
export type {
  Data as PurchasedIngredientItemData,
}