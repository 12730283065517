import React, { FC } from 'react';

import { CloseIcon } from 'views/components/icons';
import { Button } from './index.styled';

interface Props {
  onClose?: () => void;
}

const CloseButton: FC<Props> = ({ onClose }) => {
  return (
    <Button onClick={() => onClose && onClose()} tabIndex={-1}>
      <CloseIcon />
    </Button>
  );
};

export { CloseButton };