import { Role, RoleId } from '../role';

type Data = {
  role: RoleId | Role;
  content: string;
};

type DataToSend = {
  role: RoleId;
  content: string;
};

class Message {

    readonly role: Role;
    readonly content: string;

    constructor(data: Data) {
      this.role = data.role instanceof Role ? data.role : new Role({ id: data.role })
      this.content = data.content;
    }

    getDataToSend(): DataToSend {
      const { role, content } = this;
      return { role: role.id, content };
    }

}

export { Message };
export type {
  Data as MessageData,
  DataToSend as MessageDataToSend,
};