import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightPrimaryColor, darkPrimaryColor } from 'views/services/color-scheme';

import { Root as RootBase } from '../input-base/index.styled';

export const Root = styled(RootBase)`
  & > input {
    &:read-only {
      border-color: ${lightPrimaryColor};
      cursor: pointer;
      & ~ span, & ~ button {
        border-color: ${lightPrimaryColor};
      }
      &:hover, &:focus {
        border-color: ${darkPrimaryColor};
        & ~ span, & ~ button {
          border-color: ${darkPrimaryColor};
        }
      }
    }
  }
  & > ol {
    z-index: 2;
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    height: 240px;
    background-color: ${baseColor};
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.25);
    color: ${textColor};
    border: 2px solid ${darkPrimaryColor};
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    & > li {
      cursor: pointer;
      padding: 8px calc(8px - 2px);
      &:hover, &.selected {
        background-color: ${lightPrimaryColor};
      }
    }
  }
`;
