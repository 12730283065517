import { DishItem } from 'models/entities/dish-item';

import { ExportDishItemsTarget } from '../../../models/entities/target';

type Data = {
  target: ExportDishItemsTarget;
}

type DataToExport = {
  id: string;
  menuCategoryId: string;
  categoryId: string;
  order: string;
  name: string;
  price: string;
  posItemId: string;
  createdAt: string;
  updatedAt: string;
};

const fields = ['id', 'menuCategoryId', 'categoryId', 'order', 'name', 'price', 'posItemId', 'createdAt', 'updatedAt'];

class Model {

  readonly target: ExportDishItemsTarget;
  readonly tsv: string;
  readonly loading: boolean;

  constructor(data: Data) {
    this.target = data.target;
    this.tsv = this.export(this.target.dishItems);
    this.loading = false;
  }

  private export(items: DishItem[]): string {
    const data = items.map(it => Object.values(this.convert(it)).join('\t'));
    return [fields.join('\t'), ...data].join('\n');
  }

  private convert(item: DishItem): DataToExport {
    const id = item.id!;
    const menuCategoryId = item.menuCategoryId;
    const categoryId = item.category.id!;
    const order = item.order.toString();
    const name = item.name;
    const price = item.price.toString();
    const posItemId = item.posItemId;
    const createdAt = item.createdAt?.toJSON() ?? '';
    const updatedAt = item.updatedAt?.toJSON() ?? '';
    return { id, menuCategoryId, categoryId, order, name, price, posItemId, createdAt, updatedAt };
  }

}

export { Model };