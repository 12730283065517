import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lineColor, lightPrimaryColor, darkPrimaryColor, vividPrimaryColor, primaryTextColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

import { getBackgroundCss } from '../../services/background-css';

export const Root = styled.article`
  background-color: ${baseColor};
  width: 100vw;
  height: 100vh;
  ${getBackgroundCss({ zIndex: 11 })}
`;

export const Header = styled.header`
  background-color: ${baseColor};
  width: 100%;
  height: ${headerHeight};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h1 {
    flex: 0 0 auto;
    height: 100%;
    padding-left: 16px;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.25);
    color: ${textColor};
    display: flex;
    align-items: center;
  }
  & > nav {
    flex: 1 0 auto;
    padding-left: 24px;
    padding-right: 16px;
    font-size: calc(${baseFontSize} * 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Frame = styled.div`
  width: 100%;
  height: calc(100% - ${headerHeight});
  overflow: scroll;
  background-color: ${darkBaseColor};
`;

export const List = styled.div`
  height: 100%;
`;

export const ListHeader = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  height: ${headerHeight};
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  line-height: ${baseLineHeight};
  font-weight: bold;
  background-color: ${darkPrimaryColor};
  color: ${primaryTextColor};
  border-top: 1px solid ${darkPrimaryColor};
  border-bottom: 1px solid ${darkPrimaryColor};
  margin-bottom: -1px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListBody = styled.div`
  height: calc(100% - ${headerHeight});
`;

export const ListItem = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  line-height: ${baseLineHeight};
  background-color: ${baseColor};
  color: ${textColor};
  height: ${headerHeight};
  border-top: 1px solid ${lineColor};
  border-bottom: 1px solid ${lineColor};
  margin-bottom: -1px;
  &:hover {
    background-color: ${lightPrimaryColor};
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      bottom: -1px;
      border-top: 1px solid ${vividPrimaryColor};
      border-bottom: 1px solid ${vividPrimaryColor};
      pointer-events: none;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  flex: 1 0 auto;
  height: 100%;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1 0 auto;
  height: 100%;
  padding-left: 16px;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;