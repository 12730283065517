import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const CompaniesMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/companies" name="Companies" {...props} />
  );
};

export { CompaniesMenu };
