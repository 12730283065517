import { UpdateDishItemDataToFetch } from 'models/entities/dish-item';

import { InHouseIngredientItemEditor } from '../editor';
import { UpdateGqlDataToFetchForTransaction } from '../../gql';
import { DeleteTransactionGql } from './gql';

type Data = {
  ingredientItem: InHouseIngredientItemEditor;
};

type Result = Data;

class InHouseIngredientItemTransaction {

  readonly ingredientItem: InHouseIngredientItemEditor;
  readonly result?: Result;

  constructor(data: Data) {
    this.ingredientItem = data.ingredientItem;
  }

  async delete(): Promise<this> {
    const ingredientItem = this.getUpdateIngredientItemParam();
    const dishItem = this.getUpdateDishItemParam();
    const gql = await new DeleteTransactionGql().fetch({ ingredientItem, dishItem });
    if (!gql.result) throw new Error('invalid result');
    const result = { ingredientItem: this.ingredientItem };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private getUpdateIngredientItemParam(): UpdateGqlDataToFetchForTransaction {
    return {
      id: this.ingredientItem.original.id,
      input: {},
      deleted: true,
    };
  }

  private getUpdateDishItemParam(): UpdateDishItemDataToFetch {
    return {
      id: this.ingredientItem.original.dishItem.id,
      input: {},
    };
  }

}

export { InHouseIngredientItemTransaction };
