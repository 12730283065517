import { StringField } from 'models/entities/openai';

type Data = {
  field: StringField;
};

class Model {

  readonly field: StringField;
  readonly value: string;
  readonly rows: number;

  constructor(data: Data) {
    this.field = data.field;
    this.value = this.field.value;
    this.rows = this.getRows(this.value);
  }

  change(value: string): this {
    const field = this.field.change(value);
    const rows = this.getRows(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, rows });
  }

  apply(field: StringField): this {
    const value = field.value;
    const rows = this.getRows(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, rows });
  }

  private getRows(value: string): number {
    const rows = value.split('\n').length;
    return rows <= 8 ? rows : 8;
  }

}

export { Model };