import styled, { keyframes } from 'styled-components';

interface RootProps {
  order: number;
}

const open = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-64px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const close = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-64px);
  }
`;

export const Root = styled.div<RootProps>`
  position: relative;
  grid-area: 1 / 1;
  order: ${p => p.order};
  width: fit-content;
  height: fit-content;
  animation: ${open} 300ms ease-out forwards;
  &.closed {
    animation: ${close} 300ms ease-out forwards;
  }
`;
