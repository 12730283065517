import { OpenaiAccount } from '../../account';
import { OpenaiChatEditorDataToSend } from '../editor';
import { CompletionStreamData, CompletionStreamDataToAdd } from '../completion';

type DataToFetch = OpenaiChatEditorDataToSend;
type Result = CompletionStreamData | CompletionStreamDataToAdd;

class CreateApi {

  readonly account: OpenaiAccount;
  readonly result?: Result;

  constructor(account: OpenaiAccount) {
    this.account = account;
  }

  async * fetchStream(data: DataToFetch): AsyncGenerator<this> {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.account.apiKey}`,
        'OpenAI-Organization': `${this.account.organizationId}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.body) throw new Error('body is null');
    for await (const chunk of this.iterate(response.body)) {
      const results = chunk.split('data:').map(this.parse).filter(it => it) as Result[];
      for (const result of results) {
        yield Object.assign(Object.create(this.constructor.prototype), { ...this, result });
      }
    }
  }

  private async * iterate(stream: ReadableStream<Uint8Array>): AsyncGenerator<string> {
    const decoder = new TextDecoder('utf-8');
    const reader = stream.getReader();
    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) return;
        yield decoder.decode(value, { stream: true });
      }
    } finally {
      reader.releaseLock();
    }
  }

  private parse(string: string): Result | undefined {
    const s = string.trim();
    if (s === '') return undefined;
    if (s === '[DONE]') return undefined;
    return JSON.parse(s);
  }

}

export { CreateApi };