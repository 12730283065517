import React, { FC, PropsWithChildren, useState, useEffect } from 'react';

import { DishItemEditor } from 'models/entities/dish-item';
import { InHouseIngredientItemEditor } from 'models/entities/ingredient-item';
import { Business } from 'models/entities/business';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';
import { Model } from './index.model';
import { EditInHouseIngredientItemTarget } from '../../../../../models/entities/target';
import { Result as ModalResult } from '../../../../../models/entities/result';

import { useModal } from '../../../../services/use-modal';
import { EditIcon, CheckIcon } from 'views/components/icons';
import { BooleanInput } from 'views/components/inputs';
import { SmallPrimaryRoundButton, SecondaryRoundButton } from 'views/components/buttons';
import { Root, Heading, Field } from './index.styled';

interface Props extends PropsWithChildren {
  dishItem: DishItemEditor;
  onSetUp: (dishItem: DishItemEditor) => void;
  disabled: boolean;
  business: Business;
  ingredientCategories: IngredientCategoryCollection;
  unitSymbols: string[];
}

const IngredientItemSetting: FC<Props> = ({ children, dishItem, onSetUp, disabled, business, ingredientCategories, unitSymbols }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ dishItem, business, ingredientCategories, unitSymbols }));

  function edit() {
    const ingredientItem = model.dishItem.ingredientItem ?? model.build();
    const currency = model.business.currency;
    const target = new EditInHouseIngredientItemTarget({ ingredientItem, currency });
    const tell = apply;
    modal.open('edit-in-house-ingredient-item', { target, tell, finalize: false, strict: true });
  }

  function apply(result: ModalResult) {
    switch (result.state) {
      case 'canceled': return cancel();
      case 'edited': return setUp(result);
    }
  }

  function cancel() {
    setModel(model.cancel());
  }

  function setUp(result: ModalResult) {
    if (!result.target) throw new Error('invalid result');
    if (!(result.target instanceof EditInHouseIngredientItemTarget)) throw new Error('invalid target');
    const ingredientItem = result.target.ingredientItem as InHouseIngredientItemEditor;
    onSetUp(model.dishItem.apply({ ingredientItem }));
  }

  function watchDishItem() {
    if (dishItem === model.dishItem) return;
    setModel(model.load(dishItem));
  }

  function watchIsIngredientItem() {
    switch (true) {
      case model.shouldEdit: return edit();
      case model.shouldDisconnect: return onSetUp(model.dishItem.disconnect());
      case model.shouldReconnect: return onSetUp(model.dishItem.reconnect());
    }
  }

  useEffect(watchDishItem, [dishItem])
  useEffect(watchIsIngredientItem, [model.dishItem.isIngredientItem]);

  return (
    <Root>
      <Heading>{children}</Heading>
      <Field>
        {model.editButton.show && <SecondaryRoundButton onClick={() => edit()} disabled={disabled}><EditIcon /></SecondaryRoundButton>}
        {model.editedButton.show && <strong><SmallPrimaryRoundButton onClick={() => edit()} disabled={disabled || model.editedButton.disabled}><CheckIcon /></SmallPrimaryRoundButton></strong>}
        <BooleanInput field={model.dishItem.isIngredientItem} onEdit={isIngredientItem => setModel(model.edit({ isIngredientItem }))} disabled={disabled || model.disabled} />
      </Field>
    </Root>
  );

};

export { IngredientItemSetting };