import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { lightTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.div`
  & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    color: ${lightTextColor};
  }
`;

export const Field = styled.div`
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  align-items: center;
  gap: 8px;
  & > strong {
    font-size: calc(${baseFontSize} * 1.5);
  }
`;
