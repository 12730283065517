import { StringField, StringFieldData } from './string-field';

class EmailField extends StringField {

  constructor(data: StringFieldData = {}) {
    super(data);
  }

  protected check(value: string): boolean {
    if (!this.required && !value) return true;
    if (this.required && !value) return false;
    return !!value.match(/^.+@.+$/);
  }

  protected getError(value: string): string {
    if (value === '') return '';
    if (!this.check(value)) return 'Must be an email address';
    return '';
  }

}

export { EmailField };