import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';

import { OriginalDishCategory, OriginalDishCategoryCollection, OriginalDishCategoryCollectionData } from 'models/entities/original-dish-category';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  order: string | LexoRank;
  code?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  dishCategories?: OriginalDishCategoryCollectionData;
};

type DataToEdit = {
  order?: string;
  name?: string;
  code?: string;
};

type DataToSave = {
  order: string;
  name: string;
  code: string;
};

class OriginalMenuCategory {

  readonly id?: string;
  readonly order: LexoRank;
  readonly code: string;
  readonly name: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly dishCategories: OriginalDishCategoryCollection;

  private readonly createGql: CreateGql;
  private readonly updateGql: UpdateGql;
  private readonly deleteGql: DeleteGql;

  constructor(data: Data) {
    this.id = data.id;
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.code = data.code ?? '';
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.dishCategories = new OriginalDishCategoryCollection(this.id, data.dishCategories);
    this.createGql = new CreateGql();
    this.updateGql = new UpdateGql();
    this.deleteGql = new DeleteGql();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    if (!this.code) return false;
    return true;
  }

  async save(): Promise<OriginalMenuCategory> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<OriginalMenuCategory> {
    const createGql = await this.createGql.fetch({ input: this.getDataToSave() });
    if (!createGql.document) throw new Error('invalid document');
    return new OriginalMenuCategory(createGql.document);
  }

  private async update(): Promise<OriginalMenuCategory> {
    if (!this.id) throw new Error('invalid relationship');
    const updateGql = await this.updateGql.fetch({ id: this.id, input: this.getDataToSave() });
    if (!updateGql.document) throw new Error('invalid document');
    return new OriginalMenuCategory(updateGql.document);
  }

  private getDataToSave(): DataToSave {
    const { order, name, code } = this;
    return { order: order.toString(), name, code };
  }

  async delete(): Promise<OriginalMenuCategory> {
    if (!this.id) throw new Error('invalid relationship');
    const deleteGql = await this.deleteGql.fetch({ id: this.id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new OriginalMenuCategory(deleteGql.document);
  }

  toJSON(): Data {
    const { id, code, name } = this;
    const order = this.order.toString();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    const dishCategories = this.dishCategories.toJSON();
    return { id, order, code, name, createdAt, updatedAt, dishCategories };
  }

  async readAllDishCategories(): Promise<this> {
    const dishCategories = await this.dishCategories.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishCategories });
  }

  add(dishCategory: OriginalDishCategory): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishCategories: this.dishCategories.add(dishCategory) });
  }

  replace(dishCategory: OriginalDishCategory): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishCategories: this.dishCategories.replace(dishCategory) });
  }

  remove(dishCategory: OriginalDishCategory): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishCategories: this.dishCategories.remove(dishCategory) });
  }

  static sort(categories: OriginalMenuCategory[]) {
    function orderByOrder(a: OriginalMenuCategory, b: OriginalMenuCategory) {
      if (a.order.toString() < b.order.toString()) return -1;
      else if (a.order.toString() > b.order.toString()) return 1;
      else return 0;
    }
    return categories.sort(orderByOrder);
  }

  static buildNext(list: OriginalMenuCategory[]): OriginalMenuCategory {
    const order = list[list.length - 1]?.order.genNext() ?? LexoRank.middle();
    return new OriginalMenuCategory({ order });
  }

}

export { OriginalMenuCategory };
export type {
  Data as OriginalMenuCategoryData,
  DataToSave as OriginalMenuCategoryDataToSave,
};

export * from './collection';