import { CompanyFieldsQuery } from '../company';
import { FranchiseeCollectionQuery } from '../franchisee';
import { StoreCollectionQuery } from '../store';

export const FranchisorFieldsQuery = `
  id
  type
  parentId
  ownerId
  organizationId
  company {
    ${CompanyFieldsQuery}
  }
  name
  franchisees {
    ${FranchiseeCollectionQuery}
  }
  stores {
    ${StoreCollectionQuery}
  }
  userCount
  createdAt
  updatedAt
`;

export const FranchisorCollectionQuery = `
  documents {
    ${FranchisorFieldsQuery}
  }
  nextToken
`;

export const ReadFranchisorsQuery = `
  readFranchisors(nextToken: $nextToken) { 
    ${FranchisorCollectionQuery}
  }
`;

export const CreateFranchisorQuery = `
  createFranchisor(organizationId: $organizationId, input: $input) { 
    ${FranchisorFieldsQuery}
  }
`;

export const UpdateFranchisorQuery = `
  updateFranchisor(id: $id, input: $input) { 
    ${FranchisorFieldsQuery}
  }
`;

export const DeleteFranchisorQuery = `
  deleteFranchisor(id: $id) { 
    ${FranchisorFieldsQuery}
  }
`;
