import React, { FC, useState, useEffect } from 'react';

import { OriginalIngredientCategory } from 'models/entities/original-ingredient-category';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { TextInput } from 'views/components/text-input';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { Root, Footer, MoreActions } from './index.styled';

interface Props {
  target: OriginalIngredientCategory;
  ready: boolean;
}

const EditOriginalIngredientCategory: FC<Props> = ({ target, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target }));

  function save() {
    model.save().then(model => modal.close({ state: model.isNew ? 'created' : 'updated', target: model.result }));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (model.isNew) return;
    model.delete().then(model => modal.close({ state: 'deleted', target: model.result }));
  }

  function watchTarget() {
    if (!model.target?.id) return;
    model.readRelationships(model.target.id).then(m => setModel(m));
  }

  useEffect(watchTarget, [model.target]);

  return (
    <Root>
      <h1>Original Ingredient Category</h1>
      <fieldset>
        <TextInput value={model.name} onChange={e => setModel(model.change({ name: e.target.value }))} inFocus={ready}>
          <h2>Name</h2>
        </TextInput>
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.result}>Save</PrimaryButton>
      </Footer>
      {!model.isNew && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditOriginalIngredientCategory };
