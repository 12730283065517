import React, { FC, PropsWithChildren, useState, useRef, useEffect, AnimationEvent, MouseEvent } from 'react';

import { Layer } from '../../../models/entities/layer';
import { Model } from './index.model';

import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  layer: Layer;
  onOpen: () => void;
  onClose: () => void;
  onEscape: () => void;
  onClickBackground: () => void;
}

const Foreground: FC<Props> = ({ children, layer, onOpen, onClose, onEscape, onClickBackground }) => {

  const [model, setModel] = useState(new Model({ layer }))
  const root = useRef<HTMLDivElement>(null);

  function onAnimationEnd(e: AnimationEvent<HTMLDivElement>) {
    if (e.target !== root.current) return;
    if (model.state === 'open') onOpen();
    else if (model.state === 'closed') onClose();
  }

  function watchClick(e: MouseEvent<HTMLDivElement>) {
    if (!(e.target as Element).classList.contains('modal-background')) return;
    onClickBackground();
  }

  function show() {
    setModel(model.show());
  }

  function watchLayer() {
    if (model.layer === layer) return;
    setModel(model.refresh(layer))
  }

  function setupEscapeKey() {
    if (!model.layer.canClose()) return;
    function closeWithEscapeKey(e: KeyboardEvent) {
      if (e.key !== 'Escape') return;
      onEscape();
    }
    window.addEventListener('keydown', closeWithEscapeKey);
    return () => window.removeEventListener('keydown', closeWithEscapeKey);
  }

  useEffect(show, []);
  useEffect(watchLayer, [layer])
  useEffect(setupEscapeKey, [model.layer]);

  return (
    <Root ref={root} order={layer.order} className={model.state} onAnimationEnd={onAnimationEnd} onClick={e => watchClick(e)}>
      {children}
    </Root>
  );

};

export { Foreground };