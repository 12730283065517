import { Relationship, RelationshipData } from './index';
import { ParentAndOwnerCollections } from './parent-and-owner-collections';
import { ReadCollectionByParentIdGql, ReadCollectionByOwnerIdGql, ReadParentAndOwnerCollectionsGql, ReadCollectionByCategoryIdGql, ReadCollectionByOriginalIdGql, ReadCollectionByBusinessIdGql, ReadCollectionByMenuCategoryIdGql, ReadCollectionBySupplierIdGql, ReadCollectionByItemIdGql } from './gql';

type Data = {
  documents: RelationshipData[];
  nextToken: string;
};

class RelationshipCollection {

  readonly documents: Relationship[];
  readonly nextToken: string;

  constructor(data: Data = { documents: [], nextToken: '' }) {
    this.documents = data.documents.map(it => new Relationship(it));
    this.nextToken = data.nextToken;
  }

  static async readByParentId(parentId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByParentIdGql().fetch({ parentId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readByOwnerId(ownerId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByOwnerIdGql().fetch({ ownerId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readParentAndOwner(id: string, limit?: number): Promise<ParentAndOwnerCollections> {
    const gql = await new ReadParentAndOwnerCollectionsGql().fetch({ parentId: id, ownerId: id, limit });
    if (!gql.result) throw new Error('invalid result');
    return new ParentAndOwnerCollections(gql.result);
  }

  static async readByCategoryId(categoryId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByCategoryIdGql().fetch({ categoryId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readByOriginalId(originalId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByOriginalIdGql().fetch({ originalId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readByBusinessId(businessId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByBusinessIdGql().fetch({ businessId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readByMenuCategoryId(menuCategoryId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByMenuCategoryIdGql().fetch({ menuCategoryId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readBySupplierId(supplierId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionBySupplierIdGql().fetch({ supplierId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

  static async readByItemId(itemId: string, limit?: number): Promise<RelationshipCollection> {
    const gql = await new ReadCollectionByItemIdGql().fetch({ itemId, limit });
    if (!gql.result) throw new Error('invalid result');
    return new RelationshipCollection(gql.result);
  }

}

export { RelationshipCollection };
export type { Data as RelationshipCollectionData };