import { StringField, EmailField, PasswordField } from 'models/value-objects/editor-field';

import { User, UserDataToIdentify, UserDataToRevise } from './index';
import { UserTransaction } from './transaction';
import { UpdateGql } from './gql';

type Data = {
  original: User;
};

type DataToInit = {
  canDelete?: boolean;
};

type DataToApply = {
  name?: StringField;
  email?: EmailField;
  password?: PasswordField;
};

type DataToValidate = {
  name?: StringField;
  email?: EmailField;
  password?: PasswordField;
};

type DataToCreate = {
  organizationId: string;
  name: string;
  email: string;
  password: string;
};

type DataToUpdate = {
  name?: string;
  email?: string;
  password?: string;
};

class UserEditor {

  readonly original: User;
  readonly name: StringField;
  readonly email: EmailField;
  readonly password: PasswordField;
  readonly dirty: boolean;
  readonly ok: boolean;

  constructor(data: Data) {
    this.original = data.original;
    this.name = new StringField({ string: this.original.name });
    this.email = new EmailField({ string: this.original.email });
    this.password = new PasswordField({ string: this.original.password, required: !this.original.id });
    this.dirty = false;
    this.ok = this.validate();
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, dirty, ok });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.name.ok) return false;
    if (!props.email.ok) return false;
    if (!props.password.ok) return false;
    return true;
  }

  async save(): Promise<UserEditor> {
    if (!this.validate()) throw new Error('invalid object');
    return this.original.id ? await this.update() : await this.create();
  }

  private async create(): Promise<UserEditor> {
    const transaction = await new UserTransaction({ user: this }).create();
    if (!transaction.result) throw new Error('invalid result');
    return transaction.result.user;
  }

  private async update(): Promise<UserEditor> {
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new UpdateGql().fetch({ id: this.original.id, input: this.getDataToUpdate() });
    if (!gql.document) throw new Error('invalid document');
    return this.revise(gql.document);
  }

  getDataToCreate(): DataToCreate {
    const organizationId = this.original.organizationId;
    const name = this.name.string!;
    const email = this.email.string!;
    const password = this.password.string!;
    return { organizationId, name, email, password };
  }

  getDataToUpdate(): DataToUpdate {
    const name = this.name.string!;
    const email = this.email.string!;
    const password = this.password.string!;
    return { name, email, password };
  }

  identify(data: UserDataToIdentify): UserEditor {
    const original = this.rebuild().identify(data);
    return new UserEditor({ original });
  }

  revise(data: UserDataToRevise = {}): UserEditor {
    const original = this.rebuild().revise(data);
    return new UserEditor({ original });
  }

  rebuild(): User {
    if (!this.validate()) throw new Error('invalid object');
    const name = this.name.string!;
    const email = this.email.string!;
    const password = this.password.string!;
    return this.original.apply({ name, email, password });
  }

  async delete(): Promise<this> {
    if (!this.original.id) throw new Error('invalid id');
    const transaction = await new UserTransaction({ user: this }).delete();
    if (!transaction.result) throw new Error('invalid result');
    return this;
  }

}

export { UserEditor };
export type {
  DataToApply as UserEditorDataToApply,
  DataToCreate as UserEditorDataToCreate,
  DataToUpdate as UserEditorDataToUpdate,
};