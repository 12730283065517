import styled from 'styled-components';

import { Point } from './models/entities/point';

interface MenuProps {
  point: Point;
}

export const Content = styled.div`
`;

export const Root = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`;

export const Menu = styled.div<MenuProps>`
  position: absolute;
  top: ${p => p.point.y}px;
  left: ${p => p.point.x}px;
  width: fit-content;
  height: fit-content;
  visibility: hidden;
  pointer-events: none;
  &.showing {
    visibility: visible;
    pointer-events: auto;
  }
`;
