import React, { FC, useState } from 'react';

import { MenuCategory } from 'models/entities/menu-category';
import { ImportDishItemsTarget } from '../../../models/entities/target';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { MultiTextInput } from 'views/components/multi-text-input';
import { PrimaryButton, SecondaryButton } from 'views/components/buttons';
import { Root, Footer } from './index.styled';

interface Props {
  target: ImportDishItemsTarget;
  menuCategory: MenuCategory;
  ready: boolean;
}

const ImportDishItems: FC<Props> = ({ target, menuCategory, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, menuCategory }));

  function importData() {
    model.import().then(model => modal.close({ state: 'imported', target: model.conclude() }));
  }

  function cancel() {
    modal.close();
  }

  return (
    <Root>
      <h1>Dish Items</h1>
      <fieldset>
        <MultiTextInput value={model.tsv} onChange={e => setModel(model.input(e.target.value))} height={'480px'} inFocus={ready} />
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => importData()} disabled={!model.valid}>Import</PrimaryButton>
      </Footer>
    </Root>
  );

};

export { ImportDishItems };
