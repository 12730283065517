import { Api } from 'models/services/api';
import { UpdateDishItemTransactionQuery } from 'models/services/graphql';
import { UpdateDishItemDataToFetch } from 'models/entities/dish-item';

import { UpdateGqlDataToFetch } from '../../../gql';
import { GqlResult } from './gql-result';

type DataToFetch = {
  ingredientItem: UpdateGqlDataToFetch;
  dishItem: UpdateDishItemDataToFetch;
};

const RequestQuery = `
  mutation DeleteInHouseIngredientItemTransaction($dishItem: UpdateDishItemArgs!, $ingredients: [UpdateIngredientArgs!], $ingredientItem: UpdateIngredientItemArgs) {
    ${UpdateDishItemTransactionQuery}
  }
`;

type ResponseData = {
  data: {
    updateDishItemTransaction: GqlResult;
  };
};

class DeleteTransactionGql {

  readonly api: Api<ResponseData>;
  readonly result?: GqlResult;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.updateDishItemTransaction;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, result });
  }

}

export { DeleteTransactionGql };