import { Api } from 'models/services/api';
import { DeleteDishItemTransactionQuery } from 'models/services/graphql';
import { DeleteIngredientsDataToFetch } from 'models/entities/ingredient';
import { DeleteIngredientItemDataToFetch } from 'models/entities/ingredient-item';

import { DeleteGqlDataToFetch } from '../../gql';
import { GqlResult } from './gql-result';

type DataToFetch = {
  dishItem: DeleteGqlDataToFetch;
  ingredients?: DeleteIngredientsDataToFetch;
  ingredientItem?: DeleteIngredientItemDataToFetch;
};

const RequestQuery = `
  mutation DeleteDishItemTransaction($dishItem: DeleteDishItemArgs!, $ingredients: [DeleteIngredientArgs!], $ingredientItem: DeleteIngredientItemArgs) {
    ${DeleteDishItemTransactionQuery}
  }
`;

type ResponseData = {
  data: {
    deleteDishItemTransaction: GqlResult;
  };
};

class DeleteTransactionGql {

  readonly api: Api<ResponseData>;
  readonly result?: GqlResult;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.deleteDishItemTransaction;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, result });
  }

}

export { DeleteTransactionGql };