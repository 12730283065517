import React, { FC } from 'react';

import { Step } from '../../index.model';

import { useAuth } from 'views/services/auth';
import { Root } from './index.styled';

interface Props {
  current: Step;
  onGoForward: (step: Step) => void;
}

const Step1: FC<Props> = ({ current, onGoForward }) => {

  const auth = useAuth();

  function signInWithGoogle() {
    auth.signInWithGoogle()
    .catch(error => { throw new Error(error); });
  }

  return (
    <Root>
      <fieldset disabled={current !== 'step1'}>
        <div>Sign in with ...</div>
        <ol>
          <li><button onClick={() => signInWithGoogle()}>@aburirestaurants.com</button></li>
          <li><button onClick={() => onGoForward('step2')}>@aburijapan.com</button></li>
        </ol>
      </fieldset>
    </Root>
  );

};

export { Step1 };
