import React, { FC, PropsWithChildren } from 'react';
import { Cache as AmplifyCache } from 'aws-amplify';

import { CacheKey } from './models/services/cache-key';
import { ValueObject } from './models/services/value-object';
import { BusinessScope } from './models/entities/business-scope';

import { CacheContext } from './views/services/cache-context';
import { Config } from './views/services/config';

AmplifyCache.configure(Config);

const Cache: FC<PropsWithChildren> = ({ children }) => {

  function set(key: CacheKey, value: ValueObject) {
    AmplifyCache.setItem(key, JSON.stringify(value));
  }

  function get(key: CacheKey): ValueObject {
    const data = JSON.parse(AmplifyCache.getItem(key));
    if (!data) return undefined;
    switch (key) {
      case 'business-scope': return new BusinessScope(data);
    }
  }

  return (
    <CacheContext.Provider value={{ set, get }}>
      {children}
    </CacheContext.Provider>
  );

};

export { Cache };
export * from './models/entities/business-scope';
export * from './views/services/use-cache';