type Data = {
  boolean?: boolean;
};

class BooleanField {

  readonly boolean: boolean;
  readonly dirt?: boolean;
  readonly dirty: boolean;

  constructor(data: Data = {}) {
    this.boolean = data.boolean ?? false;
    this.dirty = false;
  }

  change(boolean: boolean): this {
    const dirt = boolean;
    const dirty = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, boolean, dirt, dirty });
  }

}

export { BooleanField };