import { DishItemAnalysisFieldsQuery } from '../dependent-queries';

export const ReadDishItemAnalysisQuery = `
  readDishItemAnalysis(menuCategoryId: $menuCategoryId, nextToken: $nextToken) {
    documents {
      ${DishItemAnalysisFieldsQuery.create()}
    }
    nextToken
  }
`;
