type DataToEdit = {
  farLeft?: boolean;
  farRight?: boolean;
};

class Model {

  readonly farLeft: boolean;
  readonly farRight: boolean;

  constructor() {
    this.farLeft = true;
    this.farRight = true;
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { Model };