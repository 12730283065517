import { Api } from 'models/services/api';
import { UpdateMenuCategoryQuery } from 'models/services/graphql';

import { MenuCategoryData, MenuCategoryDataToUpdate } from '../index';

type DataToUpdate = {
  id: string;
  input: MenuCategoryDataToUpdate;
};

const RequestQuery = `
  mutation UpdateMenuCategory($id: ID!, $input: MenuCategoryUpdateInput!) {
    ${UpdateMenuCategoryQuery}
  }
`;

type ResponseData = {
  data: {
    updateMenuCategory: MenuCategoryData
  }
}

class UpdateGql {

  readonly api: Api<ResponseData>;
  readonly document?: MenuCategoryData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToUpdate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.updateMenuCategory;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { UpdateGql };