import { RelationshipCollection, RelationshipCollectionData } from './collection';

type Data = {
  parentId: RelationshipCollectionData;
  ownerId: RelationshipCollectionData;
};

class ParentAndOwnerCollections {

  readonly parentId: RelationshipCollection;
  readonly ownerId: RelationshipCollection;

  constructor(data: Data) {
    this.parentId = new RelationshipCollection(data.parentId);
    this.ownerId = new RelationshipCollection(data.ownerId);
  }

  exist(): boolean {
    const { parentId, ownerId } = this;
    return [parentId, ownerId].some(it => it.documents.length);
  }

}

export { ParentAndOwnerCollections };
export type { Data as ParentAndOwnerCollectionsData };