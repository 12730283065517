import { UserEditor, UserEditorDataToApply } from 'models/entities/user';

import { EditUserTarget } from '../../../models/entities/target';
import { ResultData, ResultState } from '../../../models/entities/result';

type Data = {
  target: EditUserTarget;
};

type DataToApply = {
  user?: UserEditor;
};

type DataToValidate = {
  user?: UserEditor;
};

class Model {

  readonly target: EditUserTarget;

  readonly user: UserEditor;

  readonly ok: boolean;

  constructor(data: Data) {
    this.target = data.target;
    this.user = this.target.user;
    this.ok = this.validate(this);
  }

  edit(data: UserEditorDataToApply): this {
    const user = this.user.apply(data);
    return this.apply({ user });
  }

  apply(data: DataToApply): this {
    const { user } = { ...this, ...data };
    const ok = this.validate({ user });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, user, ok });
  }

  private validate(data: DataToValidate): boolean {
    const props = { ...this, ...data };
    if (!props.user.ok) return false;
    return true;
  }

  async save(): Promise<this> {
    const user = await this.user.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, user });
  }

  async delete(): Promise<this> {
    const user = await this.user.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, user });
  }

  conclude(state: ResultState): ResultData {
    const target = this.target.apply({ user: this.user });
    return { state, target };
  }

}

export { Model };