import React, { FC, useState, useEffect, MouseEvent } from 'react';

import { Business } from 'models/entities/business';
import { IngredientCategory } from 'models/entities/ingredient-category';
import { OriginalIngredientCategory, OriginalIngredientCategoryCollection } from 'models/entities/original-ingredient-category';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { useDnd } from 'views/services/dnd';
import { SecondaryButton } from 'views/components/buttons';
import { Root, Main, LeftPanel, RightPanel, List, Footer } from './index.styled';

interface Props {
  target: Business;
  originalCategories: OriginalIngredientCategoryCollection;
}

const EditIngredientCategories: FC<Props> = ({ target, originalCategories }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, originalCategories }));
  const dnd = useDnd();

  function close() {
    modal.close({ state: 'updated', target: model.result })
  }

  function add(category: IngredientCategory) {
    setModel(model.add(category));
    category.save().then(category => setModel(model.add(category, true)));
  }

  function move(category: IngredientCategory) {
    setModel(model.merge(category));
    category.save().then(category => setModel(model.merge(category, true)));
  }

  function remove(category: IngredientCategory) {
    if (model.relationship.has(category.id!)) return;
    setModel(model.remove(category));
    category.delete().then(category => setModel(model.remove(category, true)));
  }

  function grabOriginalCategory(target: OriginalIngredientCategory, e: MouseEvent<HTMLLIElement>) {
    dnd.grab('dnd-list-item', { target, e });
  }

  function dropToOriginalCategories() {
    if (!dnd.state.canDrop()) return;
    if (!dnd.target || !(dnd.target instanceof IngredientCategory)) return;
    remove(dnd.target as IngredientCategory);
  }

  function grabCategory(target: IngredientCategory, e: MouseEvent<HTMLLIElement>) {
    dnd.grab('dnd-list-item', { target, e, label: target.original.name });
  }

  function dropToCategories(e: MouseEvent<HTMLDivElement>) {
    if (!dnd.state.canDrop()) return;
    if (!dnd.target || !(dnd.target instanceof OriginalIngredientCategory)) return;
    if (model.categories.documents.find(it => it.id === dnd.target!.id)) return;
    add(model.build(dnd.target as OriginalIngredientCategory));
    e.stopPropagation();
    dnd.drop();
  }

  function insertAround(category: IngredientCategory, index: number, e: MouseEvent<HTMLLIElement>) {
    if (!dnd.state.canDrop()) return;
    if (!dnd.target) return;
    if (dnd.target === category) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const center = rect.top + rect.height / 2;
    const mark = index + (e.pageY < center ? 0 : 1);
    const prev = model.categories.documents[mark - 1];
    const next = model.categories.documents[mark];
    dnd.target instanceof IngredientCategory ? move(dnd.target.moveBetween(prev, next)) : add(model.buildBetween(prev, next, dnd.target as OriginalIngredientCategory));
    e.stopPropagation();
    dnd.drop();
  }

  function watchTarget() {
    if (model.categories.nextToken) model.readAllCategories().then(it => setModel(it));
    else model.readRelationshipCollections().then(it => setModel(it));
  }

  function watchOriginalCategories() {
    if (!model.originalCategories.nextToken) return;
    model.readAllOriginalCategories().then(it => setModel(it));
  }

  useEffect(watchTarget, [model.target]);
  useEffect(watchOriginalCategories, [model.originalCategories]);

  return (
    <Root>
      <Main>
        <LeftPanel>
          <h1>Original Ingredient Categories</h1>
          <List onMouseUp={() => dropToOriginalCategories()}>
            <ol>
              {model.originalCategoryList.map((it, i) => (
                <li key={i} onMouseDown={e => grabOriginalCategory(it, e)} className={[dnd.state.name, it === dnd.target ? 'dnd-target' : ''].join(' ')}>{it.name}</li>
              ))}
            </ol>
          </List>
        </LeftPanel>
        <RightPanel>
          <hgroup>
            <h1>{model.target.name}</h1>
            <p>{model.target.location}</p>
          </hgroup>
          <List onMouseUp={e => dropToCategories(e)} onMouseMove={e => dnd.enableScroll(e)} onMouseLeave={() => dnd.disableScroll()}>
            <ol>
              {model.categories.documents.map((it, i) => (
                <li key={i} onMouseDown={e => grabCategory(it, e)} onMouseMove={e => dnd.mark(e)} onMouseLeave={() => dnd.unmark()} onMouseUp={e => insertAround(it, i, e)} className={[dnd.state.name, it === dnd.target ? 'dnd-target' : '', model.relationship.has(it.id!) ? 'disabled' : ''].join(' ')}>{it.original.name}</li>
              ))}
            </ol>
          </List>
        </RightPanel>
      </Main>
      <Footer>
        <SecondaryButton onClick={() => close()}>Done</SecondaryButton>
      </Footer>
    </Root>
  );

};

export { EditIngredientCategories };
