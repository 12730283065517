import styled from 'styled-components';

import Rect from '../../../models/rect';
import Point from '../../../models/point';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightPrimaryColor, darkPrimaryColor, clearPrimaryColor } from 'views/services/color-scheme';

interface RootProps {
  rect: Rect;
  point: Point;
}

export const Root = styled.div<RootProps>`
  position: absolute;
  top: ${p => p.rect.top}px;
  left: ${p => p.rect.left}px;
  width: ${p => p.rect.width}px;
  height: ${p => p.rect.height}px;
  background-color: ${clearPrimaryColor};
  border: 1px solid ${darkPrimaryColor};
  border-radius: 0;
  box-sizing: border-box;
  &.dnd-grabbing-animation {
    transition: all 0.3s;
    top: ${p => p.point.y}px;
    left: ${p => p.point.x}px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
  }
`;

export const Label = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  color: ${textColor};
  border-left: 1px solid ${darkPrimaryColor};
  border-bottom: 1px solid ${darkPrimaryColor};
  background-color: ${lightPrimaryColor};
  white-space: nowrap;
  position: absolute;
  height: 32px;
  top: calc(-16px - 32px);
  left: -16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    height: 29px;
    background-color: ${darkPrimaryColor};
    transform-origin: left bottom;
    rotate: 135deg;
  }
`;
