import { Api } from 'models/services/api';
import { CreateSupplierQuery } from 'models/services/graphql';

import { SupplierData, SupplierDataToSave } from '../index';

type DataToCreate = {
  businessId: string;
  input: SupplierDataToSave;
};

const RequestQuery = `
  mutation CreateSupplier($businessId: ID!, $input: SupplierInput!) {
    ${CreateSupplierQuery}
  }
`;

type ResponseData = {
  data: {
    createSupplier: SupplierData
  }
}

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly document?: SupplierData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToCreate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.createSupplier;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { CreateGql };