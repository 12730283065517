import { OriginalIngredientCategory } from 'models/entities/original-ingredient-category';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  target: OriginalIngredientCategory;
}

type DataToChange = {
  name?: string;
};

class Model {

  readonly target: OriginalIngredientCategory;
  readonly isNew: boolean;
  readonly canDelete: boolean;

  readonly name: string;

  readonly result?: OriginalIngredientCategory;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.canDelete = false;
    this.name = this.target.name;
  }

  async readRelationships(originalId: string): Promise<this> {
    const relationships = await RelationshipCollection.readByOriginalId(originalId, 1);
    const canDelete = !relationships.documents.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, canDelete });
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.name) return false;
    return true;
  }

  private build(data: DataToChange): OriginalIngredientCategory {
    const name = data.name;
    return this.target.edit({ name });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };