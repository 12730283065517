import React, { FC, useState, useRef, useEffect, ChangeEvent } from 'react';

import { NumberField } from 'models/value-objects/editor-field';
import { Model } from './index.model';

import { Root } from './index.styled';

interface Props {
  field: NumberField;
  onEdit: (field: NumberField) => void;
  alt?: number;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  focused?: boolean;
  prefix?: string;
  suffix?: string;
}

const NumberInput: FC<Props> = props => {

  const { field, onEdit, placeholder = '', alt, readOnly = false, disabled = false, focused = false, prefix, suffix } = props;
  const input = useRef<HTMLInputElement>(null);
  const [model, setModel] = useState(new Model({ field, alt }));

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const it = model.change(e.target.value);
    onEdit(it.field);
    setModel(it);
  }

  function onFocus() {
    if (model.isAlt) input.current?.select();
  }

  function watchField() {
    if (field.value === model.field.value) return;
    setModel(model.renew(field));
  }

  function watchFocused() {
    if (focused) input.current?.focus();
  }

  useEffect(watchField, [field]);
  useEffect(watchFocused, [focused]);

  return (
    <Root>
      <input
        ref={input}
        type="text"
        value={model.value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={model.alt?.toString() || placeholder}
        readOnly={readOnly}
        disabled={disabled}
        className={model.isAlt ? 'alt' : ''}
      />
      {!!prefix && <span className="prefix">{prefix}</span>}
      {!!suffix && <span className="suffix">{suffix}</span>}
      {model.field.error && <p>{model.field.error}</p>}
    </Root>
  );

};

export { NumberInput };