import React, { FC } from 'react';

import { Root, RootProps, Text, Rings } from './index.styled';

const LoadingAnimation: FC<RootProps> = ({ background = 'transparent' }) => {
  return (
    <Root background={background}>
      <Text>Loading</Text>
      <Rings>
        <div />
        <div />
        <div />
      </Rings>
    </Root>
  );
};

export { LoadingAnimation };