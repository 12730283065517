export const OriginalDishCategoryFieldsQuery = `
  id
  menuCategoryId
  order
  name
  offMenu
  createdAt
  updatedAt
`;

export const OriginalDishCategoryCollectionQuery = `
  documents {
    ${OriginalDishCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadOriginalDishCategoriesQuery = `
  readOriginalDishCategories(menuCategoryId: $menuCategoryId, nextToken: $nextToken) {
    ${OriginalDishCategoryCollectionQuery}
  }
`;

export const CreateOriginalDishCategoryQuery = `
  createOriginalDishCategory(menuCategoryId: $menuCategoryId, input: $input) { 
    ${OriginalDishCategoryFieldsQuery}
  }
`;

export const UpdateOriginalDishCategoryQuery = `
  updateOriginalDishCategory(id: $id, input: $input) { 
    ${OriginalDishCategoryFieldsQuery}
  }
`;

export const DeleteOriginalDishCategoryQuery = `
  deleteOriginalDishCategory(id: $id) { 
    ${OriginalDishCategoryFieldsQuery}
  }
`;
