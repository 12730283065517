import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightTextColor, primaryColor, primaryTextColor, vividPrimaryColor, accentColor, vividAccentColor } from 'views/services/color-scheme';

export const Root = styled.div`
  font-size: calc(${baseFontSize} * 1.25);
  font-family: ${baseFontFamily};
  & > fieldset {
    & > div {
      & > button {
        cursor: pointer;
        color: ${lightTextColor};
        &:hover {
          color: ${textColor};
        }
      }
    }
    & > ol {
      & > li {
        margin: 16px 0;
        & > button {
          cursor: pointer;
          font-family: ${baseFontFamily};
          font-size: calc(${baseFontSize} * 1.25);
          color: ${primaryTextColor};
          background: linear-gradient(125deg, ${primaryColor} 30%, ${accentColor});
          margin: 8px 0;
          padding: 8px 32px;
          border-radius: 40px;
          width: 100%;
          text-align: center;
          &:hover, &:focus {
            background: linear-gradient(125deg, ${vividPrimaryColor} 30%, ${vividAccentColor});
          }
          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }
`;