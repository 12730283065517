import React, { FC, PropsWithChildren } from 'react';

import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  background?: boolean;
}

const NumberOutput: FC<Props> = ({ children, background = false }) => {
  return (
    <Root>
      <div className={background ? 'background' : ''}>{children}</div>
    </Root>
  );
};

export { NumberOutput };