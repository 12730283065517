import { CompanyFieldsQuery } from '../company';
import { StoreCollectionQuery } from '../store';

export const FranchiseeFieldsQuery = `
  id
  type
  parentId
  ownerId
  organizationId
  company {
    ${CompanyFieldsQuery}
  }
  name
  stores {
    ${StoreCollectionQuery}
  }
  userCount
  createdAt
  updatedAt
`;

export const FranchiseeCollectionQuery = `
  documents {
    ${FranchiseeFieldsQuery}
  }
  nextToken
`;

export const ReadFranchiseesQuery = `
  readFranchisees(nextToken: $nextToken) { 
    ${FranchiseeCollectionQuery}
  }
`;

export const CreateFranchiseeQuery = `
  createFranchisee(organizationId: $organizationId, input: $input) { 
    ${FranchiseeFieldsQuery}
  }
`;

export const UpdateFranchiseeQuery = `
  updateFranchisee(id: $id, input: $input) { 
    ${FranchiseeFieldsQuery}
  }
`;

export const DeleteFranchiseeQuery = `
  deleteFranchisee(id: $id) { 
    ${FranchiseeFieldsQuery}
  }
`;
