import '@fontsource/fira-sans/400.css'; // font-family: 'Fira Sans';
import '@fontsource/source-code-pro/400.css'; // font-family: 'Source Code Pro';
//import '@fontsource/major-mono-display/400.css'; // font-family: 'Major Mono Display';
import '@fontsource/nunito/400.css'; // font-family: 'Nunito';
import '@fontsource/gruppo/400.css'; // font-family: 'Gruppo';

export const baseFontFamily = "'Fira Sans', sans-serif";
export const baseFontSize = "16px";
export const baseLineHeight = 1.5;

export const codeFontFamily = "'Source Code Pro', sans-serif";
export const codeFontSize = "16px";
export const codeLineHeight = 1.5;

export const numberFontFamily = "'Nunito', sans-serif";
export const numberFontSize = "16px";
export const numberLineHeight = 1.5;

export const logoFontFamily = "'Gruppo', sans-serif";
export const logoFontSize = "32px";
export const logoLineHeight = 1;

