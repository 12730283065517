type Data = {
  x?: number;
  y?: number;
};

class Point {

  readonly x: number;
  readonly y: number;

  constructor(data: Data = {}) {
    this.x = data.x ?? 0;
    this.y = data.y ?? 0;
  }

  reset(data: Data = {}): this {
    const x = 0;
    const y = 0;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, x, y });
  }

  move(data: Data = {}): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { Point };
export type { Data as PointData };