import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor } from 'views/services/color-scheme';

export const Root = styled.article`
  background-color: ${baseColor};
  border-radius: 8px;
  padding: 32px;
  width: 480px;
  & > h1 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
  }
  & > fieldset {
    & > div {
      margin-top: 24px;
    }
  }
`;

export const Footer = styled.footer`
  margin-top: 48px;
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const MoreActions = styled.div`
  position: absolute;
  bottom: 24px;
  left: calc(100% + 8px);
  & > div {
    background-color: ${baseColor};
    font-size: calc(${baseFontSize} * 1.25);
    border-radius: 50%;
  }
`;
