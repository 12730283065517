import styled from 'styled-components';

import { clearBlackColor } from 'views/services/color-scheme';

interface Scroll { scroll: number; }

export const Main = styled.div<Scroll>`
  width: 100%;
  &.closed {
    position: static;
  }
  &.open {
    position: fixed;
    top: -${p => p.scroll}px;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  &.closed {
    visibility: hidden;
    background-color: transparent;
  }
  &.open {
    visibility: visible;
    background-color: ${clearBlackColor};
  }
  transition-duration: 300ms;
  display: grid;
  place-items: center;
`;

export const Foreground = styled.div`
  width: fit-content;
  height: fit-content;
  &.closed {
    opacity: 0;
    transform: translateY(-64px);
  }
  &.open {
    opacity: 1;
    transform: translateY(0);
  }
  transition-duration: 300ms;
`;
