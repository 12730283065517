import { Supplier, SupplierData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: SupplierData[];
  nextToken: string;
};

class SupplierCollection {

  readonly businessId: string;
  readonly documents: Supplier[];
  readonly nextToken: string;

  constructor(businessId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.businessId = businessId;
    this.documents = data.documents.map(it => new Supplier(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { businessId, nextToken } = collection;
      if (!businessId) continue;
      const gql = await new ReadCollectionGql().fetch({ businessId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new SupplierCollection(businessId, gql.result));
    }
    return collection;
  }

  merge(collection: SupplierCollection): this {
    const documents = Supplier.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(supplier: Supplier): this {
    const documents = Supplier.sort([...this.documents, supplier]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(supplier: Supplier): this {
    const documents = Supplier.sort(this.documents.map(it => it.id === supplier.id ? supplier : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(supplier: Supplier): this {
    const documents = this.documents.filter(it => it.id !== supplier.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(businessId: string): Promise<SupplierCollection> {
    const gql = await new ReadCollectionGql().fetch({ businessId });
    if (!gql.result) throw new Error('invalid result');
    return new SupplierCollection(businessId, gql.result);
  }

}

export { SupplierCollection };
export type { Data as SupplierCollectionData };