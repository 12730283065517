import { Api } from 'models/services/api';
import { DeleteOpenaiAccountQuery } from 'models/services/graphql';

import { OpenaiAccountData } from '../index';

type DataToDelete = {
  id: string;
};

const RequestQuery = `
  mutation DeleteOpenaiAccount($id: ID!) {
    ${DeleteOpenaiAccountQuery}
  }
`;

type ResponseData = {
  data: {
    deleteOpenaiAccount: OpenaiAccountData
  }
}

class DeleteGql {

  readonly api: Api<ResponseData>;
  readonly document?: OpenaiAccountData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToDelete): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw 'invalid response';
    const document = api.response.data.deleteOpenaiAccount;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { DeleteGql };