import React, { FC, useState } from 'react';

import { ExportReportTarget } from '../../../models/entities/target';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { MultiTextInput } from 'views/components/multi-text-input';
import { SecondaryButton } from 'views/components/buttons';
import { Root, Footer } from './index.styled';

interface Props {
  target: ExportReportTarget;
}

const ExportReport: FC<Props> = ({ target }) => {

  const modal = useModal();
  const [model] = useState(new Model({ target }));

  function done() {
    modal.close();
  }

  return (
    <Root>
      <h1>Report</h1>
      <fieldset>
        <MultiTextInput value={model.tsv} readOnly={true} height={'480px'} />
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => done()}>Done</SecondaryButton>
      </Footer>
    </Root>
  );

};

export { ExportReport };
