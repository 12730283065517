import { Layer } from '../../../models/entities/layer';

type State = 'init' | 'open' | 'closed';

type Data = {
  layer: Layer;
};

class Model {

  readonly layer: Layer;
  readonly state: State;

  constructor(data: Data) {
    this.layer = data.layer;
    this.state = 'init';
  }

  show(): this {
    const state = 'open';
    return Object.assign(Object.create(this.constructor.prototype), { ...this, state });
  }

  refresh(layer: Layer): this {
    const state = layer.deleted ? 'closed' : this.state;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, layer, state });
  }

}

export { Model };