import { Organization } from 'models/entities/organization';
import { User, UserCollection, UserEditor } from 'models/entities/user';

import { ShowUsersTarget } from '../../../models/entities/target';
import { ResultData, ResultState } from '../../../models/entities/result';

import { Condition } from './models/condition';
import { ReadGql } from './models/read-gql';

type Data = {
  target: ShowUsersTarget;
}

class Model {

  readonly target: ShowUsersTarget;
  readonly organization: Organization;
  readonly condition: Condition;
  readonly collection?: UserCollection;
  readonly list?: User[];

  constructor(data: Data) {
    this.target = data.target;
    this.organization = this.target.organization;
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const gql = await new ReadGql().fetch({ organizationId: this.organization.id });
    if (!gql.result) throw new Error('invalid result');
    const collection = new UserCollection(this.organization.id, gql.result.collection);
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(user?: User): UserEditor {
    const original = user ?? new User({ organizationId: this.organization.id });
    return new UserEditor({ original });
  }

  add(user: User): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(user));
  }

  replace(user: User): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(user));
  }

  remove(user: User): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(user));
  }

  private apply(collection: UserCollection): this {
    const organization = this.organization.setUserCount(collection.documents.length);
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, organization, collection, list });
  }

  conclude(state: ResultState): ResultData {
    const target = this.target.apply({ organization: this.organization });
    return { state, target };
  }

}

export { Model };