export const DishItemTransactionFieldsQuery = `
  dishItem {
    id
  }
  ingredients {
    id
  }
  ingredientItem {
    id
  }
`;

export const CreateDishItemTransactionQuery = `
  createDishItemTransaction(dishItem: $dishItem, ingredients: $ingredients, ingredientItem: $ingredientItem) { 
    ${DishItemTransactionFieldsQuery}
  }
`;

export const UpdateDishItemTransactionQuery = `
  updateDishItemTransaction(dishItem: $dishItem, ingredients: $ingredients, ingredientItem: $ingredientItem) { 
    ${DishItemTransactionFieldsQuery}
  }
`;

export const DeleteDishItemTransactionQuery = `
  deleteDishItemTransaction(dishItem: $dishItem, ingredients: $ingredients, ingredientItem: $ingredientItem) { 
    ${DishItemTransactionFieldsQuery}
  }
`;
