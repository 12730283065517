import { DependingQuery } from './depending-query';

import { DishItemAnalysisFieldsQuery } from './dish-item-analysis-fields-query';
import { IngredientItemAnalysisFieldsQuery } from './ingredient-item-analysis-fields-query';
import { IngredientAnalysisCollectionFieldsQuery } from './ingredient-analysis-collection-fields-query';


class IngredientAnalysisFieldsQuery extends DependingQuery {

  toString() {
    return `
      id
      usage
      dishItem {
        ${this.query}
      }
    `;
  }

  static create() {
    const ingredientAnalysisFieldsQuery = new IngredientAnalysisFieldsQuery();
    const dishItemAnalysisFieldsQuery = new DishItemAnalysisFieldsQuery();
    const ingredientItemAnalysisFieldsQuery = new IngredientItemAnalysisFieldsQuery();
    const ingredientAnalysisCollectionFieldsQuery = new IngredientAnalysisCollectionFieldsQuery();
    ingredientAnalysisFieldsQuery.to(dishItemAnalysisFieldsQuery);
    dishItemAnalysisFieldsQuery.to(ingredientItemAnalysisFieldsQuery);
    ingredientItemAnalysisFieldsQuery.to(ingredientAnalysisCollectionFieldsQuery);
    ingredientAnalysisCollectionFieldsQuery.to(ingredientAnalysisFieldsQuery);
    return ingredientAnalysisFieldsQuery;
  }

}

export { IngredientAnalysisFieldsQuery };
