import React, { FC, useState } from 'react';

import { PeriodPicker } from 'views/components/period-picker';
import { PrimaryButton, SecondaryButton } from 'views/components/buttons';

import { Model } from './index.model';
import { PeriodToolProps } from '../../../models/entities/props';
import { useDropdown } from '../../services/use-dropdown';
import { Root, Footer } from './index.styled';

const PeriodTool: FC<PeriodToolProps> = ({ period, onEdit }) => {

  const [model, setModel] = useState(new Model({ period }));
  const dropdown = useDropdown();

  function apply() {
    onEdit(model.period);
    dropdown.close();
  }

  function cancel() {
    dropdown.close();
  }

  return (
    <Root>
      <PeriodPicker start={model.period.start} end={model.period.end} onStart={start => setModel(model.pick(start))} onEnd={(start, end) => setModel(model.pick(start, end))} />
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => apply()}>Apply</PrimaryButton>
      </Footer>
    </Root>
  );

};

export { PeriodTool };