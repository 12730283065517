import { DependingQuery } from './depending-query';

import { IngredientAnalysisFieldsQuery } from './ingredient-analysis-fields-query';
import { DishItemAnalysisFieldsQuery } from './dish-item-analysis-fields-query';
import { IngredientItemAnalysisFieldsQuery } from './ingredient-item-analysis-fields-query';

class IngredientAnalysisCollectionFieldsQuery extends DependingQuery {

  toString() {
    return `
      documents {
        ${this.query}
      }
      nextToken
    `;
  }

  static create() {
    const ingredientAnalysisCollectionFieldsQuery = new IngredientAnalysisCollectionFieldsQuery();
    const ingredientAnalysisFieldsQuery = new IngredientAnalysisFieldsQuery();
    const dishItemAnalysisFieldsQuery = new DishItemAnalysisFieldsQuery();
    const ingredientItemAnalysisFieldsQuery = new IngredientItemAnalysisFieldsQuery();
    ingredientAnalysisCollectionFieldsQuery.to(ingredientAnalysisFieldsQuery);
    ingredientAnalysisFieldsQuery.to(dishItemAnalysisFieldsQuery);
    dishItemAnalysisFieldsQuery.to(ingredientItemAnalysisFieldsQuery);
    ingredientItemAnalysisFieldsQuery.to(ingredientAnalysisCollectionFieldsQuery);
    return ingredientAnalysisCollectionFieldsQuery;
  }

}

export { IngredientAnalysisCollectionFieldsQuery };
