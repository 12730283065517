import styled from 'styled-components';

export const Root = styled.div`
  width: 1em;
  height: 1em;
`;

export const Path = styled.path`
  fill: white;
  fill-opacity: 1;
  stroke: white;
  stroke-width: 0;
  stroke-opacity: 0;
`;