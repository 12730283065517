import dayjs, { Dayjs } from 'dayjs';

import { Organization, OrganizationData, OrganizationFactory } from 'models/entities/organization';
import { OpenaiAccount, OpenaiAccountData } from 'models/entities/openai';

import { ReadGql, ReadSettingsGql } from './gql';

type DataToBecomeAuthorized = {
  id: string;
  admin: boolean;
  google: boolean;
  invited: boolean;
};

type SettingsData = {
  openaiAccount?: OpenaiAccountData | null;
};

type DataToInit = {
  parentId?: string;
  organization?: OrganizationData;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
} & SettingsData;

type DataToApply = {
  openaiAccount?: OpenaiAccount;
};

class CurrentUser {

  readonly temporary: boolean;
  readonly id: string;
  readonly admin: boolean;
  readonly google: boolean;
  readonly invited: boolean;
  readonly parentId?: string;
  readonly organization?: Organization;
  readonly name: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly openaiAccount?: OpenaiAccount;

  constructor() {
    this.temporary = false;
    this.id = '';
    this.admin = false;
    this.google = false;
    this.invited = false;
    this.name = '';
  }

  becomeTemporary(): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, temporary: true });
  }

  becomeAuthorized(data: DataToBecomeAuthorized): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, temporary: false, ...data });
  }

  async load(): Promise<this> {
    return this.isClassicUser() ? await this.readSettings() : await this.read();
  }

  async read(): Promise<this> {
    if (!this.id) throw new Error('invalid id');
    const gql = await new ReadGql().fetch({ id: this.id });
    if (!gql.result) throw new Error('invalid result');
    return this.init(gql.result);
  }

  async readSettings(): Promise<this> {
    const gql = await new ReadSettingsGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    return this.init(gql.result);
  }

  private init(data: DataToInit): this {
    const parentId = data.parentId ?? '';
    const organization = data.organization ? OrganizationFactory.create(data.organization) : undefined;
    const name = data.name ?? '';
    const createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    const updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    const openaiAccount = data.openaiAccount ? new OpenaiAccount(data.openaiAccount) : undefined;
    const props = { parentId, organization, name, createdAt, updatedAt, openaiAccount };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...props });
  }

  apply(data: DataToApply): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  private isClassicUser(): boolean {
    return this.google || this.invited;
  }

}

export { CurrentUser };
export type {
  DataToInit as CurrentUserDataToInit,
  SettingsData as CurrentUserSettingsData,
};