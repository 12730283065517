import { Api } from 'models/services/api';
import { ReadCurrentUserQuery } from 'models/services/graphql';

import { CurrentUserDataToInit } from '../index';

type DataToRead = {
  id: string;
};

const RequestQuery = `
  query ReadCurrentUser($id: ID!) {
    ${ReadCurrentUserQuery}
  }
`;

type ResponseData = {
  data: {
    readCurrentUser: CurrentUserDataToInit;
  }
}

class ReadGql {

  readonly result?: CurrentUserDataToInit;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToRead): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.readCurrentUser;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };