import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const IngredientItemsMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/ingredient-items" name="Ingredient Items" {...props} />
  );
};

export { IngredientItemsMenu };
