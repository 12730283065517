import styled from 'styled-components';

import {logoFontFamily, logoLineHeight} from 'views/services/font-scheme';
import { primaryColor, accentColor } from 'views/services/color-scheme';

export const Root = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-family: ${logoFontFamily};
  font-size: 1em;
  line-height: ${logoLineHeight};
  color: ${primaryColor};
  background: linear-gradient(125deg, ${primaryColor} 30%, ${accentColor});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Image = styled.div`
  width: calc(1em * (40 / 32));
`;
