import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  menuCategoryId: string;
  order: string | LexoRank;
  name?: string;
  offMenu?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

type DataToBuild = {
  menuCategoryId: string;
};

type DataToEdit = {
  order?: string;
  name?: string;
  offMenu?: boolean;
};

type DataToSave = {
  order: string;
  name: string;
  offMenu: boolean;
};

class OriginalDishCategory {

  readonly id: string;
  readonly menuCategoryId: string;
  readonly order: LexoRank;
  readonly name: string;
  readonly offMenu: boolean;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  private readonly createGql: CreateGql;
  private readonly updateGql: UpdateGql;
  private readonly deleteGql: DeleteGql;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.menuCategoryId = data.menuCategoryId;
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.name = data.name ?? '';
    this.offMenu = data.offMenu ?? false;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.createGql = new CreateGql();
    this.updateGql = new UpdateGql();
    this.deleteGql = new DeleteGql();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<OriginalDishCategory> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<OriginalDishCategory> {
    const createGql = await this.createGql.fetch({ menuCategoryId: this.menuCategoryId, input: this.getDataToSave() });
    if (!createGql.document) throw new Error('invalid document');
    return new OriginalDishCategory({ ...createGql.document });
  }

  private async update(): Promise<OriginalDishCategory> {
    if (!this.id) throw new Error('invalid id');
    const updateGql = await this.updateGql.fetch({ id: this.id, input: this.getDataToSave() });
    if (!updateGql.document) throw new Error('invalid document');
    return new OriginalDishCategory({ ...updateGql.document });
  }

  private getDataToSave(): DataToSave {
    const { order, name, offMenu } = this;
    return { order: order.toString(), name, offMenu };
  }

  async delete(): Promise<OriginalDishCategory> {
    if (!this.id) throw new Error('invalid id');
    const deleteGql = await this.deleteGql.fetch({ id: this.id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new OriginalDishCategory({ ...deleteGql.document });
  }

  toJSON(): Data {
    const { id, menuCategoryId, name, offMenu } = this;
    const order = this.order.toString();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, menuCategoryId, order, name, offMenu, createdAt, updatedAt };
  }

  static sort(categories: OriginalDishCategory[]) {
    function orderByOrder(a: OriginalDishCategory, b: OriginalDishCategory) {
      if (a.order.toString() < b.order.toString()) return -1;
      else if (a.order.toString() > b.order.toString()) return 1;
      else return 0;
    }
    return categories.sort(orderByOrder);
  }

  static buildNext(list: OriginalDishCategory[], data: DataToBuild): OriginalDishCategory {
    const order = list[list.length - 1]?.order.genNext() ?? LexoRank.middle();
    return new OriginalDishCategory({ ...data, order });
  }

}

export { OriginalDishCategory };
export type {
  Data as OriginalDishCategoryData,
  DataToSave as OriginalDishCategoryDataToSave,
};

export * from './collection';