import { IngredientItemEditor, PoundBasedIngredientItemEditor, PoundBasedIngredientItemEditorDataToApply, UnitBasedIngredientItemEditor, UnitBasedIngredientItemEditorDataToApply } from 'models/entities/ingredient-item';
import { Currency } from 'models/value-objects/currency';
import { BooleanField } from 'models/value-objects/editor-field';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  ingredientItem: IngredientItemEditor;
  currency: Currency;
};

class Model {

  readonly poundMode: BooleanField;
  readonly poundBased2: PoundBasedIngredientItemEditor;
  readonly unitBased2: UnitBasedIngredientItemEditor;
  readonly ingredientItem: PoundBasedIngredientItemEditor | UnitBasedIngredientItemEditor;
  readonly isNew: boolean;
  readonly currency: Currency;
  readonly relationships?: RelationshipCollection;

  constructor(data: Data) {
    this.poundMode = new BooleanField({ boolean: data.ingredientItem instanceof PoundBasedIngredientItemEditor });
    this.poundBased2 = this.poundMode.boolean ? data.ingredientItem as PoundBasedIngredientItemEditor : (data.ingredientItem as UnitBasedIngredientItemEditor).asPoundBased();
    this.unitBased2 = !this.poundMode.boolean ? data.ingredientItem as UnitBasedIngredientItemEditor : (data.ingredientItem as PoundBasedIngredientItemEditor).asUnitBased();
    this.ingredientItem = this.poundMode.boolean ? this.poundBased2 : this.unitBased2;
    this.isNew = !this.ingredientItem.original.id;
    this.currency = data.currency;
  }

  async readRelationships(itemId: string): Promise<this> {
    const relationships = await RelationshipCollection.readByItemId(itemId, 1);
    const canDelete = !relationships.documents.length;
    const poundBased2 = this.poundBased2.init({ canDelete });
    const unitBased2 = this.unitBased2.init({ canDelete });
    const ingredientItem = this.poundMode.boolean ? poundBased2 : unitBased2;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, relationships, poundBased2, unitBased2, ingredientItem });
  }

  toggle(poundMode: BooleanField): this {
    const ingredientItem = poundMode.boolean ? this.poundBased2 : this.unitBased2;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, poundMode, ingredientItem });
  }

  edit(data: PoundBasedIngredientItemEditorDataToApply | UnitBasedIngredientItemEditorDataToApply): this {
    const poundBased2 = this.poundBased2.apply(data);
    const unitBased2 = this.unitBased2.apply(data);
    const ingredientItem = this.poundMode.boolean ? poundBased2 : unitBased2;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, poundBased2, unitBased2, ingredientItem });
  }

  editPoundBased(data: PoundBasedIngredientItemEditorDataToApply): this {
    const poundBased2 = this.poundBased2.apply(data);
    const ingredientItem = poundBased2;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, poundBased2, ingredientItem });
  }

  editUnitBased(data: UnitBasedIngredientItemEditorDataToApply): this {
    const unitBased2 = this.unitBased2.apply(data);
    const ingredientItem = unitBased2;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, unitBased2, ingredientItem });
  }

  async save(): Promise<this> {
    const ingredientItem = await this.ingredientItem.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItem });
  }

  async delete(): Promise<this> {
    const ingredientItem = await this.ingredientItem.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItem });
  }

}

export { Model };