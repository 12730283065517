import { IngredientCollectionQuery, IngredientFieldsQuery } from '../dependent-queries';

export const ReadIngredientsQuery = `
  readIngredients(dishItemId: $dishItemId, nextToken: $nextToken) { 
    ${IngredientCollectionQuery.create()}
  }
`;

export const CreateIngredientQuery = `
  createIngredient(dishItemId: $dishItemId, input: $input) { 
    id
    createdAt
    updatedAt
  }
`;

export const UpdateIngredientQuery = `
  updateIngredient(id: $id, input: $input) { 
    updatedAt
  }
`;

export const DeleteIngredientQuery = `
  deleteIngredient(id: $id) { 
    id
  }
`;

export { IngredientCollectionQuery, IngredientFieldsQuery };
