import { Api } from 'models/services/api';
import { CreateIngredientItemQuery } from 'models/services/graphql';

import { IngredientItemEditorDataToCreate, IngredientItemDataToIdentify } from '../ingredient-item';

type DataToFetch = {
  businessId: string;
  input: IngredientItemEditorDataToCreate;
};

const RequestQuery = `
  mutation CreateIngredientItem($businessId: ID!, $input: CreateIngredientItemInput!) {
    ${CreateIngredientItemQuery}
  }
`;

type ResponseData = {
  data: {
    createIngredientItem: IngredientItemDataToIdentify
  }
}

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly document?: IngredientItemDataToIdentify;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.createIngredientItem;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { CreateGql };
export type { DataToFetch as CreateGqlDataToFetch }
