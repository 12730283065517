import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const BusinessCategoriesMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/business-categories" name="Business Categories" {...props} />
  );
};

export { BusinessCategoriesMenu };
