export const OrderedMenuItemFieldsQuery = `
  sku
  name
  quantity
  timezone
  orderedAt
`;

export const OrderedMenuItemDatasetQuery = `
  sku
  dailies {
    ${OrderedMenuItemFieldsQuery}
  }
`;

export const OrderedMenuItemDatasetCollectionQuery = `
  documents {
    ${OrderedMenuItemDatasetQuery}
  }
`;

export const ReadOrderedMenuItemDatasetsQuery = `
  readOrderedMenuItemDatasets(businessCode: $businessCode, input: $input) {
    ${OrderedMenuItemDatasetCollectionQuery}
  }
`;
