import { StringField, PasswordField } from 'models/value-objects/editor-field';

type DataToEdit = {
  oldPassword?: StringField;
  newPassword?: PasswordField;
};

type DataToValidate = {
  oldPassword?: StringField;
  newPassword?: PasswordField;
};

class Model {

  readonly oldPassword: StringField;
  readonly newPassword: PasswordField;
  readonly ok: boolean;
  readonly message: string;

  constructor() {
    this.oldPassword = new StringField();
    this.newPassword = new PasswordField();
    this.ok = this.validate(this);
    this.message = '';
  }

  edit(data: DataToEdit = {}): this {
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data, ok });
  }

  private validate(data: DataToValidate): boolean {
    const props = { ...this, ...data };
    if (!props.oldPassword.ok) return false;
    if (!props.newPassword.ok) return false;
    return true;
  }

  alert(error: Error): this {
    let message = '';
    switch (true) {
      case /^NotAuthorizedException:/.test(error.toString()): message = 'Your current password was incorrect.'; break;
      case /^LimitExceededException:/.test(error.toString()): message = 'Attempt limit exceeded, please try after some time.'; break;
      default: message = 'Something went wrong, please try again.'; break;
    }
    return Object.assign(Object.create(this.constructor.prototype), { ...this, message });
  }

}

export { Model };