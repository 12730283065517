import { DependedQuery } from './depended-query';

import { DishItemFieldsQuery } from './dish-item-fields-query';
import { IngredientCollectionQuery } from './ingredient-collection-query';
import { IngredientFieldsQuery } from './ingredient-fields-query';

import { SupplierFieldsQuery } from '../supplier';
import { IngredientCategoryFieldsQuery } from '../ingredient-category';

class IngredientItemFieldsQuery extends DependedQuery {

  constructor() {
    super({ limit: 8 });
  }

  toString(): string {
    super.toString();
    if (this.isUnderLimit()) return `
      id
      businessId
      supplier {
        ${SupplierFieldsQuery}
      }
      category {
        ${IngredientCategoryFieldsQuery}
      }
      name
      poundPrice
      unitValue
      unitSymbol
      netPrice
      yieldRate
      createdAt
      updatedAt
    `;
    return `
      id
      businessId
      supplier {
        ${SupplierFieldsQuery}
      }
      dishItem {
        ${this.query}
      }
      category {
        ${IngredientCategoryFieldsQuery}
      }
      name
      poundPrice
      unitValue
      unitSymbol
      netPrice
      yieldRate
      createdAt
      updatedAt
    `;
  }

  static create() {
    const ingredientItemFieldsQuery = new IngredientItemFieldsQuery();
    const dishItemFieldsQuery = new DishItemFieldsQuery();
    const ingredientCollectionQuery = new IngredientCollectionQuery();
    const ingredientFieldsQuery = new IngredientFieldsQuery();
    ingredientItemFieldsQuery.to(dishItemFieldsQuery);
    dishItemFieldsQuery.to(ingredientCollectionQuery);
    ingredientCollectionQuery.to(ingredientFieldsQuery);
    ingredientFieldsQuery.to(ingredientItemFieldsQuery);
    return ingredientItemFieldsQuery;
  }

}

export { IngredientItemFieldsQuery };