import { PasswordField } from 'models/value-objects/editor-field';

type DataToEdit = {
  newPassword?: PasswordField;
};

type DataToValidate = {
  newPassword?: PasswordField;
};

class Model {

  readonly newPassword: PasswordField;
  readonly ok: boolean;

  constructor() {
    this.newPassword = new PasswordField();
    this.ok = this.validate(this);
  }

  edit(data: DataToEdit = {}): this {
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data, ok });
  }

  private validate(data: DataToValidate): boolean {
    const props = { ...this, ...data };
    if (!props.newPassword.ok) return false;
    return true;
  }

}

export { Model };
