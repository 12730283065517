import React, { FC, useState, useEffect } from 'react';

import { EditPurchasedIngredientItemTarget } from '../../../models/entities/target';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { showNumber, showMoney } from 'views/services/helpers';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { InputField } from 'views/components/input-field';
import { OutputField } from 'views/components/output-field';
import { CategoryArrayInput } from './views/category-array-input';
import { SupplierArrayInput } from './views/supplier-array-input';
import { StringInput, NumberInput, BooleanInput, StringArrayInput } from 'views/components/inputs';
import { StringOutput, NumberOutput } from 'views/components/outputs';
import { Root, ValueAndSymbol, NetAndGross, PoundPrice, Footer, MoreActions } from './index.styled';

interface Props {
  target: EditPurchasedIngredientItemTarget;
  ready: boolean;
}

const EditPurchasedIngredientItem: FC<Props> = ({ target, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ ...target }));

  function save() {
    model.save().then(it => modal.close({ state: it.isNew ? 'created' : 'updated', target: target.apply({ ingredientItem: it.ingredientItem }) }));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    model.delete().then(it => modal.close({ state: 'deleted', target: target.apply({ ingredientItem: it.ingredientItem }) }));
  }

  function watchIngredientItem() {
    if (model.relationships) return;
    if (!model.ingredientItem.original.id) return;
    model.readRelationships(model.ingredientItem.original.id).then(it => setModel(it));
  }

  useEffect(watchIngredientItem, [model.ingredientItem]);

  return (
    <Root>
      <h1>Ingredient Item</h1>
      <fieldset>
        <InputField>
          <h2>Category</h2>
          <CategoryArrayInput field={model.ingredientItem.category} onEdit={category => setModel(model.edit({ category }))} focused={ready} />
        </InputField>
        <InputField>
          <h2>Name</h2>
          <StringInput field={model.ingredientItem.name} onEdit={name => setModel(model.edit({ name }))} />
        </InputField>
        <InputField>
          <h2>Supplier</h2>
          <SupplierArrayInput field={model.ingredientItem.supplier} onEdit={supplier => setModel(model.edit({ supplier }))} />
        </InputField>
        <PoundPrice>
          <InputField>
            <h2>Pound</h2>
            <BooleanInput field={model.poundMode} onEdit={poundMode => setModel(model.toggle(poundMode))} />
          </InputField>
          <InputField>
            <NumberInput disabled={!model.poundMode.boolean} field={model.poundBased2.poundPrice} onEdit={poundPrice => setModel(model.editPoundBased({ poundPrice }))} prefix={model.currency.symbol} suffix="/ lb" />
          </InputField>
        </PoundPrice>
        {model.poundMode.boolean ? (
          <>
            <ValueAndSymbol>
              <OutputField>
                <h2>Unit Number</h2>
                <NumberOutput background={true}>{showNumber(model.poundBased2.unitValue)}</NumberOutput>
              </OutputField>
              <OutputField>
                <h2>Unit Symbol</h2>
                <StringOutput background={true}>{model.poundBased2.unitSymbol}</StringOutput>
              </OutputField>
            </ValueAndSymbol>
            <NetAndGross>
              <OutputField>
                <h2>Net Price</h2>
                <NumberOutput>{showMoney(model.poundBased2.netPrice, model.currency)}</NumberOutput>
              </OutputField>
              <InputField>
                <h2>Yield Rate</h2>
                <NumberInput field={model.ingredientItem.yieldRate} onEdit={yieldRate => setModel(model.edit({ yieldRate }))} />
              </InputField>
              <OutputField>
                <h2>Gross Price</h2>
                <NumberOutput>{showMoney(model.ingredientItem.grossPrice, model.currency)}</NumberOutput>
              </OutputField>
            </NetAndGross>
          </>
        ) : (
          <>
            <ValueAndSymbol>
              <InputField>
                <h2>Unit Number</h2>
                <NumberInput field={model.unitBased2.unitValue} onEdit={unitValue => setModel(model.editUnitBased({ unitValue }))} />
              </InputField>
              <InputField>
                <h2>Unit Symbol</h2>
                <StringArrayInput field={model.unitBased2.unitSymbol} onEdit={unitSymbol => setModel(model.editUnitBased({ unitSymbol }))} readOnly={false} />
              </InputField>
            </ValueAndSymbol>
            <NetAndGross>
              <InputField>
                <h2>Net Price</h2>
                <NumberInput field={model.unitBased2.netPrice} onEdit={netPrice => setModel(model.editUnitBased({ netPrice }))} prefix={model.currency.symbol} />
              </InputField>
              <InputField>
                <h2>Yield Rate</h2>
                <NumberInput field={model.ingredientItem.yieldRate} onEdit={yieldRate => setModel(model.edit({ yieldRate }))} />
              </InputField>
              <OutputField>
                <h2>Gross Price</h2>
                <NumberOutput>{showMoney(model.ingredientItem.grossPrice, model.currency)}</NumberOutput>
              </OutputField>
            </NetAndGross>
          </>
        )}
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.ingredientItem.ok}>Save</PrimaryButton>
      </Footer>
      {!model.isNew && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.ingredientItem.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditPurchasedIngredientItem };
