import { DependedQuery } from './depended-query';

import { IngredientItemAnalysisFieldsQuery } from './ingredient-item-analysis-fields-query';
import { IngredientAnalysisCollectionFieldsQuery } from './ingredient-analysis-collection-fields-query';
import { IngredientAnalysisFieldsQuery } from './ingredient-analysis-fields-query';

import { DishCategoryFieldsQuery } from '../dish-category';

class DishItemAnalysisFieldsQuery extends DependedQuery {

  constructor() {
    super({ limit: 8 });
  }

  toString() {
    super.toString();
    if (this.isUnderLimit()) return `
      id  
      menuCategoryId
      category {
        ${DishCategoryFieldsQuery}
      }
      order
      name
      price
      posItemId
    `;
    return `
      id
      menuCategoryId
      category {
        ${DishCategoryFieldsQuery}
      }
      order
      name
      price
      ingredientItem {
        ${this.query}
      }
      posItemId
    `;
  }

  static create() {
    const dishItemAnalysisFieldsQuery = new DishItemAnalysisFieldsQuery();
    const ingredientItemAnalysisFieldsQuery = new IngredientItemAnalysisFieldsQuery();
    const ingredientAnalysisCollectionFieldsQuery = new IngredientAnalysisCollectionFieldsQuery();
    const ingredientAnalysisFieldsQuery = new IngredientAnalysisFieldsQuery();
    dishItemAnalysisFieldsQuery.to(ingredientItemAnalysisFieldsQuery);
    ingredientItemAnalysisFieldsQuery.to(ingredientAnalysisCollectionFieldsQuery);
    ingredientAnalysisCollectionFieldsQuery.to(ingredientAnalysisFieldsQuery);
    ingredientAnalysisFieldsQuery.to(dishItemAnalysisFieldsQuery);
    return dishItemAnalysisFieldsQuery;
  }

}

export { DishItemAnalysisFieldsQuery };