import { Dayjs } from 'dayjs';

import { Calendar } from './models/calendar';
import { Day } from './models/day';

type Data = {
  start?: Dayjs;
  end?: Dayjs;
};

class Model {

  readonly calendar: Calendar;
  readonly start?: Day;
  readonly end?: Day;

  constructor(data: Data = {}) {
    this.start = data.start ? new Day({ object: data.start }) : undefined;
    this.end = data.end ? new Day({ object: data.end }) : undefined;
    const calendar = new Calendar();
    this.calendar = !this.start ? calendar : calendar.select(this.start, this.end);
  }

  select(day: Day): this {
    if (!this.start) return this.selectStart(day);
    return this.end ? this.selectStart(day) : this.selectEnd(day)
  }

  private selectStart(day: Day): this {
    const start = day;
    const end = undefined;
    const calendar = this.calendar.select(start);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, start, end, calendar });
  }

  private selectEnd(day: Day): this {
    if (!this.start) throw new Error('start is undefined');
    const { start, end } = day.object.isBefore(this.start.object) ? { start: day, end: this.start } : { start: this.start, end: day };
    const calendar = this.calendar.select(start, end);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, start, end, calendar });
  }

}

export { Model };