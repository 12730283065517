import type { DependedQuery } from './depended-query';

abstract class DependingQuery {

  protected query?: DependedQuery | DependingQuery;

  to(query: DependedQuery | DependingQuery) {
    this.query = query;
  }

}

export { DependingQuery };