import { MouseEvent } from 'react';

import { DndName } from './dnd-name';
import { State } from './state';
import { TargetObject } from './target-object';

type GrabParams = {
  e: MouseEvent<HTMLElement>;
  target: TargetObject;
  label?: string;
};

interface DndContextInterface {
  state: State;
  target: TargetObject;
  grab: (name: DndName, params: GrabParams) => void;
  mark: (e: MouseEvent<HTMLElement>) => void;
  unmark: () => void;
  drop: () => void;
  enableScroll: (e: MouseEvent<HTMLElement>) => void;
  disableScroll: () => void;
}

const DefaultInterface = {
  state: new State(),
  target: undefined,
  grab: () => {},
  mark: () => {},
  unmark: () => {},
  drop: () => {},
  enableScroll: () => {},
  disableScroll: () => {},
};

export type { DndContextInterface, GrabParams };
export { DefaultInterface };