import React, { FC, useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';

import { StringField } from 'models/entities/openai';
import { Model } from './index.model';

import { Root } from './index.styled';

interface Props {
  field: StringField;
  onEdit: (field: StringField) => void;
  onSend: () => void;
  readOnly?: boolean;
  focused?: boolean;
  placeholder?: string;
}

const ChatInput: FC<Props> = ({ field, onEdit, onSend, readOnly, focused, placeholder }) => {

  const textarea = useRef<HTMLTextAreaElement>(null);
  const [model, setModel] = useState(new Model({ field }));
  const [focusing, setFocusing] = useState(focused);
  const [composing, setComposing] = useState(false);

  function send() {
    onSend();
  }

  function onChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    const it = model.change(value);
    onEdit(it.field);
    setModel(it);
  }

  function onKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (composing) return;
    switch (true) {
      case !e.shiftKey && e.key === 'Enter': e.preventDefault(); return send();
    }
  }

  function onFocus() {
    setFocusing(true);
  }

  function onBlur() {
    setFocusing(false);
  }

  function onCompositionStart() {
    setComposing(true);
  }

  function onCompositionEnd() {
    setComposing(false);
  }

  function watchFocused() {
    if (focused) textarea.current?.focus();
  }

  function watchField() {
    if (field.value === model.field.value) return;
    setModel(model.apply(field));
  }

  useEffect(watchFocused, [focused]);
  useEffect(watchField, [field]);

  return (
    <Root className={focusing ? 'focusing' : ''}>
      <textarea
        ref={textarea}
        value={model.value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onCompositionStart={onCompositionStart}
        onCompositionEnd={onCompositionEnd}
        rows={model.rows}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Root>
  );

};

export { ChatInput };