import React, { useState, useEffect } from 'react';

import { Model, Step } from './index.model';

import { useAuth } from 'views/services/auth';
import { Logo } from 'views/components/logo';
import { Step1 } from './views/step1';
import { Step2 } from './views/step2';
import { Step3 } from './views/step3';
import { Root, Header, Content, Frame, Steps } from './index.styled';

const SignIn = () => {

  const auth = useAuth();
  const [model, setModel] = useState(new Model());

  function goTo(step: Step) {
    setModel(model.goTo(step));
  }

  function alert(message: string) {
    setModel(model.alert(message));
  }

  function onTransitionEnd() {
    setModel(model.focusOn(model.step));
  }

  function watchUser() {
    if (auth.user.temporary) goTo('step3');
  }

  useEffect(watchUser, [auth.user]);

  return (
    <Root>
      <Header>
        <Logo />
        {model.message && <p>{model.message}</p>}
      </Header>
      <Content>
        <Frame>
          <Steps className={model.step} onTransitionEnd={onTransitionEnd}>
            <div><Step1 current={model.step} onGoForward={goTo} /></div>
            <div><Step2 current={model.step} ready={model.focusing} onGoBack={goTo} onError={alert} /></div>
            <div><Step3 current={model.step} ready={model.focusing} /></div>
          </Steps>
        </Frame>
      </Content>
    </Root>
  );

};

export { SignIn };