import type { DependingQuery } from './depending-query';

type Data = {
  limit: number;
};

abstract class DependedQuery {

  private limit: number;
  protected query?: DependingQuery | DependedQuery;

  protected constructor(data: Data) {
    this.limit = data.limit;
  }

  protected toString(): string {
    this.limit--;
    return '';
  }

  protected isUnderLimit(): boolean {
    return this.limit < 0;
  }

  to(query: DependingQuery | DependedQuery) {
    this.query = query;
  }

}

export { DependedQuery };