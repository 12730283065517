import styled from 'styled-components';

export const Content = styled.div`
  &.dnd-dragging {
    cursor: grabbing;
  }
`;

export const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  visibility: hidden;
`;

export const MarkingLayer = styled(Layer)`
  &.dnd-marking {
    visibility: visible;
  }
`;

export const GrabbingLayer = styled(Layer)`
  &.dnd-grabbing, &.dnd-dragging, &.dnd-marking {
    visibility: visible;
  }
`;
