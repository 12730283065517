import React, { FC } from 'react';

import { faBars } from '@fortawesome/free-solid-svg-icons';

import { AbstractIcon } from '../abstract-icon';

interface Props {
  className?: string;
}

const ListIcon: FC<Props> = props => <AbstractIcon {...props} icon={faBars} />;

export { ListIcon };