export const BusinessCategoryFieldsQuery = `
  id
  order
  name
  createdAt
  updatedAt
`;

export const BusinessCategoryCollectionQuery = `
  documents {
    ${BusinessCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadBusinessCategoriesQuery = `
  readBusinessCategories(nextToken: $nextToken) {
    ${BusinessCategoryCollectionQuery}
  }
`;

export const CreateBusinessCategoryQuery = `
  createBusinessCategory(input: $input) { 
    ${BusinessCategoryFieldsQuery}
  }
`;

export const UpdateBusinessCategoryQuery = `
  updateBusinessCategory(id: $id, input: $input) { 
    ${BusinessCategoryFieldsQuery}
  }
`;

export const DeleteBusinessCategoryQuery = `
  deleteBusinessCategory(id: $id) { 
    ${BusinessCategoryFieldsQuery}
  }
`;
