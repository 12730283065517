import React, { FC, PropsWithChildren, useState, useRef, MouseEvent } from "react"

import { DndName } from './models/dnd-name';
import { TargetObject } from './models/target-object';
import { State } from './models/state';
import { Point } from './models/point';
import { Rect } from './models/rect';
import { Marking } from './models/marking';
import { GrabParams } from './models/dnd-context-interface';

import { DndContext } from './views/services/dnd-context';
import { useDnd } from './views/services/use-dnd';

import { GrabListItem } from './views/interfaces/grab-list-item';
import { MarkListItem } from './views/interfaces/mark-list-item';

import { Content, Root, MarkingLayer, GrabbingLayer } from './index.styled';

const Dnd: FC<PropsWithChildren> = ({ children }) => {

  const [name, setName] = useState<DndName>('');
  const [target, setTarget] = useState<TargetObject>();
  const [label, setLabel] = useState('Unknown Metadata');
  const [state, setState] = useState<State>(new State());

  const [grabbingRect, setGrabbingRect] = useState<Rect>(new Rect());
  const [grabbingPoint, setGrabbingPoint] = useState<Point>(new Point());
  const grabbingLayer = useRef<HTMLDivElement>(null);

  const [marking, setMarking] = useState<Marking>(new Marking());
  const [markingRect, setMarkingRect] = useState<Rect>(new Rect());
  const [markingPoint, setMarkingPoint] = useState<Point>(new Point());

  const intervalId = useRef<NodeJS.Timer>();

  function grab(name: DndName, params: GrabParams) {
    if (!state.canGrab()) return;
    const { e, target, label } = params;
    setState(state.toGrabbing());
    setName(name);
    setTarget(target);
    setLabel(label || (target && 'name' in target ? target.name : 'Unknown Metadata'));
    setGrabbingRect(grabbingRect.update(e.currentTarget.getBoundingClientRect()));
    setGrabbingPoint(grabbingPoint.update({ x: e.pageX, y: e.pageY }));
    if (grabbingLayer.current) {
      grabbingLayer.current.style.top = '0px';
      grabbingLayer.current.style.left = '0px';
    }
  }

  function drag(e: MouseEvent<HTMLElement>) {
    if (!state.canDrag()) return;
    setState(state.toDragging());
    moveGrabbingLayer(e);
  }

  function mark(e: MouseEvent<HTMLElement>) {
    if (!state.isDragging()) return;
    setMarking(marking.on());
    setMarkingRect(markingRect.update(e.currentTarget.getBoundingClientRect()));
    setMarkingPoint(markingPoint.update({ x: e.pageX, y: e.pageY }));
  }

  function unmark() {
    if (!state.isDragging()) return;
    setMarking(marking.off());
  }

  function drop() {
    setState(state.reset());
    setMarking(marking.off())
    disableScroll();
  }

  function moveGrabbingLayer(e: MouseEvent<HTMLElement>) {
    if (!grabbingLayer.current) return;
    grabbingLayer.current.style.top = `${e.pageY - grabbingPoint.y}px`;
    grabbingLayer.current.style.left = `${e.pageX - grabbingPoint.x}px`;
  }

  function enableScroll(e: MouseEvent<HTMLElement>) {
    if (!state.isActive()) return;
    const element = e.currentTarget;
    const rect = element.getBoundingClientRect();
    const max = element.children[0].getBoundingClientRect().height - rect.height;
    disableScroll();
    if (rect.top <= e.pageY && e.pageY <= rect.top + 32) intervalId.current = setInterval(() => scrollUp(element), 0);
    else if (rect.bottom - 32 <= e.pageY && e.pageY <= rect.bottom) intervalId.current = setInterval(() => scrollDown(element, max), 0);

    function scrollUp(element: HTMLElement) {
      element.scrollTop <= 0 ? disableScroll() : element.scrollBy(0, -1);
    }
    function scrollDown(element: HTMLElement, max: number) {
      element.scrollTop >= Math.round(max) ? disableScroll() : element.scrollBy(0, 1);
    }
  }

  function disableScroll() {
    if (!intervalId.current) return;
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  }

  return (
    <DndContext.Provider value={{ state, target, grab, mark, unmark, drop, enableScroll, disableScroll }}>
      <Content onMouseMove={e => drag(e)} onMouseUp={() => drop()} className={state.name}>
        {children}
      </Content>
      <Root>
        <MarkingLayer className={marking.name}>
          {(() => {
            switch (name) {
              case 'dnd-list-item': return <MarkListItem rect={markingRect} point={markingPoint} />;
            }
          })()}
        </MarkingLayer>
        <GrabbingLayer ref={grabbingLayer} className={state.name}>
          {(() => {
            switch (name) {
              case 'dnd-list-item': return <GrabListItem label={label} rect={grabbingRect} point={grabbingPoint} />;
            }
          })()}
        </GrabbingLayer>
      </Root>
    </DndContext.Provider>
  );

};

export { Dnd, useDnd };
