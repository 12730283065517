import React, { FC, useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';

import { Business, BusinessCollection } from 'models/entities/business';

import { useMenuCategoryCode } from 'views/services/hooks';
import { useMemory, BusinessScope } from 'views/services/memory';
import { ListIcon } from 'views/components/icons';
import { Root, CurrentValue, Dropdown, DropdownIcon, DropdownButton, DropdownList } from './index.styled';

interface Props {
  business?: Business;
  businesses?: BusinessCollection;
}

const BusinessMenu: FC<Props> = ({ business, businesses }) => {

  const menuCategoryCode = useMenuCategoryCode();
  const memory = useMemory();
  const [active, setActive] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  function toggle() {
    setActive(!active);
  }

  function close() {
    setActive(false);
  }

  function goTo(each: Business) {
    if (!businesses) throw new Error('businesses is undefined');
    memory.set('business-scope', new BusinessScope({ business: each, businesses }));
    navigate(getLink(each));
  }

  function getLink(each: Business) {
    const menuCategoryCodes = each.menuCategories.documents.map(it => it.original.code);
    const hasMenuCategoryCode = menuCategoryCode && [...menuCategoryCodes, 'suppliers', 'ingredient-items'].includes(menuCategoryCode);
    return hasMenuCategoryCode ? `/${each.code}/${menuCategoryCode}` : `/${each.code}`;
  }

  function watchClick(e: Event) {
    if (!button.current?.contains(e.target as Node)) close();
  }

  function watchActive() {
    if (!active) return;
    window.addEventListener('click', watchClick);
    return () => window.removeEventListener('click', watchClick);
  }

  useEffect(watchActive, [active])

  return (
    <Root>
      <Dropdown>
        <DropdownButton ref={button} onClick={() => toggle()}>
          <CurrentValue>
            <h1>{business?.name || '--------'}<small>{business?.location || '--------'}</small></h1>
          </CurrentValue>
          <DropdownIcon>
            <ListIcon />
          </DropdownIcon>
        </DropdownButton>
        <DropdownList className={active ? 'on' : ''}>
          {businesses?.documents.map((it, i) => (
            <li key={i} className={it.id === business?.id ? 'disabled' : ''} onClick={() => goTo(it)}>
              {it.name}<small>{it.location}</small>
            </li>
          ))}
        </DropdownList>
      </Dropdown>
    </Root>
  );

};

export { BusinessMenu };
