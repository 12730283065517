import { BusinessScope } from './models/entities/business-scope';

type DataToApply = {
  businessScope?: BusinessScope;
};

class Model {

  readonly businessScope: undefined | BusinessScope;

  constructor() {
    this.businessScope = undefined;
  }

  apply(data: DataToApply): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { Model };