import { Franchisee, FranchiseeData } from './index';

type Data = {
  documents: FranchiseeData[];
  nextToken: string;
};

class CompanyFranchiseeCollection {

  readonly companyId: string;
  readonly documents: Franchisee[];
  readonly nextToken: string;

  constructor(companyId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.companyId = companyId;
    this.documents = data.documents.map(it => new Franchisee(it));
    this.nextToken = data.nextToken;
  }

}

export { CompanyFranchiseeCollection };
export type { Data as CompanyFranchiseeCollectionData };