import { DishItemEditor } from 'models/entities/dish-item';
import { Currency } from 'models/value-objects/currency';

type Data = {
  dishItem: DishItemEditor;
  currency: Currency;
};

type DataToApply = {
  dishItem: DishItemEditor;
};

class EditDishItemTarget {

  readonly dishItem: DishItemEditor;
  readonly currency: Currency;

  constructor(data: Data) {
    this.dishItem = data.dishItem;
    this.currency = data.currency;
  }

  apply(data: DataToApply): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { EditDishItemTarget };

