import { UpdateOrganizationUserCountDataToFetch } from 'models/entities/organization';

import { UserEditor } from '../editor';
import { CreateGqlDataToFetch, DeleteGqlDataToFetch } from '../gql';
import { CreateTransactionGql, DeleteTransactionGql, GqlResult } from './gql';

type Data = {
  user: UserEditor;
};

type Result = Data;

class UserTransaction {

  readonly user: UserEditor;
  readonly result?: Result;

  constructor(data: Data) {
    this.user = data.user;
  }

  async create(): Promise<this> {
    const user = this.getCreateUserParam();
    const organization = this.getUpdateOrganizationUserCountParam();
    const gql = await new CreateTransactionGql().fetch({ user, organization });
    if (!gql.result) throw new Error('invalid result');
    const result = this.identify(gql.result);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private identify(result: GqlResult): Result {
    const user = this.user.identify(result.user);
    return { user };
  }

  async delete(): Promise<this> {
    const user = this.getDeleteUserParam();
    const organization = this.getUpdateOrganizationUserCountParam();
    const gql = await new DeleteTransactionGql().fetch({ user, organization });
    if (!gql.result) throw new Error('invalid result');
    const result = { user: this.user };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private getCreateUserParam(): CreateGqlDataToFetch {
    return {
      organizationId: this.user.original.organizationId,
      input: this.user.getDataToCreate(),
    };
  }

  private getDeleteUserParam(): DeleteGqlDataToFetch {
    return {
      id: this.user.original.id!,
    };
  }

  private getUpdateOrganizationUserCountParam(): UpdateOrganizationUserCountDataToFetch {
    return {
      id: this.user.original.organizationId,
    };
  }

}

export { UserTransaction };
