type Data = {
  string?: string;
  required?: boolean;
};

class StringField {

  readonly string?: string;
  readonly dirt?: string;
  readonly value: string;
  readonly required: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;
  readonly error: string;

  constructor(data: Data = {}) {
    this.string = data.string;
    this.value = data.string ?? '';
    this.required = data.required ?? true;
    this.dirty = false;
    this.ok = this.check(this.value);
    this.error = this.getError(this.value);
  }

  change(value: string): this {
    const string = value;
    const dirt = string;
    const dirty = true;
    const ok = this.check(value);
    const error = this.getError(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, value, string, dirt, dirty, ok, error });
  }

  protected check(value: string): boolean {
    if (this.required && !value) return false;
    return true;
  }

  protected getError(value: string): string {
    if (value === '') return '';
    return '';
  }

}

export { StringField };
export type { Data as StringFieldData };
