import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const DashboardMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="" name="Dashboard" {...props} />
  );
};

export { DashboardMenu };
