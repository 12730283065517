import { Api } from 'models/services/api';
import { ReadCurrentUserSettingsQuery } from 'models/services/graphql';

import { CurrentUserSettingsData } from '../index';

const RequestQuery = `
  query ReadCurrentUserSettings {
    ${ReadCurrentUserSettingsQuery}
  }
`;

type ResponseData = {
  data: {
    readCurrentUserSettings: CurrentUserSettingsData;
  }
};

class ReadSettingsGql {

  readonly result?: CurrentUserSettingsData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(): Promise<this> {
    const api = await this.api.fetch();
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.readCurrentUserSettings;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadSettingsGql };
