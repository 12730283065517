import { Api } from 'models/services/api';
import { CreateStoreQuery } from 'models/services/graphql';

import { StoreData, StoreDataToSave } from '../index';

type DataToCreate = {
  organizationId: string;
  input: StoreDataToSave;
};

const RequestQuery = `
  mutation CreateStore($organizationId: ID!, $input: StoreInput!) {
    ${CreateStoreQuery}
  }
`;

type ResponseData = {
  data: {
    createStore: StoreData
  }
}

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly document?: StoreData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToCreate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.createStore;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { CreateGql };