export function calcNetPrice(poundPrice: number | undefined): number | undefined {
  if (poundPrice === undefined) return undefined;
  return poundPrice * 2.2;
}

export function calcGrossPrice(netPrice: number | undefined, yieldRate: number | undefined): number | undefined {
  if (netPrice === undefined) return undefined;
  if (yieldRate === undefined) return undefined;
  const result = netPrice / yieldRate
  return isFinite(result) ? result : undefined;
}

export function calcUnitPrice(grossPrice: number | undefined, unitValue: number | undefined): number | undefined {
  if (grossPrice === undefined) return undefined;
  if (unitValue === undefined) return undefined;
  const result = grossPrice / unitValue;
  return isFinite(result) ? result : undefined;
}
