import { Role, RoleId } from '../role';
import { Message } from '../message';

type Data = {
  role: RoleId;
};

type DataToAdd = {
  content?: string;
};

class Delta {

  readonly role: Role;
  readonly content: string;

  constructor(data: Data) {
    this.role = new Role({ id: data.role })
    this.content = '';
  }

  add(data: DataToAdd): this {
    if (!data.content) return this;
    const content = this.content + data.content;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, content });
  }

  complete(): Message {
    const { role, content } = this;
    return new Message({ role, content });
  }

}

export { Delta };
export type {
  Data as DeltaData,
  DataToAdd as DeltaDataToAdd,
};