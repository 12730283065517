import { Api } from 'models/services/api';
import { ReadIngredientItemQuery, ReadRelationshipsByItemIdQuery } from 'models/services/graphql';
import { IngredientItemData } from 'models/entities/ingredient-item';
import { RelationshipCollectionData } from 'models/entities/relationship';

type DataToFetch = {
  id: string;
  itemId: string;
  limit?: number;
  nextToken?: string;
};

const RequestQuery = `
  query EditDishItem($id: ID!, $itemId: ID!, $limit: Int, $nextToken: String) {
    ${ReadIngredientItemQuery}
    ${ReadRelationshipsByItemIdQuery}
  }
`;

type ResponseData = {
  data: {
    readIngredientItem: IngredientItemData;
    readRelationshipsByItemId: RelationshipCollectionData;
  }
}

type Result = {
  ingredientItem: IngredientItemData;
  relationshipCollection: RelationshipCollectionData;
};

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const ingredientItem = api.response.data.readIngredientItem;
    const relationshipCollection = api.response.data.readRelationshipsByItemId;
    const result = { ingredientItem, relationshipCollection };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };