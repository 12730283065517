import { Api } from 'models/services/api';
import { ReadBusinessByCodeQuery } from 'models/services/graphql';

import { BusinessData } from '../index';

type DataToRead = {
  code: string;
};

const RequestQuery = `
  query ReadBusinessByCode($code: String!) {
    ${ReadBusinessByCodeQuery}
  }
`;

type ResponseData = {
  data: {
    readBusinessByCode: BusinessData;
  }
}

class ReadByCodeGql {

  readonly document?: BusinessData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToRead): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.readBusinessByCode;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, document, api });
  }

}

export { ReadByCodeGql };