import { Api } from 'models/services/api';
import { UpdateCompanyQuery } from 'models/services/graphql';

import { CompanyData, CompanyDataToSave } from '../index';

type DataToUpdate = {
  id: string;
  input: CompanyDataToSave;
};

const RequestQuery = `
  mutation UpdateCompany($id: ID!, $input: CompanyInput!) {
    ${UpdateCompanyQuery}
  }
`;

type ResponseData = {
  data: {
    updateCompany: CompanyData
  }
}

class UpdateGql {

  readonly api: Api<ResponseData>;
  readonly document?: CompanyData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToUpdate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.updateCompany;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { UpdateGql };