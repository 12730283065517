import { IngredientItemAnalysisFieldsQuery } from '../dependent-queries';

export const ReadIngredientItemAnalysisQuery = `
  readIngredientItemAnalysis(businessId: $businessId, nextToken: $nextToken) {
    documents {
      ${IngredientItemAnalysisFieldsQuery.create()}
    }
    nextToken
  }
`;
