const React = require('react');
const GlobalStyle = require('./src/views/services/global-style').GlobalStyle;
const Location = require('./src/views/services/location').Location;
const Auth = require('./src/views/services/auth').Auth;
const Cache = require('./src/views/services/cache').Cache;
const Dnd = require('./src/views/services/dnd').Dnd;
const Dropdown = require('./src/views/services/dropdown').Dropdown;
const Modal = require('./src/views/services/modal').Modal;
const Gate = require('./src/views/services/auth').Gate;
const Memory = require('./src/views/services/memory').Memory;

exports.wrapRootElement = ({ element }) => {
  return (
    <GlobalStyle>
      {element}
    </GlobalStyle>
  );
};

exports.wrapPageElement = ({ element, props }) => {
  return (
    <Location location={props.location}>
      <Auth>
        <Cache>
          <Dnd>
            <Dropdown>
              <Modal>
                <Gate>
                  <Memory>
                    {element}
                  </Memory>
                </Gate>
              </Modal>
            </Dropdown>
          </Dnd>
        </Cache>
      </Auth>
    </Location>
  );
};
