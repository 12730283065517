import { CentralKitchensActionsPropsTarget } from '../../../models/entities/props';

class Model {

  target: CentralKitchensActionsPropsTarget;

  constructor({ target }: { target: CentralKitchensActionsPropsTarget }) {
    this.target = target;
  }

  apply({ target }: { target: CentralKitchensActionsPropsTarget }) {
    return new Model({ target });
  }

}

export { Model };