import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightTextColor, darkTextColor, lineColor, primaryColor, primaryTextColor, lightPrimaryColor, primaryLineColor, darkPrimaryColor, vividPrimaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CurrentValue = styled.div`
  font-family: ${baseFontFamily};
  display: flex;
  align-items: center;
  & > h1 {
    font-size: calc(${baseFontSize} * 1);
    color: ${primaryTextColor};
    white-space: nowrap;
    & > small {
      font-size: calc(${baseFontSize} * 0.75);
      color: ${baseColor};
      margin-left: 8px;
    }
  }
`;

export const Dropdown = styled.div`
  position: relative;
`;

export const DropdownIcon = styled.div`
  font-size: calc(${baseFontSize} * 0.5);
  color: ${primaryTextColor};
  margin-left: 16px;
`;

export const DropdownButton = styled.button`
  position: relative;
  z-index: 1;
  cursor: pointer;
  padding: 4px 8px 4px 16px;
  border-radius: 32px;
  background-color: ${primaryColor};
  &:hover {
    background-color: ${vividPrimaryColor};
  }
  display: flex;
  align-items: center;
`;

export const DropdownList = styled.ol`
  position: absolute;
  z-index: 0;
  top: calc(100% + 4px);
  left: 0;
  border: 1px solid ${lineColor};
  & > li {
    white-space: nowrap;
    padding: 8px 16px;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    background-color: ${baseColor};
    color: ${textColor};
    & > small {
      font-size: calc(${baseFontSize} * 0.75);
      color: ${lightTextColor};
      margin-left: 8px;
    }
    &:hover {
      cursor: pointer;
      background-color: ${primaryColor};
      color: ${primaryTextColor};
      & > small {
        color: ${baseColor};
      }
    }
    &.disabled {
      pointer-events: none;
      color: ${darkTextColor};
      & > small {
        color: ${darkTextColor};
      }
    }
  }
  visibility: hidden;
  &.on {
    visibility: visible;
  }
`;
