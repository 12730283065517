export const DailySalesMetricsFieldsQuery = `
  guestCount
  subtotal
`;

export const DailySalesFieldsQuery = `
  business
  salesChannel
  billedAt
  timezone
  lunch {
    ${DailySalesMetricsFieldsQuery}
  }
  idle {
    ${DailySalesMetricsFieldsQuery}
  }
  dinner {
    ${DailySalesMetricsFieldsQuery}
  }
  whole {
    ${DailySalesMetricsFieldsQuery}
  }
`;

export const DailySalesDatasetQuery = `
  billedAt
  timezone
  salesChannels {
    ${DailySalesFieldsQuery}
  }
`;

export const DailySalesDatasetCollectionQuery = `
  documents {
    ${DailySalesDatasetQuery}
  }
`;

export const ReadDailySalesDatasetsQuery = `
  readDailySalesDatasets(businessCode: $businessCode, input: $input) {
    ${DailySalesDatasetCollectionQuery}
  }
`;
