import { Supplier } from 'models/entities/supplier';

type Data = {
  target: Supplier[];
}

type DataToExport = {
  id: string;
  businessId: string;
  name: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  phone: string;
  fax: string;
  picName: string;
  picPhone: string;
  picEmail: string;
  notes: string;
  deleted: string;
  createdAt: string;
  updatedAt: string;
};

const fields = ['id', 'businessId', 'name', 'address', 'city', 'province', 'country', 'postalCode', 'phone', 'fax', 'picName', 'picPhone', 'picEmail', 'notes', 'deleted', 'createdAt', 'updatedAt'];

class Model {

  readonly target: Supplier[];
  readonly tsv: string;
  readonly loading: boolean;

  constructor(data: Data) {
    this.target = data.target;
    this.tsv = this.export(this.target);
    this.loading = false;
  }

  private export(items: Supplier[]): string {
    const data = items.map(it => Object.values(this.convert(it)).join('\t'));
    return [fields.join('\t'), ...data].join('\n');
  }

  private convert(item: Supplier): DataToExport {
    const id = item.id!;
    const businessId = item.businessId;
    const name = item.name;
    const address = item.address;
    const city = item.city;
    const province = item.province;
    const country = item.country;
    const postalCode = item.postalCode;
    const phone = item.phone;
    const fax = item.fax;
    const picName = item.picName;
    const picPhone = item.picPhone;
    const picEmail = item.picEmail;
    const notes = item.notes;
    const deleted = item.deleted.toString();
    const createdAt = item.createdAt?.toJSON() ?? '';
    const updatedAt = item.updatedAt?.toJSON() ?? '';
    return { id, businessId, name, address, city, province, country, postalCode, phone, fax, picName, picPhone, picEmail, notes, deleted, createdAt, updatedAt };
  }

}

export { Model };