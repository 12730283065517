type Name = '' | 'dnd-marking';

class Marking {

  readonly name: Name;

  constructor(name: Name = '') {
    this.name = name;
  }

  on(): this {
    return this.update('dnd-marking');
  }

  off(): this {
    return this.update('');
  }

  private update(name: Name): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, name });
  }

}

export { Marking };