import dayjs, { Dayjs } from 'dayjs';

type Data = {
  id?: string;
  parentId?: string;
  organizationId: string;
  name?: string;
  email?: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToApply = {
  name?: string;
  email?: string;
};

type DataToIdentify = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToRevise = {
  updatedAt?: string;
};

class User {

  readonly id: string;
  readonly parentId?: string;
  readonly organizationId: string;
  readonly name: string;
  readonly email: string;
  readonly password: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.parentId = data.parentId ?? '';
    this.organizationId = data.organizationId;
    this.name = data.name ?? '';
    this.email = data.email ?? '';
    this.password = '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  apply(data: DataToApply): this {
    const { name, email } = { ...this, ...data };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, name, email });
  }

  identify(data: DataToIdentify): this {
    const id = data.id;
    const createdAt = dayjs(data.createdAt);
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, id, createdAt, updatedAt });
  }

  revise(data: DataToRevise = {}): this {
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, updatedAt });
  }

  toJSON(): Data {
    const { id, organizationId, email, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, organizationId, email, name, createdAt, updatedAt };
  }

  static sort(businesses: User[]) {
    function orderByOrder(a: User, b: User) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { User };
export type {
  Data as UserData,
  DataToIdentify as UserDataToIdentify,
  DataToRevise as UserDataToRevise,
};