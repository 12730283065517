import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const OriginalIngredientCategoriesMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/original-ingredient-categories" name="Original Ingredient Categories" {...props} />
  );
};

export { OriginalIngredientCategoriesMenu };
