import dayjs, { Dayjs } from 'dayjs';

import { Organization } from 'models/entities/organization';
import { Company, CompanyData } from 'models/entities/company';
import { SatelliteKitchenCollection, SatelliteKitchenCollectionData, SatelliteKitchen } from 'models/entities/satellite-kitchen';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  type?: string;
  parentId?: string;
  ownerId?: string;
  company?: CompanyData | Company;
  name?: string;
  satelliteKitchens?: SatelliteKitchenCollectionData;
  userCount?: number;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  company?: Company;
  name?: string;
};

type DataToSave = {
  type: string;
  companyId: string;
  name: string;
};

class CentralKitchen implements Organization {

  readonly id: string;
  readonly type: string;
  readonly parentId?: string;
  readonly ownerId?: string;
  readonly company?: Company;
  readonly name: string;
  readonly satelliteKitchens: SatelliteKitchenCollection;
  readonly userCount: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data = {}) {
    this.id = data.id ?? '';
    this.type = 'CentralKitchen';
    this.parentId = data.parentId ?? '';
    this.ownerId = data.ownerId ?? '';
    this.company = data.company ? data.company instanceof Company ? data.company : new Company(data.company) : undefined;
    this.name = data.name ?? '';
    this.satelliteKitchens = new SatelliteKitchenCollection(this.id, data.satelliteKitchens);
    this.userCount = data.userCount ?? 0;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.company) return false;
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<CentralKitchen> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<CentralKitchen> {
    const gql = await new CreateGql().fetch({ input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new CentralKitchen(gql.document);
  }

  private async update(): Promise<CentralKitchen> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new CentralKitchen(gql.document);
  }

  private getDataToSave(): DataToSave {
    const { type, company, name } = this;
    return { type, companyId: company!.id, name };
  }

  async delete(): Promise<CentralKitchen> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new DeleteGql().fetch({ id: this.id });
    if (!gql.document) throw new Error('invalid document');
    return new CentralKitchen(gql.document);
  }

  applySatelliteKitchen(satelliteKitchen: SatelliteKitchen): this {
    const satelliteKitchens = this.satelliteKitchens.apply(satelliteKitchen);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, satelliteKitchens });
  }

  removeSatelliteKitchen(satelliteKitchen: SatelliteKitchen): this {
    const satelliteKitchens = this.satelliteKitchens.remove(satelliteKitchen);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, satelliteKitchens });
  }

  getSatelliteKitchensExcept(satelliteKitchen: SatelliteKitchen): SatelliteKitchen[] {
    return this.satelliteKitchens.documents.filter(it => it.id !== satelliteKitchen.id);
  }

  setUserCount(userCount: number): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, userCount });
  }

  incrementUserCount(): this {
    return this.setUserCount(this.userCount + 1);
  }

  toJSON(): Data {
    const { id, name } = this;
    const company = this.company?.toJSON();
    const satelliteKitchens = this.satelliteKitchens.toJSON();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, name, company, satelliteKitchens, createdAt, updatedAt };
  }

  static sort(businesses: CentralKitchen[]) {
    function orderByOrder(a: CentralKitchen, b: CentralKitchen) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { CentralKitchen };
export type {
  Data as CentralKitchenData,
  DataToSave as CentralKitchenDataToSave,
};

export * from './collection';
export * from './company-collection';