import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Root, Text, Image } from './index.styled';

const Logo = () => {

  return (
    <Root>
      <Text>NEXT</Text>
      <Image>
        <StaticImage src="./assets/logo.png" alt="Logo" />
      </Image>
    </Root>
  );

};

export { Logo };