import { useState, useEffect } from 'react';

import { useLocation } from 'views/services/location';

type MenuCategoryCode = string | undefined;

const useMenuCategoryCode = (): MenuCategoryCode => {

  const location = useLocation();
  const [menuCategoryCode, setMenuCategoryCode] = useState<MenuCategoryCode>(getMenuCategoryCode());

  function getMenuCategoryCode(): MenuCategoryCode {
    const result = location.pathname.match(/^\/[^\/]+\/([^\/]+)\/?/);
    return result ? result[1] : undefined;
  }

  function watchLocation() {
    setMenuCategoryCode(getMenuCategoryCode());
  }

  useEffect(watchLocation, [location.pathname]);

  return menuCategoryCode;

}

export { useMenuCategoryCode };