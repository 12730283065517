import styled from 'styled-components';

import { numberFontFamily } from 'views/services/font-scheme';
import { textColor, darkBaseColor } from 'views/services/color-scheme';

export const Root = styled.div`
  font-family: ${numberFontFamily};
  font-size: 1em;
  color: ${textColor};
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
    height: 1.75em;
    padding: 0 8px;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: end;
    align-items: center;
    &.background {
      background-color: ${darkBaseColor};
      opacity: 0.5;
    }
  }
`;