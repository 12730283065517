import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';
import { baseColor, lightTextColor, lineColor, secondaryColor, secondaryTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Frame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar { display: none; }
  overscroll-behavior: contain;
`;

export const Content = styled.div`
  width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  width: 48px;
  height: 100%;
  font-size: calc(${baseFontSize} * 1);
  color: ${lightTextColor};
  background-color: ${baseColor};
  border: 0 solid ${lineColor};
  cursor: pointer;
  &:hover {
    color: ${secondaryTextColor};
    background-color: ${secondaryColor};
  }
  transition-duration: 160ms;
  transition-timing-function: ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeftArrow = styled(Arrow)`
  border-right-width: 1px;
  transition-property: left;
  left: 0;
  &.hidden {
    left: calc(-48px - 1px);
    pointer-events: none;
  }
`;

export const RightArrow = styled(Arrow)`
  border-left-width: 1px;
  transition-property: right;
  right: 0;
  &.hidden {
    right: calc(-48px - 1px);
    pointer-events: none;
  }
`;

