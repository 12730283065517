import { Store, StoreData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: StoreData[];
  nextToken: string;
};

class StoreCollection {

  readonly organizationId: string;
  readonly documents: Store[];
  readonly nextToken: string;
  readonly organizationIds: Set<string>;

  constructor(organizationId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.organizationId = organizationId;
    this.documents = data.documents.map(it => new Store(it));
    this.nextToken = data.nextToken;
    this.organizationIds = new Set(this.documents.map(it => it.id));
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { organizationId, nextToken } = collection;
      if (!organizationId) continue;
      const gql = await new ReadCollectionGql().fetch({ organizationId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new StoreCollection(organizationId, gql.result));
    }
    return collection;
  }

  merge(collection: StoreCollection): this {
    const documents = Store.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    const organizationIds = new Set(documents.map(it => it.id));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken, organizationIds });
  }

  apply(store: Store): this {
    return this.documents.some(it => it.id === store.id) ? this.replace(store) : this.add(store);
  }

  add(store: Store): this {
    const documents = Store.sort([...this.documents, store]);
    const organizationIds = this.organizationIds.add(store.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, organizationIds });
  }

  replace(store: Store): this {
    const documents = Store.sort(this.documents.map(it => it.id === store.id ? store : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(store: Store): this {
    const documents = this.documents.filter(it => it.id !== store.id);
    this.organizationIds.delete(store.id);
    const organizationIds = this.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, organizationIds });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(organizationId: string): Promise<StoreCollection> {
    const gql = await new ReadCollectionGql().fetch({ organizationId });
    if (!gql.result) throw new Error('invalid result');
    return new StoreCollection(organizationId, gql.result);
  }

}

export { StoreCollection };
export type { Data as StoreCollectionData };