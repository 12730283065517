import { Api } from 'models/services/api';
import { CreateFranchisorQuery } from 'models/services/graphql';

import { FranchisorData, FranchisorDataToSave } from '../index';

type DataToCreate = {
  organizationId: string;
  input: FranchisorDataToSave;
};

const RequestQuery = `
  mutation CreateFranchisor($organizationId: ID!, $input: FranchisorInput!) {
    ${CreateFranchisorQuery}
  }
`;

type ResponseData = {
  data: {
    createFranchisor: FranchisorData
  }
}

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly document?: FranchisorData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToCreate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.createFranchisor;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { CreateGql };