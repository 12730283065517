import { Franchisee } from 'models/entities/franchisee';
import { Company, CompanyCollection } from 'models/entities/company';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  target: Franchisee;
  companies: CompanyCollection;
};

type DataToChange = {
  company?: Company;
  name?: string;
};

class Model {

  readonly target: Franchisee;
  readonly isNew: boolean;
  readonly canDelete: boolean;
  readonly companies: CompanyCollection;

  readonly company?: Company;
  readonly name: string;

  readonly result?: Franchisee;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.canDelete = false;
    this.companies = data.companies;
    this.company = this.target.company;
    this.name = this.target.name;
  }

  async readRelationships(franchiseeId: string): Promise<this> {
    const relationships = await RelationshipCollection.readByParentId(franchiseeId, 1);
    const canDelete = !relationships.documents.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, canDelete });
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.name) return false;
    return true;
  }

  private build(data: DataToChange): Franchisee {
    const company = data.company;
    const name = data.name;
    return this.target.edit({ company, name });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };