import React, { FC, useState } from 'react';

import { PosReport } from 'models/entities/pos-report';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { MultiTextInput } from 'views/components/multi-text-input';
import { PrimaryButton, SecondaryButton } from 'views/components/buttons';
import { Root, Footer } from './index.styled';

interface Props {
  target: PosReport;
  ready: boolean;
}

const ImportReport: FC<Props> = ({ target, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target }));

  function importData() {
    modal.close({ state: 'imported', target: model.result });
  }

  function cancel() {
    modal.close();
  }

  return (
    <Root>
      <hgroup>
        <h1>{model.business.name}</h1>
        <p>{model.business.location}</p>
      </hgroup>
      <fieldset>
        <MultiTextInput value={model.tsv} onChange={e => setModel(model.input(e.target.value))} height={'480px'} inFocus={ready} placeholder={'Paste the item sales report here.'} />
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => importData()} disabled={!model.result}>Import</PrimaryButton>
      </Footer>
    </Root>
  );

};

export { ImportReport };
