import { Business, BusinessData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: BusinessData[];
  nextToken: string;
};

class BusinessCollection {

  readonly documents: Business[];
  readonly nextToken: string;

  constructor(data: Data = { documents: [], nextToken: '' }) {
    this.documents = data.documents.map(it => new Business(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const gql = await new ReadCollectionGql().fetch({ nextToken: collection.nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new BusinessCollection(gql.result));
    }
    return collection;
  }

  merge(collection: BusinessCollection): this {
    const documents = Business.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(business: Business): this {
    const documents = Business.sort([...this.documents, business]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(business: Business): this {
    const documents = Business.sort(this.documents.map(it => it.id === business.id ? business : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(business: Business): this {
    const documents = this.documents.filter(it => it.id !== business.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(): Promise<BusinessCollection> {
    const gql = await new ReadCollectionGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    return new BusinessCollection(gql.result);
  }

}

export { BusinessCollection };
export type { Data as BusinessCollectionData };