import { times } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

import { Week } from './week';
import { Day } from './day';

type Data = {
  first: Dayjs;
};

class Month {

  readonly first: Dayjs;
  readonly weeks: Week[];
  readonly isThisMonth: boolean;
  readonly isStartMonth: boolean;
  readonly isEndMonth: boolean;
  readonly start?: Day;
  readonly end?: Day;

  constructor(data: Data) {
    this.first = data.first;
    const weeks = this.getWeeks(this.first);
    this.weeks = times(weeks, offset => new Week({ first: this.first.startOf('week').add(offset, 'week') }));
    this.isThisMonth = this.first.isSame(dayjs(), 'month');
    this.isStartMonth = false;
    this.isEndMonth = false;
  }

  has(day: Day): boolean {
    return this.first.isSame(day.object, 'month');
  }

  select(start: Day, end?: Day): this {
    if (this.canIgnore(start, end)) return this;
    const weeks = this.weeks.map(it => it.select(start, end));
    const isStartMonth = this.first.isSame(start.object, 'month');
    const isEndMonth = end ? this.first.isSame(end.object, 'month') : isStartMonth;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, weeks, start, end, isStartMonth, isEndMonth });
  }

  private getWeeks(first: Dayjs): number {
    // Bug of dayjs: The following code sometimes returns 1 even if it is the end of December.
    //const lastWeek = first.endOf('month').week();
    const lastWeek = first.endOf('month').subtract(1, 'week').week() + 1;
    return lastWeek - (first.week() - 1);
  }

  private canIgnore(start: Day, end?: Day): boolean {
    switch (true) {
      case !end && !this.start && start.object.isAfter(this.first, 'month'): return true;
      case !end && this.start && [this.start, start].every(it => it.object.isAfter(this.first, 'month')): return true;
      case end && !this.end && end.object.isBefore(this.first, 'month'): return true;
      case end && this.end && [this.end, end].every(it => it.object.isBefore(this.first, 'month')): return true;
      default: return false;
    }
  }

}

export { Month };