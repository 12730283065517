import { Api } from 'models/services/api';
import { ReadDishItemsQuery } from 'models/services/graphql';

import { DishItemCollectionData } from '../collection';

type DataToFetch = {
  menuCategoryId: string;
  nextToken?: string;
};

const RequestQuery = `
  query ReadDishItems($menuCategoryId: ID!, $nextToken: String) {
    ${ReadDishItemsQuery}
  }
`;

type ResponseData = {
  data: {
    readDishItems: DishItemCollectionData;
  }
}

class ReadCollectionGql {

  readonly result?: DishItemCollectionData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.readDishItems;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadCollectionGql };
export type { DataToFetch as ReadCollectionGqlDataToFetch }
