import styled from 'styled-components';

import Rect from '../../../models/rect';

import { primaryColor } from 'views/services/color-scheme';

interface RootProps {
  position: Rect;
}

export const Root = styled.div<RootProps>`
  position: absolute;
  top: ${p => p.position.top}px;
  left: ${p => p.position.left}px;
  width: ${p => p.position.width}px;
  height: ${p => p.position.height}px;
  background-color: ${primaryColor};
`;