import React, { FC, PropsWithChildren, useState } from 'react';

import { MemoryKey } from './models/services/memory-key';
import { MemoryValue } from './models/services/memory-value';
import { BusinessScope } from './models/entities/business-scope';
import { Model } from './index.model';

import { MemoryContext } from './views/services/memory-context';

const Memory: FC<PropsWithChildren> = ({ children }) => {

  const [model, setModel] = useState(new Model());

  function set(key: MemoryKey, value: MemoryValue) {
    switch (key) {
      case 'business-scope': return setModel(model.apply({ businessScope: value as BusinessScope }));
    }
  }

  function get(key: MemoryKey): MemoryValue {
    switch (key) {
      case 'business-scope': return model.businessScope;
    }
  }

  return (
    <MemoryContext.Provider value={{ set, get }}>
      {children}
    </MemoryContext.Provider>
  );

};

export { Memory };
export * from './views/services/use-memory';
export * from './models/entities/business-scope';