export const UserTransactionFieldsQuery = `
  user {
    id
  }
  organization {
    id
  }
`;

export const CreateUserTransactionQuery = `
  createUserTransaction(user: $user, organization: $organization) { 
    ${UserTransactionFieldsQuery}
  }
`;

export const DeleteUserTransactionQuery = `
  deleteUserTransaction(user: $user, organization: $organization) { 
    ${UserTransactionFieldsQuery}
  }
`;
