export const StoreFieldsQuery = `
  id
  type
  parentId
  organizationId
  name
  userCount
  createdAt
  updatedAt
`;

export const StoreCollectionQuery = `
  documents {
    ${StoreFieldsQuery}
  }
  nextToken
`;

export const ReadStoresQuery = `
  readStores(nextToken: $nextToken) { 
    ${StoreCollectionQuery}
  }
`;

export const CreateStoreQuery = `
  createStore(organizationId: $organizationId, input: $input) { 
    ${StoreFieldsQuery}
  }
`;

export const UpdateStoreQuery = `
  updateStore(id: $id, input: $input) { 
    ${StoreFieldsQuery}
  }
`;

export const DeleteStoreQuery = `
  deleteStore(id: $id) { 
    ${StoreFieldsQuery}
  }
`;
