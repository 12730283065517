import React, { FC, useState, useRef, useEffect, MouseEvent, ChangeEvent, KeyboardEvent } from 'react';

import { StringField } from 'models/value-objects/field';
import { Model } from './index.model';

import { SearchIcon } from 'views/components/icons';
import { Root, Label, Space, Field } from './index.styled';

interface Props {
  th: string;
  field: StringField;
  onEdit: (field: StringField) => void;
}

const SearchTh: FC<Props> = ({ th, field, onEdit }) => {

  const root = useRef<HTMLDivElement>(null);
  const label = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const [fieldLeft, setFieldLeft] = useState(0);
  const [model, setModel] = useState(new Model({ field }));

  function reset() {
    setModel(model => !model.field.value ? model.reset() : model);
  }

  function onClick(e: MouseEvent<HTMLDivElement>) {
    if (model.mode === 'default') return setModel(model.search());
    if (model.mode === 'searching' && e.target !== input.current) reset();
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const it = model.change(e.target.value);
    onEdit(it.field);
    setModel(it);
  }

  function onFocus() {
    if (model.field.value) input.current?.select();
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case 'Tab': return reset();
      case 'Escape': return reset();
    }
  }

  function watchLabel() {
    if (!label.current) return;
    setFieldLeft(label.current.getBoundingClientRect().width);
  }

  function watchMode() {
    if (model.mode === 'default') return input.current?.blur();
    input.current?.focus();
    window.addEventListener('click', watchClick);
    return () => window.removeEventListener('click', watchClick);
  }

  function watchClick(e: Event) {
    if (!root.current?.contains(e.target as Node)) reset();
  }

  useEffect(watchLabel, [label.current])
  useEffect(watchMode, [model.mode]);

  return (
    <Root ref={root} className={model.mode} onClick={onClick}>
      <Label ref={label} className={model.mode}>{th}</Label>
      <Space><SearchIcon /></Space>
      <Field left={fieldLeft} className={model.mode}>
        <span><SearchIcon /></span>
        <input ref={input} value={model.field.value} onChange={onChange} onFocus={onFocus} onKeyDown={onKeyDown} />
      </Field>
    </Root>
  );

};

export { SearchTh };