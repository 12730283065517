import React, { FC, PropsWithChildren, useState, useRef, useEffect } from 'react';

import { MenuCategory } from 'models/entities/menu-category';

import { ChevronDownIcon } from 'views/components/icons';
import { Root, Label, List } from './index.styled';

interface Props {
  list: MenuCategory[];
  onSelect: (it: MenuCategory) => void;
}

const Dropdown: FC<Props & PropsWithChildren> = ({ list, onSelect, children }) => {

  const [active, setActive] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  function toggle() {
    setActive(!active);
  }

  function close() {
    setActive(false);
  }

  function watchClick(e: Event) {
    if (!button.current?.contains(e.target as Node)) close();
  }

  function watchActive() {
    if (!active) return;
    window.addEventListener('click', watchClick);
    return () => window.removeEventListener('click', watchClick);
  }

  useEffect(watchActive, [active])

  return (
    <Root>
      <Label>
        <div>
          {children}
        </div>
        <button ref={button} onClick={() => toggle()}><ChevronDownIcon /></button>
      </Label>
      {active && (
        <List>
          <ol>
            {list.map((it, i) => (
              <li key={i} onClick={() => onSelect(it)}>{it.original.name}</li>
            ))}
          </ol>
        </List>
      )}
    </Root>
  );

};

export { Dropdown };