import { Company, CompanyData } from './company';
import { CompanyStructure, CompanyStructureData } from './structure';

type Data = CompanyData | CompanyStructureData;
type Result = Company | CompanyStructure;

class CompanyFactory {

  static create(data: Data): Result {
    return this.isCompanyStructure(data) ? new CompanyStructure(data) : new Company(data);
  }

  private static isCompanyStructure(data: Data): boolean {
    const { headquarters, franchisors, franchisees, centralKitchens } = data as CompanyStructureData;
    return [headquarters, franchisors, franchisees, centralKitchens].some(it => it !== undefined);
  }

}

export { CompanyFactory };
export type { Data as CompanyFactoryData };