import { DishItem } from 'models/entities/dish-item';

type Data = {
  dishItems?: DishItem[];
};

class ImportDishItemsTarget {

  readonly dishItems: DishItem[];

  constructor(data: Data = {}) {
    this.dishItems = data.dishItems ?? [];
  }

}

export { ImportDishItemsTarget };

