import { IngredientCategory } from '../index';

type Data = {
  list: IngredientCategory[];
  object?: IngredientCategory;
  required?: boolean;
};

class IngredientCategoryArrayField {

  readonly list: IngredientCategory[];
  readonly object?: IngredientCategory;
  readonly dirt?: IngredientCategory;
  readonly index: number;
  readonly required: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;
  readonly error: string;

  constructor(data: Data) {
    this.list = data.list;
    this.object = data.object;
    this.index = this.object && this.list.find(it => it.id === this.object?.id) ? this.list.findIndex(it => it.id === this.object?.id) : 0;
    this.required = data.required ?? true;
    this.dirty = false;
    this.ok = this.check(this.index);
    this.error = this.getError(this.index);
  }

  change(index: number): this {
    const object = this.list[index];
    const dirt = object;
    const dirty = true;
    const ok = this.check(index);
    const error = this.getError(index);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, index, object, dirt, dirty, ok, error });
  }

  private check(index: number): boolean {
    if (!this.list[index]) return false;
    return true;
  }

  private getError(index: number): string {
    if (!this.list[index]) return 'must be in the list';
    return '';
  }

}

export { IngredientCategoryArrayField };