import React, { FC } from 'react';

import { faPen } from '@fortawesome/free-solid-svg-icons';

import { AbstractIcon } from '../abstract-icon';

interface Props {
  className?: string;
}

const EditIcon: FC<Props> = props => <AbstractIcon {...props} icon={faPen} />;

export { EditIcon };