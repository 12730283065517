import React, { FC, useState, useEffect } from 'react';

import { OriginalDishCategory } from 'models/entities/original-dish-category';
import { OriginalMenuCategory } from 'models/entities/original-menu-category';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { TextInput } from 'views/components/text-input';
import { ToggleSwitchInput } from 'views/components/toggle-switch-input';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { Root, Footer, MoreActions } from './index.styled';

interface Props {
  target: OriginalDishCategory;
  menuCategory: OriginalMenuCategory;
  ready: boolean;
}

const EditOriginalDishCategory: FC<Props> = ({ target, menuCategory, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, menuCategory }));

  function save() {
    model.save().then(model => modal.close({ state: model.isNew ? 'created' : 'updated', target: model.result }));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (model.isNew) return;
    model.delete().then(model => modal.close({ state: 'deleted', target: model.result }));
  }

  function watchReady() {
    if (ready) setModel(model.focus());
  }

  function watchTarget() {
    if (!model.target?.id) return;
    model.readRelationships(model.target.id).then(m => setModel(m));
  }

  useEffect(watchReady, [ready]);
  useEffect(watchTarget, [model.target]);

  return (
    <Root>
      <h1>{model.menuCategory.name}</h1>
      <fieldset>
        <TextInput value={model.name} onChange={e => setModel(model.change({ name: e.target.value }))} inFocus={ready}>
          <h2>Name</h2>
        </TextInput>
        <ToggleSwitchInput value={model.offMenu} onToggle={v => setModel(model.change({ offMenu: v }))}>
          <h2>Off-menu</h2>
        </ToggleSwitchInput>
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.result}>Save</PrimaryButton>
      </Footer>
      {!model.isNew && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditOriginalDishCategory };
