import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
`;

export const Main = styled.div`
`;

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
