import { IngredientItem } from 'models/entities/ingredient-item';

type Data = {
  target: IngredientItem[];
}

type DataToExport = {
  id: string;
  businessId: string;
  supplierId: string;
  categoryId: string;
  dishItemId: string;
  name: string;
  poundPrice: string;
  unitValue: string;
  unitSymbol: string;
  netPrice: string;
  yieldRate: string;
  createdAt: string;
  updatedAt: string;
};

const fields = ['id', 'businessId', 'supplierId', 'categoryId', 'dishItemId', 'name', 'poundPrice', 'unitValue', 'unitSymbol', 'netPrice', 'yieldRate', 'createdAt', 'updatedAt'];

class Model {

  readonly target: IngredientItem[];
  readonly tsv: string;
  readonly loading: boolean;

  constructor(data: Data) {
    this.target = data.target;
    this.tsv = this.export(this.target);
    this.loading = false;
  }

  private export(items: IngredientItem[]): string {
    const data = items.map(it => Object.values(this.convert(it)).join('\t'));
    return [fields.join('\t'), ...data].join('\n');
  }

  private convert(item: IngredientItem): DataToExport {
    const id = item.id!;
    const businessId = item.businessId;
    const supplierId = item.supplier?.id ?? '';
    const dishItemId = item.dishItem?.id ?? '';
    const categoryId = item.category.id!;
    const name = item.name;
    const unitValue = item.unitValue?.toString() ?? '';
    const unitSymbol = item.unitSymbol ?? '';
    const poundPrice = item.poundPrice?.toString() ?? '';
    const netPrice = item.netPrice?.toString() ?? '';
    const yieldRate = item.yieldRate.toString();
    const createdAt = item.createdAt?.toJSON() ?? '';
    const updatedAt = item.updatedAt?.toJSON() ?? '';
    return { id, businessId, supplierId, categoryId, dishItemId, name, poundPrice, unitValue, unitSymbol, netPrice, yieldRate, createdAt, updatedAt };
  }

}

export { Model };