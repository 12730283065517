import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, lightTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 2);
  color: ${lightTextColor};
  background-color: ${baseColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;