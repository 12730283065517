import React, { FC, useState, useEffect } from 'react';

import { EditInHouseIngredientItemTarget } from '../../../models/entities/target';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { showMoney } from 'views/services/helpers';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { InputField } from 'views/components/input-field';
import { OutputField } from 'views/components/output-field';
import { StringArrayInput, NumberInput } from 'views/components/inputs';
import { NumberOutput } from 'views/components/outputs';
import { CategoryArrayInput } from './views/category-array-input';
import { Root, Category, ValueAndSymbol, NetAndGross, Footer, MoreActions } from './index.styled';

interface Props {
  target: EditInHouseIngredientItemTarget;
  ready: boolean;
  background: boolean;
  finalize?: boolean;
}

const EditInHouseIngredientItem: FC<Props> = ({ target, ready, background, finalize = true }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ ...target }));

  function commit() {
    if (finalize) model.save().then(it => modal.close({ state: 'updated', target: target.apply({ ingredientItem: it.ingredientItem }) }));
    else modal.close({ state: 'edited', target: target.apply({ ingredientItem: model.ingredientItem }) });
  }

  function cancel() {
    modal.close({ state: 'canceled' });
  }

  function remove() {
    model.delete().then(it => modal.close({ state: 'deleted', target: target.apply({ ingredientItem: it.ingredientItem }) }));
  }

  function watchIngredientItem() {
    if (!finalize) return;
    if (model.relationships) return;
    if (!model.ingredientItem.original.id) return;
    model.readRelationships(model.ingredientItem.original.id).then(it => setModel(it));
  }

  useEffect(watchIngredientItem, [model.ingredientItem]);

  return (
    <Root>
      <h1>{model.ingredientItem.name.string}</h1>
      <fieldset>
        <Category>
          <InputField>
            <h2>Category</h2>
            <CategoryArrayInput field={model.ingredientItem.category} onEdit={category => setModel(model.edit({ category }))} focused={ready} />
          </InputField>
        </Category>
        <ValueAndSymbol>
          <InputField>
            <h2>Unit Number</h2>
            <NumberInput field={model.ingredientItem.unitValue} onEdit={unitValue => setModel(model.edit({ unitValue }))} />
          </InputField>
          <InputField>
            <h2>Unit Symbol</h2>
            <StringArrayInput field={model.ingredientItem.unitSymbol} onEdit={unitSymbol => setModel(model.edit({ unitSymbol }))} readOnly={false} />
          </InputField>
        </ValueAndSymbol>
        <NetAndGross>
          <OutputField>
            <h2>Net Price</h2>
            <NumberOutput>{showMoney(model.ingredientItem.original.netPrice, model.currency)}</NumberOutput>
          </OutputField>
          <InputField>
            <h2>Yield Rate</h2>
            <NumberInput field={model.ingredientItem.yieldRate} onEdit={yieldRate => setModel(model.edit({ yieldRate }))} />
          </InputField>
          <OutputField>
            <h2>Gross Price</h2>
            <NumberOutput>{showMoney(model.ingredientItem.grossPrice, model.currency)}</NumberOutput>
          </OutputField>
        </NetAndGross>
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => commit()} disabled={!model.ingredientItem.ok}>{finalize ? 'Save' : 'Done'}</PrimaryButton>
      </Footer>
      {(finalize && !model.isNew) && (
        <MoreActions>
          <div className={background ? 'modal-background' : ''}>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.ingredientItem.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditInHouseIngredientItem };
