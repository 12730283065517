import { Headquarters, HeadquartersData } from './index';

type Data = {
  documents: HeadquartersData[];
  nextToken: string;
};

class CompanyHeadquartersCollection {

  readonly companyId: string;
  readonly documents: Headquarters[];
  readonly nextToken: string;

  constructor(companyId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.companyId = companyId;
    this.documents = data.documents.map(it => new Headquarters(it));
    this.nextToken = data.nextToken;
  }

}

export { CompanyHeadquartersCollection };
export type { Data as CompanyHeadquartersCollectionData };