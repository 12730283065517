import React, { FC } from 'react';

import { Model } from '../../index.model';

import { SelectInput } from 'views/components/select-input';

interface Props {
  model: Model;
  onUpdate: (model: Model) => void;
  inFocus: boolean;
}

const StoreInput: FC<Props> = ({ model, onUpdate, inFocus }) => {

  const list = model.stores.map(it => it.name);
  const index = model.stores.findIndex(it => it.id === model.store?.id);

  function select(index: number) {
    onUpdate(model.change({ store: model.stores[index] }));
  }

  return (
    <SelectInput list={list} index={index} onSelect={i => select(i)} readOnly={true} inFocus={inFocus}>
      <h2>Store</h2>
    </SelectInput>
  );

};

export { StoreInput };