import { TargetObject } from '../../services/target-object';

type State = ''
  | 'canceled'
  | 'edited'
  | 'created'
  | 'updated'
  | 'updated-created'
  | 'updated-updated'
  | 'updated-deleted'
  | 'deleted'
  | 'imported'
  | 'chatted'
  ;

type Data = {
  state?: State;
  target?: TargetObject;
};

class Result {

  readonly state: State;
  readonly target?: TargetObject;

  constructor(data: Data = {}) {
    this.state = data.state || '';
    this.target = data.target;
  }

}

export { Result };
export type { Data as ResultData, State as ResultState };