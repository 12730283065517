import { SatelliteKitchen } from 'models/entities/satellite-kitchen';

import { CentralKitchen, CentralKitchenData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: CentralKitchenData[];
  nextToken: string;
};

class CentralKitchenCollection {

  readonly documents: CentralKitchen[];
  readonly nextToken: string;

  constructor(data: Data = { documents: [], nextToken: '' }) {
    this.documents = data.documents.map(it => new CentralKitchen(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const gql = await new ReadCollectionGql().fetch({ nextToken: collection.nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new CentralKitchenCollection(gql.result));
    }
    return collection;
  }

  merge(collection: CentralKitchenCollection): this {
    const documents = CentralKitchen.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(centralKitchen: CentralKitchen): this {
    const documents = CentralKitchen.sort([...this.documents, centralKitchen]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(centralKitchen: CentralKitchen): this {
    const documents = CentralKitchen.sort(this.documents.map(it => it.id === centralKitchen.id ? centralKitchen : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(centralKitchen: CentralKitchen): this {
    const documents = this.documents.filter(it => it.id !== centralKitchen.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  applySatelliteKitchen(satelliteKitchen: SatelliteKitchen): this {
    const centralKitchen = this.documents.find(it => it.id === satelliteKitchen.centralKitchenId);
    if (!centralKitchen) throw new Error('centralKitchen is undefined');
    return this.replace(centralKitchen.applySatelliteKitchen(satelliteKitchen));
  }

  removeSatelliteKitchen(satelliteKitchen: SatelliteKitchen): this {
    const centralKitchen = this.documents.find(it => it.id === satelliteKitchen.centralKitchenId);
    if (!centralKitchen) throw new Error('centralKitchen is undefined');
    return this.replace(centralKitchen.removeSatelliteKitchen(satelliteKitchen));
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(): Promise<CentralKitchenCollection> {
    const gql = await new ReadCollectionGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    return new CentralKitchenCollection(gql.result);
  }

}

export { CentralKitchenCollection };
export type { Data as CentralKitchenCollectionData };