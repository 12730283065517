import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight } from 'views/services/font-scheme';
import { baseColor, textColor, lightPrimaryColor, darkPrimaryColor, lightTextColor, darkTextColor, darkBaseColor } from 'views/services/color-scheme';

export const Root = styled.div`
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 16px;
`;

const Panel = styled.div`
  background-color: ${baseColor};
  border-radius: 8px;
  padding: 24px;
`;

export const LeftPanel = styled(Panel)`
  & > h1 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
  }
`;

export const RightPanel = styled(Panel)`
  & > hgroup {
    display: flex;
    align-items: end;
    font-family: ${baseFontFamily};
    & > h1 {
      font-size: calc(${baseFontSize} * 1.5);
      color: ${textColor};
    }
    & > p {
      margin-left: 16px;
      font-size: calc(${baseFontSize} * 1);
      color: ${lightTextColor};
    }
  }
`;

export const List = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  line-height: calc(${baseLineHeight} + 0.4);
  color: ${textColor};
  width: 560px;
  height: 480px;
  margin-top: 16px;
  border: 2px solid ${lightPrimaryColor};
  border-radius: 4px;
  background-color: ${darkBaseColor};
  overflow-y: scroll;
  &:hover {
    border-color: ${darkPrimaryColor};
  }
  & > ol {
    padding: 8px 0;
    & > li {
      padding: 0 16px;
      cursor: grab;
      user-select: none;
      &:hover {
        background-color: ${lightPrimaryColor};
      }
      &.dnd-grabbing, &.dnd-dragging {
        cursor: grabbing;
        &:hover {
          background-color: transparent;
        }
        &.dnd-target {
          background-color: ${lightPrimaryColor};
        }
      }
      &.disabled {
        color: ${darkTextColor};
      }
    }
  }
`;

export const Footer = styled.div`
  margin-top: 16px;
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  justify-content: center;
`;
