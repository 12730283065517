import { times } from 'lodash';
import dayjs from 'dayjs';

import { Month } from './month';
import { Day } from './day';

class Calendar {

  readonly months: Month[];

  constructor() {
    const months = 12 * 5;
    const tomorrow = dayjs().add(1, 'day');
    this.months = times(months).reverse().map(past => new Month({ first: tomorrow.subtract(past, 'month').startOf('month') }));
  }

  select(start: Day, end?: Day): this {
    const months = this.months.map(it => it.select(start, end));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, months });
  }

}

export { Calendar };