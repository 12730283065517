import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lineColor } from 'views/services/color-scheme';

export const Root = styled.article`
  width: 1024px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  flex: 0 0 auto;
  background-color: ${baseColor};
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid ${lineColor};
  padding: 0 16px;
  display: flex;
  align-items: center;
  & > h1 {
    flex: 1 1 auto;
    margin: 16px 0;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
  }
  & > nav {
    flex: 0 0 auto;
    font-size: calc(${baseFontSize} * 1);
  }
`;

export const Conversation = styled.div`
  flex: 1 0 80vh;
  background-color: ${darkBaseColor};
  overflow: scroll;
  & > ol {
    & > li {
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 1);
      line-height: 2;
      color: ${textColor};
      &:nth-child(odd) {
        background-color: ${darkBaseColor};
      }
      &:nth-child(even) {
        background-color: ${baseColor};
      }
    }
  }
`;

export const Message = styled.div`
  padding: 32px;
  display: flex;
  align-items: start;
`;

export const Role = styled.div`
  flex: 1;
  font-size: calc(${baseFontSize} * 1.25);
`;

export const Content = styled.div`
  flex: 11;
  white-space: pre-wrap;
`;

export const Footer = styled.footer`
  flex: 0 0 auto;
  font-size: calc(${baseFontSize} * 1);
`;

