import { Business } from 'models/entities/business';
import { IngredientCategory, IngredientCategoryCollection } from 'models/entities/ingredient-category';
import { OriginalIngredientCategory, OriginalIngredientCategoryCollection } from 'models/entities/original-ingredient-category';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  target: Business;
  originalCategories: OriginalIngredientCategoryCollection;
};

class Model {

  readonly target: Business;
  readonly categories: IngredientCategoryCollection;
  readonly originalCategories: OriginalIngredientCategoryCollection;
  readonly originalCategoryList: OriginalIngredientCategory[];
  readonly relationship: Set<string>;
  readonly result: Business;

  constructor(data: Data) {
    this.target = data.target;
    this.categories = this.target.ingredientCategories;
    this.originalCategories = data.originalCategories;
    this.originalCategoryList = this.getOriginalCategoryList(this.originalCategories, this.categories);
    this.relationship = new Set();
    this.result = this.target;
  }

  async readAllCategories(): Promise<this> {
    const categories = await this.categories.readAll();
    const originalCategoryList = this.getOriginalCategoryList(this.originalCategories, categories);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, categories, originalCategoryList });
  }

  async readAllOriginalCategories(): Promise<this> {
    const originalCategories = await this.originalCategories.readAll();
    const originalCategoryList = this.getOriginalCategoryList(originalCategories, this.categories);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, originalCategories, originalCategoryList });
  }

  async readRelationshipCollections(): Promise<this> {
    const collections = await Promise.all(this.categories.documents.map(it => RelationshipCollection.readByCategoryId(it.id!, 1)));
    const relationship = new Set(this.categories.documents.map((it, i) => collections[i].documents.length ? it.id : ''));
    relationship.delete('');
    return Object.assign(Object.create(this.constructor.prototype), { ...this, relationship });
  }

  build(originalCategory: OriginalIngredientCategory): IngredientCategory {
    const businessId = this.target.id!;
    const original = originalCategory;
    return IngredientCategory.buildNext(this.categories.documents, { businessId, original });
  }

  buildBetween(prev: IngredientCategory | undefined, next: IngredientCategory | undefined, originalCategory: OriginalIngredientCategory): IngredientCategory {
    const businessId = this.target.id!;
    const original = originalCategory;
    return IngredientCategory.buildBetween(prev, next, { businessId, original })
  }

  add(category: IngredientCategory, done: boolean = false): this {
    return this.apply(this.categories.add(category), done);
  }

  remove(category: IngredientCategory, done: boolean = false): this {
    return this.apply(this.categories.remove(category), done);
  }

  merge(category: IngredientCategory, done: boolean = false): this {
    if (!category.id) return this.add(category);
    return this.apply(this.categories.replace(category), done);
  }

  private apply(categories: IngredientCategoryCollection, done: boolean = false): this {
    const originalCategoryList = this.getOriginalCategoryList(this.originalCategories, categories);
    const result = done ? this.result.edit({ ingredientCategories: categories }) : this.result;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, categories, originalCategoryList, result });
  }

  private getOriginalCategoryList(originalCategories: OriginalIngredientCategoryCollection, categories: IngredientCategoryCollection): OriginalIngredientCategory[] {
    const ids = categories.documents.map(it => it.original.id);
    return originalCategories.documents.filter(it => !ids.includes(it.id));
  }

}

export { Model };