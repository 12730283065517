import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const BrandsMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/brands" name="Brands" {...props} />
  );
};

export { BrandsMenu };
