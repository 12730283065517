type Data = {
  x: number;
  y: number;
};

class Point {

  readonly x: number;
  readonly y: number;

  constructor(data: Data = { x: 0, y: 0 }) {
    this.x = data.x;
    this.y = data.y;
  }

  update(data: Data): this {
    const { x, y } = data;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, x, y });
  }

  reset(): this {
    return this.update({ x: 0, y: 0 });
  }

}

export { Point };