import { CacheTarget } from './cache-target';

import { Business, BusinessData, BusinessCollection, BusinessCollectionData } from 'models/entities/business';

type Data = {
  business: Business | BusinessData;
  businesses: BusinessCollection | BusinessCollectionData;
};

class BusinessScope extends CacheTarget {

  static readonly key = 'business-scope';

  readonly business: Business;
  readonly businesses: BusinessCollection;

  constructor(data: Data) {
    super();
    this.business = data.business instanceof Business ? data.business : new Business(data.business);
    this.businesses = data.businesses instanceof BusinessCollection ? data.businesses : new BusinessCollection(data.businesses);
  }

  get key(): string {
    return BusinessScope.key;
  }

  toJSON(): Data {
    const business = this.business.toJSON();
    const businesses = this.businesses.toJSON();
    return { business, businesses };
  }

}

export { BusinessScope };
export type { Data as BusinessScopeData };