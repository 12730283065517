import dayjs, { Dayjs } from 'dayjs';

import { BrandIdentity } from 'models/entities/brand-identity';
import { Headquarters, HeadquartersData } from 'models/entities/headquarters';
import { Franchisor } from 'models/entities/franchisor';
import { Franchisee } from 'models/entities/franchisee';
import { Store } from 'models/entities/store';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  name?: string;
  headquarters?: HeadquartersData | Headquarters;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  name?: string;
};

type DataToSave = {
  name: string;
};

class Brand {

  readonly id: string;
  readonly name: string;
  readonly headquarters?: Headquarters;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly organizationIds: Set<string>;

  constructor(data: Data = {}) {
    this.id = data.id ?? '';
    this.name = data.name ?? '';
    this.headquarters = data.headquarters ? data.headquarters instanceof Headquarters ? data.headquarters : new Headquarters(data.headquarters) : undefined;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.organizationIds = this.headquarters?.organizationIds ?? new Set();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<Brand> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<Brand> {
    const gql = await new CreateGql().fetch({ input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Brand(gql.document);
  }

  private async update(): Promise<Brand> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Brand(gql.document);
  }

  private getDataToSave(): DataToSave {
    const { name } = this;
    return { name };
  }

  async delete(): Promise<Brand> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new DeleteGql().fetch({ id: this.id });
    if (!gql.document) throw new Error('invalid document');
    return new Brand(gql.document);
  }

  has(organizationId: string): boolean {
    return this.organizationIds.has(organizationId);
  }

  applyHeadquarters(headquarters: Headquarters): this {
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  removeHeadquarters(headquarters: Headquarters): this {
    if (this.headquarters && this.headquarters.id !== headquarters.id) throw new Error('invalid headquarters');
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters: undefined, organizationIds: new Set() });
  }

  applyFranchisor(franchisor: Franchisor): this {
    if (!this.headquarters) throw new Error('headquarters is undefined');
    const headquarters = this.headquarters.applyFranchisor(franchisor);
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  removeFranchisor(franchisor: Franchisor): this {
    if (!this.headquarters) throw new Error('headquarters is undefined');
    const headquarters = this.headquarters.removeFranchisor(franchisor);
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  applyFranchisee(franchisee: Franchisee): this {
    if (!this.headquarters) throw new Error('headquarters is undefined');
    const headquarters = this.headquarters.applyFranchisee(franchisee);
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  removeFranchisee(franchisee: Franchisee): this {
    if (!this.headquarters) throw new Error('headquarters is undefined');
    const headquarters = this.headquarters.removeFranchisee(franchisee);
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  applyStore(store: Store): this {
    if (!this.headquarters) throw new Error('headquarters is undefined');
    const headquarters = this.headquarters.applyStore(store);
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  removeStore(store: Store): this {
    if (!this.headquarters) throw new Error('headquarters is undefined');
    const headquarters = this.headquarters.removeStore(store);
    const organizationIds = headquarters.organizationIds;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, headquarters, organizationIds });
  }

  getIdentity(): BrandIdentity {
    const { id, name, createdAt, updatedAt } = this;
    return new BrandIdentity({ id, name, createdAt, updatedAt });
  }

  getStores(): Store[] {
    return this.headquarters?.getStores() ?? [];
  }

  toJSON(): Data {
    const { id, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, name, createdAt, updatedAt };
  }

  static sort(businesses: Brand[]) {
    function orderByOrder(a: Brand, b: Brand) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { Brand };
export type {
  Data as BrandData,
  DataToSave as BrandDataToSave,
};

export * from './collection';