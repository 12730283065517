import { Api } from 'models/services/api';
import { DeleteFranchisorQuery } from 'models/services/graphql';

import { FranchisorData } from '../index';

type DataToDelete = {
  id: string;
};

const RequestQuery = `
  mutation DeleteFranchisor($id: ID!) {
    ${DeleteFranchisorQuery}
  }
`;

type ResponseData = {
  data: {
    deleteFranchisor: FranchisorData
  }
}

class DeleteGql {

  readonly api: Api<ResponseData>;
  readonly document?: FranchisorData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToDelete): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.deleteFranchisor;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { DeleteGql };