import { Business } from 'models/entities/business';
import { BusinessCategory, BusinessCategoryCollection } from 'models/entities/business-category';
import { PosSystem, PosSystemCollection } from 'models/entities/pos-system';
import { RelationshipCollection } from 'models/entities/relationship';
import { Currency } from 'models/value-objects/currency';

type Data = {
  target: Business;
  categories: BusinessCategoryCollection;
  posSystems: PosSystemCollection;
};

type DataToChange = {
  category?: BusinessCategory;
  posSystem?: PosSystem;
  name?: string;
  location?: string;
  code?: string;
  currency?: Currency;
  closed?: boolean;
};

class Model {

  readonly target: Business;
  readonly isNew: boolean;
  readonly canDelete: boolean;
  readonly categories: BusinessCategoryCollection;
  readonly posSystems: PosSystemCollection;
  readonly currencies: Currency[];

  readonly category?: BusinessCategory
  readonly posSystem?: PosSystem
  readonly name: string;
  readonly location: string;
  readonly code: string;
  readonly currency: Currency;
  readonly closed: boolean;

  readonly result?: Business;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.canDelete = false;
    this.categories = data.categories;
    this.posSystems = data.posSystems;
    this.currencies = Currency.all();
    this.category = this.target.category;
    this.posSystem = this.target.posSystem;
    this.name = this.target.name;
    this.location = this.target.location;
    this.code = this.target.code;
    this.currency = this.target.currency;
    this.closed = this.target.closed;
  }

  async readRelationships(businessId: string): Promise<this> {
    const relationships = await RelationshipCollection.readByBusinessId(businessId, 1);
    const canDelete = !relationships.documents.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, canDelete });
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.category) return false;
    if (!data.posSystem) return false;
    if (!data.name) return false;
    if (!data.location) return false;
    if (!data.code) return false;
    if (!data.currency) return false;
    return true;
  }

  private build(data: DataToChange): Business {
    const category = data.category;
    const posSystem = data.posSystem;
    const name = data.name;
    const location = data.location;
    const code = data.code;
    const currency = data.currency;
    const closed = data.closed;
    return this.target.edit({ category, posSystem, name, location, code, currency, closed });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };