import { Model } from './model';
import { Message } from './message';
import { CompletionStream } from './completion';

type Turn = 'user' | 'assistant';

class OpenaiChat {

  readonly model: Model;
  readonly messages: Message[];
  readonly conversation: Message[];
  readonly turn: Turn;

  constructor(content?: string) {
    this.model = new Model();
    this.messages = content ? [new Message({ role: 'system', content })] : [];
    this.conversation = this.getConversation(this.messages);
    this.turn = 'user';
  }

  post(message: Message): this {
    const messages = [...this.messages, message];
    const conversation = this.getConversation(messages);
    const turn = 'assistant';
    return Object.assign(Object.create(this.constructor.prototype), { ...this, messages, conversation, turn });
  }

  receive(completion: CompletionStream): this {
    if (!completion.finished) throw new Error('not finished');
    const messages = [...this.messages, ...completion.messages!];
    const conversation = this.getConversation(messages);
    const turn = 'user';
    return Object.assign(Object.create(this.constructor.prototype), { ...this, messages, conversation, turn });
  }

  private getConversation(messages: Message[]): Message[] {
    return messages.filter(it => it.role.id !== 'system');
  }

}

export { OpenaiChat };
