import React, { FC, useState } from 'react';

import { DishItemReport } from 'models/entities/dish-item-report';
import { Business } from 'models/entities/business';
import { MenuCategory } from 'models/entities/menu-category';
import { DishCategory } from 'models/entities/dish-category';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { showMoney, showPercentage } from 'views/services/helpers';
import { SecondaryButton } from 'views/components/buttons';
import { Root, Report, Footer } from './index.styled';

interface Props {
  target: DishItemReport;
  business: Business;
  menuCategory: MenuCategory;
  category?: DishCategory;
}

const ShowDishItemReport: FC<Props> = ({ target, business, menuCategory, category }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, business, menuCategory, category }));

  function close() {
    modal.close();
  }

  return (
    <Root>
      <h1>{`${model.menuCategory.original.name} / ${model.category ? model.category.original.name : 'All'}`}</h1>
      <div>
        <Report>
          <table>
            <thead>
            <tr>
              <th>Item</th>
              <th className="number">Price</th>
              <th className="number">Cost Rate</th>
              <th className="number">Cost</th>
              <th className="number">Total</th>
              <th className="number">Product Cost</th>
              <th className="number">Product Sales</th>
            </tr>
            </thead>
            <tbody>
            {model.records.map((it, i) => (
              <tr key={i}>
                <td>{it.item.name}</td>
                <td className="number">{showMoney(it.item.price, model.business.currency)}</td>
                <td className="number">{showPercentage(it.item.costRate * 100)} %</td>
                <td className="number">{showMoney(it.item.cost, model.business.currency)}</td>
                <td className="number">{it.term3Total}</td>
                <td className="number">{showMoney(it.productCost, model.business.currency)}</td>
                <td className="number">{showMoney(it.productSales, model.business.currency)}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </Report>
      </div>
      <Footer>
        <SecondaryButton onClick={() => close()}>Close</SecondaryButton>
      </Footer>
    </Root>
  );

};

export { ShowDishItemReport };
