import { DishItemEditor, DishItemEditorDataToApply } from 'models/entities/dish-item';
import { InHouseIngredientItemEditor, InHouseIngredientItem } from 'models/entities/ingredient-item';
import { Business } from 'models/entities/business';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';

type Data = {
  dishItem: DishItemEditor;
  business: Business;
  ingredientCategories: IngredientCategoryCollection;
  unitSymbols: string[];
};

type ButtonStatus = {
  show: boolean;
  disabled?: boolean;
};

class Model {

  readonly dishItem: DishItemEditor;
  readonly business: Business;
  readonly ingredientCategories: IngredientCategoryCollection;
  readonly unitSymbols: string[];

  readonly shouldEdit: boolean;
  readonly shouldDisconnect: boolean;
  readonly shouldReconnect: boolean;
  readonly disabled: boolean;
  readonly editButton: ButtonStatus;
  readonly editedButton: ButtonStatus;

  constructor(data: Data) {
    this.dishItem = data.dishItem;
    this.business = data.business;
    this.ingredientCategories = data.ingredientCategories;
    this.unitSymbols = data.unitSymbols;
    this.shouldEdit = this.getShouldEdit(this.dishItem);
    this.shouldDisconnect = this.getShouldDisconnect(this.dishItem);
    this.shouldReconnect = this.getShouldReconnect(this.dishItem);
    this.disabled = this.getDisabled(this.dishItem);
    this.editButton = this.getEditButton(this.dishItem);
    this.editedButton = this.getEditedButton(this.dishItem);
  }

  load(dishItem: DishItemEditor): this {
    const shouldEdit = this.getShouldEdit(dishItem);
    const shouldDisconnect = this.getShouldDisconnect(dishItem);
    const shouldReconnect = this.getShouldReconnect(dishItem);
    const disabled = this.getDisabled(dishItem);
    const editButton = this.getEditButton(dishItem);
    const editedButton = this.getEditedButton(dishItem);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishItem, shouldEdit, shouldDisconnect, shouldReconnect, disabled, editButton, editedButton });
  }

  edit(data: DishItemEditorDataToApply): this {
    const dishItem = this.dishItem.apply(data);
    return this.load(dishItem);
  }

  build(): InHouseIngredientItemEditor {
    const original = new InHouseIngredientItem({ businessId: this.business.id!, category: this.ingredientCategories.documents[0], dishItem: this.dishItem.rebuild() });
    return new InHouseIngredientItemEditor({ original, categories: this.ingredientCategories, unitSymbols: this.unitSymbols });
  }

  cancel(): this {
    if (this.dishItem.ingredientItem) return this;
    const isIngredientItem = this.dishItem.isIngredientItem.change(false);
    return this.edit({ isIngredientItem });
  }

  private getShouldEdit(dishItem: DishItemEditor): boolean {
    if (!dishItem.original.ingredientItemId && dishItem.isIngredientItem.boolean && !dishItem.ingredientItem) return true;
    return false;
  }

  private getShouldDisconnect(dishItem: DishItemEditor): boolean {
    if (dishItem.original.ingredientItemId && !dishItem.isIngredientItem.boolean && dishItem.ingredientItem) return true;
    return false;
  }

  private getShouldReconnect(dishItem: DishItemEditor): boolean {
    if (dishItem.original.ingredientItemId && dishItem.isIngredientItem.boolean && dishItem.ingredientItem) return true;
    return false;
  }

  private getDisabled(dishItem: DishItemEditor): boolean {
    if (!dishItem.canDelete) return true;
    if (dishItem.ingredients.isMaxDepth) return true;
    return false;
  }

  private getEditButton(dishItem: DishItemEditor): ButtonStatus {
    return {
      show: this.getEditButtonShow(dishItem),
    };
  }

  private getEditedButton(dishItem: DishItemEditor): ButtonStatus {
    return {
      show: this.getEditedButtonShow(dishItem),
      disabled: this.getEditedButtonDisabled(dishItem),
    };
  }

  private getEditButtonShow(dishItem: DishItemEditor): boolean {
    if (dishItem.original.ingredientItemId && !dishItem.ingredientItem) return true;
    if (dishItem.ingredientItem && !dishItem.ingredientItem.dirty && dishItem.isIngredientItem.boolean) return true;
    return false;
  }

  private getEditedButtonShow(dishItem: DishItemEditor): boolean {
    if (dishItem.ingredientItem && dishItem.ingredientItem.dirty) return true;
    return false;
  }

  private getEditedButtonDisabled(dishItem: DishItemEditor): boolean {
    if (!dishItem.isIngredientItem.boolean) return true;
    return false;
  }

}

export { Model };