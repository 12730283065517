import { Dayjs } from 'dayjs';

import { Period } from 'models/value-objects/period';

type Data = {
  period: Period;
};

class Model {

  readonly period: Period;

  constructor(data: Data) {
    this.period = data.period;
  }

  pick(start: Dayjs, end?: Dayjs): this {
    const period = this.period.apply({ start, end: end ?? start });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, period });
  }

}

export { Model };