import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';

import { OriginalIngredientCategory, OriginalIngredientCategoryData } from 'models/entities/original-ingredient-category';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  businessId: string;
  original: OriginalIngredientCategoryData | OriginalIngredientCategory;
  order: string | LexoRank;
  createdAt?: string;
  updatedAt?: string;
};

type DataToBuild = {
  businessId: string;
  original: OriginalIngredientCategory;
};

type DataToCreate = {
  originalId: string;
  order: string;
};

type DataToUpdate = {
  order: string;
};

type Prev = IngredientCategory | undefined;
type Next = IngredientCategory | undefined;

class IngredientCategory {

  readonly id: string;
  readonly businessId: string;
  readonly original: OriginalIngredientCategory;
  readonly order: LexoRank;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  private readonly createGql: CreateGql;
  private readonly updateGql: UpdateGql;
  private readonly deleteGql: DeleteGql;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.businessId = data.businessId;
    this.original = data.original instanceof OriginalIngredientCategory ? data.original : new OriginalIngredientCategory(data.original);
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.createGql = new CreateGql();
    this.updateGql = new UpdateGql();
    this.deleteGql = new DeleteGql();
  }

  moveBetween(prev: Prev, next: Next): this {
    const order = IngredientCategory.getOrderBetween(prev, next);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, order });
  }

  async save(): Promise<IngredientCategory> {
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<IngredientCategory> {
    const createGql = await this.createGql.fetch({ businessId: this.businessId, input: this.getDataToCreate() });
    if (!createGql.document) throw new Error('invalid document');
    return new IngredientCategory(createGql.document);
  }

  private async update(): Promise<IngredientCategory> {
    if (!this.id) throw new Error('invalid relationship');
    const updateGql = await this.updateGql.fetch({ id: this.id, input: this.getDataToUpdate() });
    if (!updateGql.document) throw new Error('invalid document');
    return new IngredientCategory(updateGql.document);
  }

  private getDataToCreate(): DataToCreate {
    const { original, order } = this;
    return { originalId: original.id!, order: order.toString() };
  }

  private getDataToUpdate(): DataToUpdate {
    const { order } = this;
    return { order: order.toString() };
  }

  async delete(): Promise<IngredientCategory> {
    if (!this.id) throw new Error('invalid relationship');
    const deleteGql = await this.deleteGql.fetch({ id: this.id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new IngredientCategory(deleteGql.document);
  }

  toJSON(): Data {
    const { id, businessId } = this;
    const original = this.original.toJSON();
    const order = this.order.toString();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, businessId, original, order, createdAt, updatedAt };
  }

  static sort(categories: IngredientCategory[]) {
    function orderByOrder(a: IngredientCategory, b: IngredientCategory) {
      if (a.order.toString() < b.order.toString()) return -1;
      else if (a.order.toString() > b.order.toString()) return 1;
      else return 0;
    }
    return categories.sort(orderByOrder);
  }

  static buildNext(list: IngredientCategory[], data: DataToBuild): IngredientCategory {
    const order = list[list.length - 1]?.order.genNext() ?? LexoRank.middle();
    return new IngredientCategory({ ...data, order });
  }

  static buildBetween(prev: Prev, next: Next, data: DataToBuild): IngredientCategory {
    const order = IngredientCategory.getOrderBetween(prev, next);
    return new IngredientCategory({ ...data, order });
  }

  private static getOrderBetween(prev: Prev, next: Next): LexoRank {
    switch (true) {
      case !prev && !next: return LexoRank.middle();
      case !prev && !!next: return next!.order.genPrev();
      case !!prev && !next: return prev!.order.genNext();
      default: return prev!.order.between(next!.order);
    }
  }

}

export { IngredientCategory };
export type {
  Data as IngredientCategoryData,
  DataToCreate as IngredientCategoryDataToCreate,
  DataToUpdate as IngredientCategoryDataToUpdate,
};

export * from './collection';
export * from './editor-field';
