import React, { FC, useState } from 'react';

import { PasswordField } from 'models/value-objects/editor-field';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { useAuth } from 'views/services/auth';
import { InputField } from 'views/components/input-field';
import { StringInput } from 'views/components/inputs';
import { PrimaryButton, SecondaryButton } from 'views/components/buttons';
import { Root, Header, Alert, Content, Footer } from './index.styled';

interface Props {
  ready: boolean;
}

const EditPassword: FC<Props> = ({ ready }) => {

  const modal = useModal();
  const auth = useAuth();
  const [model, setModel] = useState(new Model());

  function save() {
    if (!model.ok) throw new Error('invalid passwords');
    auth.changePassword(model.oldPassword.string!, model.newPassword.string!)
    .then(() => modal.close())
    .catch(error => setModel(model.alert(error)));
  }

  function cancel() {
    modal.close();
  }

  return (
    <Root>
      <Header>
        <h1>Password</h1>
      </Header>
      {model.message && <Alert><p>{model.message}</p></Alert>}
      <Content>
        <fieldset>
          <InputField>
            <h2>Current Password</h2>
            <StringInput field={model.oldPassword} onEdit={field => setModel(model.edit({ oldPassword: field }))} secure={true} focused={ready} />
          </InputField>
          <InputField>
            <h2>New Password</h2>
            <StringInput field={model.newPassword} onEdit={field => setModel(model.edit({ newPassword: field as PasswordField }))} secure={true} />
          </InputField>
        </fieldset>
      </Content>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.ok}>Save</PrimaryButton>
      </Footer>
    </Root>
  );

};

export { EditPassword };
