import { Api } from 'models/services/api';
import { ReadRelationshipsByParentIdQuery, ReadRelationshipsByOwnerIdQuery } from 'models/services/graphql';

import { RelationshipCollectionData } from '../collection';
import { ParentAndOwnerCollectionsData } from '../parent-and-owner-collections';

type DataToFetch = {
  parentId: string;
  ownerId: string;
  limit?: number;
};

const RequestQuery = `
  query ReadParentAndOwnerRelationships($parentId: ID!, $ownerId: ID!, $limit: Int, $nextToken: String) {
    ${ReadRelationshipsByParentIdQuery}
    ${ReadRelationshipsByOwnerIdQuery}
  }
`;

type ResponseData = {
  data: {
    readRelationshipsByParentId: RelationshipCollectionData;
    readRelationshipsByOwnerId: RelationshipCollectionData;
  }
}

class ReadParentAndOwnerCollectionsGql {

  readonly result?: ParentAndOwnerCollectionsData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const parentId = api.response.data.readRelationshipsByParentId;
    const ownerId = api.response.data.readRelationshipsByOwnerId;
    const result = { parentId, ownerId };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadParentAndOwnerCollectionsGql };
