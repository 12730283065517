import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, darkSecondaryColor, vividSecondaryColor, secondaryTextColor, secondaryLineColor } from 'views/services/color-scheme';

export const Root = styled.article`
  background-color: ${baseColor};
  border-radius: 8px;
  padding: 32px;
  & > h1 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
`;

const Form = styled.div`
  margin-top: 32px;
  width: 520px;
  & > h2 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    color: ${textColor};
  }
  & > fieldset {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
  }
`;

export const Company = styled(Form)`
  & > fieldset {
    & > div {
      &:nth-child(1) {
        grid-column: 1 / span 12;
        grid-row: 1;
      }
      &:nth-child(2) {
        grid-column: 1 / span 6;
        grid-row: 2;
      }
      &:nth-child(3) {
        grid-column: 7 / span 6;
        grid-row: 2;
      }
      &:nth-child(4) {
        grid-column: 1 / span 4;
        grid-row: 3;
      }
      &:nth-child(5) {
        grid-column: 5 / span 4;
        grid-row: 3;
      }
      &:nth-child(6) {
        grid-column: 9 / span 4;
        grid-row: 3;
      }
      &:nth-child(7) {
        grid-column: 1 / span 6;
        grid-row: 4;
      }
      &:nth-child(8) {
        grid-column: 7 / span 6;
        grid-row: 4;
      }
    }
  }
`;

export const Pic = styled(Form)`
  & > fieldset {
    & > div {
      &:nth-child(1) {
        grid-column: 1 / span 12;
        grid-row: 1;
      }
      &:nth-child(2) {
        grid-column: 1 / span 6;
        grid-row: 2;
      }
      &:nth-child(3) {
        grid-column: 7 / span 6;
        grid-row: 2;
      }
    }
  }
`;

export const Other = styled(Form)`
  & > fieldset {
    & > div {
      &:nth-child(1) {
        grid-column: 1 / span 12;
        grid-row: 1;
      }
    }
  }
`;

export const Footer = styled.footer`
  margin-top: 48px;
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const MoreActions = styled.div`
  position: absolute;
  bottom: 24px;
  left: calc(100% + 8px);
  & > div {
    background-color: ${baseColor};
    font-size: calc(${baseFontSize} * 1.25);
    border-radius: 50%;
  }
`;
