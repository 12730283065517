export const RelationshipFieldsQuery = `
  id
`;

export const RelationshipCollectionQuery = `
  documents {
    ${RelationshipFieldsQuery}
  }
  nextToken
`;

export const ReadRelationshipsByParentIdQuery = `
  readRelationshipsByParentId(parentId: $parentId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsByOwnerIdQuery = `
  readRelationshipsByOwnerId(ownerId: $ownerId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsByCategoryIdQuery = `
  readRelationshipsByCategoryId(categoryId: $categoryId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsByOriginalIdQuery = `
  readRelationshipsByOriginalId(originalId: $originalId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsByBusinessIdQuery = `
  readRelationshipsByBusinessId(businessId: $businessId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsByMenuCategoryIdQuery = `
  readRelationshipsByMenuCategoryId(menuCategoryId: $menuCategoryId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsBySupplierIdQuery = `
  readRelationshipsBySupplierId(supplierId: $supplierId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

export const ReadRelationshipsByItemIdQuery = `
  readRelationshipsByItemId(itemId: $itemId, limit: $limit, nextToken: $nextToken) { 
    ${RelationshipCollectionQuery}
  }
`;

