import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { lightTextColor, darkTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  & > label {
    position: relative;
    display: block;
    cursor: pointer;
    & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 1);
      color: ${lightTextColor};
      margin-bottom: 8px;
    }
    &.disabled {
      & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
        color: ${darkTextColor};
      }
    }
  }
`;