import { Credentials } from 'models/value-objects/credentials';
import { CurrentUser } from 'models/entities/current-user';

interface AuthContextInterface {
  ok: undefined | boolean;
  user: CurrentUser;
  signInWithGoogle: () => Promise<void>;
  signIn: (credentials: Credentials) => Promise<CurrentUser>;
  createPassword: (password: string) => Promise<CurrentUser>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  signOut: () => Promise<CurrentUser>;
  apply: (user: CurrentUser) => void;
}

const defaultInterface = {
  ok: undefined,
  user: new CurrentUser(),
  signInWithGoogle: () => {},
  signIn: () => {},
  createPassword: () => {},
  changePassword: () => {},
  signOut: () => {},
  apply: () => {},
};

export type { AuthContextInterface };
export { defaultInterface };