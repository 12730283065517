import { StringField } from 'models/value-objects/editor-field';

type Data = {
  field: StringField;
  alt?: string;
  secure?: boolean;
};

class Model {

  readonly field: StringField;
  readonly value: string;
  readonly alt?: string;
  readonly isAlt: boolean;
  readonly hiding: boolean;

  constructor(data: Data) {
    this.field = data.field;
    this.value = this.field.value;
    this.alt = data.alt;
    this.isAlt = this.getIsAlt(this.field);
    this.hiding = data.secure ?? false;
  }

  change(value: string): this {
    const field = this.field.change(this.getAlt(value));
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  renew(field: StringField): this {
    const value = field.value;
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  toggle(): this {
    const hiding = !this.hiding;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, hiding });
  }

  private getIsAlt(field: StringField): boolean {
    return field.string === this.alt;
  }

  private getAlt(value: string): string {
    if (!value) return this.alt ?? '';
    return value;
  }

}

export { Model };
