import React, { FC, PropsWithChildren, useState, useRef, useEffect, UIEvent } from 'react';

import { Model } from './index.model';

import { ChevronLeftIcon, ChevronRightIcon } from 'views/components/icons'
import { Root, Frame, Content, LeftArrow, RightArrow } from './index.styled';

interface Props extends PropsWithChildren {
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
}

const XScrollableArea: FC<Props> = ({ children, onScroll }) => {

  const target = useRef<HTMLDivElement>(null);
  const [model, setModel] = useState(new Model());
  const intervalId = useRef<NodeJS.Timer>();

  function handleScroll(e: UIEvent<HTMLDivElement>) {
    parseScroll();
    onScroll && onScroll(e);
  }

  function parseScroll() {
    if (!target.current) return;
    const scrollLeft = target.current.scrollLeft;
    const frame = target.current.getBoundingClientRect();
    const content = target.current.children[0].getBoundingClientRect();
    const scrollRight = Math.round(content.width - frame.width) - scrollLeft;
    if (!model.farLeft && scrollLeft <= 0) setModel(model.edit({ farLeft: true }));
    else if (model.farLeft && scrollLeft > 0) setModel(model.edit({ farLeft: false }));
    if (!model.farRight && scrollRight <= 0) setModel(model.edit({ farRight: true }));
    else if (model.farRight && scrollRight > 0) setModel(model.edit({ farRight: false }));
  }

  function startScrolling() {
    if (!target.current) return;
    const scrollLeft = target.current.scrollLeft;
    const frame = target.current.getBoundingClientRect();
    const content = target.current.children[0].getBoundingClientRect();
    const scrollRight = Math.round(content.width - frame.width) - scrollLeft;
    const scrollToLeft = () => target.current && scrollLeft > 0 ? target.current.scrollBy(-1, 0) : stopScrolling();
    const scrollToRight = () => target.current && scrollRight > 0 ? target.current.scrollBy(1, 0) : stopScrolling();
    stopScrolling();
    if (!model.farLeft) intervalId.current = setInterval(() => scrollToLeft(), 0);
    else if (!model.farRight) intervalId.current = setInterval(() => scrollToRight(), 0);
  }

  function stopScrolling() {
    if (!intervalId.current) return;
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  }

  function watchContent() {
    parseScroll();
  }

  useEffect(watchContent, [(target.current?.children[0] as HTMLDivElement)?.offsetWidth])

  return (
    <Root>
      <Frame ref={target} onScroll={handleScroll}>
        <Content>
          {children}
        </Content>
      </Frame>
      <LeftArrow className={model.farLeft ? 'hidden' : ''} onMouseEnter={() => startScrolling()} onMouseLeave={() => stopScrolling()}><ChevronLeftIcon /></LeftArrow>
      <RightArrow className={model.farRight ? 'hidden' : ''} onMouseEnter={() => startScrolling()} onMouseLeave={() => stopScrolling()}><ChevronRightIcon /></RightArrow>
    </Root>
  );

};

export { XScrollableArea };