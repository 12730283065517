import React, { FC, useState } from 'react';

import { PasswordField } from 'models/value-objects/editor-field';
import { Step } from '../../index.model';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { StringInput } from 'views/components/inputs';
import { Root } from './index.styled';

interface Props {
  current: Step;
  ready: Step;
}

const Step3: FC<Props> = ({ current, ready }) => {

  const auth = useAuth();
  const [model, setModel] = useState(new Model());

  function createPassword() {
    auth.createPassword(model.newPassword.string!)
    .catch(error => { throw new Error(error); });
  }

  return (
    <Root>
      <fieldset disabled={current !== 'step3'}>
        <div>New Password</div>
        <ol>
          <li><StringInput field={model.newPassword} onEdit={field => setModel(model.edit({ newPassword: field as PasswordField }))} focused={ready === 'step3'} secure={true} /></li>
          <li><button onClick={() => createPassword()} disabled={!model.ok}>Save</button></li>
        </ol>
      </fieldset>
    </Root>
  );

};

export { Step3 };