type Data = {
  list: string[];
  string?: string;
  required?: boolean;
};

class StringArrayField {

  readonly list: string[];
  readonly string?: string;
  readonly dirt?: string;
  readonly value: string;
  readonly index: number;
  readonly required: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;
  readonly error: string;

  constructor(data: Data) {
    this.list = data.list;
    this.string = data.string;
    this.value = this.string ?? '';
    this.index = this.string && this.list.find(it => it === this.string) ? this.list.findIndex(it => it === this.string) : -1;
    this.required = data.required ?? true;
    this.dirty = false;
    this.ok = this.check(this.value);
    this.error = this.getError(this.value);
  }

  change(input: string | number): this {
    const { value, index } = this.getValueAndIndex(input);
    const string = value;
    const dirt = string;
    const dirty = true;
    const ok = this.check(value);
    const error = this.getError(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, value, index, string, dirt, dirty, ok, error });
  }

  private getValueAndIndex(input: string | number): { value: string, index?: number } {
    return typeof input === 'number' ? { value: this.list[input], index: input } : { value: input, index: -1 };
  }

  private check(value: string): boolean {
    if (this.required && !value) return false;
    return true;
  }

  private getError(value: string): string {
    if (value === '') return '';
    return '';
  }

}

export { StringArrayField };