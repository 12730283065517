import { Franchisor, FranchisorData } from './index';

type Data = {
  documents: FranchisorData[];
  nextToken: string;
};

class CompanyFranchisorCollection {

  readonly companyId: string;
  readonly documents: Franchisor[];
  readonly nextToken: string;

  constructor(companyId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.companyId = companyId;
    this.documents = data.documents.map(it => new Franchisor(it));
    this.nextToken = data.nextToken;
  }

}

export { CompanyFranchisorCollection };
export type { Data as CompanyFranchisorCollectionData };