import { CompanyFieldsQuery } from '../company';
import { SatelliteKitchenCollectionQuery } from '../satellite-kitchen';

export const CentralKitchenFieldsQuery = `
  id
  type
  ownerId
  name
  company {
    ${CompanyFieldsQuery}
  }
  satelliteKitchens {
    ${SatelliteKitchenCollectionQuery}
  }
  userCount
  createdAt
  updatedAt
`;

export const CentralKitchenCollectionQuery = `
  documents {
    ${CentralKitchenFieldsQuery}
  }
  nextToken
`;

export const ReadCentralKitchensQuery = `
  readCentralKitchens(nextToken: $nextToken) { 
    ${CentralKitchenCollectionQuery}
  }
`;

export const CreateCentralKitchenQuery = `
  createCentralKitchen(input: $input) { 
    ${CentralKitchenFieldsQuery}
  }
`;

export const UpdateCentralKitchenQuery = `
  updateCentralKitchen(id: $id, input: $input) { 
    ${CentralKitchenFieldsQuery}
  }
`;

export const DeleteCentralKitchenQuery = `
  deleteCentralKitchen(id: $id) { 
    ${CentralKitchenFieldsQuery}
  }
`;
