import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const OriginalMenuCategoriesMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/original-menu-categories" name="Original Menu Categories" {...props} />
  );
};

export { OriginalMenuCategoriesMenu };
