import React, { FC, useState, useEffect } from 'react';

import { BooleanField } from 'models/value-objects/editor-field';
import { Model } from './index.model';

import { ToggleSwitch } from 'views/components/toggle-switch';
import { Root } from '../input-base/index.styled';

interface Props {
  field: BooleanField;
  onEdit: (field: BooleanField) => void;
  disabled?: boolean;
  focused?: boolean;
  prefix?: string;
  suffix?: string;
}

const BooleanInput: FC<Props> = props => {

  const { field, onEdit, disabled = false, focused = false, prefix, suffix } = props;
  const [model, setModel] = useState(new Model({ field }));

  function onToggle(value: boolean) {
    const it = model.change(value);
    onEdit(it.field);
    setModel(it);
  }

  function watchField() {
    if (field.boolean === model.field.boolean) return;
    setModel(model.renew(field));
  }

  useEffect(watchField, [field]);

  return (
    <Root>
      <ToggleSwitch value={model.field.boolean} onToggle={onToggle} disabled={disabled} inFocus={focused} />
      {!!prefix && <span className="prefix">{prefix}</span>}
      {!!suffix && <span className="suffix">{suffix}</span>}
    </Root>
  );

};

export { BooleanInput };