import dayjs, { Dayjs } from 'dayjs';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  name?: string;
  apiKey?: string;
  organizationId?: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  apiKey?: string;
  organizationId?: string;
};

type DataToSave = {
  name: string;
  apiKey: string;
  organizationId: string;
};

class OpenaiAccount {

  readonly id: string;
  readonly name: string;
  readonly apiKey: string;
  readonly organizationId: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data = {}) {
    this.id = data.id ?? '';
    this.name = data.name ?? 'Default';
    this.apiKey = data.apiKey ?? '';
    this.organizationId = data.organizationId ?? '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    if (!this.apiKey) return false;
    if (!this.organizationId) return false;
    return true;
  }

  async save(): Promise<OpenaiAccount> {
    if (!this.validate()) throw new Error('invalid object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<OpenaiAccount> {
    const createGql = await new CreateGql().fetch({ input: this.getDataToSave() });
    if (!createGql.document) throw new Error('invalid document');
    return new OpenaiAccount(createGql.document);
  }

  private async update(): Promise<OpenaiAccount> {
    if (!this.id) throw new Error('invalid relationship');
    const updateGql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!updateGql.document) throw new Error('invalid document');
    return new OpenaiAccount(updateGql.document);
  }

  private getDataToSave(): DataToSave {
    const { name, apiKey, organizationId } = this;
    return { name, apiKey, organizationId };
  }

  async delete(): Promise<OpenaiAccount> {
    if (!this.id) throw new Error('invalid relationship');
    const { id } = this;
    const deleteGql = await new DeleteGql().fetch({ id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new OpenaiAccount(deleteGql.document);
  }

  toJSON(): Data {
    const { id, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, name, createdAt, updatedAt };
  }

}

export { OpenaiAccount };
export type {
  Data as OpenaiAccountData,
  DataToSave as OpenaiAccountDataToSave,
 };