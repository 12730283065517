import { BusinessCategoryFieldsQuery } from '../business-category';
import { PosSystemFieldsQuery } from '../pos-system';
import { IngredientCategoryCollectionQuery } from '../ingredient-category';
import { MenuCategoryCollectionQuery } from '../menu-category';

export const BusinessFieldsQuery = `
  id
  category {
    ${BusinessCategoryFieldsQuery}
  }
  posSystem {
    ${PosSystemFieldsQuery}
  }
  order
  code
  name
  location
  currency
  closed
  deleted
  createdAt
  updatedAt
  ingredientCategories {
    ${IngredientCategoryCollectionQuery}
  }
  menuCategories {
    ${MenuCategoryCollectionQuery}
  }
`;

export const BusinessCollectionQuery = `
  documents {
    ${BusinessFieldsQuery}
  }
  nextToken
`;

export const ReadBusinessesQuery = `
  readBusinesses(nextToken: $nextToken) { 
    ${BusinessCollectionQuery}
  }
`;

export const ReadBusinessesByCategoryIdQuery = `
  readBusinessesByCategoryId(categoryId: $categoryId, nextToken: $nextToken) { 
    ${BusinessCollectionQuery}
  }
`;

export const ReadBusinessByCodeQuery = `
  readBusinessByCode(code: $code) { 
    ${BusinessFieldsQuery}
  }
`;

export const CreateBusinessQuery = `
  createBusiness(input: $input) { 
    ${BusinessFieldsQuery}
  }
`;

export const UpdateBusinessQuery = `
  updateBusiness(id: $id, input: $input) { 
    ${BusinessFieldsQuery}
  }
`;

export const DeleteBusinessQuery = `
  deleteBusiness(id: $id) { 
    ${BusinessFieldsQuery}
  }
`;
