import styled from 'styled-components';

import { numberFontFamily } from 'views/services/font-scheme';

import { Root as RootBase } from '../input-base/index.styled';

export const Root = styled(RootBase)`
  & > input {
    font-family: ${numberFontFamily};
    text-align: right;
  }
`;
