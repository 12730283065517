import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const SuppliersMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/suppliers" name="Suppliers" {...props} />
  );
};

export { SuppliersMenu };
