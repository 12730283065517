import { ModalName } from '../../services/modal-name';
import { TargetObject } from '../../services/target-object';
import { OtherObject } from '../../services/other-object';
import { Result } from '../result';

type Data = {
  name: ModalName;
  target?: TargetObject;
  other?: OtherObject;
  strict?: boolean;
  finalize?: boolean;
  tell?: (result: Result) => void;
  order: number;
};

class Layer {

  readonly name: ModalName;
  readonly target?: TargetObject;
  readonly other?: OtherObject;
  readonly strict: boolean;
  readonly finalize: boolean;
  readonly tell?: (result: Result) => void;
  readonly order: number;
  readonly foreground: boolean;
  readonly ready: boolean;
  readonly active: boolean;
  readonly deleted: boolean;

  readonly result?: Result;

  constructor(data: Data) {
    this.name = data.name;
    this.target = data.target;
    this.other = data.other;
    this.strict = data.strict ?? false;
    this.finalize = data.finalize ?? true;
    this.tell = data.tell;
    this.order = data.order;
    this.foreground = true;
    this.ready = false;
    this.active = false;
    this.deleted = false;
  }

  canClose(): boolean {
    return !this.strict && this.active;
  }

  sendToBack(): this {
    const foreground = false;
    const active = false;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, foreground, active });
  }

  sendToFront(): this {
    const foreground = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, foreground });
  }

  activate(): this {
    const ready = true;
    const active = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ready, active });
  }

  conclude(result: Result): this {
    this.tell && this.tell(result);
    const deleted = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, deleted });
  }

}

export { Layer };