import React, { FC } from 'react';

import { Model } from '../../index.model';

import { SelectInput } from 'views/components/select-input';

interface Props {
  model: Model;
  onUpdate: (model: Model) => void;
}

const PosSystemInput: FC<Props> = ({ model, onUpdate }) => {

  const list = model.posSystems.documents.map(it => it.name);
  const index = model.posSystems.documents.findIndex(it => it.id === model.posSystem?.id);

  function select(index: number) {
    onUpdate(model.change({ posSystem: model.posSystems.documents[index] }));
  }

  return (
    <SelectInput list={list} index={index} onSelect={i => select(i)} readOnly={true}>
      <h2>POS System</h2>
    </SelectInput>
  );

};

export { PosSystemInput };