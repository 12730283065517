import { Api } from 'models/services/api';
import { CreateOriginalIngredientCategoryQuery } from 'models/services/graphql';

import { OriginalIngredientCategoryData, OriginalIngredientCategoryDataToSave } from '../index';

type DataToCreate = {
  input: OriginalIngredientCategoryDataToSave;
};

const RequestQuery = `
  mutation CreateOriginalIngredientCategory($input: OriginalIngredientCategoryInput!) {
    ${CreateOriginalIngredientCategoryQuery}
  }
`;

type ResponseData = {
  data: {
    createOriginalIngredientCategory: OriginalIngredientCategoryData
  }
}

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly document?: OriginalIngredientCategoryData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToCreate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.createOriginalIngredientCategory;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { CreateGql };