import React, { FC, useState, useEffect } from 'react';

import { Rect } from '../../../models/rect';
import { Point } from '../../../models/point';

import { Root } from './index.styled';

interface Props {
  rect: Rect;
  point: Point;
}

const MarkListItem: FC<Props> = ({ rect, point }) => {

  const [position, setPosition] = useState<Rect>(new Rect());

  function watchProps() {
    const center = rect.top + rect.height / 2;
    const top = point.y < center ? rect.top : rect.top + rect.height;
    const left = rect.left;
    const width = rect.width;
    const height = 2;
    setPosition(position.update({ top, left, width, height }));
  }

  useEffect(watchProps, [rect, point]);

  return (
    <Root position={position} />
  );

};

export { MarkListItem };