import { User, UserData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: UserData[];
  nextToken: string;
};

class UserCollection {

  readonly organizationId: string;
  readonly documents: User[];
  readonly nextToken: string;

  constructor(organizationId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.organizationId = organizationId;
    this.documents = data.documents.map(it => new User(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { organizationId, nextToken } = collection;
      const gql = await new ReadCollectionGql().fetch({ organizationId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new UserCollection(organizationId, gql.result));
    }
    return collection;
  }

  merge(collection: UserCollection): this {
    const documents = User.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(business: User): this {
    const documents = User.sort([...this.documents, business]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(business: User): this {
    const documents = User.sort(this.documents.map(it => it.id === business.id ? business : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(business: User): this {
    const documents = this.documents.filter(it => it.id !== business.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(organizationId: string): Promise<UserCollection> {
    const gql = await new ReadCollectionGql().fetch({ organizationId });
    if (!gql.result) throw new Error('invalid result');
    return new UserCollection(organizationId, gql.result);
  }

}

export { UserCollection };
export type { Data as UserCollectionData };