import React, { FC, useState } from 'react';

import { EmailField, PasswordField } from 'models/value-objects/editor-field';

import { EditUserTarget } from '../../../models/entities/target';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { InputField } from 'views/components/input-field';
import { StringInput } from 'views/components/inputs';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { Root, Footer, MoreActions } from './index.styled';

interface Props {
  target: EditUserTarget;
  ready: boolean;
}

const EditUser: FC<Props> = ({ target, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target }));

  function save() {
    model.save().then(it => modal.close(it.conclude(model.user.original.id ? 'updated' : 'created')));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (!model.user.original.id) return;
    model.delete().then(it => modal.close(it.conclude('deleted')));
  }

  return (
    <Root>
      <h1>User</h1>
      <fieldset>
        <InputField>
          <h2>Name</h2>
          <StringInput field={model.user.name} onEdit={name => setModel(model.edit({ name }))} focused={ready} />
        </InputField>
        <InputField>
          <h2>Email</h2>
          <StringInput field={model.user.email} onEdit={field => setModel(model.edit({ email: field as EmailField }))} />
        </InputField>
        <InputField>
          <h2>Password</h2>
          <StringInput field={model.user.password} onEdit={field => setModel(model.edit({ password: field as PasswordField }))} secure={true} />
        </InputField>
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.ok}>Save</PrimaryButton>
      </Footer>
      {model.user.original.id && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditUser };
