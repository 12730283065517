import { LexoRank } from 'lexorank';

type Data = {
  object: LexoRank;
};

class OrderField {

  readonly object: LexoRank;
  readonly dirt?: LexoRank;
  readonly dirty: boolean;

  constructor(data: Data) {
    this.object = data.object;
    this.dirty = false;
  }

  change(object: LexoRank): this {
    const dirt = object;
    const dirty = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, object, dirt, dirty });
  }

}

export { OrderField };