type Name = '' | 'dnd-grabbing' | 'dnd-dragging';

class State {

  readonly name: Name;

  constructor(name: Name = '') {
    this.name = name;
  }

  toGrabbing(): this {
    return this.update('dnd-grabbing');
  }

  toDragging(): this {
    return this.update('dnd-dragging');
  }

  reset(): this {
    return this.update('');
  }

  private update(name: Name): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, name });
  }

  canGrab(): boolean {
    return [''].includes(this.name);
  }

  canDrag(): boolean {
    return ['dnd-grabbing', 'dnd-dragging'].includes(this.name);
  }

  canDrop(): boolean {
    return ['dnd-dragging'].includes(this.name);
  }

  isActive(): boolean {
    return ['dnd-grabbing', 'dnd-dragging'].includes(this.name);
  }

  isDragging(): boolean {
    return this.name === 'dnd-dragging';
  }

}

export { State };