import styled from 'styled-components';

import { baseColor, lineColor, lightPrimaryColor, primaryTextColor, vividPrimaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
`;

export const Label = styled.div`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  & > button {
    cursor: pointer;
    margin-left: 16px;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const List = styled.div`
  position: relative;
  & > ol {
    position: absolute;
    top: 8px;
    left: calc(-16px - 1px - 4px - 1px);
    min-width: 240px;
    max-height: 360px;
    overflow: scroll;
    border: 1px solid ${lineColor};
    padding: 4px;
    & > li {
      cursor: pointer;
      white-space: nowrap;
      padding: 8px 16px;
      background-color: ${baseColor};
      border: 1px solid transparent;
      border-radius: 4px;
      &:hover {
        background-color: ${lightPrimaryColor};
        color: ${primaryTextColor};
        border-color: ${vividPrimaryColor};
      }
    }
  }
`;
