import { StringField, StringFieldData } from './string-field';

class PasswordField extends StringField {

  constructor(data: StringFieldData = {}) {
    super(data);
  }

  protected check(value: string): boolean {
    if (!this.required && !value) return true;
    if (this.required && !value) return false;
    if (value.length < 8) return false;
    return !!value.match(/^[\w\d^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+-]*$/);
  }

  protected getError(value: string): string {
    if (value === '') return '';
    if (!this.check(value)) return 'Must be eight or more characters\n  - upper and lowercase letters\n  - numbers\n  - symbols';
    return '';
  }

}

export { PasswordField };