import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightPrimaryColor, darkPrimaryColor, darkBaseColor, primaryTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  padding: 8px 8px;
  background-color: ${darkBaseColor};
  border: 2px solid ${lightPrimaryColor};
  border-radius: 4px;
  & > textarea {
    display: block;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: 1.75;
    white-space: pre;
    color: ${textColor};
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    resize: none;
    &::selection {
      background-color: ${darkPrimaryColor};
      color: ${primaryTextColor};
    }
  }
  &:hover, &.focusing {
    border-color: ${darkPrimaryColor};
  }
`;
