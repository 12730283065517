import { DishCategory, DishCategoryData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: DishCategoryData[];
  nextToken: string;
};

class DishCategoryCollection {

  readonly menuCategoryId: string;
  readonly documents: DishCategory[];
  readonly nextToken: string;

  constructor(menuCategoryId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.menuCategoryId = menuCategoryId;
    this.documents = data.documents.map(it => new DishCategory(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { menuCategoryId, nextToken } = collection;
      if (!menuCategoryId) continue;
      const gql = await new ReadCollectionGql().fetch({ menuCategoryId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new DishCategoryCollection(menuCategoryId, gql.result));
    }
    return collection;
  }

  merge(collection: DishCategoryCollection): this {
    const documents = DishCategory.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(category: DishCategory): this {
    const documents = DishCategory.sort([...this.documents, category]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(category: DishCategory): this {
    const documents = DishCategory.sort(this.documents.map(it => it.id === category.id ? category : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(category: DishCategory): this {
    const documents = this.documents.filter(it => it.id !== category.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(menuCategoryId: string): Promise<DishCategoryCollection> {
    const gql = await new ReadCollectionGql().fetch({ menuCategoryId });
    if (!gql.result) throw new Error('invalid result');
    return new DishCategoryCollection(menuCategoryId, gql.result);
  }

}

export { DishCategoryCollection };
export type { Data as DishCategoryCollectionData };