import styled from 'styled-components';

import { baseColor, lightTextColor, darkTextColor, primaryColor, primaryTextColor, darkPrimaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${darkPrimaryColor};
  }
  &.searching {
    background-color: ${darkPrimaryColor};
  }
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  width: fit-content;
  padding-left: 8px;
  color: ${lightTextColor};
  ${Root}:hover > & {
    color: ${primaryTextColor};
  }
`;

export const Space = styled.div`
  padding: 0 8px;
  visibility: hidden;
`;

interface FieldProps {
  left?: number;
}
export const Field = styled.div<FieldProps>`
  position: absolute;
  top: 0;
  left: ${p => p.left ?? 0}px;
  right: 0;
  bottom: 0;
  padding: 0 8px;
  border-radius: 4px;
  color: ${darkTextColor};
  display: flex;
  align-items: center;
  & > input {
    width: 100%;
    padding: 0 8px;
    cursor: pointer;
    &::selection {
      background-color: ${primaryColor};
    }
  }
  ${Root}.default:hover > & {
    background-color: ${darkPrimaryColor};
    color: ${baseColor};
  }
  transition: none 160ms ease-out;
  transition-property: left, color;
  &.searching {
    left: 0;
    background-color: ${darkPrimaryColor};
    color: ${primaryTextColor};
    & > input {
      cursor: text;
    }
  }
`;
