export const SupplierFieldsQuery = `
  id
  businessId
  name
  address
  city
  province
  country
  postalCode
  phone
  fax
  picName
  picPhone
  picEmail
  notes
  deleted
  createdAt
  updatedAt
`;

export const SupplierCollectionQuery = `
  documents {
    ${SupplierFieldsQuery}
  }
  nextToken
`;

export const ReadSuppliersQuery = `
  readSuppliers(businessId: $businessId, nextToken: $nextToken) { 
    ${SupplierCollectionQuery}
  }
`;

export const CreateSupplierQuery = `
  createSupplier(businessId: $businessId, input: $input) { 
    ${SupplierFieldsQuery}
  }
`;

export const UpdateSupplierQuery = `
  updateSupplier(id: $id, input: $input) { 
    ${SupplierFieldsQuery}
  }
`;

export const DeleteSupplierQuery = `
  deleteSupplier(id: $id) { 
    ${SupplierFieldsQuery}
  }
`;
