import React, { FC, useRef, useEffect } from 'react';

import { Button } from './index.styled';

interface Props {
  value: boolean;
  onToggle: (value: boolean) => void;
  inFocus?: boolean;
  disabled?: boolean;
}

const ToggleSwitch: FC<Props> = ({ value, onToggle, inFocus = false, disabled = false }) => {

  const button = useRef<HTMLButtonElement>(null);

  function toggle() {
    onToggle(!value);
  }

  function watchInFocus() {
    if (inFocus) button.current?.focus();
  }

  useEffect(watchInFocus, [inFocus]);

  return (
    <Button ref={button} onClick={() => toggle()} className={value ? 'on' : 'off'} disabled={disabled}>
      <div className={value ? 'on' : 'off'} />
    </Button>
  );

};

export { ToggleSwitch };