import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, darkBaseColor, primaryColor, lightPrimaryColor, darkPrimaryColor, primaryTextColor, primaryLightTextColor, lightSecondaryColor, darkSecondaryColor, secondaryTextColor, secondaryLightTextColor, darkAccentColor, accentTextColor } from 'views/services/color-scheme';

export const Root = styled.div`
  width: 320px;
  height: 320px;
  background-color: ${darkBaseColor};
  overflow: scroll;
`;

export const Calender = styled.div`
  font-family: ${baseFontFamily};
  color: ${textColor};
  & > table {
    width: 100%;
    text-align: center;
    & > thead {
      font-size: calc(${baseFontSize} * 0.8);
      & > tr {
        & > th {
          height: 24px;
          vertical-align: middle;
        }
        &:first-child {
          background-color: ${darkPrimaryColor};
          color: ${primaryTextColor};
          & > th {
            text-align: left;
            padding: 0 8px;
          }
        }
        &:nth-child(2) {
          background-color: ${lightSecondaryColor};
          color: ${secondaryLightTextColor};
        }
      }
    }
    & > tbody {
      font-size: calc(${baseFontSize} * 1);
      & > tr {
        & > td {
          height: 48px;
          vertical-align: middle;
          & > button {
            cursor: pointer;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid transparent;
            &.today {
              background-color: ${darkAccentColor};
              color: ${accentTextColor};
            }
            &.start, &.end {
              background-color: ${primaryColor};
              color: ${primaryTextColor};
            }
            &.period {
              background-color: ${lightPrimaryColor};
              color: ${primaryTextColor};
            }
            &:hover {
              background-color: ${lightPrimaryColor};
              color: ${primaryTextColor};
              border-color: ${darkPrimaryColor};
            }
          }
        }
      }
    }
  }
//  ${Root}:hover > & {
//    & > table {
//      & > thead {
//        & > tr {
//          &:first-child {
//            background-color: ${darkPrimaryColor};
//            color: ${primaryTextColor};
//          }
//          &:nth-child(2) {
//            background-color: ${lightPrimaryColor};
//            color: ${primaryLightTextColor};
//          }
//        }
//      }
//    }
//  }
`;
