import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightPrimaryColor, darkPrimaryColor, primaryLineColor } from 'views/services/color-scheme';

export const Root = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
    height: 100%;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
    display: flex;
    align-items: center;
    & > input {
      cursor: pointer;
      width: 100%;
      height: 100%;
      padding-left: 24px;
      &::placeholder {
        color: ${primaryLineColor};
      }
    }
    & > button {
      cursor: pointer;
      height: 100%;
      padding-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  position: relative;
  & > ol {
    z-index: 2;
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    height: 320px;
    background-color: ${baseColor};
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
    border: 2px solid ${darkPrimaryColor};
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    overflow: scroll;
    & > li {
      cursor: pointer;
      padding: 8px calc(24px - 2px);
      &:hover, &.selected {
        background-color: ${lightPrimaryColor};
      }
    }
  }
`;
