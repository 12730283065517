import { OriginalMenuCategoryFieldsQuery } from '../original-menu-category';
import { DishCategoryCollectionQuery } from '../dish-category';

export const MenuCategoryFieldsQuery = `
  id
  businessId
  original {
    ${OriginalMenuCategoryFieldsQuery}
  }
  order
  createdAt
  updatedAt
  dishCategories {
    ${DishCategoryCollectionQuery}
  }
`;

export const MenuCategoryCollectionQuery = `
  documents {
    ${MenuCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadMenuCategoriesQuery = `
  readMenuCategories(businessId: $businessId, nextToken: $nextToken) {
    ${MenuCategoryCollectionQuery}
  }
`;

export const CreateMenuCategoryQuery = `
  createMenuCategory(businessId: $businessId, input: $input) { 
    ${MenuCategoryFieldsQuery}
  }
`;

export const UpdateMenuCategoryQuery = `
  updateMenuCategory(id: $id, input: $input) { 
    ${MenuCategoryFieldsQuery}
  }
`;

export const DeleteMenuCategoryQuery = `
  deleteMenuCategory(id: $id) { 
    ${MenuCategoryFieldsQuery}
  }
`;