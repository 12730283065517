import { IngredientItemEditor, InHouseIngredientItemEditor, InHouseIngredientItemEditorDataToApply } from 'models/entities/ingredient-item';
import { Currency } from 'models/value-objects/currency';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  ingredientItem: IngredientItemEditor;
  currency: Currency;
};

class Model {

  readonly ingredientItem: InHouseIngredientItemEditor;
  readonly isNew: boolean;
  readonly currency: Currency;
  readonly relationships?: RelationshipCollection;

  constructor(data: Data) {
    this.ingredientItem = data.ingredientItem as InHouseIngredientItemEditor;
    this.isNew = !this.ingredientItem.original.id;
    this.currency = data.currency;
  }

  async readRelationships(itemId: string): Promise<this> {
    const relationships = await RelationshipCollection.readByItemId(itemId, 1);
    const ingredientItem = this.ingredientItem.init({ canDelete: !relationships.documents.length });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, relationships, ingredientItem });
  }

  edit(data: InHouseIngredientItemEditorDataToApply): this {
    const ingredientItem = this.ingredientItem.apply(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItem });
  }

  async save(): Promise<this> {
    const ingredientItem = await this.ingredientItem.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItem });
  }

  async delete(): Promise<this> {
    const ingredientItem = await this.ingredientItem.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItem });
  }

}

export { Model };