import React, { FC } from 'react';

import { MenuCategory } from 'models/entities/menu-category';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

interface MenuCategoryMenuProps {
  category: MenuCategory;
}

const DishItemsMenu: FC<MenuCategoryMenuProps & Props> = ({ category, ...props }) => {
  return (
    <AbstractMenuItem page={`/${category.original.code}`} name={category.original.name} {...props} />
  );
};

export { DishItemsMenu };
