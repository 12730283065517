import React, { FC, useState, useEffect } from 'react';

import { User } from 'models/entities/user';
import { ShowUsersTarget, EditUserTarget } from '../../../models/entities/target';
import { Result } from '../../../models/entities/result';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { CenteredLoading } from 'views/components/centered-loading';
import { CenteredEmptyMessage } from 'views/components/centered-empty-message';
import { SmallSecondaryRoundButton } from 'views/components/buttons';
import { AddIcon } from 'views/components/icons';
import { CloseButton } from 'views/components/close-button';
import { Root, Header, Frame, List, ListHeader, ListBody, ListItem, Label, Content } from './index.styled';

interface Props {
  target: ShowUsersTarget;
  background: boolean;
}

const ShowUsers: FC<Props> = ({ target, background }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target }));

  function close() {
    modal.close(model.conclude('updated'));
  }

  function edit(user?: User) {
    const target = new EditUserTarget({ user: model.build(user), organization: model.organization });
    const tell = apply;
    modal.open('edit-user', { target, strict: true, tell });
  }

  function apply(result: Result) {
    if (!result.target) return;
    if (!(result.target instanceof EditUserTarget)) return;
    const { user } = result.target;
    switch (result.state) {
      case 'created': setModel(model.add(user.original)); break;
      case 'updated': setModel(model.replace(user.original)); break;
      case 'deleted': setModel(model.remove(user.original)); break;
    }
  }

  function watchCollection() {
    if (!model.collection) model.read().then(it => setModel(it));
    else if (model.collection.nextToken) model.readAllCollection().then(it => setModel(it));
  }

  useEffect(watchCollection, [model.collection]);

  return (
    <Root className={background ? 'modal-background' : ''}>
      <Header>
        <h1>Users</h1>
        <nav>
          <SmallSecondaryRoundButton onClick={() => edit()}><AddIcon /></SmallSecondaryRoundButton>
          <CloseButton onClose={() => close()} />
        </nav>
      </Header>
      <Frame>
        <List>
          <ListHeader>
            <Label>Name</Label>
          </ListHeader>
          <ListBody>
            {!model.list && <CenteredLoading />}
            {!!model.list && !model.list.length && <CenteredEmptyMessage>No Users</CenteredEmptyMessage>}
            {!!model.list && !!model.list.length && model.list.map((it, i) => (
              <ListItem key={i}>
                <Content onClick={() => edit(it)}>
                  <div>{it.name}</div>
                </Content>
              </ListItem>
            ))}
          </ListBody>
        </List>
      </Frame>
    </Root>
  );

};

export { ShowUsers };
