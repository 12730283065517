import { IngredientItem } from 'models/entities/ingredient-item';

import { IngredientCollection } from './collection';
import { IngredientEditor } from './editor';
import { IngredientDataToIdentify, IngredientDataToRevise } from './ingredient';

type Data = {
  original: IngredientCollection;
};

type DataToApply = {
  editors?: IngredientEditor[];
  deleted?: IngredientEditor[];
};

type DataToValidate = {
  editors?: IngredientEditor[];
};

const MaxDepth = 5;

class IngredientEditorCollection {

  readonly original: IngredientCollection;
  readonly editors: IngredientEditor[];
  readonly deleted: IngredientEditor[];
  readonly cost: number;
  readonly isMaxDepth: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;

  constructor(data: Data) {
    this.original = data.original;
    this.editors = this.original.documents.map(original => new IngredientEditor({ original })) ?? [];
    this.deleted = [];
    this.cost = this.getCost(this.editors);
    this.isMaxDepth = this.getIsMaxDepth(this.editors);
    this.dirty = false;
    this.ok = this.validate();
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const cost = this.getCost(props.editors);
    const isMaxDepth = this.getIsMaxDepth(props.editors);
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, cost, isMaxDepth, dirty, ok });
  }

  replace(editor: IngredientEditor): this {
    const editors = IngredientEditor.sort(this.editors.map(it => it.original.item.id === editor.original.item.id ? editor : it));
    return this.apply({ editors });
  }

  move(editor: IngredientEditor, index: number): this {
    const newOne = editor.moveBetween(this.editors[index - 1], this.editors[index]);
    return this.replace(newOne);
  }

  remove(editor: IngredientEditor): this {
    const editors = this.editors.filter(it => it.original.item.id !== editor.original.item.id);
    const deleted = editor.original.id ? [...this.deleted, editor] : this.deleted;
    return this.apply({ editors, deleted });
  }

  addItem(item: IngredientItem): this {
    const { restored, deleted } = this.restoreEditorFromDeleted(item);
    const editor = restored ?? IngredientEditor.buildNext(this.editors, { dishItemId: this.original.dishItemId, item, dirty: true });
    const editors = IngredientEditor.sort([...this.editors, editor]);
    return this.apply({ editors, deleted });
  }

  insertItem(item: IngredientItem, index: number): this {
    const { restored, deleted } = this.restoreEditorFromDeleted(item);
    const prev = this.editors[index - 1];
    const next = this.editors[index];
    const editor = restored ? restored.moveBetween(prev, next) : IngredientEditor.buildBetween(prev, next, { dishItemId: this.original.dishItemId, item, dirty: true });
    const editors = IngredientEditor.sort([...this.editors, editor]);
    return this.apply({ editors, deleted });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.editors.every(it => it.ok)) return false;
    return true;
  }

  identify(data: IngredientDataToIdentify[]): IngredientEditorCollection {
    const documents = this.editors.map((it, i) => it.rebuild().identify(data[i]));
    const original = this.original.apply({ documents });
    return new IngredientEditorCollection({ original });
  }

  revise(data: IngredientDataToRevise[]): IngredientEditorCollection {
    const edited = this.editors.filter(it => it.dirty).map((it, i) => it.rebuild().revise(data[i]));
    const rest = this.editors.filter(it => !it.dirty).map(it => it.rebuild());
    const original = this.original.apply({ documents: [...edited, ...rest] });
    return new IngredientEditorCollection({ original });
  }

  rebuild(): IngredientCollection {
    const documents = this.editors.map(it => it.rebuild());
    return this.original.apply({ documents });
  }

  private restoreEditorFromDeleted(item: IngredientItem): { restored: IngredientEditor | undefined, deleted: IngredientEditor[] } {
    const restored = this.deleted.find(it => it.original.item.id === item.id);
    const deleted = restored ? this.deleted.filter(it => it.original.item.id !== item.id) : this.deleted;
    return { restored, deleted };
  }

  private getCost(editors: IngredientEditor[]): number {
    return editors.reduce((cost, it) => cost + it.cost, 0);
  }

  private getIsMaxDepth(editors: IngredientEditor[]): boolean {
    return Math.max(...editors.map(it => it.original.item.depth)) >= MaxDepth;
  }

}

export { IngredientEditorCollection, MaxDepth };