import { Api } from 'models/services/api';
import { ReadRelationshipsByItemIdQuery } from 'models/services/graphql';

import { RelationshipCollectionData } from '../collection';

type DataToFetch = {
  itemId: string;
  limit?: number;
  nextToken?: string;
};

const RequestQuery = `
  query ReadRelationshipsByItemId($itemId: ID!, $limit: Int, $nextToken: String) {
    ${ReadRelationshipsByItemIdQuery}
  }
`;

type ResponseData = {
  data: {
    readRelationshipsByItemId: RelationshipCollectionData;
  }
}

class ReadCollectionByItemIdGql {

  readonly result?: RelationshipCollectionData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.readRelationshipsByItemId;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadCollectionByItemIdGql };
