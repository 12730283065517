import { IngredientItemFieldsQuery } from '../dependent-queries';

export const IngredientItemCollectionQuery = `
  documents {
    ${IngredientItemFieldsQuery.create()}
  }
  nextToken
`;

export const ReadIngredientItemsQuery = `
  readIngredientItems(businessId: $businessId, nextToken: $nextToken) { 
    ${IngredientItemCollectionQuery}
  }
`;

export const ReadIngredientItemQuery = `
  readIngredientItem(id: $id) { 
    ${IngredientItemFieldsQuery.create()}
  }
`;

export const CreateIngredientItemQuery = `
  createIngredientItem(businessId: $businessId, input: $input) { 
    id
    createdAt
    updatedAt
  }
`;

export const UpdateIngredientItemQuery = `
  updateIngredientItem(id: $id, input: $input) { 
    updatedAt
  }
`;

export const DeleteIngredientItemQuery = `
  deleteIngredientItem(id: $id) { 
    id
  }
`;

export const CreateIngredientItemsQuery = `
  createIngredientItems(businessId: $businessId, input: $input) { 
    ${IngredientItemFieldsQuery.create()}
  }
`;

export { IngredientItemFieldsQuery };
