import { ModalName } from './models/services/modal-name';
import { OpenOptions } from './models/services/modal-interface';
import { Layer } from './models/entities/layer';
import { Result, ResultData } from './models/entities/result';

type State = 'closed' | 'open';

class Model {

  readonly state: State;
  readonly scroll: number;
  readonly layers: Layer[];

  constructor() {
    this.state = 'closed';
    this.scroll = 0;
    this.layers = [];
  }

  open(name: ModalName, options: OpenOptions = {}): this {
    const state = 'open';
    const scroll = this.getWindowScroll();
    const order = this.layers.length;
    const layers = [...this.layers.map(it => it.sendToBack()), new Layer({ ...options, name, order })];
    return Object.assign(Object.create(this.constructor.prototype), { ...this, state, scroll, layers });
  }

  close(data?: ResultData): this {
    const result = new Result(data);
    return this.layers.length >= 2 ? this.closeLayer(result) : this.closeModal(result);
  }

  private closeLayer(result: Result): this {
    const layers = this.layers;
    const last1 = layers.pop()!;
    const last2 = layers.pop()!;
    layers.push(last2.sendToFront(), last1.conclude(result));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, layers });
  }

  private closeModal(result: Result): this {
    if (!this.layers.length) return this;
    const layers = [this.layers[0].conclude(result)];
    return Object.assign(Object.create(this.constructor.prototype), { ...this, layers });
  }

  canClose(): boolean {
    if (!this.layers.length) return false;
    return this.layers[this.layers.length - 1].canClose();
  }

  ready(): this {
    const layers = this.layers;
    const last = layers.pop();
    if (last) layers.push(last.activate());
    return Object.assign(Object.create(this.constructor.prototype), { ...this, layers });
  }

  unmount(): this {
    const layers = this.layers.filter(it => !it.deleted);
    const last = layers.pop();
    if (last) layers.push(last.activate());
    const state = !layers.length ? 'closed' : this.state;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, layers, state });
  }

  private getWindowScroll() {
    return window.scrollY;
  }

}

export { Model };