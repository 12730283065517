import dayjs, { Dayjs } from 'dayjs';

import { Organization } from 'models/entities/organization';
import { Company, CompanyData } from 'models/entities/company';
import { FranchiseeCollection, FranchiseeCollectionData, Franchisee } from 'models/entities/franchisee';
import { StoreCollection, StoreCollectionData, Store } from 'models/entities/store';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  type?: string;
  parentId?: string;
  ownerId?: string;
  organizationId: string;
  company?: CompanyData | Company;
  name?: string;
  franchisees?: FranchiseeCollectionData;
  stores?: StoreCollectionData;
  userCount?: number;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  company?: Company;
  name?: string;
};

type DataToSave = {
  type: string;
  organizationId: string;
  companyId: string;
  name: string;
};

class Franchisor implements Organization {

  readonly id: string;
  readonly type: string;
  readonly parentId?: string;
  readonly ownerId?: string;
  readonly organizationId: string;
  readonly company?: Company;
  readonly name: string;
  readonly franchisees: FranchiseeCollection;
  readonly stores: StoreCollection;
  readonly userCount: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly organizationIds: Set<string>;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.type = 'Franchisor';
    this.parentId = data.parentId ?? '';
    this.ownerId = data.ownerId ?? '';
    this.organizationId = data.organizationId;
    this.company = data.company ? data.company instanceof Company ? data.company : new Company(data.company) : undefined;
    this.name = data.name ?? '';
    this.franchisees = new FranchiseeCollection(this.id, data.franchisees);
    this.stores = new StoreCollection(this.id, data.stores);
    this.userCount = data.userCount ?? 0;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.organizationIds = this.getOrganizationIds();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.company) return false;
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<Franchisor> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<Franchisor> {
    const gql = await new CreateGql().fetch({ organizationId: this.organizationId, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Franchisor(gql.document);
  }

  private async update(): Promise<Franchisor> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Franchisor(gql.document);
  }

  private getDataToSave(): DataToSave {
    const { type, organizationId, company, name } = this;
    return { type, organizationId, companyId: company!.id!, name };
  }

  async delete(): Promise<Franchisor> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new DeleteGql().fetch({ id: this.id });
    if (!gql.document) throw new Error('invalid document');
    return new Franchisor(gql.document);
  }

  is(organizationId: string): boolean {
    return this.id === organizationId;
  }

  has(organizationId: string): boolean {
    return this.organizationIds.has(organizationId);
  }

  applyFranchisee(franchisee: Franchisee): this {
    if (!this.is(franchisee.organizationId)) return this;
    const franchisees = this.franchisees.apply(franchisee);
    const organizationIds = this.getOrganizationIds({ franchisees });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisees, organizationIds });
  }

  removeFranchisee(franchisee: Franchisee): this {
    if (!this.is(franchisee.organizationId)) return this;
    const franchisees = this.franchisees.remove(franchisee);
    const organizationIds = this.getOrganizationIds({ franchisees });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisees, organizationIds });
  }

  applyStore(store: Store): this {
    if (!this.has(store.organizationId)) return this;
    const franchisees = !this.is(store.organizationId) ? this.franchisees.applyStore(store) : this.franchisees;
    const stores = this.is(store.organizationId) ? this.stores.apply(store) : this.stores;
    const organizationIds = this.getOrganizationIds({ franchisees, stores });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisees, stores, organizationIds });
  }

  removeStore(store: Store): this {
    if (!this.has(store.organizationId)) return this;
    const franchisees = !this.is(store.organizationId) ? this.franchisees.removeStore(store) : this.franchisees;
    const stores = this.is(store.organizationId) ? this.stores.remove(store) : this.stores;
    const organizationIds = this.getOrganizationIds({ franchisees, stores });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisees, stores, organizationIds });
  }

  getStores(): Store[] {
    return [...this.stores.documents, ...this.franchisees.getStores()];
  }

  setUserCount(userCount: number): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, userCount });
  }

  incrementUserCount(): this {
    return this.setUserCount(this.userCount + 1);
  }

  toJSON(): Data {
    const { id, organizationId, name } = this;
    const company = this.company?.toJSON();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, organizationId, company, name, createdAt, updatedAt };
  }

  private getOrganizationIds(data: { franchisees?: FranchiseeCollection, stores?: StoreCollection } = {}): Set<string> {
    const { franchisees, stores } = { ...this, ...data };
    const a = new Set(this.id ? [this.id] : []);
    const b = franchisees.organizationIds;
    const c = stores.organizationIds;
    return new Set([...a, ...b, ...c]);
  }

  static sort(businesses: Franchisor[]) {
    function orderByOrder(a: Franchisor, b: Franchisor) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { Franchisor };
export type {
  Data as FranchisorData,
  DataToSave as FranchisorDataToSave,
};

export * from './collection';
export * from './company-collection';