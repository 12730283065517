type Data = {
  top: number;
  left: number;
  width: number;
  height: number;
}

class Rect {

  readonly top: number;
  readonly left: number;
  readonly width: number;
  readonly height: number;

  constructor(data: Data = { top: 0, left: 0, width: 0, height: 0 }) {
    this.top = data.top;
    this.left = data.left;
    this.width = data.width;
    this.height = data.height;
  }

  update(data: Data): this {
    const { top, left, width, height } = data;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, top, left, width, height });
  }

  reset(): this {
    return this.update({ top: 0, left: 0, width: 0, height: 0 });
  }

}

export { Rect };