import { SupplierCollection, SupplierArrayField } from 'models/entities/supplier';
import { IngredientCategoryCollection, IngredientCategoryArrayField } from 'models/entities/ingredient-category';
import { StringField, NumberField, StringArrayField } from 'models/value-objects/editor-field';

import { IngredientItemEditor, IngredientItemDataToIdentify, IngredientItemDataToRevise } from '../../ingredient-item';
import { UnitBasedIngredientItem } from './unit-based';
import { PoundBasedIngredientItem, PoundBasedIngredientItemEditor } from '../pound-based';
import { calcGrossPrice } from '../../formula';
import { CreateGql, UpdateGql, DeleteGql } from '../../gql';

type Original = UnitBasedIngredientItem | PoundBasedIngredientItem;

type Data = {
  original: Original;
  suppliers: SupplierCollection;
  categories: IngredientCategoryCollection;
  unitSymbols: string[];
};

type DataToInit = {
  canDelete?: boolean;
};

type DataToApply = {
  supplier?: SupplierArrayField;
  category?: IngredientCategoryArrayField;
  name?: StringField;
  unitValue?: NumberField;
  unitSymbol?: StringArrayField;
  netPrice?: NumberField;
  yieldRate?: NumberField;
};

type DataToValidate = {
  supplier?: SupplierArrayField;
  category?: IngredientCategoryArrayField;
  name?: StringField;
  unitValue?: NumberField;
  unitSymbol?: StringArrayField;
  netPrice?: NumberField;
  yieldRate?: NumberField;
};

type DataToCreate = {
  supplierId: string;
  categoryId: string;
  name: string;
  unitValue: number;
  unitSymbol: string;
  netPrice: number;
  yieldRate: number;
}

type DataToUpdate = {
  supplierId?: string;
  categoryId?: string;
  name?: string;
  poundPrice?: null;
  unitValue?: number;
  unitSymbol?: string;
  netPrice?: number;
  yieldRate?: number;
}

class UnitBasedIngredientItemEditor extends IngredientItemEditor {

  readonly original: Original;
  readonly suppliers: SupplierCollection;
  readonly categories: IngredientCategoryCollection;
  readonly unitSymbols: string[];
  readonly supplier: SupplierArrayField;
  readonly category: IngredientCategoryArrayField;
  readonly name: StringField;
  readonly unitValue: NumberField;
  readonly unitSymbol: StringArrayField;
  readonly netPrice: NumberField;
  readonly yieldRate: NumberField;
  readonly grossPrice?: number;
  readonly canDelete: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;

  constructor(data: Data) {
    super(data);
    this.original = data.original;
    this.suppliers = data.suppliers;
    this.categories = data.categories;
    this.unitSymbols = data.unitSymbols;
    this.supplier = new SupplierArrayField({ list: this.suppliers.documents, object: this.original.supplier });
    this.category = new IngredientCategoryArrayField({ list: this.categories.documents, object: this.original.category });
    this.name = new StringField({ string: this.original.name });
    this.unitValue = new NumberField({ number: this.original.unitValue });
    this.unitSymbol = new StringArrayField({ list: this.unitSymbols, string: this.original.unitSymbol, required: false });
    this.netPrice = new NumberField({ number: this.original.netPrice });
    this.yieldRate = new NumberField({ number: this.original.yieldRate });
    this.grossPrice = calcGrossPrice(this.netPrice.number, this.yieldRate.number);
    this.canDelete = false;
    this.dirty = false;
    this.ok = this.validate();
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const grossPrice = calcGrossPrice(props.netPrice.number, props.yieldRate.number);
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, grossPrice, dirty, ok });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.supplier.ok) return false;
    if (!props.category.ok) return false;
    if (!props.name.ok) return false;
    if (!props.unitValue.ok) return false;
    if (!props.unitSymbol.ok) return false;
    if (!props.netPrice.ok) return false;
    if (!props.yieldRate.ok) return false;
    return true;
  }

  async save(): Promise<UnitBasedIngredientItemEditor> {
    if (!this.validate()) throw new Error('invalid object');
    return this.original.id ? await this.update() : await this.create();
  }

  private async create(): Promise<UnitBasedIngredientItemEditor> {
    const gql = await new CreateGql().fetch({ businessId: this.original.businessId, input: this.getDataToCreate() });
    if (!gql.document) throw new Error('invalid document');
    return this.identify(gql.document);
  }

  private async update(): Promise<UnitBasedIngredientItemEditor> {
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new UpdateGql().fetch({ id: this.original.id, input: this.getDataToUpdate() });
    if (!gql.document) throw new Error('invalid document');
    return this.revise(gql.document);
  }

  getDataToCreate(): DataToCreate {
    const supplierId = this.supplier.object!.id;
    const categoryId = this.category.object!.id;
    const name = this.name.string!;
    const unitValue = this.unitValue.number!;
    const unitSymbol = this.unitSymbol.string!;
    const netPrice = this.netPrice.number!;
    const yieldRate = this.yieldRate.number!;
    return { supplierId, categoryId, name, unitValue, unitSymbol, netPrice, yieldRate };
  }

  getDataToUpdate(): DataToUpdate {
    const supplierId = this.supplier.dirt?.id;
    const categoryId = this.category.dirt?.id;
    const name = this.name.dirt;
    const poundPrice = this.original instanceof PoundBasedIngredientItem ? null : undefined;
    const unitValue = this.unitValue.dirt;
    const unitSymbol = this.unitSymbol.dirt;
    const netPrice = this.netPrice.dirt;
    const yieldRate = this.yieldRate.dirt;
    return { supplierId, categoryId, name, poundPrice, unitValue, unitSymbol, netPrice, yieldRate };
  }

  identify(data: IngredientItemDataToIdentify): UnitBasedIngredientItemEditor {
    const original = this.rebuild().identify(data);
    return new UnitBasedIngredientItemEditor({ original, suppliers: this.suppliers, categories: this.categories, unitSymbols: this.unitSymbols });
  }

  revise(data: IngredientItemDataToRevise = {}): UnitBasedIngredientItemEditor {
    const original = this.rebuild().revise(data);
    return new UnitBasedIngredientItemEditor({ original, suppliers: this.suppliers, categories: this.categories, unitSymbols: this.unitSymbols });
  }

  rebuild(): UnitBasedIngredientItem {
    if (!this.validate()) throw new Error('invalid object');
    const supplier = this.supplier.object!;
    const category = this.category.object!;
    const name = this.name.string!;
    const unitValue = this.unitValue.number!;
    const unitSymbol = this.unitSymbol.string || '';
    const netPrice = this.netPrice.number!;
    const yieldRate = this.yieldRate.number!;
    const original = this.original instanceof PoundBasedIngredientItem ? this.original.toUnitBased() : this.original;
    return original.apply({ supplier, category, name, unitValue, unitSymbol, netPrice, yieldRate });
  }

  async delete(): Promise<this> {
    if (!this.canDelete) throw new Error('cannot delete');
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new DeleteGql().fetch({ id: this.original.id });
    if (!gql.document) throw new Error('invalid document');
    return this;
  }

  asPoundBased(): PoundBasedIngredientItemEditor {
    const original = this.original;
    return new PoundBasedIngredientItemEditor({ original, categories: this.categories, suppliers: this.suppliers, unitSymbols: this.unitSymbols });
  }

}

export { UnitBasedIngredientItemEditor };
export type {
  Data as UnitBasedIngredientItemEditorData,
  DataToApply as UnitBasedIngredientItemEditorDataToApply,
};
