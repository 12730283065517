import React, { FC, useState, useEffect, useRef, MouseEvent } from 'react';
import { navigate } from 'gatsby';

import { Business, BusinessCollection } from 'models/entities/business';

import { useLocation } from 'views/services/location';
import { useMemory, BusinessScope } from 'views/services/memory';
import { useDropdown, MenuItemActionsProps } from 'views/services/dropdown';
import { MoreActionsIcon } from 'views/components/icons';
import { CurrentPage, OtherPage, ActionsButton } from './index.styled';

interface BaseProps {
  page: string;
  name: string;
  actions?: boolean;
}

interface Props extends MenuItemActionsProps {
  business?: Business;
  businesses?: BusinessCollection;
}

const AbstractMenuItem: FC<BaseProps & Props> = props => {

  const { page, name, actions = true, business, businesses, ...actionsProps } = props;

  const location = useLocation();
  const memory = useMemory();
  const dropdown = useDropdown();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  function goTo() {
    if (business && businesses) memory.set('business-scope', new BusinessScope({ business, businesses }));
    navigate(getPathname());
  }

  function onClick(e: MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    const button = target.getBoundingClientRect();
    const x = button.left + button.width;
    const y = button.top + button.height;
    const props = actionsProps;
    dropdown.toggle('menu-item-actions', { target, point: { x, y }, props });
  }

  function getPathname() {
    return business ? `/${business.code}${page}` : page;
  }

  function watchBusiness() {
    const pathname = new RegExp('^' + getPathname() + '/?$');
    setIsCurrentPage(pathname.test(location.pathname));
  }

  useEffect(watchBusiness, [business]);

  return isCurrentPage ? (
    <CurrentPage>
      <h2>{name}</h2>
      {actions && (
        <ActionsButton>
          <button ref={button} onClick={onClick}><MoreActionsIcon /></button>
        </ActionsButton>
      )}
    </CurrentPage>
  ) : (
    <OtherPage onClick={() => goTo()}>
      <h2>{name}</h2>
      {actions && (
        <ActionsButton>
          <button disabled={true}><MoreActionsIcon /></button>
        </ActionsButton>
      )}
    </OtherPage>
  );

};

export { AbstractMenuItem, Props };