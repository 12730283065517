import { IngredientCategory, IngredientCategoryCollection } from 'models/entities/ingredient-category';

type Data = {
  target: IngredientCategoryCollection;
}

type DataToExport = {
  id: string;
  businessId: string;
  originalId: string;
  order: string;
  createdAt: string;
  updatedAt: string;
  originalName: string;
};

const fields = ['id', 'businessId', 'originalId', 'order', 'createdAt', 'updatedAt', 'originalName'];

class Model {

  readonly target: IngredientCategoryCollection;
  readonly tsv: string;

  constructor(data: Data) {
    this.target = data.target;
    this.tsv = this.export(this.target.documents);
  }

  private export(categories: IngredientCategory[]): string {
    const data = categories.map(it => Object.values(this.convert(it)).join('\t'));
    return [fields.join('\t'), ...data].join('\n');

  }

  private convert(category: IngredientCategory): DataToExport {
    const id = category.id!;
    const businessId = category.businessId;
    const originalId = category.original.id!;
    const order = category.order.toString();
    const createdAt = category.createdAt?.toJSON() ?? '';
    const updatedAt = category.updatedAt?.toJSON() ?? '';
    const originalName = category.original.name;
    return { id, businessId, originalId, order, createdAt, updatedAt, originalName };
  }

}

export { Model };