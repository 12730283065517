import { DependingQuery } from './depending-query';

import { IngredientCollectionQuery } from './ingredient-collection-query';
import { IngredientFieldsQuery } from './ingredient-fields-query';
import { IngredientItemFieldsQuery } from './ingredient-item-fields-query';

import { DishCategoryFieldsQuery } from '../dish-category';

class DishItemFieldsQuery extends DependingQuery {

  toString() {
    return `
      id
      menuCategoryId
      category {
        ${DishCategoryFieldsQuery}
      }
      order
      name
      ingredients {
        ${this.query}
      }
      price
      ingredientItemId
      posItemId
      createdAt
      updatedAt
    `;
  }

  static create() {
    const dishItemFieldsQuery = new DishItemFieldsQuery();
    const ingredientCollectionQuery = new IngredientCollectionQuery();
    const ingredientFieldsQuery = new IngredientFieldsQuery();
    const ingredientItemFieldsQuery = new IngredientItemFieldsQuery();
    dishItemFieldsQuery.to(ingredientCollectionQuery);
    ingredientCollectionQuery.to(ingredientFieldsQuery);
    ingredientFieldsQuery.to(ingredientItemFieldsQuery);
    ingredientItemFieldsQuery.to(dishItemFieldsQuery);
    return dishItemFieldsQuery;
  }

}

export { DishItemFieldsQuery };