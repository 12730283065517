import { UserEditor } from 'models/entities/user';
import { Organization } from 'models/entities/organization';

type Data = {
  user: UserEditor;
  organization: Organization;
};

type DataToApply = {
  user: UserEditor;
};

class EditUserTarget {

  readonly user: UserEditor;
  readonly organization: Organization;

  constructor(data: Data) {
    this.user = data.user;
    this.organization = data.organization
  }

  apply(data: DataToApply): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { EditUserTarget };

