import React, { FC } from 'react';

import { useAuth } from 'views/services/auth';

import { UserMenuActionsProps } from '../../../models/entities/props';
import { useDropdown } from '../../services/use-dropdown';
import { Root } from './index.styled';

const UserMenuActions: FC<UserMenuActionsProps> = ({ onManageOpenai, onPassword, onSignOut }) => {

  const dropdown = useDropdown();
  const auth = useAuth();

  function start(action: () => void) {
    dropdown.close();
    action();
  }

  return (
    <Root>
      <ol>
        {auth.user.admin && <li onClick={() => start(onManageOpenai)}>OpenAI Account</li>}
        {auth.user.invited && <li onClick={() => start(onPassword)}>Password</li>}
        <li onClick={() => start(onSignOut)}>Sign Out</li>
      </ol>
    </Root>
  );

};

export { UserMenuActions };