import { Api } from 'models/services/api';
import { CreateDishItemTransactionQuery } from 'models/services/graphql';
import { CreateIngredientsDataToFetch } from 'models/entities/ingredient';
import { CreateIngredientItemDataToFetch } from 'models/entities/ingredient-item';

import { CreateGqlDataToFetch } from '../../gql';
import { GqlResult } from './gql-result';

type DataToFetch = {
  dishItem: CreateGqlDataToFetch;
  ingredients?: CreateIngredientsDataToFetch;
  ingredientItem?: CreateIngredientItemDataToFetch;
};

const RequestQuery = `
  mutation CreateDishItemTransaction($dishItem: CreateDishItemArgs!, $ingredients: [CreateIngredientArgs!], $ingredientItem: CreateIngredientItemArgs) {
    ${CreateDishItemTransactionQuery}
  }
`;

type ResponseData = {
  data: {
    createDishItemTransaction: GqlResult;
  };
};

class CreateTransactionGql {

  readonly api: Api<ResponseData>;
  readonly result?: GqlResult;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.createDishItemTransaction;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, result });
  }

}

export { CreateTransactionGql };