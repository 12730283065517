import styled from 'styled-components';

import { logoFontSize, baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, lightRedColor, redTextColor, redLineColor } from 'views/services/color-scheme';

export const Root = styled.div`
  width: 480px;
  height: 480px;
  padding: 32px;
  background-color: ${baseColor};
  border-radius: 50%;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
`;

export const Header = styled.div`
  grid-row: 1 / 5;
  font-size: calc(${logoFontSize} * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 16px;
  & > p {
    padding: 4px 16px;
    white-space: pre-wrap;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: 1.5;
    color: ${redTextColor};
    background-color: ${lightRedColor};
    border-top: 1px solid ${redLineColor};
    border-bottom: 1px solid ${redLineColor};
    display: flex;
    align-items: center;
  }
`;

export const Content = styled.div`
  grid-row: 5 / 12;
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const Frame = styled.div`
  position: relative;
  width: calc(280px + 32px * 2);
  height: 100%;
  overflow: hidden;
`;

export const Steps = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% * 3);
  height: 100%;
  transition: left 300ms ease;
  &.step1 {
    left: 0;
  }
  &.step2 {
    left: -100%;
  }
  &.step3 {
    left: calc(-100% * 2);
  }
  display: flex;
  align-items: center;
  & > div {
    flex: 1;
    padding: 0 32px;
  }
`;
