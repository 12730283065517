import { css } from 'styled-components';

import { clearBlackColor } from 'views/services/color-scheme';

type Params = {
  zIndex: number;
};

export const getBackgroundCss = ({ zIndex }: Params) => {
  return css`
    position: relative;
    &::after {
      content: '';
      z-index: ${zIndex};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      pointer-events: none;
      transition-duration: 300ms;
    }
    &.modal-background::after {
      background-color: ${clearBlackColor};
      pointer-events: auto;
    }
  `;
};

export const BackgroundCss = getBackgroundCss({ zIndex: 0 })