import { Api } from 'models/services/api';
import { ReadIngredientItemQuery } from 'models/services/graphql';

import { IngredientItemData } from '../ingredient-item';

type DataToFetch = {
  id: string;
};

const RequestQuery = `
  query ReadIngredientItem($id: ID!) {
    ${ReadIngredientItemQuery}
  }
`;

type ResponseData = {
  data: {
    readIngredientItem: IngredientItemData;
  }
}

class ReadGql {

  readonly result?: IngredientItemData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.readIngredientItem;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };
export type { DataToFetch as ReadGqlGqlDataToFetch }
