import { Business } from 'models/entities/business';
import { OriginalDishCategory, OriginalDishCategoryCollection } from 'models/entities/original-dish-category';
import { MenuCategory, MenuCategoryCollection } from 'models/entities/menu-category';
import { DishCategory, DishCategoryCollection } from 'models/entities/dish-category';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  target: Business;
};

class Model {

  readonly target: Business;
  readonly menuCategories: MenuCategoryCollection;
  readonly current: MenuCategory;
  readonly categories: DishCategoryCollection;
  readonly originalCategories: OriginalDishCategoryCollection;
  readonly originalCategoryList: OriginalDishCategory[];
  readonly relationship?: Set<string>;
  readonly result: Business;

  constructor(data: Data) {
    this.target = data.target;
    this.menuCategories = this.target.menuCategories;
    this.current = this.menuCategories.documents[0];
    this.categories = this.current.dishCategories;
    this.originalCategories = this.current.original.dishCategories;
    this.originalCategoryList = this.getOriginalCategoryList(this.originalCategories, this.categories);
    this.result = this.target;
  }

  async readAllMenuCategories(): Promise<this> {
    const menuCategories = await this.menuCategories.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, menuCategories });
  }

  async readAllCategories(): Promise<this> {
    const categories = await this.categories.readAll();
    const originalCategoryList = this.getOriginalCategoryList(this.originalCategories, categories);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, categories, originalCategoryList });
  }

  async readAllOriginalCategories(): Promise<this> {
    const originalCategories = await this.originalCategories.readAll();
    const originalCategoryList = this.getOriginalCategoryList(originalCategories, this.categories);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, originalCategories, originalCategoryList });
  }

  async readRelationshipCollections(): Promise<this> {
    const collections = await Promise.all(this.categories.documents.map(it => RelationshipCollection.readByCategoryId(it.id!, 1)));
    const relationship = new Set(this.categories.documents.map((it, i) => collections[i].documents.length ? it.id : ''));
    relationship.delete('');
    return Object.assign(Object.create(this.constructor.prototype), { ...this, relationship });
  }

  select(current: MenuCategory): this {
    if (current === this.current) return this;
    const categories = current.dishCategories;
    const originalCategories = current.original.dishCategories;
    const originalCategoryList = this.getOriginalCategoryList(originalCategories, categories);
    const relationship = undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, current, categories, originalCategories, originalCategoryList, relationship });
  }

  build(original: OriginalDishCategory): DishCategory {
    return DishCategory.buildNext(this.current.dishCategories.documents, { menuCategoryId: this.current.id!, original });
  }

  buildBetween(prev: DishCategory | undefined, next: DishCategory | undefined, original: OriginalDishCategory): DishCategory {
    return DishCategory.buildBetween(prev, next, { menuCategoryId: this.current.id!, original })
  }

  add(category: DishCategory, done: boolean = false): this {
    return this.apply(this.categories.add(category), done);
  }

  remove(category: DishCategory, done: boolean = false): this {
    return this.apply(this.categories.remove(category), done);
  }

  merge(category: DishCategory, done: boolean = false): this {
    if (!category.id) return this.add(category);
    return this.apply(this.categories.replace(category), done);
  }

  private apply(categories: DishCategoryCollection, done: boolean): this {
    const originalCategoryList = this.getOriginalCategoryList(this.originalCategories, categories);
    const current = this.current.edit({ dishCategories: categories });
    const menuCategories = this.menuCategories.replace(current);
    const result = done ? this.result.edit({ menuCategories }) : this.result;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, categories, originalCategoryList, current, menuCategories, result });
  }

  private getOriginalCategoryList(originalCategories: OriginalDishCategoryCollection, categories: DishCategoryCollection): OriginalDishCategory[] {
    const ids = categories.documents.map(it => it.original.id);
    return originalCategories.documents.filter(it => !ids.includes(it.id));
  }

}

export { Model };