import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightTextColor } from 'views/services/color-scheme';

export const Root = styled.article`
  background-color: ${baseColor};
  border-radius: 8px;
  padding: 32px;
  width: 960px;
  & > hgroup {
    display: flex;
    align-items: end;
    font-family: ${baseFontFamily};
    & > h1 {
      font-size: calc(${baseFontSize} * 1.5);
      color: ${textColor};
    }
    & > p {
      margin-left: 16px;
      font-size: calc(${baseFontSize} * 1);
      color: ${lightTextColor};
    }
  }
  & > fieldset {
    & > div {
      margin-top: 24px;
    }
  }
`;

export const Footer = styled.footer`
  margin-top: 48px;
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

