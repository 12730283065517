import { Api } from 'models/services/api';
import { DeleteDishItemQuery } from 'models/services/graphql';

type DataToFetch = {
  id: string;
};

const RequestQuery = `
  mutation DeleteDishItem($id: ID!) {
    ${DeleteDishItemQuery}
  }
`;

type ResponseData = {
  data: {
    deleteDishItem: DataToFetch;
  };
};

class DeleteGql {

  readonly api: Api<ResponseData>;
  readonly document?: DataToFetch;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.deleteDishItem;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { DeleteGql };
export type { DataToFetch as DeleteGqlDataToFetch }
