import { DishCategory, DishCategoryCollection } from 'models/entities/dish-category';

type Data = {
  target: DishCategoryCollection;
}

type DataToExport = {
  id: string;
  menuCategoryId: string;
  originalId: string;
  order: string;
  createdAt: string;
  updatedAt: string;
  originalName: string;
};

const fields = ['id', 'menuCategoryId', 'originalId', 'order', 'createdAt', 'updatedAt', 'originalName'];

class Model {

  readonly target: DishCategoryCollection;
  readonly tsv: string;

  constructor(data: Data) {
    this.target = data.target;
    this.tsv = this.export(this.target.documents);
  }

  private export(categories: DishCategory[]): string {
    const data = categories.map(it => Object.values(this.convert(it)).join('\t'));
    return [fields.join('\t'), ...data].join('\n');

  }

  private convert(category: DishCategory): DataToExport {
    const id = category.id!;
    const menuCategoryId = category.menuCategoryId;
    const originalId = category.original.id!;
    const order = category.order.toString();
    const createdAt = category.createdAt?.toJSON() ?? '';
    const updatedAt = category.updatedAt?.toJSON() ?? '';
    const originalName = category.original.name;
    return { id, menuCategoryId, originalId, order, createdAt, updatedAt, originalName };
  }

}

export { Model };