import { OpenaiAccountFieldsQuery } from '../openai-account';

export const CurrentUserSettingsFieldsQuery = `
  openaiAccount {
    ${OpenaiAccountFieldsQuery}
  }
`;

export const ReadCurrentUserSettingsQuery = `
  readCurrentUserSettings { 
    ${CurrentUserSettingsFieldsQuery}
  }
`;
