import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';
import { Currency, CurrencyCode } from 'models/value-objects/currency';

import { BusinessCategory, BusinessCategoryData } from 'models/entities/business-category';
import { PosSystem, PosSystemData } from 'models/entities/pos-system';
import { IngredientCategoryCollection, IngredientCategoryCollectionData } from 'models/entities/ingredient-category';
import { MenuCategoryCollection, MenuCategoryCollectionData } from 'models/entities/menu-category';

import { ReadByCodeGql, CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  category: BusinessCategoryData | BusinessCategory;
  posSystem: PosSystemData | PosSystem;
  order: string | LexoRank;
  code?: string;
  name?: string;
  location?: string;
  currency?: CurrencyCode | Currency;
  closed?: boolean;
  deleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  ingredientCategories?: IngredientCategoryCollectionData;
  menuCategories?: MenuCategoryCollectionData;
};

type DataToBuild = {
  category: BusinessCategory;
  posSystem: PosSystem;
};

type DataToEdit = {
  category?: BusinessCategory;
  posSystem?: PosSystem;
  name?: string;
  location?: string;
  currency?: Currency;
  code?: string;
  closed?: boolean;
  ingredientCategories?: IngredientCategoryCollection;
  menuCategories?: MenuCategoryCollection;
};

type DataToSave = {
  categoryId: string;
  posSystemId: string;
  order: string;
  code: string;
  name: string;
  location: string;
  currency: string;
  closed: boolean;
  deleted: boolean;
};

class Business {

  readonly id?: string;
  readonly category: BusinessCategory;
  readonly posSystem: PosSystem;
  readonly order: LexoRank;
  readonly code: string;
  readonly name: string;
  readonly location: string;
  readonly currency: Currency;
  readonly closed: boolean;
  readonly deleted: boolean;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly ingredientCategories: IngredientCategoryCollection;
  readonly menuCategories: MenuCategoryCollection;

  private readonly createGql: CreateGql;
  private readonly updateGql: UpdateGql;
  private readonly deleteGql: DeleteGql;

  constructor(data: Data) {
    this.id = data.id;
    this.category = data.category instanceof BusinessCategory ? data.category : new BusinessCategory(data.category);
    this.posSystem = data.posSystem instanceof PosSystem ? data.posSystem : new PosSystem(data.posSystem);
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.code = data.code ?? '';
    this.name = data.name ?? '';
    this.location = data.location ?? '';
    this.currency = data.currency instanceof Currency ? data.currency : new Currency({ code: data.currency });
    this.closed = data.closed ?? false;
    this.deleted = data.deleted ?? false;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.ingredientCategories = new IngredientCategoryCollection(this.id, data.ingredientCategories);
    this.menuCategories = new MenuCategoryCollection(this.id, data.menuCategories);
    this.createGql = new CreateGql();
    this.updateGql = new UpdateGql();
    this.deleteGql = new DeleteGql();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.category) return false;
    if (!this.posSystem) return false;
    if (!this.name) return false;
    if (!this.location) return false;
    if (!this.currency) return false;
    if (!this.code) return false;
    return true;
  }

  async save(): Promise<Business> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<Business> {
    const createGql = await this.createGql.fetch({ input: this.getDataToSave() });
    if (!createGql.document) throw new Error('invalid document');
    return new Business(createGql.document);
  }

  private async update(): Promise<Business> {
    if (!this.id) throw new Error('invalid relationship');
    const updateGql = await this.updateGql.fetch({ id: this.id, input: this.getDataToSave() });
    if (!updateGql.document) throw new Error('invalid document');
    return new Business(updateGql.document);
  }

  private getDataToSave(): DataToSave {
    if (!this.posSystem) throw new Error('posSystem is undefined');
    const { category, posSystem, order, code, name, location, currency, closed, deleted } = this;
    return { categoryId: category.id!, posSystemId: posSystem.id, order: order.toString(), code, name, location, currency: currency.code, closed, deleted };
  }

  async delete(): Promise<Business> {
    if (!this.id) throw new Error('invalid relationship');
    const deleteGql = await this.deleteGql.fetch({ id: this.id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new Business(deleteGql.document);
  }

  toJSON(): Data {
    const { id, code, name, location, closed, deleted } = this;
    const category = this.category.toJSON();
    const posSystem = this.posSystem.toJSON();
    const order = this.order.toString();
    const currency = this.currency.code;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    const ingredientCategories = this.ingredientCategories.toJSON();
    const menuCategories = this.menuCategories.toJSON();
    return { id, category, posSystem, order, code, name, location, currency, closed, deleted, createdAt, updatedAt, ingredientCategories, menuCategories };
  }

  static sort(businesses: Business[]) {
    function orderByOrder(a: Business, b: Business) {
      if (a.order.toString() < b.order.toString()) return -1;
      else if (a.order.toString() > b.order.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

  static async readByCode(code: string): Promise<Business> {
    const readByCodeGql = await new ReadByCodeGql().fetch({ code });
    if (!readByCodeGql.document) throw new Error('invalid document');
    return new Business(readByCodeGql.document);
  }

  static buildNext(list: Business[], data: DataToBuild): Business {
    const order = list[list.length - 1]?.order.genNext() ?? LexoRank.middle();
    return new Business({ order, category: data.category, posSystem: data.posSystem });
  }

}

export { Business };
export type {
  Data as BusinessData,
  DataToSave as BusinessDataToSave,
};

export * from './collection';