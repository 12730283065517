type Data = {
  id: string;
};

class Relationship {

  readonly id: string;

  constructor(data: Data) {
    this.id = data.id;
  }

}

export { Relationship };
export type { Data as RelationshipData };

export * from './collection';