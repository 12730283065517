import React, { FC } from 'react';

import { useAuth } from 'views/services/auth';
import { OpenaiIcon } from 'views/components/openai-icon';
import { Root } from './index.styled';

interface Props {
  onChat: () => void;
  disabled?: boolean;
}

const ChatMenu: FC<Props> = ({ onChat, disabled = false }) => {

  const auth = useAuth();

  function open() {
    onChat();
  }

  return (
    <Root>
      <button onClick={() => open()} disabled={disabled || !auth.user.openaiAccount}>
        <OpenaiIcon />
      </button>
    </Root>
  );

};

export { ChatMenu };