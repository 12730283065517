import { StringField } from '../field';

import { OpenaiAccount } from '../account';
import { ModelDataToSend } from './model';
import { Message, MessageDataToSend } from './message';
import { CompletionStream } from './completion';
import { OpenaiChat } from './chat';
import { CreateApi } from './api';

type Data = {
  original: OpenaiChat;
  account: OpenaiAccount;
};

type DataToApply = {
  content?: StringField;
};

type DataToValidate = {
  content?: StringField;
};

type DataToSend = {
  model: ModelDataToSend;
  messages: MessageDataToSend[];
  stream?: boolean;
};

class OpenaiChatEditor {

  readonly original: OpenaiChat;
  readonly account: OpenaiAccount;
  readonly content: StringField;
  readonly ok: boolean;
  readonly completion?: CompletionStream;

  constructor(data: Data) {
    this.original = data.original;
    this.account = data.account;
    this.content = new StringField();
    this.ok = this.validate();
  }

  apply(data: DataToApply): this {
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data, ok });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.content.ok) return false;
    return true;
  }

  commit(): OpenaiChatEditor {
    if (!this.ok) throw new Error('invalid editor');
    const role = 'user';
    const content = this.content.string!;
    return new OpenaiChatEditor({ original: this.original.post(new Message({ role, content })), account: this.account });
  }

  async * send(): AsyncGenerator<OpenaiChatEditor> {
    let completion;
    for await (const api of new CreateApi(this.account).fetchStream(this.getDataToSend())) {
      if (!api.result) throw new Error('invalid result');
      completion = completion ? completion.add(api.result) : new CompletionStream(api.result);
      if (completion.finished) yield new OpenaiChatEditor({ original: this.original.receive(completion), account: this.account });
      else yield Object.assign(Object.create(this.constructor.prototype), { ...this, completion });
    }
  }

  getDataToSend(): DataToSend {
    const model = this.original.model.id;
    const messages = this.original.messages.map(it => it.getDataToSend());
    const stream = true;
    return { model, messages, stream };
  }

}

export { OpenaiChatEditor };
export type {
  Data as OpenaiChatEditorData,
  DataToApply as OpenaiChatEditorDataToApply,
  DataToSend as OpenaiChatEditorDataToSend,
};