import { CentralKitchen, CentralKitchenData } from './index';

type Data = {
  documents: CentralKitchenData[];
  nextToken: string;
};

class CompanyCentralKitchenCollection {

  readonly companyId: string;
  readonly documents: CentralKitchen[];
  readonly nextToken: string;

  constructor(companyId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.companyId = companyId;
    this.documents = data.documents.map(it => new CentralKitchen(it));
    this.nextToken = data.nextToken;
  }

}

export { CompanyCentralKitchenCollection };
export type { Data as CompanyCentralKitchenCollectionData };