import { Api } from 'models/services/api';
import { UpdateSupplierQuery } from 'models/services/graphql';

import { SupplierData, SupplierDataToSave } from '../index';

type DataToUpdate = {
  id: string;
  input: SupplierDataToSave;
};

const RequestQuery = `
  mutation UpdateSupplier($id: ID!, $input: SupplierInput!) {
    ${UpdateSupplierQuery}
  }
`;

type ResponseData = {
  data: {
    updateSupplier: SupplierData
  }
}

class UpdateGql {

  readonly api: Api<ResponseData>;
  readonly document?: SupplierData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToUpdate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.updateSupplier;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { UpdateGql };