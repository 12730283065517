import styled, { keyframes } from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight, numberFontFamily, numberFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, darkTextColor, lightPrimaryColor, darkPrimaryColor, lightTextColor, darkBaseColor } from 'views/services/color-scheme';
import { headerHeight } from 'views/components/header';

import { getBackgroundCss } from '../../services/background-css';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainPanel = styled.div`
  order: 2;
  background-color: ${baseColor};
  border-radius: 8px;
  position: relative;
  ${getBackgroundCss({ zIndex: 4 })}
`;

export const Category = styled.div`
  background-color: ${darkPrimaryColor};
  height: calc(${headerHeight} * 1.5);
  border-radius: 8px 8px 0 0;
`;

export const ItemHeader = styled.div`
  padding: calc(32px - (4px + 2px)) calc(24px - (8px + 2px)) calc(24px - (4px + 2px));
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h1 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
    width: 400px;
  }
  & > div {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    color: ${lightTextColor};
    width: 160px;
    display: flex;
    align-items: center;
    gap: 8px;
    & > h2 {
      white-space: nowrap;
    }
  }
`;

export const ItemFooter = styled.div`
  padding: 16px 24px 24px;
  & > table {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: calc(${baseLineHeight} + 0.4);
    color: ${textColor};
    width: 100%;
    & > thead > tr > th {
      width: calc(100% / 3);
      color: ${lightTextColor};
      text-align: center;
    }
    & > tbody > tr > td {
      font-family: ${numberFontFamily};
      font-size: calc(${numberFontSize} * 1.25);
      text-align: right;
      & > fieldset {
        position: relative;
        display: inline;
        width: 8em;
      }
    }
  }
`;

export const Item = styled.div`
  width: 640px;
`;

export const Ingredients = styled.div`
  padding: calc(16px - (4px + 2px)) calc(24px - (8px + 2px));
  & > div {
    cursor: pointer;
    box-sizing: border-box;
    padding: 4px 0;
    border: 2px solid ${lightPrimaryColor};
    border-radius: 4px;
    background-color: ${darkBaseColor};
    &.editing {
      cursor: auto;
      &.dnd-dragging {
        cursor: grabbing;
      }
    }
    &:hover {
      border-color: ${darkPrimaryColor};
    }
    & > div {
      width: 100%;
      max-height: 320px;
      overflow-y: scroll;
      & > table {
        font-family: ${baseFontFamily};
        font-size: calc(${baseFontSize} * 1);
        line-height: calc(${baseLineHeight} + 0.4);
        color: ${textColor};
        width: 100%;
        & > thead {
          position: sticky;
          top: 0;
          z-index: 1;
          & > tr {
            & > th {
              padding: 0 8px;
              background-color: ${darkBaseColor};
              color: ${lightTextColor};
              white-space: nowrap;
              &.center {
                text-align: center;
              }
            }
          }
        }
        & > tbody {
          z-index: 0;
          & > tr {
            &.editing {
              cursor: grab;
              user-select: none;
              &:hover {
                background-color: ${lightPrimaryColor};
              }
              &.dnd-grabbing, &.dnd-dragging {
                cursor: grabbing;
                &:hover {
                  background-color: transparent;
                }
                &.dnd-target {
                  background-color: ${lightPrimaryColor};
                }
              }
            }
            & > td {
              padding: 0 8px;
              white-space: nowrap;
              &.text {
                max-width: 240px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              &.right {
                text-align: right;
              }
              &.number {
                font-family: ${numberFontFamily};
                font-size: calc(${numberFontSize} * 1);
                text-align: right;
              }
              &.none {
                color: ${darkTextColor};
              }
              & > fieldset {
                position: relative;
                display: inline;
                width: 4em;
              }
            }
          }
        }
      }
    }
  }
`;

export const Settings = styled.div`
  padding: 8px 24px;
  background-color: ${darkBaseColor};
`;

const showIngredientItemsPanel = keyframes`
  0% {
    margin-right: 0;
    width: 0;
    height: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: transparent;
  }
  1% {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: ${darkPrimaryColor};
  }
  50% {
    margin-right: 16px;
    width: 640px;
    height: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  51% {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }
  99% {
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-color: ${darkPrimaryColor};
  }
  100% {
    margin-right: 16px;
    width: 640px;
    height: 520px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: transparent;
  }
`;

const hideIngredientItemsPanel = keyframes`
  0% {
    margin-right: 16px;
    width: 640px;
    height: 520px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: transparent;
  }
  1% {
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-color: ${darkPrimaryColor};
  }
  49% {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }
  50% {
    margin-right: 16px;
    width: 640px;
    height: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  99% {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: ${darkPrimaryColor};
  }
  100% {
    margin-right: 0;
    width: 0;
    height: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-color: transparent;
  }
`;

export const PanelOpener = styled.div`
  order: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 0;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 0 solid transparent;
  &.editing {
    animation: ${showIngredientItemsPanel} 600ms ease forwards;
  }
  &.done {
    animation: ${hideIngredientItemsPanel} 600ms ease forwards;
  }
`;

export const IngredientItemsPanel = styled.div`
  background-color: ${baseColor};
  border-radius: 8px;
  width: 640px;
  ${getBackgroundCss({ zIndex: 4 })}
`;

export const IngredientItems = styled.div`
  padding: 24px calc(24px - (8px + 2px));
  & > h2 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${lightTextColor};
    padding-bottom: 16px;
  }
  & > div {
    box-sizing: border-box;
    padding: 4px 0;
    border: 2px solid ${lightPrimaryColor};
    border-radius: 4px;
    background-color: ${darkBaseColor};
    &:hover {
      border-color: ${darkPrimaryColor};
    }
    & > div {
      width: 100%;
      height: 320px;
      overflow: scroll;
      & > table {
        font-family: ${baseFontFamily};
        font-size: calc(${baseFontSize} * 1);
        line-height: calc(${baseLineHeight} + 0.4);
        color: ${textColor};
        width: 100%;
        & > thead {
          position: sticky;
          top: 0;
          & > tr {
            & > th {
              background-color: ${darkBaseColor};
              color: ${lightTextColor};
              white-space: nowrap;
              &.text {
                padding: 0 8px;
              }
              &.center {
                text-align: center;
              }
            }
          }
        }
        & > tbody {
          & > tr {
            cursor: grab;
            user-select: none;
            &:hover {
              background-color: ${lightPrimaryColor};
            }
            &.disabled {
              color: ${darkTextColor};
              pointer-events: none;
            }
            &.dnd-grabbing, &.dnd-dragging {
              cursor: grabbing;
              &:hover {
                background-color: transparent;
              }
              &.dnd-target {
                background-color: ${lightPrimaryColor};
              }
            }
            & > td {
              padding: 0 8px;
              white-space: nowrap;
              &.right {
                text-align: right;
              }
              &.number {
                font-family: ${numberFontFamily};
                font-size: calc(${numberFontSize} * 1);
                text-align: right;
              }
              &.none {
                color: ${darkTextColor};
              }
            }
          }
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  box-sizing: border-box;
  padding: 24px 0 24px;
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const MoreActions = styled.div`
  position: absolute;
  bottom: 16px;
  left: calc(100% + 8px);
  & > div {
    background-color: ${baseColor};
    font-size: calc(${baseFontSize} * 1.25);
    border-radius: 50%;
    ${getBackgroundCss({ zIndex: 4 })}
  }
`;
