import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';

import { OriginalDishCategory, OriginalDishCategoryData } from 'models/entities/original-dish-category';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  menuCategoryId: string;
  original: OriginalDishCategoryData | OriginalDishCategory;
  order: string | LexoRank;
  createdAt?: string;
  updatedAt?: string;
};

type DataToBuild = {
  menuCategoryId: string;
  original: OriginalDishCategory;
};

type DataToCreate = {
  originalId: string;
  order: string;
};

type DataToUpdate = {
  order: string;
};

type Prev = DishCategory | undefined;
type Next = DishCategory | undefined;

class DishCategory {

  readonly id: string;
  readonly menuCategoryId: string;
  readonly original: OriginalDishCategory;
  readonly order: LexoRank;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.menuCategoryId = data.menuCategoryId;
    this.original = data.original instanceof OriginalDishCategory ? data.original : new OriginalDishCategory(data.original);
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  moveBetween(prev: Prev, next: Next): this {
    const order = DishCategory.getOrderBetween(prev, next);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, order });
  }

  async save(): Promise<DishCategory> {
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<DishCategory> {
    const gql = await new CreateGql().fetch({ menuCategoryId: this.menuCategoryId, input: this.getDataToCreate() });
    if (!gql.document) throw new Error('invalid document');
    return new DishCategory(gql.document);
  }

  private async update(): Promise<DishCategory> {
    if (!this.id) throw new Error('invalid id');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToUpdate() });
    if (!gql.document) throw new Error('invalid document');
    return new DishCategory(gql.document);
  }

  private getDataToCreate(): DataToCreate {
    const { original, order } = this;
    return { originalId: original.id, order: order.toString() };
  }

  private getDataToUpdate(): DataToUpdate {
    const { order } = this;
    return { order: order.toString() };
  }

  async delete(): Promise<DishCategory> {
    if (!this.id) throw new Error('invalid relationship');
    const { id } = this;
    const gql = await new DeleteGql().fetch({ id });
    if (!gql.document) throw new Error('invalid document');
    return new DishCategory(gql.document);
  }

  toJSON(): Data {
    const { id, menuCategoryId } = this;
    const original = this.original.toJSON();
    const order = this.order.toString();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, menuCategoryId, original, order, createdAt, updatedAt };
  }

  static sort(categories: DishCategory[]) {
    function orderByOrder(a: DishCategory, b: DishCategory) {
      if (a.order.toString() < b.order.toString()) return -1;
      else if (a.order.toString() > b.order.toString()) return 1;
      else return 0;
    }
    return categories.sort(orderByOrder);
  }

  static buildNext(list: DishCategory[], data: DataToBuild): DishCategory {
    const order = list[list.length - 1]?.order.genNext() ?? LexoRank.middle();
    return new DishCategory({ ...data, order });
  }

  static buildBetween(prev: Prev, next: Next, data: DataToBuild): DishCategory {
    const order = DishCategory.getOrderBetween(prev, next);
    return new DishCategory({ ...data, order });
  }

  private static getOrderBetween(prev: Prev, next: Next): LexoRank {
    switch (true) {
      case !prev && !next: return LexoRank.middle();
      case !prev && !!next: return next!.order.genPrev();
      case !!prev && !next: return prev!.order.genNext();
      default: return prev!.order.between(next!.order);
    }
  }

}

export { DishCategory };
export type {
  Data as DishCategoryData,
  DataToCreate as DishCategoryDataToCreate,
  DataToUpdate as DishCategoryDataToUpdate,
};

export * from './collection';
export * from './editor-field';