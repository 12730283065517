import { BrandIdentityFieldsQuery } from '../brand-identity';
import { CompanyFieldsQuery } from '../company';
import { FranchisorCollectionQuery } from '../franchisor';
import { StoreCollectionQuery } from '../store';

export const HeadquartersFieldsQuery = `
  id
  type
  parentId
  ownerId
  brandIdentity {
    ${BrandIdentityFieldsQuery}
  }
  company {
    ${CompanyFieldsQuery}
  }
  name
  franchisors {
    ${FranchisorCollectionQuery}
  }
  stores {
    ${StoreCollectionQuery}
  }
  userCount
  createdAt
  updatedAt
`;

export const HeadquartersCollectionQuery = `
  documents {
    ${HeadquartersFieldsQuery}
  }
  nextToken
`;

export const ReadHeadquartersQuery = `
  readHeadquarters(nextToken: $nextToken) { 
    ${HeadquartersCollectionQuery}
  }
`;

export const CreateHeadquartersQuery = `
  createHeadquarters(brandId: $brandId, input: $input) { 
    ${HeadquartersFieldsQuery}
  }
`;

export const UpdateHeadquartersQuery = `
  updateHeadquarters(id: $id, input: $input) { 
    ${HeadquartersFieldsQuery}
  }
`;

export const DeleteHeadquartersQuery = `
  deleteHeadquarters(id: $id) { 
    ${HeadquartersFieldsQuery}
  }
`;
