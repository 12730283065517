import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lightRedColor, redTextColor, redLineColor } from 'views/services/color-scheme';

export const Root = styled.article`
  background-color: ${baseColor};
  border-radius: 8px;
  padding: 32px;
  width: 560px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.header`
  & > h1 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
  }
`;

export const Alert = styled.div`
  & > p {
    padding: 4px 8px;
    white-space: pre-wrap;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: 1.5;
    color: ${redTextColor};
    background-color: ${lightRedColor};
    border-top: 1px solid ${redLineColor};
    border-bottom: 1px solid ${redLineColor};
    display: flex;
    align-items: center;
  }
`;

export const Content = styled.div`
  & > fieldset {
    font-size: calc(${baseFontSize} * 1.25);
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Footer = styled.footer`
  margin-top: 16px;
  font-size: calc(${baseFontSize} * 1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

