import { StringField } from 'models/value-objects/field';

type Mode = 'default' | 'searching';

type Data = {
  field: StringField;
};

class Model {

  readonly mode: Mode;
  readonly field: StringField;

  constructor(data: Data) {
    this.mode = 'default';
    this.field = data.field;
  }

  toggle(): this {
    return this.mode === 'default' ? this.search() : this.reset();
  }

  search(): this {
    const mode = 'searching';
    return Object.assign(Object.create(this.constructor.prototype), { ...this, mode });
  }

  reset(): this {
    const mode = 'default';
    return Object.assign(Object.create(this.constructor.prototype), { ...this, mode });
  }

  change(value: string): this {
    const field = this.field.change(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field });
  }

}

export { Model };