import { Api } from 'models/services/api';
import { CreateDishItemsQuery } from 'models/services/graphql';
import { DishItemData, DishItemEditorDataToCreate } from 'models/entities/dish-item';

type DataToCreate = {
  menuCategoryId: string;
  input: DishItemEditorDataToCreate[];
};

const RequestQuery = `
  mutation ImportDishItems($menuCategoryId: ID!, $input: [CreateDishItemInput!]!) {
    ${CreateDishItemsQuery}
  }
`;

type ResponseData = {
  data: {
    createDishItems: DishItemData[]
  }
}

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly documents?: DishItemData[];

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToCreate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const documents = api.response.data.createDishItems;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, documents });
  }

}

export { CreateGql };