type Data = {
  number?: number;
  required?: boolean;
};

class NumberField {

  readonly number?: number;
  readonly dirt?: number;
  readonly value: string;
  readonly required: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;
  readonly error: string;

  constructor(data: Data) {
    this.number = data.number;
    this.value = this.number?.toString() ?? '';
    this.required = data.required ?? true;
    this.dirty = false;
    this.ok = this.check(this.value);
    this.error = this.getError(this.value);
  }

  change(value: string): this {
    const number = this.getNumber(value);
    const dirt = number;
    const dirty = true;
    const ok = this.check(value);
    const error = this.getError(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, value, number, dirt, dirty, ok, error });
  }

  private check(value: string): boolean {
    if (this.required && !value) return false;
    return !!value.match(/^\d+[\d,]*(\.\d+)?$/);
  }

  private getNumber(value: string): number | undefined {
    return this.check(value) ? parseFloat(value.replace(',', '')) : this.number;
  }

  private getError(value: string): string {
    if (value === '') return '';
    if (!this.check(value)) return 'Must be a number';
    return '';
  }

}

export { NumberField };