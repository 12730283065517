import { HeadquartersFieldsQuery } from '../headquarters';

export const BrandFieldsQuery = `
  id
  name
  headquarters {
    ${HeadquartersFieldsQuery}
  }
  createdAt
  updatedAt
`;

export const BrandCollectionQuery = `
  documents {
    ${BrandFieldsQuery}
  }
  nextToken
`;

export const ReadBrandsQuery = `
  readBrands(nextToken: $nextToken) { 
    ${BrandCollectionQuery}
  }
`;

export const CreateBrandQuery = `
  createBrand(input: $input) { 
    ${BrandFieldsQuery}
  }
`;

export const UpdateBrandQuery = `
  updateBrand(id: $id, input: $input) { 
    ${BrandFieldsQuery}
  }
`;

export const DeleteBrandQuery = `
  deleteBrand(id: $id) { 
    ${BrandFieldsQuery}
  }
`;
