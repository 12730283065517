import React, { FC } from 'react';

import { faUser } from '@fortawesome/free-solid-svg-icons';

import { AbstractIcon } from '../abstract-icon';

interface Props {
  className?: string;
}

const UserIcon: FC<Props> = props => <AbstractIcon {...props} icon={faUser} />;

export { UserIcon };