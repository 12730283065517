import { DishItemReport, DishItemReportRecord } from 'models/entities/dish-item-report';
import { Business } from 'models/entities/business';
import { MenuCategory } from 'models/entities/menu-category';
import { DishCategory } from 'models/entities/dish-category';

type Data = {
  target: DishItemReport;
  business: Business;
  menuCategory: MenuCategory;
  category?: DishCategory;
}

class Model {

  readonly target: DishItemReport;
  readonly business: Business;
  readonly menuCategory: MenuCategory;
  readonly category?: DishCategory;
  readonly records: DishItemReportRecord[];

  constructor(data: Data) {
    this.target = data.target;
    this.business = data.business;
    this.menuCategory = data.menuCategory;
    this.category = data.category;
    this.records = (this.category ? this.target.records.filter(it => it.item.category.id === this.category!.id) : this.target.records).sort((a, b) => b.productSales - a.productSales);
  }

}

export { Model };