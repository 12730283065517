import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightPrimaryColor, darkPrimaryColor, lightTextColor, darkBaseColor, redTextColor, lightRedColor, redLineColor, darkTextColor, primaryTextColor } from 'views/services/color-scheme';

const h2FontSize = baseFontSize;
const h2Margin = '8px';

interface Height { height?: string; }

export const Root = styled.div<Height>`
  & > label {
    display: block;
    cursor: pointer;
    & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
      font-family: ${baseFontFamily};
      font-size: calc(${h2FontSize} * 1);
      color: ${lightTextColor};
      margin-bottom: ${h2Margin};
      &.disabled {
        color: ${darkTextColor};
      }
    }
    & > div {
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 1.25);
      color: ${textColor};
      display: flex;
      align-items: center;
      & > textarea {
        display: block;
        font-family: ${baseFontFamily};
        font-size: calc(${baseFontSize} * 1.25);
        line-height: 1.5;
        white-space: pre;
        color: ${textColor};
        background-color: ${darkBaseColor};
        width: 100%;
        height: ${p => p.height ?? '120px'};
        padding: 4px 8px;
        border: 2px solid ${lightPrimaryColor};
        border-radius: 4px;
        box-sizing: border-box;
        resize: none;
        &:hover, &:focus {
          border-color: ${darkPrimaryColor};
        }
        &::selection {
          background-color: ${darkPrimaryColor};
          color: ${primaryTextColor};
        }
      }
      &:hover {
        & > input, & > span, & > button {
          border-color: ${darkPrimaryColor};
        }
      }
      & > button {
        cursor: pointer;
      }
    }
    position: relative;
    & > p {
      z-index: 1;
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 0.8);
      line-height: 1;
      color: ${redTextColor};
      background-color: ${lightRedColor};
      border-left: 1px solid ${redLineColor};
      border-bottom: 1px solid ${redLineColor};
      white-space: nowrap;
      position: absolute;
      height: 24px;
      bottom: calc(40px + 8px);
      left: -16px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 22px;
        background-color: ${redLineColor};
        transform-origin: left bottom;
        rotate: 135deg;
      }
    }
  }
`;
