import { DishItem } from './index';
import { IngredientItem } from 'models/entities/ingredient-item';

import { ReadCollectionGql } from './gql';
import { DishItemData } from './dish-item';

export type Data = {
  documents: DishItemData[];
  nextToken: string;
};

class DishItemCollection {

  readonly menuCategoryId: string;
  readonly documents: DishItem[];
  readonly nextToken: string;

  constructor(menuCategoryId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.menuCategoryId = menuCategoryId;
    this.documents = data.documents.map(it => new DishItem(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { menuCategoryId, nextToken } = collection;
      if (!menuCategoryId) continue;
      const gql = await new ReadCollectionGql().fetch({ menuCategoryId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new DishItemCollection(menuCategoryId, gql.result));
    }
    return collection;
  }

  merge(collection: DishItemCollection): this {
    const documents = DishItem.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(item: DishItem): this {
    const documents = DishItem.sort([...this.documents, item]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  addBatch(items: DishItem[]): this {
    const documents = DishItem.sort([...this.documents, ...items]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(item: DishItem, ingredientItem?: IngredientItem): this {
    const documents = DishItem.sort(this.documents.map(it => it.id === item.id ? item : !ingredientItem ? it : it.replace(ingredientItem)));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(item: DishItem): this {
    const documents = this.documents.filter(it => it.id !== item.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(menuCategoryId: string): Promise<DishItemCollection> {
    const gql = await new ReadCollectionGql().fetch({ menuCategoryId });
    if (!gql.result) throw new Error('invalid result');
    return await new DishItemCollection(menuCategoryId, gql.result).readAll();
  }

}

export { DishItemCollection };
export type {
  Data as DishItemCollectionData,
};