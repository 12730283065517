import dayjs, { Dayjs } from 'dayjs';

import { Organization } from 'models/entities/organization';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  type?: string;
  name?: string;
  userCount?: number;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  name?: string;
};

type DataToSave = {
  type: string;
  name: string;
};

class Company implements Organization {

  readonly id: string;
  readonly type: string;
  readonly name: string;
  readonly userCount: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data = {}) {
    if (data.type && data.type !== 'Company') throw new Error('invalid type');
    this.id = data.id ?? '';
    this.type = 'Company';
    this.name = data.name ?? '';
    this.userCount = data.userCount ?? 0;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<Company> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<Company> {
    const gql = await new CreateGql().fetch({ input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Company(gql.document);
  }

  private async update(): Promise<Company> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Company(gql.document);
  }

  private getDataToSave(): DataToSave {
    const { type, name } = this;
    return { type, name };
  }

  async delete(): Promise<Company> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new DeleteGql().fetch({ id: this.id });
    if (!gql.document) throw new Error('invalid document');
    return new Company(gql.document);
  }

  is(organizationId: string): boolean {
    return this.id === organizationId;
  }

  setUserCount(userCount: number): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, userCount });
  }

  incrementUserCount(): this {
    return this.setUserCount(this.userCount + 1);
  }

  toJSON(): Data {
    const { id, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, name, createdAt, updatedAt };
  }

  static sort(businesses: Company[]) {
    function orderByOrder(a: Company, b: Company) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { Company };
export type {
  Data as CompanyData,
  DataToSave as CompanyDataToSave,
};