import dayjs, { Dayjs } from 'dayjs';

import { Organization } from 'models/entities/organization';
import { CompanyHeadquartersCollection, CompanyHeadquartersCollectionData } from 'models/entities/headquarters';
import { CompanyFranchisorCollection, CompanyFranchisorCollectionData } from 'models/entities/franchisor';
import { CompanyFranchiseeCollection, CompanyFranchiseeCollectionData } from 'models/entities/franchisee';
import { CompanyCentralKitchenCollection, CompanyCentralKitchenCollectionData } from 'models/entities/central-kitchen';

type Data = {
  id?: string;
  type?: string;
  name?: string;
  userCount?: number;
  createdAt?: string;
  updatedAt?: string;
  headquarters?: CompanyHeadquartersCollectionData;
  franchisors?: CompanyFranchisorCollectionData;
  franchisees?: CompanyFranchiseeCollectionData;
  centralKitchens?: CompanyCentralKitchenCollectionData;
};

class CompanyStructure implements Organization {

  readonly id: string;
  readonly type: string;
  readonly name: string;
  readonly userCount: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly headquarters: CompanyHeadquartersCollection;
  readonly franchisors: CompanyFranchisorCollection;
  readonly franchisees: CompanyFranchiseeCollection;
  readonly centralKitchens: CompanyCentralKitchenCollection;

  constructor(data: Data = {}) {
    if (data.type && data.type !== 'Company') throw new Error('invalid type');
    this.id = data.id ?? '';
    this.type = 'CompanyStructure';
    this.name = data.name ?? '';
    this.userCount = data.userCount ?? 0;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.headquarters = new CompanyHeadquartersCollection(this.id, data.headquarters);
    this.franchisors = new CompanyFranchisorCollection(this.id, data.franchisors);
    this.franchisees = new CompanyFranchiseeCollection(this.id, data.franchisees);
    this.centralKitchens = new CompanyCentralKitchenCollection(this.id, data.centralKitchens);
  }

  setUserCount(userCount: number): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, userCount });
  }

  incrementUserCount(): this {
    return this.setUserCount(this.userCount + 1);
  }

  toJSON(): Data {
    const { id, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, name, createdAt, updatedAt };
  }

  static sort(businesses: CompanyStructure[]) {
    function orderByOrder(a: CompanyStructure, b: CompanyStructure) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { CompanyStructure };
export type { Data as CompanyStructureData };