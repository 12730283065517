import styled from 'styled-components';

import { lightTextColor, secondaryTextColor, vividSecondaryColor } from 'views/services/color-scheme';

export const Button = styled.button`
  cursor: pointer;
  font-size: 1em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  color: ${lightTextColor};
  &:hover {
    color: ${secondaryTextColor};
    background-color: ${vividSecondaryColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;