import { Organization } from 'models/entities/organization';

type Data = {
  organization: Organization;
};

type DataToApply = {
  organization: Organization;
};

class ShowUsersTarget {

  readonly organization: Organization;

  constructor(data: Data) {
    this.organization = data.organization
  }

  apply(data: DataToApply): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { ShowUsersTarget };

