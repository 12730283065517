import React, { FC } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { AbstractIcon } from '../abstract-icon';

interface Props {
  className?: string;
}

const AddIcon: FC<Props> = props => <AbstractIcon {...props} icon={faPlus} />;

export { AddIcon };