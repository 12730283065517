import { Api } from 'models/services/api';
import { DeleteUserTransactionQuery } from 'models/services/graphql';
import { UpdateOrganizationUserCountDataToFetch } from 'models/entities/organization';

import { DeleteGqlDataToFetch } from '../../gql';
import { GqlResult } from './gql-result';

type DataToFetch = {
  user: DeleteGqlDataToFetch;
  organization: UpdateOrganizationUserCountDataToFetch;
};

const RequestQuery = `
  mutation DeleteUserTransaction($user: DeleteUserArgs!, $organization: UpdateOrganizationUserCountArgs!) {
    ${DeleteUserTransactionQuery}
  }
`;

type ResponseData = {
  data: {
    deleteUserTransaction: GqlResult;
  };
};

class DeleteTransactionGql {

  readonly api: Api<ResponseData>;
  readonly result?: GqlResult;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.deleteUserTransaction;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, result });
  }

}

export { DeleteTransactionGql };