import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const BusinessesMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/businesses" name="Businesses" {...props} />
  );
};

export { BusinessesMenu };
