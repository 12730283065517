import React, { FC, useState } from 'react';

import { IngredientItem } from 'models/entities/ingredient-item';
import { Business } from 'models/entities/business';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { MultiTextInput } from 'views/components/multi-text-input';
import { PrimaryButton, SecondaryButton } from 'views/components/buttons';
import { Root, Footer } from './index.styled';

interface Props {
  target: IngredientItem[];
  business: Business;
  ready: boolean;
}

const ImportIngredientItems: FC<Props> = ({ target, business, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, business }));

  function importData() {
    model.import().then(model => modal.close({ state: 'imported', target: model.result }));
  }

  function cancel() {
    modal.close();
  }

  return (
    <Root>
      <h1>Ingredient Items</h1>
      <fieldset>
        <MultiTextInput value={model.tsv} onChange={e => setModel(model.input(e.target.value))} height={'480px'} inFocus={ready} />
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => importData()} disabled={!model.valid}>Import</PrimaryButton>
      </Footer>
    </Root>
  );

};

export { ImportIngredientItems };
