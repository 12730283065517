import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, darkTextColor, lineColor, primaryTextColor, lightPrimaryColor, vividPrimaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
  position: absolute;
  & > ol {
    position: absolute;
    top: 4px;
    right: 0;
    min-width: 160px;
    background-color: ${baseColor};
    border: 1px solid ${lineColor};
    & > li {
      white-space: nowrap;
      padding: 8px 16px;
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 1);
      background-color: ${baseColor};
      color: ${textColor};
      &:hover {
        cursor: pointer;
        background-color: ${lightPrimaryColor};
        color: ${primaryTextColor};
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          border: 1px solid ${vividPrimaryColor};
        }
      }
      &.disabled {
        color: ${darkTextColor};
      }
    }
  }
`;