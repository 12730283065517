import { User } from 'models/entities/user';

class Condition {

  constructor() {
  }

  filter(all: User[]): User[] {
    return all.filter(it => this.check(it));
  }

  private check(user: User): boolean {
    return true;
  }

}

export { Condition };