import dayjs, { Dayjs } from 'dayjs';

import { Supplier, SupplierData } from 'models/entities/supplier';
import { DishItem, DishItemData } from 'models/entities/dish-item';
import { IngredientCategory, IngredientCategoryData } from 'models/entities/ingredient-category';

export type Data = {
  id?: string;
  businessId: string;
  supplier?: SupplierData | Supplier;
  dishItem?: DishItemData | DishItem;
  category: IngredientCategoryData | IngredientCategory;
  name?: string;
  poundPrice?: number | null;
  unitValue?: number | null;
  unitSymbol?: string | null;
  netPrice?: number | null;
  yieldRate?: number;
  createdAt?: string | Dayjs;
  updatedAt?: string | Dayjs;
};

type DataToIdentify = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToRevise = {
  id?: string;
  updatedAt?: string;
};

abstract class IngredientItem {

  readonly id: string;
  readonly businessId: string;
  readonly supplier?: Supplier;
  readonly dishItem?: DishItem;
  readonly category: IngredientCategory;
  readonly name: string;
  readonly poundPrice?: number;
  readonly unitValue?: number;
  readonly unitSymbol?: string;
  readonly netPrice?: number;
  readonly yieldRate: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly grossPrice?: number;
  readonly unitPrice?: number;
  readonly depth: number;

  protected constructor(data: Data) {
    this.id = data.id ?? '';
    this.businessId = data.businessId;
    this.category = data.category instanceof IngredientCategory ? data.category : new IngredientCategory(data.category);
    this.name = data.name ?? '';
    this.yieldRate = data.yieldRate ?? 1;
    this.createdAt = data.createdAt ? dayjs.isDayjs(data.createdAt) ? data.createdAt : dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs.isDayjs(data.updatedAt) ? data.updatedAt : dayjs(data.updatedAt) : undefined;
    this.depth = 1;
  }

  identify(data: DataToIdentify): this {
    const id = data.id;
    const createdAt = dayjs(data.createdAt);
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, id, createdAt, updatedAt });
  }

  revise(data: DataToRevise = {}): this {
    const id = data.id ?? this.id;
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, id, updatedAt });
  }

  toJSON(): Data {
    const { id, businessId, name, yieldRate } = this;
    const supplier = undefined;
    const dishItem = undefined;
    const category = this.category.toJSON();
    const poundPrice = undefined;
    const unitValue = undefined;
    const unitSymbol = undefined;
    const netPrice = undefined;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, businessId, supplier, dishItem, category, name, poundPrice, unitValue, unitSymbol, netPrice, yieldRate, createdAt, updatedAt };
  }

  static sort(items: IngredientItem[]) {
    function order(a: IngredientItem, b: IngredientItem) {
      switch (true) {
        case a.category.order.toString() < b.category.order.toString(): return -1;
        case a.category.order.toString() > b.category.order.toString(): return 1;
        case a.category.order.toString() === b.category.order.toString() && a.name < b.name: return -1;
        case a.category.order.toString() === b.category.order.toString() && a.name > b.name: return 1;
        default: return 0;
      }
    }
    return items.sort(order);
  }

}

export { IngredientItem };
export type {
  Data as IngredientItemData,
  DataToIdentify as IngredientItemDataToIdentify,
  DataToRevise as IngredientItemDataToRevise,
};

