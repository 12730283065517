import React, { FC, useState, useEffect, useRef, KeyboardEvent } from 'react';

import { ChevronDownIcon } from 'views/components/icons';
import { Root } from './index.styled';

interface Props {
  list: string[];
  index: number;
  onSelect: (index: number) => void;
  inFocus?: boolean;
  placeholder?: string;
}

const AbstractSelectInput: FC<Props> = ({ list, index, onSelect, inFocus = false, placeholder = '' }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(index);
  const input = useRef<HTMLInputElement>(null);
  const ol = useRef<HTMLOListElement>(null);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function closeWithClick(e: MouseEvent) {
    close();
  }

  function back() {
    if (!isOpen) open();
    const first = 0;
    const last = list.length - 1;
    let next = selected !== -1 ? selected - 1 : last;
    if (next < first) next = last;
    select(next);
  }

  function next() {
    if (!isOpen) open();
    const first = 0;
    const last = list.length - 1;
    let next = selected !== -1 ? selected + 1 : first;
    if (next > last) next = first;
    select(next);
  }

  function watchKeyboard(e: KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case 'Tab': return close();
      case 'ArrowUp': return back();
      case 'ArrowDown': return next();
      case 'Enter': return toggle();
      case ' ': return toggle();
      case 'Escape': return close();
    }
  }

  function select(index: number) {
    setSelected(index);
    onSelect(index);
    input.current?.focus();
  }

  function focus() {
    if (!input.current) return;
    if (!inFocus) return;
    input.current.focus();
    close();
  }

  function setupWindowClick() {
    if (!isOpen) return;
    window.addEventListener('click', closeWithClick);
    return () => window.removeEventListener('click', closeWithClick);
  }

  function scrollOl() {
    if (!isOpen) return;
    if (selected === -1) return;
    const olHeight = ol.current!.getBoundingClientRect().height;
    const liHeight = ol.current!.scrollHeight / list.length;
    const centerTop = olHeight / 2 - liHeight / 2;
    const selectedTop = liHeight * selected;
    ol.current!.scroll({top: selectedTop - centerTop, left: 0, behavior: 'smooth'});
  }

  useEffect(focus, [inFocus]);
  useEffect(setupWindowClick, [isOpen]);
  useEffect(scrollOl, [selected]);
  useEffect(() => setSelected(index), [index])

  return (
    <Root>
      <div>
        <input type="text" value={list[selected] || ''} readOnly={true} ref={input} onClick={e => e.stopPropagation()} onMouseDown={() => toggle()} onFocus={() => open()} onKeyDown={e => watchKeyboard(e)} placeholder={placeholder} />
        <button tabIndex={-1} onClick={e => e.stopPropagation()} onMouseDown={() => toggle()}><ChevronDownIcon /></button>
      </div>
      {isOpen && (
        <ol ref={ol}>
          {list.map((it, i) => (
            <li key={i} onClick={() => select(i)} className={i === selected ? 'selected' : ''}>{it}</li>
          ))}
        </ol>
      )}
    </Root>
  );

};

export { AbstractSelectInput };
