import { SalesReport } from 'models/entities/sales-report';

type Data = {
  salesReport: SalesReport;
};

class ExportReportTarget {

  readonly salesReport: SalesReport;

  constructor(data: Data) {
    this.salesReport = data.salesReport;
  }

}

export { ExportReportTarget };

