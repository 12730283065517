import { Api } from 'models/services/api';
import { UpdateDishItemTransactionQuery } from 'models/services/graphql';
import { UpdateIngredientsDataToFetch } from 'models/entities/ingredient';
import { UpdateIngredientItemDataToFetch } from 'models/entities/ingredient-item';

import { UpdateGqlDataToFetch } from '../../gql';
import { GqlResult } from './gql-result';

type DataToFetch = {
  dishItem: UpdateGqlDataToFetch;
  ingredients?: UpdateIngredientsDataToFetch;
  ingredientItem?: UpdateIngredientItemDataToFetch;
};

const RequestQuery = `
  mutation UpdateDishItemTransaction($dishItem: UpdateDishItemArgs!, $ingredients: [UpdateIngredientArgs!], $ingredientItem: UpdateIngredientItemArgs) {
    ${UpdateDishItemTransactionQuery}
  }
`;

type ResponseData = {
  data: {
    updateDishItemTransaction: GqlResult;
  };
};

class UpdateTransactionGql {

  readonly api: Api<ResponseData>;
  readonly result?: GqlResult;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.updateDishItemTransaction;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, result });
  }

}

export { UpdateTransactionGql };