import React, { FC } from 'react';

import { DishCategoryArrayField } from 'models/entities/dish-category';

import { AbstractSelectInput } from '../abstract-select-input';

interface Props {
  field: DishCategoryArrayField;
  onEdit: (field: DishCategoryArrayField) => void;
  inFocus?: boolean;
}

const CategoryInput: FC<Props> = ({ field, onEdit, inFocus = false }) => {

  const list = field.list.map(it => it.original.name);
  const index = field.index;

  function select(index: number) {
    onEdit(field.change(index));
  }

  return (
    <AbstractSelectInput list={list} index={index} onSelect={i => select(i)} inFocus={inFocus} placeholder="Category" />
  );

};

export { CategoryInput };