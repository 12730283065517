import { OpenaiChatEditor, OpenaiChatEditorDataToApply } from 'models/entities/openai';

type Data = {
  editor: OpenaiChatEditor;
};

class Model {

  readonly editor: OpenaiChatEditor;

  constructor(data: Data) {
    this.editor = data.editor;
  }

  edit(data: OpenaiChatEditorDataToApply): this {
    const editor = this.editor.apply(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, editor });
  }

  commit(): this {
    const editor = this.editor.commit();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, editor });
  }

  async * send(): AsyncGenerator<this> {
    for await (const editor of this.editor.send()) {
      yield Promise.resolve(Object.assign(Object.create(this.constructor.prototype), { ...this, editor }));
    }
  }

}

export { Model };