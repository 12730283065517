import { Api } from 'models/services/api';
import { UpdateOriginalMenuCategoryQuery } from 'models/services/graphql';

import { OriginalMenuCategoryData, OriginalMenuCategoryDataToSave } from '../index';

type DataToUpdate = {
  id: string;
  input: OriginalMenuCategoryDataToSave;
};

const RequestQuery = `
  mutation UpdateOriginalMenuCategory($id: ID!, $input: OriginalMenuCategoryInput!) {
    ${UpdateOriginalMenuCategoryQuery}
  }
`;

type ResponseData = {
  data: {
    updateOriginalMenuCategory: OriginalMenuCategoryData
  }
}

class UpdateGql {

  readonly api: Api<ResponseData>;
  readonly document?: OriginalMenuCategoryData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToUpdate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.updateOriginalMenuCategory;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { UpdateGql };