import { DishItemFieldsQuery } from '../dependent-queries';

export const DishItemCollectionQuery = `
  documents {
    ${DishItemFieldsQuery.create()}
  }
  nextToken
`;

export const ReadDishItemsQuery = `
  readDishItems(menuCategoryId: $menuCategoryId, nextToken: $nextToken) { 
    ${DishItemCollectionQuery}
  }
`;

export const CreateDishItemQuery = `
  createDishItem(menuCategoryId: $menuCategoryId, input: $input) { 
    id
    createdAt
    updatedAt
  }
`;

export const UpdateDishItemQuery = `
  updateDishItem(id: $id, input: $input) { 
    updatedAt
  }
`;

export const DeleteDishItemQuery = `
  deleteDishItem(id: $id) { 
    id
  }
`;

export const CreateDishItemsQuery = `
  createDishItems(menuCategoryId: $menuCategoryId, input: $input) { 
    ${DishItemFieldsQuery.create()}
  }
`;

export { DishItemFieldsQuery };
export * from './transaction';
