import dayjs, { Dayjs } from 'dayjs';
import { Model, ModelId } from '../model';
import { ChoiceStream, ChoiceStreamData, ChoiceStreamDataToAdd } from '../choice';
import { Message } from '../message';

type Data = {
  id: string;
  object: string;
  created: number;
  model: ModelId;
  choices: ChoiceStreamData[];
};

type DataToAdd = Data & {
  choices: ChoiceStreamDataToAdd[];
};

class CompletionStream {

  readonly id: string;
  readonly object: string;
  readonly created: Dayjs;
  readonly model: Model;
  readonly choices: ChoiceStream[];
  readonly finished: boolean;
  readonly messages?: Message[];

  constructor(data: Data) {
    this.id = data.id;
    this.object = data.object;
    this.created = dayjs.unix(data.created);
    this.model = new Model({ id: data.model });
    this.choices = data.choices.map(it => new ChoiceStream(it));
    this.finished = this.getFinished(this.choices);
    this.messages = this.getMessages(this.finished, this.choices);
  }

  add(data: DataToAdd): this {
    const choices = this.choices.map((it, i) => it.add(data.choices[i]));
    const finished = this.getFinished(choices);
    const messages = this.getMessages(finished, choices);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, choices, finished, messages });
  }

  private getFinished(choices: ChoiceStream[]): boolean {
    return choices.every(it => !!it.finishReason);
  }

  private getMessages(finished: boolean, choices: ChoiceStream[]): Message[] | undefined {
    if (!finished) return undefined;
    return choices.map(it => it.message!);
  }

}

export { CompletionStream };
export type {
  Data as CompletionStreamData,
  DataToAdd as CompletionStreamDataToAdd,
};