import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, lineColor, textColor, secondaryColor, secondaryLineColor, secondaryTextColor, vividSecondaryColor, primaryTextColor, lightPrimaryColor, vividPrimaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
  & > button {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    font-size: ${baseFontSize};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${secondaryColor};
    border-color: ${secondaryLineColor};
    color: ${secondaryTextColor};
    &:hover {
      background-color: ${vividSecondaryColor};
    }
  }
  position: relative;
  & > ol {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    min-width: 160px;
    background-color: ${baseColor};
    border: 1px solid ${lineColor};
    & > li {
      font-family: ${baseFontFamily};
      font-size: ${baseFontSize};
      color: ${textColor};
      white-space: nowrap;
      padding: 8px 16px;
      &:hover {
        cursor: pointer;
        background-color: ${lightPrimaryColor};
        color: ${primaryTextColor};
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          border: 1px solid ${vividPrimaryColor};
        }
      }
    }
  }
`;