import React, { FC, PropsWithChildren } from 'react';

import { LocationContext } from './views/services/location-context';

interface Props {
  location: Location;
}

const Location: FC<Props & PropsWithChildren> = ({ location, children }) => {

  const { pathname } = location;

  return (
    <LocationContext.Provider value={{ pathname }}>
      {children}
    </LocationContext.Provider>
  );

};

export { Location };
export { useLocation } from './views/services/use-location';
