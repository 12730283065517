import { PosSystem, PosSystemData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: PosSystemData[];
  nextToken: string;
};

class PosSystemCollection {

  readonly documents: PosSystem[];
  readonly nextToken: string;

  constructor(data: Data = { documents: [], nextToken: '' }) {
    this.documents = data.documents.map(it => new PosSystem(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const gql = await new ReadCollectionGql().fetch({ nextToken: collection.nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new PosSystemCollection(gql.result));
    }
    return collection;
  }

  merge(collection: PosSystemCollection): this {
    const documents = PosSystem.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(system: PosSystem): this {
    const documents = PosSystem.sort([...this.documents, system]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(system: PosSystem): this {
    const documents = PosSystem.sort(this.documents.map(it => it.id === system.id ? system : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(system: PosSystem): this {
    const documents = this.documents.filter(it => it.id !== system.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(): Promise<PosSystemCollection> {
    const gql = await new ReadCollectionGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    return new PosSystemCollection(gql.result);
  }

}

export { PosSystemCollection };
export type { Data as PosSystemCollectionData };