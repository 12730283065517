import React, { FC, PropsWithChildren } from 'react';

import { ToggleSwitch } from 'views/components/toggle-switch';
import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  value: boolean;
  onToggle: (value: boolean) => void;
  inFocus?: boolean;
}

const ToggleSwitchInput: FC<Props> = ({ value, onToggle, children, inFocus }) => {
  return (
    <Root>
      {children}
      <div>
        <ToggleSwitch value={value} onToggle={v => onToggle(v)} inFocus={inFocus} />
      </div>
    </Root>
  );
};

export { ToggleSwitchInput };