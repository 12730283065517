import React, { FC } from 'react';

import { Model } from '../../index.model';

import { SelectInput } from 'views/components/select-input';

interface Props {
  model: Model;
  onUpdate: (model: Model) => void;
}

const CurrencyInput: FC<Props> = ({ model, onUpdate }) => {

  const list = model.currencies.map(it => it.code);
  const index = model.currencies.findIndex(it => it.code === model.currency.code);

  function select(index: number) {
    onUpdate(model.change({ currency: model.currencies[index] }));
  }

  return (
    <SelectInput list={list} index={index} onSelect={i => select(i)} readOnly={true}>
      <h2>Currency</h2>
    </SelectInput>
  );

};

export { CurrencyInput };