import { Brand } from 'models/entities/brand';
import { Headquarters } from 'models/entities/headquarters';
import { Franchisor } from 'models/entities/franchisor';
import { Franchisee } from 'models/entities/franchisee';
import { Store } from 'models/entities/store';

import { BrandsActionsPropsTarget } from '../../../models/entities/props';

class Model {

  target: BrandsActionsPropsTarget;

  constructor({ target }: { target: BrandsActionsPropsTarget }) {
    this.target = target;
  }

  apply({ target }: { target: BrandsActionsPropsTarget }) {
    return new Model({ target });
  }

  isBrand() {
    return this.target instanceof Brand;
  }

  isHeadquarters() {
    return this.target instanceof Headquarters;
  }

  isFranchisor() {
    return this.target instanceof Franchisor;
  }

  isFranchisee() {
    return this.target instanceof Franchisee;
  }

  isStore() {
    return this.target instanceof Store;
  }

}

export { Model };