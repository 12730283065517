import { Delta, DeltaData, DeltaDataToAdd } from '../delta';
import { Message } from '../message';

type Data = {
  delta: DeltaData;
  index: number;
  finish_reason: string | null;
};

type DataToAdd = {
  delta: DeltaDataToAdd;
  index: number;
  finish_reason: string | null;
};

class ChoiceStream {

  readonly delta: Delta;
  readonly index: number;
  readonly finishReason?: string;
  readonly message?: Message;

  constructor(data: Data) {
    this.delta = new Delta(data.delta);
    this.index = data.index;
    this.finishReason = data.finish_reason ?? undefined;
  }

  add(data: DataToAdd): this {
    const delta = this.delta.add(data.delta);
    const finishReason = data.finish_reason ?? undefined;
    const message = finishReason ? delta.complete() : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, delta, finishReason, message });
  }

}

export { ChoiceStream };
export type {
  Data as ChoiceStreamData,
  DataToAdd as ChoiceStreamDataToAdd,
};