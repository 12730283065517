import React, { FC, PropsWithChildren } from 'react';

import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  disabled?: boolean;
}

const InputField: FC<Props> = ({ children, disabled = false }) => {

  return (
    <Root>
      <label className={disabled ? 'disabled' : ''}>
        {children}
      </label>
    </Root>
  );

};

export { InputField };