import { OriginalMenuCategory } from 'models/entities/original-menu-category';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  target: OriginalMenuCategory;
}

type DataToChange = {
  name?: string;
  code?: string;
};

class Model {

  readonly target: OriginalMenuCategory;
  readonly isNew: boolean;
  readonly canDelete: boolean;

  readonly name: string;
  readonly code: string;

  readonly result?: OriginalMenuCategory;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.canDelete = false;
    this.name = this.target.name;
    this.code = this.target.code;
  }

  async readRelationships(originalId: string): Promise<this> {
    const relationships = await RelationshipCollection.readByOriginalId(originalId, 1);
    const canDelete = !relationships.documents.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, canDelete });
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.name) return false;
    if (!data.code) return false;
    return true;
  }

  private build(data: DataToChange): OriginalMenuCategory {
    const name = data.name;
    const code = data.code;
    return this.target.edit({ name, code });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };