import { OriginalDishCategoryFieldsQuery } from '../original-dish-category';

export const DishCategoryFieldsQuery = `
  id
  menuCategoryId
  original {
    ${OriginalDishCategoryFieldsQuery}
  }
  order
  createdAt
  updatedAt
`;

export const DishCategoryCollectionQuery = `
  documents {
    ${DishCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadDishCategoriesQuery = `
  readDishCategories(menuCategoryId: $menuCategoryId, nextToken: $nextToken) {
    ${DishCategoryCollectionQuery}
  }
`;

export const CreateDishCategoryQuery = `
  createDishCategory(menuCategoryId: $menuCategoryId, input: $input) { 
    ${DishCategoryFieldsQuery}
  }
`;

export const UpdateDishCategoryQuery = `
  updateDishCategory(id: $id, input: $input) { 
    ${DishCategoryFieldsQuery}
  }
`;

export const DeleteDishCategoryQuery = `
  deleteDishCategory(id: $id) { 
    ${DishCategoryFieldsQuery}
  }
`;
