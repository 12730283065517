import { LexoRank } from 'lexorank';

import { IngredientItem } from 'models/entities/ingredient-item';
import { OrderField, NumberField } from 'models/value-objects/editor-field';

import { Ingredient, IngredientDataToRevise } from './ingredient';

type Data = {
  original: Ingredient;
  dirty?: boolean;
};

type DataToBuild = {
  dishItemId: string;
  item: IngredientItem;
  dirty?: boolean;
};

type DataToApply = {
  usage?: NumberField;
};

type DataToCreate = {
  itemId: string;
  order: string;
  usage: number;
};

type DataToUpdate = {
  order?: string;
  usage?: number;
};

type Prev = IngredientEditor | undefined;
type Next = IngredientEditor | undefined;

class IngredientEditor {

  readonly original: Ingredient;
  readonly order: OrderField;
  readonly usage: NumberField;
  readonly cost: number;
  readonly dirty: boolean;
  readonly ok: boolean;

  constructor(data: Data) {
    this.original = data.original;
    this.order = new OrderField({ object: this.original.order });
    this.usage = new NumberField({ number: this.original.usage });
    this.cost = this.getCost(this.usage);
    this.dirty = data.dirty ?? false;
    this.ok = this.usage.ok;
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const cost = this.getCost(props.usage);
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, cost, dirty, ok });
  }

  moveBetween(prev: Prev, next: Next): this {
    const order = this.order.change(IngredientEditor.getOrderBetween(prev, next));
    const dirty = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, order, dirty });
  }

  getDataToCreate(): DataToCreate {
    const itemId = this.original.item.id;
    const order = this.order.object.toString();
    const usage = this.usage.number!;
    return { itemId, order, usage };
  }

  getDataToUpdate(): DataToUpdate {
    const order = this.order.dirt?.toString();
    const usage = this.usage.dirt;
    return { order, usage };
  }

  validate(data: DataToApply = {}): boolean {
    const props = { ...this, ...data };
    if (!props.usage.ok) return false;
    return true;
  }

  revise(data: IngredientDataToRevise): IngredientEditor {
    const original = this.rebuild().revise(data);
    return new IngredientEditor({ original });
  }

  rebuild(): Ingredient {
    if (!this.validate()) throw new Error('invalid object');
    const order = this.order.object;
    const usage = this.usage.number!;
    return this.original.apply({ order, usage });
  }

  private getCost(usage: NumberField): number {
    return Ingredient.calcCost(this.original.item.unitPrice, usage.number ?? 0) ?? 0;
  }

  static sort(list: IngredientEditor[]): IngredientEditor[] {
    function order(a: IngredientEditor, b: IngredientEditor) {
      if (a.order.object.toString() < b.order.object.toString()) return -1;
      else if (a.order.object.toString() > b.order.object.toString()) return 1;
      else return 0;
    }
    return list.sort(order);
  }

  static buildNext(list: IngredientEditor[], data: DataToBuild): IngredientEditor {
    const { dirty, ...params } = data;
    const order = list[list.length - 1]?.order.object.genNext() ?? LexoRank.middle();
    return new IngredientEditor({ original: new Ingredient({ ...params, order }), dirty });
  }

  static buildBetween(prev: Prev, next: Next, data: DataToBuild): IngredientEditor {
    const { dirty, ...params } = data;
    const order = IngredientEditor.getOrderBetween(prev, next);
    return new IngredientEditor({ original: new Ingredient({ ...params, order }), dirty });
  }

  private static getOrderBetween(prev: Prev, next: Next): LexoRank {
    switch (true) {
      case !prev && !next: return LexoRank.middle();
      case !prev && !!next: return next!.order.object.genPrev();
      case !!prev && !next: return prev!.order.object.genNext();
      default: return prev!.order.object.between(next!.order.object);
    }
  }

}

export { IngredientEditor };
export type {
  DataToCreate as IngredientEditorDataToCreate,
  DataToUpdate as IngredientEditorDataToUpdate,
};