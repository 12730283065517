import React, { FC, PropsWithChildren, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Amplify, Auth as AmplifyAuth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';

import { Credentials } from 'models/value-objects/credentials';
import { CurrentUser } from 'models/entities/current-user';

import { AuthContext } from './views/services/auth-context';
import { useAuth } from './views/services/use-auth';
import { Config } from './views/services/config';
import { Gate } from './views/components/gate';

Amplify.configure(Config);

const Auth: FC<PropsWithChildren> = ({ children }) => {

  const [ok, setOk] = useState<boolean>();
  const [user, setUser] = useState(new CurrentUser());
  const [tmpCognitoUser, setTmpCognitoUser] = useState<CognitoUser>();

  async function signInWithGoogle(): Promise<void> {
    await AmplifyAuth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }

  async function signIn(credentials: Credentials): Promise<CurrentUser> {
    const cognitoUser = await AmplifyAuth.signIn(credentials.email, credentials.password);
    return cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED' ? requirePassword(cognitoUser) : await authenticate(cognitoUser);
  }

  async function createPassword(password: string): Promise<CurrentUser> {
    if (!tmpCognitoUser) throw new Error('no tmpCognitoUser');
    const cognitoUser = await AmplifyAuth.completeNewPassword(tmpCognitoUser, password);
    return await authenticate(cognitoUser);
  }

  async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const cognitoUser = await AmplifyAuth.currentAuthenticatedUser();
    await AmplifyAuth.changePassword(cognitoUser, oldPassword, newPassword);
  }

  async function authenticate(cognitoUser: CognitoUser): Promise<CurrentUser> {
    return checkBlackList(cognitoUser) ? await signOut() : await authorize(cognitoUser);
  }

  function requirePassword(tmpCognitoUser: CognitoUser): CurrentUser {
    const currentUser = user.becomeTemporary();
    setOk(false);
    setUser(currentUser);
    setTmpCognitoUser(tmpCognitoUser);
    return currentUser;
  }

  async function authorize(cognitoUser: CognitoUser): Promise<CurrentUser> {
    const id = getUsername(cognitoUser);
    const admin = checkAdmin(cognitoUser);
    const google = checkGoogle(cognitoUser);
    const invited = checkInvited(cognitoUser);
    const currentUser = await user.becomeAuthorized({ id, admin, google, invited }).load();
    setOk(true);
    setUser(currentUser);
    setTmpCognitoUser(undefined);
    return currentUser;
  }

  function prohibit(): CurrentUser {
    const currentUser = new CurrentUser();
    setOk(false);
    setUser(currentUser);
    setTmpCognitoUser(undefined);
    return currentUser;
  }

  async function signOut(): Promise<CurrentUser> {
    const currentUser = prohibit();
    await AmplifyAuth.signOut();
    typeof window !== 'undefined' ? window.location.assign('/') : await navigate('/');
    return currentUser;
  }

  function apply(currentUser: CurrentUser) {
    setUser(currentUser);
  }

  function getUsername(cognitoUser: CognitoUser): string {
    return cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:username'] ?? '';
  }

  function checkAdmin(cognitoUser: CognitoUser): boolean {
    return !!cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups']?.includes('Admin');
  }

  function checkGoogle(cognitoUser: CognitoUser): boolean {
    return !!cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups']?.some((it: string) => /_Google$/.test(it));
  }

  function checkInvited(cognitoUser: CognitoUser): boolean {
    return !!cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups']?.includes('Invited');
  }

  function checkBlackList(cognitoUser: CognitoUser): boolean {
    return !!cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups']?.includes('BlackList');
  }

  function init() {
    AmplifyAuth.currentAuthenticatedUser()
    .then(cognitoUser => authenticate(cognitoUser))
    .catch(() => prohibit());
  }

  useEffect(init, []);

  return (
    <AuthContext.Provider value={{ ok, user, signInWithGoogle, signIn, createPassword, changePassword, signOut, apply }}>
      {children}
    </AuthContext.Provider>
  );

};

export { Auth, useAuth, Gate };
