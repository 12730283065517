import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';
import { baseColor } from 'views/services/color-scheme';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  font-size: calc(${baseFontSize} * 1.5);
  background-color: ${baseColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;