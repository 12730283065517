import React, { FC, useState } from 'react';

import { SupplierArrayField } from 'models/entities/supplier';
import { StringArrayField } from 'models/value-objects/editor-field';
import { Model } from './index.model';

import { StringArrayInput } from 'views/components/inputs';

interface Props {
  field: SupplierArrayField;
  onEdit: (field: SupplierArrayField) => void;
  focused?: boolean;
}

const SupplierArrayInput: FC<Props> = ({ field, onEdit, focused = false }) => {

  const [model, setModel] = useState(new Model({ field }));

  function edit(stringArrayField: StringArrayField) {
    const it = model.apply(stringArrayField);
    onEdit(it.field);
    setModel(it);
  }

  return (
    <StringArrayInput field={model.stringArrayField} onEdit={stringArrayField => edit(stringArrayField)} focused={focused} />
  );

};

export { SupplierArrayInput };