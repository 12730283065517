import { CacheKey } from './cache-key';
import { ValueObject } from './value-object';

interface CacheContextInterface {
  set: (key: CacheKey, value: ValueObject) => void;
  get: (key: CacheKey) => ValueObject;
}

const DefaultInterface = {
  set: () => {},
  get: () => {},
};

export type { CacheContextInterface };
export { DefaultInterface };