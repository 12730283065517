import React, { FC, useState } from 'react';

import { EmailField, PasswordField } from 'models/value-objects/editor-field';
import { Step } from '../../index.model';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { ChevronLeftIcon } from 'views/components/icons';
import { StringInput } from 'views/components/inputs';
import { Root } from './index.styled';

interface Props {
  current: Step;
  ready: Step;
  onGoBack: (step: Step) => void;
  onError: (message: string) => void;
}

const Step2: FC<Props> = ({ current, ready, onGoBack, onError }) => {

  const auth = useAuth();
  const [model, setModel] = useState(new Model());

  function signIn() {
    auth.signIn(model.issueCredentials())
    .catch(() => onError('Your email or password was incorrect.'));
  }

  return (
    <Root>
      <fieldset disabled={current !== 'step2'}>
        <div>
          <button onClick={() => onGoBack('step1')}><ChevronLeftIcon /></button>
        </div>
        <ol>
          <li><StringInput field={model.email} onEdit={field => setModel(model.edit({ email: field as EmailField }))} placeholder="Email" focused={ready === 'step2'} /></li>
          <li><StringInput field={model.password} onEdit={field => setModel(model.edit({ password: field as PasswordField }))} placeholder="Password" secure={true} /></li>
          <li><button onClick={() => signIn()} disabled={!model.ok}>Sign in</button></li>
        </ol>
      </fieldset>
    </Root>
  );

};

export { Step2 };