import styled, { css } from 'styled-components';

import { baseFontFamily } from 'views/services/font-scheme';
import { darkPrimaryColor, vividPrimaryColor, primaryTextColor, primaryLineColor, darkSecondaryColor, vividSecondaryColor, secondaryTextColor, secondaryLineColor } from 'views/services/color-scheme';

const PrimaryStyle = css`
  background-color: ${darkPrimaryColor};
  border-color: ${primaryLineColor};
  color: ${primaryTextColor};
  &:hover, &:focus {
    background-color: ${vividPrimaryColor};
  }
`;

const SecondaryStyle = css`
  background-color: ${darkSecondaryColor};
  border-color: ${secondaryLineColor};
  color: ${secondaryTextColor};
  &:hover, &:focus {
    background-color: ${vividSecondaryColor};
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  font-family: ${baseFontFamily};
  font-size: 1em;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const PrimaryButton = styled(Button)`
  ${PrimaryStyle}
`;

export const SecondaryButton = styled(Button)`
  ${SecondaryStyle}
`;

const RoundButton = styled(Button)`
  padding: 1em;
  border-radius: 50%;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrimaryRoundButton = styled(RoundButton)`
  ${PrimaryStyle}
`;

export const SecondaryRoundButton = styled(RoundButton)`
  ${SecondaryStyle}
`;

const SmallRoundButton = styled(Button)`
  padding: 0.5em;
  border-radius: 50%;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallPrimaryRoundButton = styled(SmallRoundButton)`
  ${PrimaryStyle}
`;

export const SmallSecondaryRoundButton = styled(SmallRoundButton)`
  ${SecondaryStyle}
`;

