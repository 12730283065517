import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';

import { IngredientItem, IngredientItemData, IngredientItemFactory } from 'models/entities/ingredient-item';

import { IngredientEditorDataToCreate } from './editor';

type Data = {
  id?: string;
  dishItemId: string;
  item: IngredientItemData | IngredientItem;
  order: string | LexoRank;
  usage?: number;
  createdAt?: string;
  updatedAt?: string;
};

type DataToApply = {
  order?: LexoRank;
  item?: IngredientItem;
  usage?: number;
};

type DataToIdentify = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToRevise = {
  id?: string;
  updatedAt?: string;
};

class Ingredient {

  readonly id: string;
  readonly dishItemId: string;
  readonly item: IngredientItem;
  readonly order: LexoRank;
  readonly usage: number;
  readonly cost?: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.dishItemId = data.dishItemId;
    this.item = data.item instanceof IngredientItem ? data.item : IngredientItemFactory.create(data.item);
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.usage = data.usage ?? 1;
    this.cost = Ingredient.calcCost(this.item.unitPrice, this.usage);
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const cost = Ingredient.calcCost(props.item.unitPrice, props.usage);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, cost });
  }

  identify(data: DataToIdentify): this {
    const id = data.id;
    const createdAt = dayjs(data.createdAt);
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, id, createdAt, updatedAt });
  }

  revise(data: DataToRevise = {}): this {
    const id = data.id ?? this.id;
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, id, updatedAt });
  }

  toJSON(): Data {
    const { id, dishItemId, usage } = this;
    const item = this.item.toJSON();
    const order = this.order.toString();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, dishItemId, item, order, usage, createdAt, updatedAt };
  }

  toJSONtoExport(): IngredientEditorDataToCreate {
    const { item, order, usage } = this;
    return { itemId: item.id, order: order.toString(), usage };
  }

  static calcCost(unitPrice: number | undefined, usage: number): number | undefined {
    if (unitPrice === undefined) return undefined;
    return unitPrice * usage;
  }

}

export { Ingredient };
export type {
  Data as IngredientData,
  DataToIdentify as IngredientDataToIdentify,
  DataToRevise as IngredientDataToRevise,
};
