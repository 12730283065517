import { ExportReportTarget } from '../../../models/entities/target';
import { SalesReport } from 'models/entities/sales-report';

type Data = {
  target: ExportReportTarget;
}

class Model {

  readonly target: ExportReportTarget;
  readonly salesReport: SalesReport;
  readonly tsv: string;

  constructor(data: Data) {
    this.target = data.target;
    this.salesReport = this.target.salesReport;
    this.tsv = this.salesReport.toTsv();
  }

}

export { Model };