import React, { FC, useState, useEffect, MouseEvent } from 'react';

//import { SubscribeGql } from './models/subscribe-gql';

import { IngredientEditor } from 'models/entities/ingredient';
import { IngredientItem, IngredientItemCollection } from 'models/entities/ingredient-item';
import { Business } from 'models/entities/business';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';
import { EditDishItemTarget } from '../../../models/entities/target';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { useDnd } from 'views/services/dnd';
import { showUnit, showPercentage, showMoney } from 'views/services/helpers';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { StringInput, NumberInput } from 'views/components/inputs';
import { CategoryInput } from './views/category-input';
import { IngredientItemSetting } from './views/ingredient-item-setting';
import { SearchTh } from './views/search-th';
import { Root, MainPanel, Category, ItemHeader, ItemFooter, Item, Ingredients, Settings, PanelOpener, IngredientItemsPanel, IngredientItems, Footer, MoreActions } from './index.styled';

interface Props {
  target: EditDishItemTarget;
  ingredientItems: IngredientItemCollection;
  business: Business;
  ingredientCategories: IngredientCategoryCollection;
  ready: boolean;
  background: boolean;
}

const EditDishItem: FC<Props> = ({ target, ingredientItems, business, ingredientCategories, ready, background }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, ingredientItems, ingredientCategories }));
  const dnd = useDnd();

  function save() {
    model.save().then(it => modal.close(it.conclude()));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (!model.dishItem.original.id) return;
    model.delete().then(it => modal.close(it.conclude('deleted')));
  }

  function grabIngredientItem(target: IngredientItem, e: MouseEvent<HTMLTableRowElement>) {
    if (model.mode !== 'editing') return;
    dnd.grab('dnd-list-item', { target, e });
  }

  function dropToIngredientItems() {
    if (!dnd.state.canDrop()) return;
    if (!dnd.target) return;
    if (!(dnd.target instanceof IngredientEditor)) return;
    setModel(model.removeIngredient(dnd.target as IngredientEditor));
  }

  function grabIngredient(target: IngredientEditor, e: MouseEvent<HTMLTableRowElement>) {
    if (model.mode !== 'editing') return;
    if ((e.target as HTMLElement).tagName.toLowerCase() === 'input') return;
    dnd.grab('dnd-list-item', { target, e, label: target.original.item.name });
  }

  function dropToIngredients(e: MouseEvent<HTMLDivElement>) {
    if (!dnd.state.canDrop()) return;
    if (!dnd.target) return;
    if (!(dnd.target instanceof IngredientItem)) return;
    setModel(model.addIngredientItem(dnd.target as IngredientItem));
    e.stopPropagation();
    dnd.drop();
  }

  function insertToIngredients(ingredient: IngredientEditor, index: number, e: MouseEvent<HTMLTableRowElement>) {
    if (!dnd.state.canDrop()) return;
    if (!dnd.target) return;
    if (dnd.target === ingredient) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const center = rect.top + rect.height / 2;
    const position = index + (e.pageY < center ? 0 : 1);
    if (dnd.target instanceof IngredientItem) setModel(model.insertIngredientItem(dnd.target as IngredientItem, position));
    else if (dnd.target instanceof IngredientEditor) setModel(model.moveIngredient(dnd.target as IngredientEditor, position));
    e.stopPropagation();
    dnd.drop();
  }

  function watchDishItem() {
    if (model.dishItem.original.ingredientItemId && !model.dishItem.ingredientItem) model.readIngredientItem().then(it => setModel(it));
  }

  useEffect(watchDishItem, [model.dishItem]);

//  useEffect(() => {
//    new SubscribeGql().subscribe();
//  }, [])

  return (
    <Root>
      <MainPanel className={background ? 'modal-background' : ''}>
        <Category>
          <CategoryInput field={model.dishItem.category} onEdit={category => setModel(model.edit({ category }))} inFocus={ready} />
        </Category>
        <Item>
          <ItemHeader>
            <h1>
              <StringInput field={model.dishItem.name} onEdit={name => setModel(model.edit({ name }))} placeholder="Untitled" disabled={dnd.state.isActive()} />
            </h1>
            <div>
              <h2>POS : </h2>
              <StringInput field={model.dishItem.posItemId} onEdit={posItemId => setModel(model.edit({ posItemId }))} disabled={dnd.state.isActive()} />
            </div>
          </ItemHeader>
          <Ingredients className={model.mode}>
            <div onClick={() => setModel(model.toggle('editing'))} className={[model.mode, dnd.state.name].join(' ')} onMouseUp={e => dropToIngredients(e)}>
              <div onMouseMove={e => dnd.enableScroll(e)} onMouseLeave={() => dnd.disableScroll()}>
                <table>
                  <thead>
                  <tr>
                    <th>Ingredients</th>
                    <th className="center">Unit</th>
                    <th className="center">Gross Price</th>
                    <th className="center">Usage</th>
                    <th className="center">Cost</th>
                  </tr>
                  </thead>
                  <tbody>
                  {model.dishItem.ingredients.editors.map((it, k) => (
                    <tr key={k} onMouseDown={e => grabIngredient(it, e)} onMouseMove={e => dnd.mark(e)} onMouseLeave={() => dnd.unmark()} onMouseUp={e => insertToIngredients(it, k, e)} className={[model.mode, dnd.state.name, it.original.item === dnd.target ? 'dnd-target' : ''].join(' ')}>
                      <td className="text">{it.original.item.name}</td>
                      <td className="right">{showUnit(it.original.item.unitValue, it.original.item.unitSymbol)}</td>
                      <td className="number">{showMoney(it.original.item.grossPrice, model.currency)}</td>
                      <td className="number">
                        <fieldset>
                          <NumberInput field={it.usage} onEdit={usage => setModel(model.editUsage(usage, it))} alt={0} readOnly={model.mode !== 'editing'} disabled={dnd.state.isActive()} />
                        </fieldset>
                      </td>
                      <td className="number">{showMoney(it.cost, model.currency)}</td>
                    </tr>
                  ))}
                  {!model.dishItem.ingredients.editors.length && (
                    <tr>
                      <td colSpan={5} className="none">No ingredients</td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </Ingredients>
          <ItemFooter>
            <table>
              <thead>
              <tr>
                <th>Price</th>
                <th>Cost Rate</th>
                <th>Cost</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <fieldset>
                    <NumberInput field={model.dishItem.price} onEdit={price => setModel(model.edit({ price }))} alt={0} prefix={model.currency.symbol} disabled={dnd.state.isActive()} />
                  </fieldset>
                </td>
                <td>{showPercentage(model.dishItem.costRate ? model.dishItem.costRate * 100 : undefined)} %</td>
                <td>{showMoney(model.dishItem.cost, model.currency)}</td>
              </tr>
              </tbody>
            </table>
          </ItemFooter>
        </Item>
        <Settings>
          <IngredientItemSetting dishItem={model.dishItem} onSetUp={dishItem => setModel(model.apply({ dishItem }))} disabled={dnd.state.isActive() || !model.dishItem.ok} business={business} ingredientCategories={model.ingredientCategories} unitSymbols={model.unitSymbols}>
            <h3>Use as an Ingredient Item</h3>
          </IngredientItemSetting>
        </Settings>
        <Footer>
          <SecondaryButton onClick={() => cancel()} disabled={dnd.state.isActive()}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => save()} disabled={!model.ok || dnd.state.isActive()}>Save</PrimaryButton>
        </Footer>
        {model.dishItem.original.id && (
          <MoreActions>
            <div className={background ? 'modal-background' : ''}>
              <SecondaryRoundButton onClick={() => remove()} disabled={!model.dishItem.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
            </div>
          </MoreActions>
        )}
      </MainPanel>
      <PanelOpener className={model.mode}>
        <IngredientItemsPanel className={background ? 'modal-background' : ''}>
          <IngredientItems>
            <h2>Ingredient Item List</h2>
            <div onMouseUp={() => dropToIngredientItems()}>
              <div>
                <table>
                  <thead>
                  <tr>
                    <th className="text">Category</th>
                    <th><SearchTh th="Name" field={model.ingredientItemList.name} onEdit={name => setModel(model.search({ name }))} /></th>
                    <th className="text center">Unit</th>
                    <th className="text center">Gross Price</th>
                  </tr>
                  </thead>
                  <tbody>
                  {model.ingredientItemList.result.map((it, k) => (
                    <tr key={k} onMouseDown={e => grabIngredientItem(it, e)} className={[dnd.state.name, it === dnd.target ? 'dnd-target' : '', it.depth > model.dishItem.depthLimit ? 'disabled' : ''].join(' ')}>
                      <td>{it.category.original.name}</td>
                      <td>{it.name}</td>
                      <td className="right">{showUnit(it.unitValue, it.unitSymbol)}</td>
                      <td className="number">{showMoney(it.grossPrice, model.currency)}</td>
                    </tr>
                  ))}
                  {!model.ingredientItemList.result.length && (
                    <tr>
                      <td colSpan={4} className="none">No ingredient items</td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </IngredientItems>
          <Footer>
            <SecondaryButton onClick={() => setModel(model.toggle('done'))} className={dnd.state.isActive() ? 'disabled' : ''}>Close</SecondaryButton>
          </Footer>
        </IngredientItemsPanel>
      </PanelOpener>
    </Root>
  );

};

export { EditDishItem };
