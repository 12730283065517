import { DishItem } from 'models/entities/dish-item';
import { calcCostRate, calcGrossPrice } from 'models/services/formula';
import { IngredientCategoryCollection, IngredientCategoryArrayField } from 'models/entities/ingredient-category';
import { StringField, NumberField, StringArrayField } from 'models/value-objects/editor-field';

import { IngredientItemEditor, IngredientItemDataToIdentify, IngredientItemDataToRevise } from '../ingredient-item';
import { InHouseIngredientItem } from './in-house';
import { CreateGql, UpdateGql } from '../gql';
import { InHouseIngredientItemTransaction } from './transaction';

type Data = {
  original: InHouseIngredientItem;
  categories: IngredientCategoryCollection;
  unitSymbols: string[];
};

type DataToInit = {
  canDelete?: boolean;
};

type DataToApply = {
  category?: IngredientCategoryArrayField;
  name?: StringField;
  unitValue?: NumberField;
  unitSymbol?: StringArrayField;
  yieldRate?: NumberField;
};

type DataToValidate = {
  category?: IngredientCategoryArrayField;
  name?: StringField;
  unitValue?: NumberField;
  unitSymbol?: StringArrayField;
  yieldRate?: NumberField;
};

type DataToCreate = {
  categoryId: string;
  name: string;
  unitValue: number;
  unitSymbol: string;
  yieldRate: number;
}

type DataToUpdate = {
  categoryId?: string;
  name?: string;
  unitValue?: number;
  unitSymbol?: string;
  yieldRate?: number;
}

type DataToRebuild = {
  dishItem?: DishItem;
};

class InHouseIngredientItemEditor extends IngredientItemEditor {

  readonly original: InHouseIngredientItem;
  readonly categories: IngredientCategoryCollection;
  readonly unitSymbols: string[];
  readonly category: IngredientCategoryArrayField;
  readonly name: StringField;
  readonly unitValue: NumberField;
  readonly unitSymbol: StringArrayField;
  readonly yieldRate: NumberField;
  readonly grossPrice?: number;
  readonly canDelete: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;
  readonly deleted: boolean;

  constructor(data: Data) {
    super(data);
    this.original = data.original;
    this.categories = data.categories;
    this.unitSymbols = data.unitSymbols;
    this.category = new IngredientCategoryArrayField({ list: this.categories.documents, object: this.original.category });
    this.name = new StringField({ string: this.original.name });
    this.unitValue = new NumberField({ number: this.original.unitValue });
    this.unitSymbol = new StringArrayField({ list: this.unitSymbols, string: this.original.unitSymbol, required: false });
    this.yieldRate = new NumberField({ number: this.original.yieldRate });
    this.grossPrice = calcGrossPrice(this.original.netPrice, this.yieldRate.number);
    this.canDelete = false;
    this.dirty = false;
    this.ok = this.validate();
    this.deleted = false;
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const grossPrice = calcCostRate(this.original.netPrice, props.yieldRate.number ?? 0);
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, grossPrice, dirty, ok });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.category.ok) return false;
    if (!props.name.ok) return false;
    if (!props.unitValue.ok) return false;
    if (!props.unitSymbol.ok) return false;
    if (!props.yieldRate.ok) return false;
    return true;
  }

  remove(): this {
    const deleted = true;
    const dirty = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, deleted, dirty });
  }

  restore(): this {
    const deleted = false;
    const dirty = true;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, deleted, dirty });
  }

  async save(): Promise<InHouseIngredientItemEditor> {
    if (!this.validate()) throw new Error('invalid object');
    return this.original.id ? await this.update() : await this.create();
  }

  private async create(): Promise<InHouseIngredientItemEditor> {
    const gql = await new CreateGql().fetch({ businessId: this.original.businessId, input: this.getDataToCreate() });
    if (!gql.document) throw new Error('invalid document');
    return this.identify(gql.document);
  }

  private async update(): Promise<InHouseIngredientItemEditor> {
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new UpdateGql().fetch({ id: this.original.id, input: this.getDataToUpdate() });
    if (!gql.document) throw new Error('invalid document');
    return this.revise(gql.document);
  }

  getDataToCreate(): DataToCreate {
    const categoryId = this.category.object!.id;
    const name = this.name.string!;
    const unitValue = this.unitValue.number!;
    const unitSymbol = this.unitSymbol.string!;
    const yieldRate = this.yieldRate.number!;
    return { categoryId, name, unitValue, unitSymbol, yieldRate };
  }

  getDataToUpdate(): DataToUpdate {
    const categoryId = this.category.dirt?.id;
    const name = this.name.dirt;
    const unitValue = this.unitValue.dirt;
    const unitSymbol = this.unitSymbol.dirt;
    const yieldRate = this.yieldRate.dirt;
    return { categoryId, name, unitValue, unitSymbol, yieldRate };
  }

  identify(data: IngredientItemDataToIdentify, dataToRebuild?: DataToRebuild): InHouseIngredientItemEditor {
    const original = this.rebuild(dataToRebuild).identify(data);
    return new InHouseIngredientItemEditor({ original, categories: this.categories, unitSymbols: this.unitSymbols });
  }

  revise(data: IngredientItemDataToRevise = {}, dataToRebuild?: DataToRebuild): InHouseIngredientItemEditor {
    if (this.deleted) return this;
    const original = this.rebuild(dataToRebuild).revise(data);
    return new InHouseIngredientItemEditor({ original, categories: this.categories, unitSymbols: this.unitSymbols });
  }

  rebuild(data: DataToRebuild = {}): InHouseIngredientItem {
    if (!this.validate()) throw new Error('invalid object');
    const dishItem = data.dishItem ?? this.original.dishItem;
    const category = this.category.object!;
    const name = this.name.string!;
    const unitValue = this.unitValue.number!;
    const unitSymbol = this.unitSymbol.string || '';
    const yieldRate = this.yieldRate.number!;
    return this.original.apply({ dishItem, category, name, unitValue, unitSymbol, yieldRate });
  }

  async delete(): Promise<this> {
    if (!this.canDelete) throw new Error('cannot delete');
    const transaction = await new InHouseIngredientItemTransaction({ ingredientItem: this }).delete();
    if (!transaction.result) throw new Error('invalid result');
    return this;
  }

}

export { InHouseIngredientItemEditor };
export type {
  Data as InHouseIngredientItemEditorData,
  DataToApply as InHouseIngredientItemEditorDataToApply,
};
