import React, { FC, useState, useEffect } from 'react';

import { Model } from './index.model';

import { CentralKitchensActionName } from '../../../models/entities/name';
import { CentralKitchensActionsProps } from '../../../models/entities/props';
import { useDropdown } from '../../services/use-dropdown';
import { Root } from './index.styled';

const CentralKitchensActions: FC<CentralKitchensActionsProps> = ({ target, onSelect }) => {

  const dropdown = useDropdown();
  const [model, setModel] = useState(new Model({ target }));

  function select(action: CentralKitchensActionName) {
    dropdown.close();
    onSelect(action);
  }

  function watchTarget() {
    setModel(model.apply({ target }));
  }

  useEffect(watchTarget, [target]);

  return (
    <Root>
      <ol>
        <li onClick={() => select('edit-satellite-kitchen')}>Add Satellite Kitchen</li>
        <li onClick={() => select('edit-user')}>Add User</li>
      </ol>
    </Root>
  );

};

export { CentralKitchensActions };