import { IngredientItem } from 'models/entities/ingredient-item';

import { Ingredient, IngredientData, IngredientDataToIdentify, IngredientDataToRevise } from './ingredient';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: IngredientData[];
  nextToken: string;
};

type DataToApply = {
  documents?: Ingredient[];
  nextToken?: string;
};

type DataToIdentify = IngredientDataToIdentify[];
type DataToRevise = IngredientDataToRevise[];

class IngredientCollection {

  readonly dishItemId: string;
  readonly documents: Ingredient[];
  readonly nextToken: string;
  readonly cost: number;

  constructor(dishItemId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.dishItemId = dishItemId;
    this.documents = data.documents.map(it => new Ingredient(it)).sort(this.order);
    this.nextToken = data.nextToken;
    this.cost = this.getCost(this.documents);
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { dishItemId, nextToken } = collection;
      if (!dishItemId) continue;
      const gql = await new ReadCollectionGql().fetch({ dishItemId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new IngredientCollection(dishItemId, gql.result));
    }
    return collection;
  }

  merge(collection: IngredientCollection): this {
    const documents = [...this.documents, ...collection.documents].sort(this.order);
    const nextToken = collection.nextToken;
    return this.apply({ documents, nextToken });
  }

  replace(item: IngredientItem): this {
    const documents = this.documents.map(it => it.item.id === item.id ? it.apply({ item }) : it);
    return this.apply({ documents });
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const documents = props.documents.sort(this.order);
    const nextToken = data.nextToken ?? '';
    const cost = this.getCost(documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, documents, nextToken, cost });
  }

  identify(data: DataToIdentify): this {
    const documents = this.documents.map((it, i) => it.identify(data[i]));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  revise(data: DataToRevise): this {
    const documents = this.documents.map((it, i) => it.revise(data[i]));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  private order(a: Ingredient, b: Ingredient) {
    if (a.order.toString() < b.order.toString()) return -1;
    else if (a.order.toString() > b.order.toString()) return 1;
    else return 0;
  }

  private getCost(documents: Ingredient[]): number {
    return documents.reduce((cost, it) => cost + (it.cost ?? 0), 0);
  }

  static async read(dishItemId: string): Promise<IngredientCollection> {
    const gql = await new ReadCollectionGql().fetch({ dishItemId });
    if (!gql.result) throw new Error('invalid result');
    return new IngredientCollection(dishItemId, gql.result);
  }

}

export { IngredientCollection };
export type { Data as IngredientCollectionData };
