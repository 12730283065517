import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const PreparationMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/preparation" name="Preparation" {...props} />
  );
};

export { PreparationMenu };
