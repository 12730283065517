//type ID = 'gpt-4' | 'gpt-4-0314' | 'gpt-4-32k' | 'gpt-4-32k-0314' | 'gpt-3.5-turbo' | 'gpt-3.5-turbo-0301';
type ID = 'gpt-3.5-turbo' | 'gpt-3.5-turbo-0301';

type Data = {
  id?: ID;
};

type DataToSend = ID;

class Model {

  readonly id: ID;

  constructor(data: Data = {}) {
    this.id = data.id ?? 'gpt-3.5-turbo';
  }

}

export { Model };
export type {
  ID as ModelId,
  Data as ModelData,
  DataToSend as ModelDataToSend,
};