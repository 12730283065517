import { Supplier } from 'models/entities/supplier';
import { IngredientCategory } from 'models/entities/ingredient-category';

import { PurchasedIngredientItem, PurchasedIngredientItemData } from '../index';
import { UnitBasedIngredientItem } from '../unit-based';
import { calcNetPrice, calcGrossPrice, calcUnitPrice } from '../../formula';

type Data = PurchasedIngredientItemData & {
  poundPrice?: number;
};

type DataToApply = {
  supplier?: Supplier;
  category?: IngredientCategory;
  name?: string;
  poundPrice?: number;
  yieldRate?: number;
};

class PoundBasedIngredientItem extends PurchasedIngredientItem {

  readonly poundPrice?: number;
  readonly unitValue: number;
  readonly unitSymbol: string;
  readonly netPrice?: number;
  readonly grossPrice?: number;
  readonly unitPrice?: number;

  constructor(data: Data) {
    super(data);
    this.poundPrice = data.poundPrice;
    this.unitValue = 1000;
    this.unitSymbol = 'g';
    this.netPrice = calcNetPrice(this.poundPrice);
    this.grossPrice = calcGrossPrice(this.netPrice, this.yieldRate);
    this.unitPrice = calcUnitPrice(this.grossPrice, this.unitValue);
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const netPrice = calcNetPrice(props.poundPrice);
    const grossPrice = calcGrossPrice(netPrice, props.yieldRate);
    const unitPrice = calcUnitPrice(grossPrice, this.unitValue);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, netPrice, grossPrice, unitPrice });
  }

  toJSON(): Data {
    const data = super.toJSON();
    const { poundPrice } = this;
    return { ...data, poundPrice };
  }

  toUnitBased(): UnitBasedIngredientItem {
    const { id, businessId, supplier, category, name, unitValue, unitSymbol, netPrice, yieldRate, createdAt, updatedAt } = this;
    return new UnitBasedIngredientItem({ id, businessId, supplier, category, name, unitValue, unitSymbol, netPrice, yieldRate, createdAt, updatedAt });
  }

}

export { PoundBasedIngredientItem };
export type { Data as PoundBasedIngredientItemData }