import dayjs, { Dayjs } from 'dayjs';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  businessId: string;
  name?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  fax?: string;
  picName?: string;
  picPhone?: string;
  picEmail?: string;
  notes?: string;
  deleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  name?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  fax?: string;
  picName?: string;
  picPhone?: string;
  picEmail?: string;
  notes?: string;
};

type DataToSave = {
  name: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  phone: string;
  fax: string;
  picName: string;
  picPhone: string;
  picEmail: string;
  notes: string;
  deleted: boolean;
};

class Supplier {

  readonly id: string;
  readonly businessId: string;
  readonly name: string;
  readonly address: string;
  readonly city: string;
  readonly province: string;
  readonly country: string;
  readonly postalCode: string;
  readonly phone: string;
  readonly fax: string;
  readonly picName: string;
  readonly picPhone: string;
  readonly picEmail: string;
  readonly notes: string;
  readonly deleted: boolean;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  private readonly createGql: CreateGql;
  private readonly updateGql: UpdateGql;
  private readonly deleteGql: DeleteGql;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.businessId = data.businessId;
    this.name = data.name ?? '';
    this.address = data.address ?? '';
    this.city = data.city ?? '';
    this.province = data.province ?? '';
    this.country = data.country ?? '';
    this.postalCode = data.postalCode ?? '';
    this.phone = data.phone ?? '';
    this.fax = data.fax ?? '';
    this.picName = data.picName ?? '';
    this.picPhone = data.picPhone ?? '';
    this.picEmail = data.picEmail ?? '';
    this.notes = data.notes ?? '';
    this.deleted = data.deleted ?? false;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.createGql = new CreateGql();
    this.updateGql = new UpdateGql();
    this.deleteGql = new DeleteGql();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<Supplier> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<Supplier> {
    const createGql = await this.createGql.fetch({ businessId: this.businessId, input: this.getDataToSave() });
    if (!createGql.document) throw new Error('invalid document');
    return new Supplier(createGql.document);
  }

  private async update(): Promise<Supplier> {
    if (!this.id) throw new Error('invalid relationship');
    const updateGql = await this.updateGql.fetch({ id: this.id, input: this.getDataToSave() });
    if (!updateGql.document) throw new Error('invalid document');
    return new Supplier(updateGql.document);
  }

  private getDataToSave(): DataToSave {
    const { name, address, city, province, country, postalCode, phone, fax, picName, picPhone, picEmail, notes, deleted } = this;
    return { name, address, city, province, country, postalCode, phone, fax, picName, picPhone, picEmail, notes, deleted };
  }

  async delete(): Promise<Supplier> {
    if (!this.id) throw new Error('invalid relationship');
    const { id } = this;
    const deleteGql = await this.deleteGql.fetch({ id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new Supplier(deleteGql.document);
  }

  toJSON(): Data {
    const { id, businessId, name, address, city, province, country, postalCode, phone, fax, picName, picPhone, picEmail, notes, deleted } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, businessId, name, address, city, province, country, postalCode, phone, fax, picName, picPhone, picEmail, notes, deleted, createdAt, updatedAt };
  }

  static sort(suppliers: Supplier[]) {
    function orderByName(a: Supplier, b: Supplier) {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      else return 0;
    }
    return suppliers.sort(orderByName);
  }

}

export { Supplier };
export type {
  Data as SupplierData,
  DataToSave as SupplierDataToSave,
};

export * from './collection';
export * from './editor-field';
