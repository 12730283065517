import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, primaryColor, primaryTextColor, lightPrimaryColor, darkPrimaryColor } from 'views/services/color-scheme';

export const CurrentPage = styled.div`
  background-color: ${darkPrimaryColor};
  padding: 0 16px;
  display: flex;
  align-items: center;
  & > h2 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    height: 100%;
    display: flex;
    align-items: center;
    color: ${primaryTextColor};
    white-space: nowrap;
  }
`;

export const OtherPage = styled.div`
  cursor: pointer;
  background-color: ${baseColor};
  &:hover {
    background-color: ${lightPrimaryColor};
  }
  padding: 0 16px;
  display: flex;
  align-items: center;
  & > h2 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    display: flex;
    align-items: center;
    color: ${textColor};
    white-space: nowrap;
  }
`;

export const ActionsButton = styled.div`
  transform: translateX(8px);
  & > button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: calc(${baseFontSize} * 0.8);
    color: ${baseColor};
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:disabled):hover {
      background-color: ${primaryColor};
      color: ${primaryTextColor};
    }
  }
`;
