import { DependingQuery } from './depending-query';

import { IngredientFieldsQuery } from './ingredient-fields-query';
import { IngredientItemFieldsQuery } from './ingredient-item-fields-query';
import { DishItemFieldsQuery } from './dish-item-fields-query';

class IngredientCollectionQuery extends DependingQuery {

  toString() {
    return `
      documents {
        ${this.query}
      }
      nextToken
    `;
  }

  static create() {
    const ingredientCollectionQuery = new IngredientCollectionQuery();
    const ingredientFieldsQuery = new IngredientFieldsQuery();
    const ingredientItemFieldsQuery = new IngredientItemFieldsQuery();
    const dishItemFieldsQuery = new DishItemFieldsQuery();
    ingredientCollectionQuery.to(ingredientFieldsQuery);
    ingredientFieldsQuery.to(ingredientItemFieldsQuery);
    ingredientItemFieldsQuery.to(dishItemFieldsQuery);
    dishItemFieldsQuery.to(ingredientCollectionQuery);
    return ingredientFieldsQuery;
  }

}

export { IngredientCollectionQuery };