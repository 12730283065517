import styled from 'styled-components';

import { textColor, lightPrimaryColor, darkPrimaryColor, darkBaseColor } from 'views/services/color-scheme';

export const Button = styled.button`
  cursor: pointer;
  width: 64px;
  height: 40px;
  border: 2px solid ${lightPrimaryColor};
  border-radius: 20px;
  box-sizing: border-box;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:focus, &:hover {
    border-color: ${darkPrimaryColor};
  }
  transition: background-color 0.3s ease-in-out;
  &.off {
    background-color: ${darkBaseColor};
  }
  &.on {
    background-color: ${darkPrimaryColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${textColor};
    transition: transform 0.3s ease-in-out;
    &.off {
      transform: translateX(-12px);
    }
    &.on {
      transform: translateX(12px);
    }
  }
`;