import React, { FC, useState, useRef, useEffect } from 'react';
import { Dayjs } from 'dayjs';

import { Day } from './models/day';
import { Model } from './index.model';

import { Root, Calender } from './index.styled';

interface Props {
  start?: Dayjs;
  end?: Dayjs;
  onStart?: (start: Dayjs) => void;
  onEnd?: (start: Dayjs, end: Dayjs) => void;
}

const PeriodPicker: FC<Props> = ({ start, end, onStart, onEnd }) => {

  const [model, setModel] = useState(new Model({ start, end }));
  const root = useRef<HTMLDivElement>(null);
  const calendar = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(false);

  function select(day: Day) {
    const it = model.select(day);
    if (onStart && it.start && !it.end) onStart(it.start.object);
    if (onEnd && it.start && it.end) onEnd(it.start.object, it.end.object);
    setModel(it);
  }

  function showCurrentMonth() {
    if (ready) return;
    if (!root.current) return;
    if (!calendar.current) return;
    const index = model.calendar.months.findIndex(it => model.start ? it.isStartMonth : it.isThisMonth);
    const calendarTop = calendar.current.getBoundingClientRect().top;
    const thisMonthTop = calendar.current.children[index].getBoundingClientRect().top;
    root.current.scroll({top: thisMonthTop - calendarTop, left: 0});
    setReady(true);
  }

  useEffect(showCurrentMonth, [root.current, calendar.current]);

  return (
    <Root ref={root}>
      <Calender ref={calendar}>
        {model.calendar.months.map((m, i) => (
          <table key={i}>
            <thead>
            <tr>
              <th colSpan={7}>{m.first.format('MMMM YYYY')}</th>
            </tr>
            <tr>
              <th>S</th>
              <th>M</th>
              <th>T</th>
              <th>W</th>
              <th>T</th>
              <th>F</th>
              <th>S</th>
            </tr>
            </thead>
            <tbody>
            {m.weeks.map((w, j) => (
              <tr key={j}>
                {w.days.map((d, k) => (
                  <td key={k}>
                    {!m.has(d) ? '' : (
                      <button onClick={() => select(d)} className={d.states.join(' ')} tabIndex={-1}>{d.number}</button>
                    )}
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </table>
        ))}
      </Calender>
    </Root>
  );

};

export { PeriodPicker };