import React from 'react';

import { LoadingAnimation } from 'views/components/loading-animation';

import { Root } from './index.styled';

const CenteredLoading = () => {
  return (
    <Root>
      <LoadingAnimation />
    </Root>
  );
}

export { CenteredLoading };