import dayjs, { Dayjs } from 'dayjs';

type Data = {
  id?: string;
  name?: string;
  createdAt?: string | Dayjs;
  updatedAt?: string | Dayjs;
};

class BrandIdentity {

  readonly id: string;
  readonly name: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data = {}) {
    this.id = data.id ?? '';
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  toJSON(): Data {
    const { id, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, name, createdAt, updatedAt };
  }

}

export { BrandIdentity };
export type { Data as BrandIdentityData };