import { useState, useEffect } from 'react';

import { useLocation } from 'views/services/location';

type ServiceCode = string | undefined;

const useBusinessCode = (): ServiceCode => {

  const location = useLocation();
  const [serviceCode, setServiceCode] = useState<ServiceCode>(getServiceCode());

  function getServiceCode(): ServiceCode {
    const result = location.pathname.match(/^\/([^\/]+)\/?/);
    return result ? result[1] : undefined;
  }

  function watchLocation() {
    setServiceCode(getServiceCode());
  }

  useEffect(watchLocation, [location.pathname]);

  return serviceCode;

}

export { useBusinessCode };