import { Api } from 'models/services/api';
import { CreateDishItemQuery } from 'models/services/graphql';

import { DishItemEditorDataToCreate } from '../editor';
import { DishItemDataToIdentify } from '../dish-item';

type DataToFetch = {
  menuCategoryId: string;
  input: DishItemEditorDataToCreate;
};

const RequestQuery = `
  mutation CreateDishItem($menuCategoryId: ID!, $input: CreateDishItemInput!) {
    ${CreateDishItemQuery}
  }
`;

type ResponseData = {
  data: {
    createDishItem: DishItemDataToIdentify;
  };
};

class CreateGql {

  readonly api: Api<ResponseData>;
  readonly document?: DishItemDataToIdentify;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.createDishItem;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { CreateGql };
export type { DataToFetch as CreateGqlDataToFetch }