import styled, { keyframes } from 'styled-components';

import { baseFontFamily } from 'views/services/font-scheme';
import { baseColor, darkTextColor, primaryColor, accentColor } from 'views/services/color-scheme';

type Background = 'transparent' | 'base-color' | 'black';

function getBackground(code: Background = 'transparent'): string {
  switch (true) {
    case code === 'base-color': return baseColor;
    default: return code;
  }
}

export interface RootProps {
  background?: Background;
}

export const Root = styled.div<RootProps>`
  position: relative;
  width: 8em;
  height: 8em;
  background-color: ${p => getBackground(p.background)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const flicker = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Text = styled.div`
  font-family: ${baseFontFamily};
  font-size: 1em;
  color: ${darkTextColor};
  opacity: 1;
  animation: ${flicker} calc(300ms * 10) ease-in infinite;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
    border-color: ${primaryColor};
  }
  50% {
    border-color: ${accentColor};
  }
  100% {
    transform: rotate(360deg);
    border-color: ${primaryColor};
  }
`;

export const Rings = styled.div`
  position: absolute;
  top: -4px;
  left: 4px;
  right: 4px;
  bottom: -4px;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${primaryColor};
    border-radius: 50%;
    opacity: 0.5;
    &:nth-child(1) {
      animation: ${rotate} 600ms linear infinite;
    }
    &:nth-child(2) {
      animation: ${rotate} 600ms 200ms linear infinite reverse;
    }
    &:nth-child(3) {
      animation: ${rotate} 600ms 400ms linear infinite;
    }
  }
`;