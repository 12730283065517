import { Api } from 'models/services/api';
import { ReadUsersQuery } from 'models/services/graphql';
import { UserCollectionData } from 'models/entities/user';

type DataToFetch = {
  organizationId: string;
};

const RequestQuery = `
  query ShowUsers($organizationId: ID!, $nextToken: String) {
    ${ReadUsersQuery}
  }
`;

type ResponseData = {
  data: {
    readUsers: UserCollectionData;
  }
}

type Result = {
  collection: UserCollectionData;
}

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const collection = api.response.data.readUsers;
    const result = { collection };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };