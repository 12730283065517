import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lineColor, lightPrimaryColor, darkPrimaryColor, vividPrimaryColor, primaryTextColor } from 'views/services/color-scheme';

export const Root = styled.article`
  background-color: ${baseColor};
  border-radius: 8px;
  width: 85vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  & > h1 {
    padding: 16px;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.5);
    color: ${textColor};
  }
  & > div {
    height: 100%;
    overflow: scroll;
    background-color: ${darkBaseColor};
  }
`;

export const Report = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1);
  color: ${textColor};
  & > table {
    width: 100%;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: ${baseLineHeight};
    background-color: ${baseColor};
    color: ${textColor};
    border-collapse: collapse;
    & > thead {
      z-index: 10;
      position: sticky;
      top: 0;
      & > tr {
        & > th {
          text-align: left;
          vertical-align: middle;
          font-weight: bold;
          padding: 8px 16px;
          background-color: ${darkPrimaryColor};
          color: ${primaryTextColor};
          &.number {
            text-align: center;
          }
        }
      }
    }
    & > tbody {
      & > tr {
        &:hover {
          background-color: ${lightPrimaryColor};
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: -0.5px;
            left: 0;
            right: 0;
            bottom: -0.5px;
            border-top: 1px solid ${vividPrimaryColor};
            border-bottom: 1px solid ${vividPrimaryColor};
            pointer-events: none;
          }
        }
        & > td {
          cursor: pointer;
          border-top: 1px solid ${lineColor};
          border-bottom: 1px solid ${lineColor};
          padding: 8px 16px;
          &.number {
            text-align: right;
          }
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  font-size: calc(${baseFontSize} * 1);
  border-top: 1px solid ${lineColor};
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
