import React from 'react';

import { Root, Path } from './index.styled';

const OpenaiIcon = () => {
  return (
    <Root>
      <svg viewBox="0 0 32 32">
        <g>
          <Path d="m29.7 13.1c.4-1.1.5-2.2.4-3.3s-.5-2.2-1-3.2c-.9-1.5-2.2-2.7-3.7-3.4-1.6-.7-3.3-.9-5-.5-.8-.8-1.7-1.5-2.7-2s-2.2-.7-3.3-.7c-1.7 0-3.4.5-4.8 1.5s-2.4 2.4-2.9 4c-1.2.3-2.2.8-3.2 1.4-.9.7-1.6 1.6-2.2 2.5-.9 1.5-1.2 3.2-1 4.9s.9 3.3 2 4.6c-.4 1.1-.5 2.2-.4 3.3s.5 2.2 1 3.2c.9 1.5 2.2 2.7 3.7 3.4 1.6.7 3.3.9 5 .5.8.8 1.7 1.5 2.7 2s2.2.7 3.3.7c1.7 0 3.4-.5 4.8-1.5s2.4-2.4 2.9-4c1.1-.2 2.2-.7 3.1-1.4s1.7-1.5 2.2-2.5c.9-1.5 1.2-3.2 1-4.9s-.8-3.3-1.9-4.6zm-12 16.8c-1.6 0-2.8-.5-3.9-1.4 0 0 .1-.1.2-.1l6.4-3.7c.2-.1.3-.2.4-.4s.1-.3.1-.5v-9l2.7 1.6v7.4c.1 3.5-2.7 6.1-5.9 6.1zm-12.9-5.5c-.7-1.2-1-2.6-.7-4 0 0 .1.1.2.1l6.4 3.7c.2.1.3.1.5.1s.4 0 .5-.1l7.8-4.5v3.1l-6.5 3.8c-1.4.8-3 1-4.5.6-1.6-.4-2.9-1.4-3.7-2.8zm-1.7-13.9c.7-1.2 1.8-2.1 3.1-2.6v.2 7.4c0 .2 0 .4.1.5.1.2.2.3.4.4l7.8 4.5-2.7 1.6-6.4-3.7c-1.4-.8-2.4-2.1-2.8-3.6s-.3-3.3.5-4.7zm22.1 5.1-7.8-4.5 2.7-1.6 6.4 3.7c1 .6 1.8 1.4 2.3 2.4s.8 2.1.7 3.3c-.1 1.1-.5 2.2-1.2 3.1s-1.6 1.6-2.7 2v-7.6c0-.2 0-.4-.1-.5 0 0-.1-.2-.3-.3zm2.7-4s-.1-.1-.2-.1l-6.4-3.7c-.2-.1-.3-.1-.5-.1s-.4 0-.5.1l-7.8 4.5v-3.1l6.5-3.8c1-.6 2.1-.8 3.3-.8 1.1 0 2.2.4 3.2 1.1.9.7 1.7 1.6 2.1 2.6s.5 2.2.3 3.3zm-16.8 5.6-2.7-1.6v-7.5c0-1.1.3-2.3.9-3.2.6-1 1.5-1.7 2.5-2.2s2.2-.7 3.3-.5c1.1.1 2.2.6 3.1 1.3 0 0-.1.1-.2.1l-6.4 3.7c-.2.1-.3.2-.4.4s-.1.3-.1.5zm1.4-3.2 3.5-2 3.5 2v4l-3.5 2-3.5-2z" />
        </g>
      </svg>
    </Root>
  );
};

export { OpenaiIcon };