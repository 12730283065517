import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import {
  baseColor,
  darkBaseColor,
  textColor,
  lightPrimaryColor,
  darkPrimaryColor,
  lightTextColor,
  lightRedColor,
  redTextColor,
  redLineColor,
  darkTextColor
} from 'views/services/color-scheme';

const h2FontSize = baseFontSize;
const h2Margin = '8px';

const TextInput = styled.div`
  & > label {
    display: block;
    cursor: pointer;
    & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
      font-family: ${baseFontFamily};
      font-size: calc(${h2FontSize} * 1);
      color: ${lightTextColor};
      margin-bottom: ${h2Margin};
      &.disabled {
        color: ${darkTextColor};
      }
    }
    & > div {
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 1.25);
      color: ${textColor};
      display: flex;
      align-items: center;
      & > input {
        order: 2;
        background-color: ${darkBaseColor};
        padding: 0 8px;
        border-bottom: 2px solid ${lightPrimaryColor};
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        & ~ span, & ~ button {
          background-color: ${darkBaseColor};
          padding: 4px 8px;
          border-bottom: 2px solid ${lightPrimaryColor};
          box-sizing: border-box;
          height: 40px;
          &.prefix {
            order: 1;
            padding-right: 0;
          }
          &.suffix {
            order: 3;
            padding-left: 0;
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:focus {
          border-color: ${darkPrimaryColor};
          & ~ span, & ~ button {
            border-color: ${darkPrimaryColor};
          }
        }
      }
      &:hover {
        & > input, & > span, & > button {
          border-color: ${darkPrimaryColor};
        }
      }
      & > button {
        cursor: pointer;
      }
    }
    position: relative;
    & > p {
      z-index: 1;
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 0.8);
      line-height: 1;
      color: ${redTextColor};
      background-color: ${lightRedColor};
      border-left: 1px solid ${redLineColor};
      border-bottom: 1px solid ${redLineColor};
      white-space: nowrap;
      position: absolute;
      height: 24px;
      bottom: calc(40px + 8px);
      left: -16px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 22px;
        background-color: ${redLineColor};
        transform-origin: left bottom;
        rotate: 135deg;
      }
    }
  }
`;

export const Root = styled(TextInput)`
  & > label {
    & > div {
      & > input {
        cursor: pointer;
      }
    }
  }
  position: relative;
  & > ol {
    z-index: 2;
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    height: 240px;
    background-color: ${baseColor};
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1.25);
    color: ${textColor};
    border: 2px solid ${darkPrimaryColor};
    border-radius: 0 0 4px 4px;
    box-sizing: border-box;
    overflow: scroll;
    & > li {
      cursor: pointer;
      padding: 8px calc(8px - 2px);
      &:hover, &.selected {
        background-color: ${lightPrimaryColor};
      }
    }
  }
`;
