import { PosReport } from 'models/entities/pos-report';
import { Business } from 'models/entities/business';

type Data = {
  target: PosReport;
}

class Model {

  readonly target: PosReport;
  readonly business: Business;

  readonly tsv: string;

  readonly result?: PosReport;

  constructor(data: Data) {
    this.target = data.target;
    this.business = data.target.business;
    this.tsv = '';
  }

  input(tsv: string): this {
    const result = this.target.import(tsv);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, tsv, result });
  }

}

export { Model };