import React, { FC, PropsWithChildren } from 'react';

import './index.scss';
import { HtmlStyle } from './index.styled';

const GlobalStyle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <HtmlStyle />
      {children}
    </>
  );
};

export { GlobalStyle };
