import dayjs, { Dayjs } from 'dayjs';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  code?: string;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  code?: string;
  name?: string;
};

type DataToSave = {
  code: string;
  name: string;
};

class PosSystem {

  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  private readonly createGql: CreateGql;
  private readonly updateGql: UpdateGql;
  private readonly deleteGql: DeleteGql;

  constructor(data: Data = {}) {
    this.id = data.id ?? '';
    this.code = data.code ?? '';
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.createGql = new CreateGql();
    this.updateGql = new UpdateGql();
    this.deleteGql = new DeleteGql();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<PosSystem> {
    if (!this.validate()) throw new Error('invalid object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<PosSystem> {
    const createGql = await this.createGql.fetch({ input: this.getDataToSave() });
    if (!createGql.document) throw new Error('invalid document');
    return new PosSystem(createGql.document);
  }

  private async update(): Promise<PosSystem> {
    if (!this.id) throw new Error('invalid relationship');
    const updateGql = await this.updateGql.fetch({ id: this.id, input: this.getDataToSave() });
    if (!updateGql.document) throw new Error('invalid document');
    return new PosSystem(updateGql.document);
  }

  private getDataToSave(): DataToSave {
    const { code, name } = this;
    return { code, name };
  }

  async delete(): Promise<PosSystem> {
    if (!this.id) throw new Error('invalid relationship');
    const { id } = this;
    const deleteGql = await this.deleteGql.fetch({ id });
    if (!deleteGql.document) throw new Error('invalid document');
    return new PosSystem(deleteGql.document);
  }

  toJSON(): Data {
    const { id, code, name } = this;
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, code, name, createdAt, updatedAt };
  }

  static sort(categories: PosSystem[]) {
    function order(a: PosSystem, b: PosSystem) {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      else return 0;
    }
    return categories.sort(order);
  }

}

export { PosSystem };
export type {
  Data as PosSystemData,
  DataToSave as PosSystemDataToSave,
};

export * from './collection';