import { ModalName } from './modal-name';
import { TargetObject } from './target-object';
import { OtherObject } from './other-object';
import { Result, ResultData } from '../entities/result';

type OpenOptions = {
  target?: TargetObject;
  other?: OtherObject;
  strict?: boolean;
  finalize?: boolean;
  tell?: (result: Result) => void;
};

interface ModalInterface {
  open: (name: ModalName, options?: OpenOptions) => void;
  close: (result?: ResultData) => void;
}

const DefaultInterface = {
  open: () => {},
  close: () => {},
};

export type { ModalInterface, OpenOptions };
export { DefaultInterface };