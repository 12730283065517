import { SupplierCollection } from 'models/entities/supplier';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';

import { IngredientItem } from './ingredient-item';

type Data = {
  original: IngredientItem;
  suppliers?: SupplierCollection;
  categories: IngredientCategoryCollection;
  unitSymbols?: string[];
};

type DataToCreate = {
  supplierId?: string;
  dishItemId?: string;
  categoryId: string;
  name: string;
  poundPrice?: number;
  unitValue?: number;
  unitSymbol?: string;
  netPrice?: number;
  yieldRate: number;
};

type DataToUpdate = {
  supplierId?: string;
  dishItemId?: string;
  categoryId?: string;
  name?: string;
  poundPrice?: number;
  unitValue?: number;
  unitSymbol?: string;
  netPrice?: number;
  yieldRate?: number;
};

abstract class IngredientItemEditor {

  readonly original: IngredientItem;
  readonly categories: IngredientCategoryCollection;
  readonly unitSymbols?: string[];

  protected constructor(data: Data) {
    this.original = data.original;
    this.categories = data.categories;
    this.unitSymbols = data.unitSymbols;
  }

}

export { IngredientItemEditor };
export type {
  Data as IngredientItemEditorData,
  DataToCreate as IngredientItemEditorDataToCreate,
  DataToUpdate as IngredientItemEditorDataToUpdate,
};