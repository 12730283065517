import { SatelliteKitchen } from 'models/entities/satellite-kitchen';
import { Store } from 'models/entities/store';

type Data = {
  target: SatelliteKitchen;
  stores: Store[];
};

type DataToChange = {
  store?: Store;
  name?: string;
};

class Model {

  readonly target: SatelliteKitchen;
  readonly isNew: boolean;
  readonly canDelete: boolean;
  readonly stores: Store[];

  readonly store?: Store;
  readonly name: string;

  readonly result?: SatelliteKitchen;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.canDelete = true;
    this.stores = data.stores;
    this.store = this.target.store;
    this.name = this.target.name;
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.name) return false;
    return true;
  }

  private build(data: DataToChange): SatelliteKitchen {
    const store = data.store;
    const name = data.name;
    return this.target.edit({ store, name });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };