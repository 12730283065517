import React, { FC } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { AbstractIcon } from '../abstract-icon';

interface Props {
  className?: string;
}

const ChevronDownIcon: FC<Props> = props => <AbstractIcon {...props} icon={faChevronDown} />;

export { ChevronDownIcon };