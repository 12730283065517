import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';
import { secondaryTextColor, vividSecondaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
  & > button {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: calc(${baseFontSize} * 1.25);
    color: ${secondaryTextColor};
    &:hover {
      background-color: ${vividSecondaryColor};
    }
    display: flex;
    justify-content: center;
    align-items: center;
    &:disabled {
      pointer-events: none;
      opacity: 0.25;
    }
  }
`;