import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, primaryColor, primaryTextColor, accentColor, vividAccentColor } from 'views/services/color-scheme';

export const Root = styled.div`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 1.25);
  & > fieldset {
    & > div {
      color: ${textColor};
    }
    & > ol {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > li {
        & > button {
          color: ${primaryColor};
          background: linear-gradient(125deg, ${primaryColor} 30%, ${accentColor});
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding: 16px 32px;
          border-radius: 40px;
          display: block;
          width: 100%;
          &:hover {
            cursor: pointer;
            color: ${primaryTextColor};
            background: linear-gradient(125deg, ${primaryColor} 30%, ${vividAccentColor});
            -webkit-background-clip: padding-box;
            -webkit-text-fill-color: ${primaryTextColor};
          }
        }
      }
    }
  }
`;