import React, { FC, useRef, useEffect, PropsWithChildren, ChangeEvent } from 'react';

import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  value: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  readOnly?: boolean;
  inFocus?: boolean;
  height?: string;
  placeholder?: string;
}

const MultiTextInput: FC<Props> = ({ value, onChange, readOnly, children, inFocus, height, placeholder }) => {

  const textarea = useRef<HTMLTextAreaElement>(null);

  function watchInFocus() {
    if (inFocus) textarea.current?.focus();
  }

  useEffect(watchInFocus, [inFocus]);

  return (
    <Root height={height}>
      <label>
        {children}
        <div>
          <textarea value={value} onChange={onChange} readOnly={readOnly} ref={textarea} placeholder={placeholder} />
        </div>
      </label>
    </Root>
  );

};

export { MultiTextInput };