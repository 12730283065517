import React, { FC, useState, useEffect } from 'react';

import { Rect } from '../../../models/rect';
import { Point } from '../../../models/point';
import { Animation } from '../../../models/animation';

import { useDnd } from '../../services/use-dnd';

import { Root, Label } from './index.styled';

interface Props {
  label: string;
  rect: Rect;
  point: Point;
}

const GrabListItem: FC<Props> = ({ label, rect, point }) => {

  const dnd = useDnd();
  const [animation, setAnimation] = useState<Animation>('');

  function watchState() {
    if (dnd.state.name === 'dnd-grabbing') setAnimation('dnd-grabbing-animation');
    else if (dnd.state.name === '') setAnimation('');
  }

  useEffect(watchState, [dnd.state]);

  return (
    <Root rect={rect} point={point} className={animation}>
      <Label>{label}</Label>
    </Root>
  );
};

export { GrabListItem };