import React, { FC, PropsWithChildren, useState, useEffect } from 'react';

import { useAuth } from '../../services/use-auth';
import { useModal } from 'views/services/modal';
import { Root, Main, Loading } from './index.styled';

const Gate: FC<PropsWithChildren> = ({ children }) => {

  const auth = useAuth();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(true);

  function unlock() {
    setIsLoading(false);
    modal.close();
  }

  function lock() {
    setIsLoading(true);
    modal.open('sign-in', { strict: true });
  }

  function watch() {
    if (auth.ok === undefined) return;
    auth.ok ? unlock() : lock();
  }

  useEffect(watch, [auth.ok]);

  return (
    <Root>
      <Main>{children}</Main>
      {isLoading && <Loading />}
    </Root>
  );

};

export { Gate };