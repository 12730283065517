import React, { FC, useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';

import { StringArrayField } from 'models/value-objects/editor-field';
import { Model } from './index.model';

import { ChevronDownIcon } from 'views/components/icons';
import { Root } from './index.styled';

interface Props {
  field: StringArrayField;
  onEdit: (field: StringArrayField) => void;
  alt?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  focused?: boolean;
  prefix?: string;
  suffix?: string;
}

const StringArrayInput: FC<Props> = props => {

  const { field, onEdit, placeholder = '', alt, readOnly = true, disabled = false, focused = false, prefix, suffix } = props;
  const input = useRef<HTMLInputElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const ol = useRef<HTMLOListElement>(null);
  const [model, setModel] = useState(new Model({ field, alt }));
  const [isOpen, setIsOpen] = useState(false);
  const [focusing, setFocusing] = useState(false);

  function toggle() {
    if (!model.field.list.length) return close();
    setIsOpen(!isOpen);
  }

  function open() {
    if (!model.field.list.length) return close();
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function closeWithClick(e: Event) {
    if (input.current?.contains(e.target as Node)) return;
    if (button.current?.contains(e.target as Node)) return;
    close();
  }

  function dropDown() {
    toggle();
    setFocusing(true);
  }

  function pick(index: number) {
    select(index);
    close();
    setFocusing(true);
  }

  function select(index: number) {
    const it = model.select(index);
    onEdit(it.field);
    setModel(it);
  }

  function back() {
    if (!isOpen) open();
    const first = 0;
    const last = model.field.list.length - 1;
    let next = model.field.index !== -1 ? model.field.index - 1 : last;
    if (next < first) next = last;
    select(next);
  }

  function next() {
    if (!isOpen) open();
    const first = 0;
    const last = model.field.list.length - 1;
    let next = model.field.index !== -1 ? model.field.index + 1 : first;
    if (next > last) next = first;
    select(next);
  }

  function scroll() {
    if (!ol.current) return;
    if (!isOpen) return;
    if (model.field.index === -1) return;
    const olHeight = ol.current.getBoundingClientRect().height;
    const liHeight = ol.current.scrollHeight / model.field.list.length;
    const centerTop = olHeight / 2 - liHeight / 2;
    const selectedTop = liHeight * model.field.index;
    ol.current.scroll({ top: selectedTop - centerTop, left: 0 });
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const it = model.change(e.target.value);
    onEdit(it.field);
    setModel(it);
  }

  function onMouseDown() {
    toggle();
    setFocusing(true);
  }

  function onFocus() {
    if (!readOnly) input.current?.select();
    if (!focusing) open();
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case 'Tab': return close();
      case 'ArrowUp': return back();
      case 'ArrowDown': return next();
      case 'Enter': return toggle();
      case ' ': return toggle();
      case 'Escape': return close();
    }
  }

  function watchField() {
    if (field.value === model.field.value) return;
    setModel(model.renew(field));
  }

  function watchFocused() {
    if (!focused) return;
    setFocusing(true);
  }

  function setupWindowClick() {
    if (!isOpen) return;
    window.addEventListener('click', closeWithClick);
    return () => window.removeEventListener('click', closeWithClick);
  }

  function watchFocusing() {
    if (!focusing) return;
    setTimeout(() => setFocusing(false), 300);
    input.current?.focus();
  }

  function watchIndex() {
    scroll();
  }

  useEffect(watchField, [field]);
  useEffect(watchFocused, [focused]);
  useEffect(setupWindowClick, [isOpen]);
  useEffect(watchFocusing, [focusing]);
  useEffect(watchIndex, [model.field.index]);

  return (
    <Root>
      <input
        ref={input}
        type="text"
        value={model.value}
        onChange={onChange}
        onMouseDown={onMouseDown}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={model.alt || placeholder}
        readOnly={readOnly}
        disabled={disabled}
        className={model.isAlt ? 'alt' : ''}
      />
      {!!prefix && <span className="prefix">{prefix}</span>}
      {!!suffix && <span className="suffix">{suffix}</span>}
      {readOnly && <button ref={button} onClick={() => dropDown()} tabIndex={-1}><ChevronDownIcon /></button>}
      {isOpen && model.field.list.length && (
        <ol ref={ol}>
          {model.field.list.map((it, i) => (
            <li key={i} onClick={() => pick(i)} className={i === model.field.index ? 'selected' : ''}>{it}</li>
          ))}
        </ol>
      )}
    </Root>
  );

};

export { StringArrayInput };