import React, { FC, PropsWithChildren } from 'react';

import { Root } from './index.styled';

const OutputField: FC<PropsWithChildren> = ({ children }) => {

  return (
    <Root>
      {children}
    </Root>
  );

};

export { OutputField };