import React, { FC, useState, useEffect } from 'react';

import { Model } from './index.model';

import { BrandsActionName } from '../../../models/entities/name';
import { BrandsActionsProps } from '../../../models/entities/props';
import { useDropdown } from '../../services/use-dropdown';
import { Root } from './index.styled';

const BrandsActions: FC<BrandsActionsProps> = ({ target, onSelect }) => {

  const dropdown = useDropdown();
  const [model, setModel] = useState(new Model({ target }));

  function select(action: BrandsActionName) {
    dropdown.close();
    onSelect(action);
  }

  function watchTarget() {
    setModel(model.apply({ target }));
  }

  useEffect(watchTarget, [target]);

  return (
    <Root>
      <ol>
        {model.isBrand() && (
          <li onClick={() => select('edit-headquarters')}>Add Headquarters</li>
        )}
        {model.isHeadquarters() && (
          <>
            <li onClick={() => select('edit-store')}>Add Store</li>
            <li onClick={() => select('edit-franchisor')}>Add Franchisor</li>
            <li onClick={() => select('edit-user')}>Add User</li>
          </>
        )}
        {model.isFranchisor() && (
          <>
            <li onClick={() => select('edit-store')}>Add Store</li>
            <li onClick={() => select('edit-franchisee')}>Add Franchisee</li>
            <li onClick={() => select('edit-user')}>Add User</li>
          </>
        )}
        {model.isFranchisee() && (
          <>
            <li onClick={() => select('edit-store')}>Add Store</li>
            <li onClick={() => select('edit-user')}>Add User</li>
          </>
        )}
        {model.isStore() && (
          <>
            <li onClick={() => select('edit-user')}>Add User</li>
          </>
        )}
      </ol>
    </Root>
  );

};

export { BrandsActions };