import React, { FC, PropsWithChildren } from 'react';

import { Root } from './index.styled';

const CenteredEmptyMessage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Root>
      {children}
    </Root>
  );
}

export { CenteredEmptyMessage };