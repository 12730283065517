import { MemoryKey } from './memory-key';
import { MemoryValue } from './memory-value';

interface MemoryContextInterface {
  set: (key: MemoryKey, value: MemoryValue) => void;
  get: (key: MemoryKey) => MemoryValue;
}

const DefaultInterface = {
  set: () => {},
  get: () => undefined,
};

export type { MemoryContextInterface };
export { DefaultInterface };