import React, { FC } from 'react';
import { Link } from 'gatsby';

import { Business, BusinessCollection } from 'models/entities/business';
import { Mode } from './models/mode';

import { useDropdown } from 'views/services/dropdown';
import { Logo } from 'views/components/logo';
import { BusinessMenu } from 'views/components/business-menu';
import { XScrollableArea } from 'views/components/x-scrollable-area';
import { CompaniesMenu, BrandsMenu, CentralKitchensMenu, BusinessCategoriesMenu, PosSystemsMenu, BusinessesMenu, OriginalIngredientCategoriesMenu, OriginalMenuCategoriesMenu, OriginalDishCategoriesMenu, DashboardMenu, SuppliersMenu, IngredientItemsMenu, DishItemsMenu, PreparationMenu, PurchasingMenu } from 'views/components/menu-item';
import { ChatMenu } from 'views/components/chat-menu';
import { UserMenu } from 'views/components/user-menu';
import { Root, Left, Home, Center, Menu, Right } from './index.styled';

interface Props {
  mode?: Mode;
  business?: Business;
  businesses?: BusinessCollection;
  border?: boolean;
  onCreate?: () => void;
  onImport?: () => void;
  onExport?: () => void;
  onExportCategories?: () => void;
  onImportReport?: () => void;
  onExportReport?: () => void;
  onChat?: () => void;
  disabledAction?: {
    onCreate?: boolean;
    onImport?: boolean;
    onExport?: boolean;
    onExportCategories?: boolean;
    onImportReport?: boolean;
    onExportReport?: boolean;
    onChat?: boolean;
  };
}

const height = '48px';

const Header: FC<Props> = props => {

  const { mode = '', business, businesses, border = false, onCreate, onImport, onExport, onExportCategories, onImportReport, onExportReport, onChat, disabledAction } = props;
  const dropdown = useDropdown();

  return (
    <Root height={height} border={border} >
      <Left>
        <Home><Link to="/"><Logo /></Link></Home>
        {mode === 'business' && <BusinessMenu business={business} businesses={businesses} />}
      </Left>
      <Center>
        <XScrollableArea onScroll={() => dropdown.close()}>
          {(() => {
            switch (true) {
              case mode === 'admin': return (
                <Menu>
                  <CompaniesMenu onCreate={onCreate} />
                  <BrandsMenu onCreate={onCreate} />
                  <CentralKitchensMenu onCreate={onCreate} />
                  <BusinessCategoriesMenu onCreate={onCreate} />
                  <PosSystemsMenu onCreate={onCreate} />
                  <BusinessesMenu onCreate={onCreate} />
                  <OriginalIngredientCategoriesMenu onCreate={onCreate} />
                  <OriginalMenuCategoriesMenu onCreate={onCreate} />
                  <OriginalDishCategoriesMenu />
                </Menu>
              );
              case mode === 'business': return (
                <Menu>
                  <DashboardMenu business={business} businesses={businesses} onImportReport={onImportReport} onExportReport={onExportReport} disabledAction={disabledAction} />
                  <PreparationMenu business={business} businesses={businesses} onImportReport={onImportReport} />
                  <PurchasingMenu business={business} businesses={businesses} onImportReport={onImportReport} />
                  <SuppliersMenu business={business} businesses={businesses} onCreate={onCreate} onExport={onExport} />
                  <IngredientItemsMenu business={business} businesses={businesses} onCreate={onCreate} onImport={onImport} onExport={onExport} onExportCategories={onExportCategories} disabledAction={disabledAction} />
                  {business?.menuCategories.documents.map((it, i) => (
                    <DishItemsMenu key={i} business={business} businesses={businesses} category={it} onCreate={onCreate} onImport={onImport} onExport={onExport} onExportCategories={onExportCategories} disabledAction={disabledAction} />
                  ))}
                </Menu>
              );
            }
          })()}
        </XScrollableArea>
      </Center>
      <Right>
        {onChat && <ChatMenu onChat={onChat} disabled={disabledAction?.onChat} />}
        <UserMenu />
      </Right>
    </Root>
  );

};

export { Header, height as headerHeight };