type Number = number | undefined;

export function calcCostRate(cost: number, price: number): number {
  return cost / price;
}

export function calcGrossPrice(netPrice: Number, yieldRate: Number): Number {
  if (netPrice === undefined) return undefined;
  if (yieldRate === undefined) return undefined;
  const result = netPrice / yieldRate
  return isFinite(result) ? result : undefined;
}
