import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

type State = 'today' | 'start' | 'period' | 'end';

type Data = {
  object: Dayjs;
};

class Day {

  readonly object: Dayjs;
  readonly number: string;
  readonly isToday: boolean;
  readonly isStart: boolean;
  readonly isPeriod: boolean;
  readonly isEnd: boolean;
  readonly states: State[];

  constructor(data: Data) {
    this.object = data.object;
    this.number = this.object.format('D');
    this.states = this.getStates();
    this.isToday = this.object.isSame(dayjs(), 'day');
    this.isStart = false;
    this.isPeriod = false;
    this.isEnd = false;
    this.states = this.getStates();
  }

  select(start: Day, end?: Day): this {
    const isStart = this.object.isSame(start.object, 'day');
    const isPeriod = end ? this.object.isBetween(start.object, end.object) : false;
    const isEnd = end ? this.object.isSame(end.object, 'day') : false;
    const states = this.getStates({ isStart, isPeriod, isEnd });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, isStart, states });
  }

  private getStates(data: { isStart?: boolean, isPeriod?: boolean, isEnd?: boolean } = {}): State[] {
    const props = { ...this, ...data };
    const states: State[] = [];
    if (props.isToday) states.push('today');
    if (props.isStart) states.push('start');
    if (props.isPeriod) states.push('period');
    if (props.isEnd) states.push('end');
    return states;
  }

}

export { Day };