import { StoreFieldsQuery } from '../store';

export const SatelliteKitchenFieldsQuery = `
  id
  parentId
  ownerId
  centralKitchenId
  store {
    ${StoreFieldsQuery}
  }
  name
  createdAt
  updatedAt
`;

export const SatelliteKitchenCollectionQuery = `
  documents {
    ${SatelliteKitchenFieldsQuery}
  }
  nextToken
`;

export const ReadSatelliteKitchensQuery = `
  readSatelliteKitchens(nextToken: $nextToken) { 
    ${SatelliteKitchenCollectionQuery}
  }
`;

export const CreateSatelliteKitchenQuery = `
  createSatelliteKitchen(centralKitchenId: $centralKitchenId, input: $input) { 
    ${SatelliteKitchenFieldsQuery}
  }
`;

export const UpdateSatelliteKitchenQuery = `
  updateSatelliteKitchen(id: $id, input: $input) { 
    ${SatelliteKitchenFieldsQuery}
  }
`;

export const DeleteSatelliteKitchenQuery = `
  deleteSatelliteKitchen(id: $id) { 
    ${SatelliteKitchenFieldsQuery}
  }
`;
