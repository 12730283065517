type Step = 'step1' | 'step2' | 'step3';

class Model {

  readonly step: Step;
  readonly focusing: Step;
  readonly message: string;

  constructor() {
    this.step = 'step1';
    this.focusing = 'step1';
    this.message = '';
  }

  goTo(step: Step): this {
    const message = '';
    return Object.assign(Object.create(this.constructor.prototype), { ...this, step, message });
  }

  focusOn(focusing: Step): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, focusing });
  }

  alert(message: string): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, message });
  }

}

export { Model };
export type { Step };