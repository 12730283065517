import { chunk } from 'lodash';

import { IngredientItem, IngredientItemEditorDataToCreate, IngredientItemFactory } from 'models/entities/ingredient-item';
import { Business } from 'models/entities/business';

import { CreateGql } from './models/create-gql';

type Data = {
  target: IngredientItem[];
  business: Business;
}

class Model {

  readonly target: IngredientItem[];
  readonly business: Business;
  readonly tsv: string;
  readonly data: IngredientItemEditorDataToCreate[];
  readonly valid: boolean;
  readonly result?: IngredientItem[];

  private readonly createGql: CreateGql;

  constructor(data: Data) {
    this.target = data.target;
    this.business = data.business;
    this.tsv = ['supplierId', 'categoryId', 'dishItemId', 'name', 'poundPrice', 'unitValue', 'unitSymbol', 'netPrice', 'yieldRate'].join('\t');
    this.data = [];
    this.valid = false;
    this.createGql = new CreateGql();
  }

  input(tsv: string): this {
    const data = tsv.split('\n').slice(1).map(it => this.parse(it));
    const valid = data.every(it => !!it);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, tsv, data, valid });
  }

  private parse(row: string): IngredientItemEditorDataToCreate | undefined {
    let error = false;
    function logError(message: string) {
      error = true;
      console.error(new Error(message));
    }
    const data = row.split('\t');
    const supplierId = data[0] || undefined;
    const categoryId = data[1];
    const dishItemId = data[2] || undefined;
    const name = data[3];
    const poundPrice = data[4] ? parseFloat(data[4]) : undefined; if (data[4] && !isFinite(poundPrice ?? NaN)) logError('poundPrice must be a number');
    const unitValue = poundPrice === undefined ? parseFloat(data[5]) : undefined; if (poundPrice === undefined && !isFinite(unitValue ?? NaN)) logError('unitValue must be a number');
    const unitSymbol = poundPrice === undefined ? data[6] ?? '' : undefined;
    const netPrice = poundPrice === undefined ? parseFloat(data[7]) : undefined; if (poundPrice === undefined && !isFinite(netPrice ?? NaN)) logError('netPrice must be a number');
    const yieldRate = parseFloat(data[8]); if (!isFinite(yieldRate)) logError('yieldRate must be a number');
    if (error) return undefined;
    return { supplierId, categoryId, dishItemId, name, poundPrice, unitValue, unitSymbol, netPrice, yieldRate };
  }

  async import(): Promise<this> {
    const inputs = chunk(this.data, 25);
    const results = [];
    for (const input of inputs) results.push(this.createGql.fetch({ businessId: this.business.id!, input }));
    const createGqls = await Promise.all(results);
    const result = createGqls.map(gql => {
      if (!gql.documents) throw new Error('invalid documents');
      return gql.documents.map(it => IngredientItemFactory.create(it));
    }).flat();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };