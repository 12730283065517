import { CompanyFieldsQuery } from '../company';
import { CompanyStructureFieldsQuery } from '../company-structure';
import { HeadquartersFieldsQuery } from '../headquarters';
import { FranchisorFieldsQuery } from '../franchisor';
import { FranchiseeFieldsQuery } from '../franchisee';
import { StoreFieldsQuery } from '../store';
import { CentralKitchenFieldsQuery } from '../central-kitchen';
import { OpenaiAccountFieldsQuery } from '../openai-account';

export const CurrentUserFieldsQuery = `
  id
  parentId
  organization {
    ... on Company {
      ${CompanyFieldsQuery}
    }
    ... on CompanyStructure {
      ${CompanyStructureFieldsQuery}
    }
    ... on Headquarters {
      ${HeadquartersFieldsQuery}
    }
    ... on Franchisor {
      ${FranchisorFieldsQuery}
    }
    ... on Franchisee {
      ${FranchiseeFieldsQuery}
    }
    ... on Store {
      ${StoreFieldsQuery}
    }
    ... on CentralKitchen {
      ${CentralKitchenFieldsQuery}
    }
  }
  name
  createdAt
  updatedAt
  openaiAccount {
    ${OpenaiAccountFieldsQuery}
  }
`;

export const ReadCurrentUserQuery = `
  readCurrentUser(id: $id) { 
    ${CurrentUserFieldsQuery}
  }
`;
