import { LexoRank } from 'lexorank';
import dayjs, { Dayjs } from 'dayjs';

import { DishCategory, DishCategoryData } from 'models/entities/dish-category';
import { Ingredient, IngredientCollection, IngredientCollectionData } from 'models/entities/ingredient';
import { IngredientItem } from 'models/entities/ingredient-item';

type Data = {
  id?: string;
  menuCategoryId: string;
  category: DishCategoryData | DishCategory;
  order: string | LexoRank;
  name?: string;
  ingredients?: IngredientCollectionData;
  price?: number;
  ingredientItemId?: string;
  posItemId?: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToBuild = {
  menuCategoryId: string;
  category: DishCategory;
};

type DataToApply = {
  category?: DishCategory;
  order?: LexoRank;
  name?: string;
  ingredients?: IngredientCollection;
  price?: number;
  ingredientItemId?: string;
  posItemId?: string;
};

type DataToIdentify = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToRevise = {
  updatedAt?: string;
};

const MaxDepth = 5;

class DishItem {

  readonly id: string;
  readonly menuCategoryId: string;
  readonly category: DishCategory;
  readonly order: LexoRank;
  readonly name: string;
  readonly ingredients: IngredientCollection;
  readonly price: number;
  readonly cost: number;
  readonly costRate: number;
  readonly ingredientItemId: string;
  readonly posItemId: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly depth: number;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.menuCategoryId = data.menuCategoryId;
    this.category = data.category instanceof DishCategory ? data.category : new DishCategory(data.category);
    this.order = data.order instanceof LexoRank ? data.order : LexoRank.parse(data.order);
    this.name = data.name ?? '';
    this.ingredients = new IngredientCollection(this.id, data.ingredients);
    this.price = data.price ?? 0;
    this.cost = DishItem.calcCost(this.ingredients.documents.map(it => it.cost || 0));
    this.costRate = DishItem.calcCostRate(this.cost, this.price);
    this.ingredientItemId = data.ingredientItemId ?? '';
    this.posItemId = data.posItemId ?? '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.depth = this.getDepth(this.ingredients.documents);
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const cost = DishItem.calcCost(props.ingredients.documents.map(it => it.cost || 0));
    const costRate = DishItem.calcCostRate(cost, props.price);
    const depth = this.getDepth(props.ingredients.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, cost, costRate, depth });
  }

  replace(ingredientItem: IngredientItem): this {
    const ingredients = this.ingredients.replace(ingredientItem);
    return this.apply({ ingredients });
  }

  identify(data: DataToIdentify): this {
    const id = data.id;
    const createdAt = dayjs(data.createdAt);
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, id, createdAt, updatedAt });
  }

  revise(data: DataToRevise = {}): this {
    const updatedAt = dayjs(data.updatedAt);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, updatedAt });
  }

  toJSON(): Data {
    const { id, menuCategoryId, name, price, ingredientItemId, posItemId } = this;
    const category = this.category.toJSON();
    const order = this.order.toString();
    const ingredients = this.ingredients.toJSON();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, menuCategoryId, category, order, name, ingredients, price, ingredientItemId, posItemId, createdAt, updatedAt };
  }

  private getDepth(ingredients: Ingredient[]): number {
    return ingredients.length ? Math.max(...ingredients.map(it => it.item.depth)) : 0;
  }

  static calcCost(costs: number[]): number {
    return costs.reduce((a, b) => a + b, 0)
  }

  static calcCostRate(cost: number, price: number): number {
    return cost / price;
  }

  static sort(items: DishItem[]) {
    function orderByOrder(a: DishItem, b: DishItem) {
      if (a.order.toString() < b.order.toString()) return -1;
      else if (a.order.toString() > b.order.toString()) return 1;
      else return 0;
    }
    return items.sort(orderByOrder);
  }

  static buildNext(list: DishItem[], data: DataToBuild): DishItem {
    const order = list[list.length - 1]?.order.genNext() ?? LexoRank.middle();
    return new DishItem({ ...data, order });
  }

}

export { DishItem, MaxDepth };
export type {
  Data as DishItemData,
  DataToIdentify as DishItemDataToIdentify,
  DataToRevise as DishItemDataToRevise,
};