import { Api } from 'models/services/api';
import { UpdateIngredientItemQuery } from 'models/services/graphql';

import { DataToUpdate, DataToRevise } from '../ingredient-item/types';

type DataToFetch = {
  id: string;
  input: DataToUpdate;
};

type DataToFetchForTransaction = DataToFetch & {
  businessId?: string;
  deleted?: boolean;
};

const RequestQuery = `
  mutation UpdateIngredientItem($id: ID!, $input: UpdateIngredientItemInput!) {
    ${UpdateIngredientItemQuery}
  }
`;

type ResponseData = {
  data: {
    updateIngredientItem: DataToRevise
  }
}

class UpdateGql {

  readonly api: Api<ResponseData>;
  readonly document?: DataToRevise;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToFetch): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.updateIngredientItem;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { UpdateGql };
export type { DataToFetch as UpdateGqlDataToFetch, DataToFetchForTransaction as UpdateGqlDataToFetchForTransaction }
