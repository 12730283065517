import { StringArrayField } from 'models/value-objects/editor-field';

type Data = {
  field: StringArrayField;
  alt?: string;
};

class Model {

  readonly field: StringArrayField;
  readonly value: string;
  readonly alt?: string;
  readonly isAlt: boolean;

  constructor(data: Data) {
    this.field = data.field;
    this.value = this.field.value;
    this.alt = data.alt;
    this.isAlt = this.getIsAlt(this.field);
  }

  change(value: string): this {
    const field = this.field.change(this.getAlt(value));
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  select(index: number): this {
    const field = this.field.change(index);
    const value = field.value;
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  renew(field: StringArrayField): this {
    const value = field.value;
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  private getIsAlt(field: StringArrayField): boolean {
    return field.string === this.alt;
  }

  private getAlt(value: string): string {
    if (!value) return this.alt ?? '';
    return value;
  }

}

export { Model };
