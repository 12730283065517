import { Supplier } from 'models/entities/supplier';
import { IngredientCategory } from 'models/entities/ingredient-category';

import { PurchasedIngredientItem, PurchasedIngredientItemData } from '../index';
import { PoundBasedIngredientItem } from '../pound-based';
import { calcGrossPrice, calcUnitPrice } from '../../formula';

type Data = PurchasedIngredientItemData & {
  unitValue?: number;
  unitSymbol?: string;
  netPrice?: number;
};

type DataToApply = {
  supplier?: Supplier;
  category?: IngredientCategory;
  name?: string;
  unitValue?: number;
  unitSymbol?: string;
  netPrice?: number;
  yieldRate?: number;
};

class UnitBasedIngredientItem extends PurchasedIngredientItem {

  readonly unitValue: number;
  readonly unitSymbol: string;
  readonly netPrice?: number;
  readonly grossPrice?: number;
  readonly unitPrice?: number;

  constructor(data: Data) {
    super(data);
    this.unitValue = data.unitValue ?? 1;
    this.unitSymbol = data.unitSymbol ?? '';
    this.netPrice = data.netPrice;
    this.grossPrice = calcGrossPrice(this.netPrice, this.yieldRate);
    this.unitPrice = calcUnitPrice(this.grossPrice, this.unitValue);
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const grossPrice = calcGrossPrice(props.netPrice, props.yieldRate);
    const unitPrice = calcUnitPrice(grossPrice, props.unitValue);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, grossPrice, unitPrice });
  }

  toJSON(): Data {
    const data = super.toJSON();
    const { unitValue, unitSymbol, netPrice } = this;
    return { ...data, unitValue, unitSymbol, netPrice };
  }

  toPoundBased(): PoundBasedIngredientItem {
    const { id, businessId, supplier, category, name, yieldRate, createdAt, updatedAt } = this;
    return new PoundBasedIngredientItem({ id, businessId, supplier, category, name, yieldRate, createdAt, updatedAt });
  }

}

export { UnitBasedIngredientItem };
export type { Data as UnitBasedIngredientItemData };