export const OpenaiAccountFieldsQuery = `
  id
  apiKey
  organizationId
  createdAt
  updatedAt
`;

export const ReadOpenaiAccountQuery = `
  readOpenaiAccount {
    ${OpenaiAccountFieldsQuery}
  }
`;

export const CreateOpenaiAccountQuery = `
  createOpenaiAccount(input: $input) { 
    ${OpenaiAccountFieldsQuery}
  }
`;

export const UpdateOpenaiAccountQuery = `
  updateOpenaiAccount(id: $id, input: $input) { 
    ${OpenaiAccountFieldsQuery}
  }
`;

export const DeleteOpenaiAccountQuery = `
  deleteOpenaiAccount(id: $id) { 
    ${OpenaiAccountFieldsQuery}
  }
`;
