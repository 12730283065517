import dayjs, { Dayjs } from 'dayjs';

import { Store, StoreData } from 'models/entities/store';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  parentId?: string;
  ownerId?: string;
  centralKitchenId: string;
  store?: StoreData | Store;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  store?: Store;
  name?: string;
};

type DataToSave = {
  centralKitchenId: string;
  storeId: string;
  name: string;
};

class SatelliteKitchen {

  readonly id: string;
  readonly parentId?: string;
  readonly ownerId?: string;
  readonly centralKitchenId: string;
  readonly store?: Store;
  readonly name: string;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.parentId = data.parentId ?? '';
    this.ownerId = data.ownerId ?? '';
    this.centralKitchenId = data.centralKitchenId;
    this.store = data.store ? data.store instanceof Store ? data.store : new Store(data.store) : undefined;
    this.name = data.name ?? '';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.store) return false;
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<SatelliteKitchen> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<SatelliteKitchen> {
    const gql = await new CreateGql().fetch({ centralKitchenId: this.centralKitchenId, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new SatelliteKitchen(gql.document);
  }

  private async update(): Promise<SatelliteKitchen> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new SatelliteKitchen(gql.document);
  }

  private getDataToSave(): DataToSave {
    const { centralKitchenId, store, name } = this;
    return { centralKitchenId, storeId: store!.id, name };
  }

  async delete(): Promise<SatelliteKitchen> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new DeleteGql().fetch({ id: this.id });
    if (!gql.document) throw new Error('invalid document');
    return new SatelliteKitchen(gql.document);
  }

  toJSON(): Data {
    const { id, centralKitchenId, name } = this;
    const store = this.store?.toJSON();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, centralKitchenId, store, name, createdAt, updatedAt };
  }

  static sort(businesses: SatelliteKitchen[]) {
    function orderByOrder(a: SatelliteKitchen, b: SatelliteKitchen) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { SatelliteKitchen };
export type {
  Data as SatelliteKitchenData,
  DataToSave as SatelliteKitchenDataToSave,
};

export * from './collection';