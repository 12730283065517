import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightPrimaryColor, darkPrimaryColor, darkBaseColor, darkTextColor, lightTextColor, primaryTextColor, lightRedColor, redTextColor, redLineColor } from 'views/services/color-scheme';

export const Root = styled.div`
  position: relative;
  font-family: ${baseFontFamily};
  font-size: 1em;
  line-height: 1;
  color: ${textColor};
  display: flex;
  align-items: center;
  & > input {
    order: 2;
    width: 100%;
    height: 1.75em;
    padding: 0 8px;
    border-bottom: 2px solid ${lightPrimaryColor};
    background-color: ${darkBaseColor};
    &::placeholder {
      color: ${darkTextColor};
    }
    &::selection {
      background-color: ${darkPrimaryColor};
      color: ${primaryTextColor};
    }
    &.alt {
      color: ${darkTextColor};
    }
    &:hover, &:focus {
      border-color: ${darkPrimaryColor};
      & ~ span, & ~ button {
        border-color: ${darkPrimaryColor};
      }
    }
    &:read-only {
      border-color: transparent;
      cursor: inherit;
      &::selection {
        background-color: ${darkBaseColor};
        color: ${textColor};
      }
      & ~ span, & ~ button {
        border-color: transparent;
      }
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
      & ~ span {
        opacity: 0.5;
      }
    }
    & ~ span {
      height: 1.75em;
      border-bottom: 2px solid ${lightPrimaryColor};
      background-color: ${darkBaseColor};
      white-space: nowrap;
      display: flex;
      align-items: center;
      &.prefix {
        order: 1;
        padding-left: 8px;
      }
      &.suffix {
        order: 3;
        padding-right: 8px;
      }
    }
    & ~ button {
      height: 1.75em;
      border-bottom: 2px solid ${lightPrimaryColor};
      background-color: ${darkBaseColor};
      color: ${lightTextColor};
      white-space: nowrap;
      display: flex;
      box-sizing: content-box;
      cursor: pointer;
      align-items: center;
      order: 4;
      padding-right: 8px;
      &:hover {
        color: ${textColor};
      }
    }
  }
  & > p {
    z-index: 1;
    position: absolute;
    height: fit-content;
    left: -16px;
    bottom: calc(100% + 4px);
    padding: 4px 16px;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 0.8);
    line-height: 1.5;
    color: ${redTextColor};
    background-color: ${lightRedColor};
    border-left: 1px solid ${redLineColor};
    border-bottom: 1px solid ${redLineColor};
    white-space: pre;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 22px;
      background-color: ${redLineColor};
      transform-origin: left bottom;
      rotate: 135deg;
    }
  }
`;
