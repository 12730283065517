exports.components = {
  "component---src-views-interfaces-404-index-tsx": () => import("./../../../src/views/interfaces/404/index.tsx" /* webpackChunkName: "component---src-views-interfaces-404-index-tsx" */),
  "component---src-views-interfaces-brands-index-tsx": () => import("./../../../src/views/interfaces/brands/index.tsx" /* webpackChunkName: "component---src-views-interfaces-brands-index-tsx" */),
  "component---src-views-interfaces-business-categories-index-tsx": () => import("./../../../src/views/interfaces/business-categories/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-categories-index-tsx" */),
  "component---src-views-interfaces-business-dish-items-index-tsx": () => import("./../../../src/views/interfaces/business/dish-items/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-dish-items-index-tsx" */),
  "component---src-views-interfaces-business-index-tsx": () => import("./../../../src/views/interfaces/business/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-index-tsx" */),
  "component---src-views-interfaces-business-ingredient-items-index-tsx": () => import("./../../../src/views/interfaces/business/ingredient-items/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-ingredient-items-index-tsx" */),
  "component---src-views-interfaces-business-preparation-index-tsx": () => import("./../../../src/views/interfaces/business/preparation/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-preparation-index-tsx" */),
  "component---src-views-interfaces-business-preparation-v-1-index-tsx": () => import("./../../../src/views/interfaces/business/preparation-v1/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-preparation-v-1-index-tsx" */),
  "component---src-views-interfaces-business-purchasing-index-tsx": () => import("./../../../src/views/interfaces/business/purchasing/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-purchasing-index-tsx" */),
  "component---src-views-interfaces-business-suppliers-index-tsx": () => import("./../../../src/views/interfaces/business/suppliers/index.tsx" /* webpackChunkName: "component---src-views-interfaces-business-suppliers-index-tsx" */),
  "component---src-views-interfaces-businesses-index-tsx": () => import("./../../../src/views/interfaces/businesses/index.tsx" /* webpackChunkName: "component---src-views-interfaces-businesses-index-tsx" */),
  "component---src-views-interfaces-central-kitchens-index-tsx": () => import("./../../../src/views/interfaces/central-kitchens/index.tsx" /* webpackChunkName: "component---src-views-interfaces-central-kitchens-index-tsx" */),
  "component---src-views-interfaces-companies-index-tsx": () => import("./../../../src/views/interfaces/companies/index.tsx" /* webpackChunkName: "component---src-views-interfaces-companies-index-tsx" */),
  "component---src-views-interfaces-home-index-tsx": () => import("./../../../src/views/interfaces/home/index.tsx" /* webpackChunkName: "component---src-views-interfaces-home-index-tsx" */),
  "component---src-views-interfaces-original-dish-categories-index-tsx": () => import("./../../../src/views/interfaces/original-dish-categories/index.tsx" /* webpackChunkName: "component---src-views-interfaces-original-dish-categories-index-tsx" */),
  "component---src-views-interfaces-original-ingredient-categories-index-tsx": () => import("./../../../src/views/interfaces/original-ingredient-categories/index.tsx" /* webpackChunkName: "component---src-views-interfaces-original-ingredient-categories-index-tsx" */),
  "component---src-views-interfaces-original-menu-categories-index-tsx": () => import("./../../../src/views/interfaces/original-menu-categories/index.tsx" /* webpackChunkName: "component---src-views-interfaces-original-menu-categories-index-tsx" */),
  "component---src-views-interfaces-pos-systems-index-tsx": () => import("./../../../src/views/interfaces/pos-systems/index.tsx" /* webpackChunkName: "component---src-views-interfaces-pos-systems-index-tsx" */)
}

