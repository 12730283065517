import { IngredientItemCollection, IngredientItem } from 'models/entities/ingredient-item';
import { DishItemEditor } from 'models/entities/dish-item';
import { IngredientEditor } from 'models/entities/ingredient';
import { StringField } from 'models/value-objects/editor-field';

type Data = {
  collection: IngredientItemCollection;
  dishItem: DishItemEditor;
};

type DataToApply = {
  dishItem?: DishItemEditor;
};

type DataToSearch = {
  name?: StringField;
};

type DataToFilter = {
  ids?: string[];
  name?: StringField;
};

type DataToCheck = {
  ids: string[];
  name: StringField;
};

class IngredientItemList {

  readonly collection: IngredientItemCollection;
  readonly dishItem: DishItemEditor;

  readonly ids: string[];
  readonly name: StringField;

  readonly result: IngredientItem[];

  constructor(data: Data) {
    this.collection = data.collection;
    this.dishItem = data.dishItem;
    this.ids = this.getIds(this.dishItem);
    this.name = new StringField();
    this.result = this.filter();
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const ids = this.getIds(props.dishItem);
    const result = this.filter({ ids });
    return Object.assign(Object.create(this.constructor.prototype), { ...props, ids, result });
  }

  search(data: DataToSearch): this {
    const result = this.filter(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data, result });
  }

  private filter(data: DataToFilter = {}): IngredientItem[] {
    const { ids, name } = { ...this, ...data };
    return this.collection.documents.filter(it => this.check(it, { ids, name }));
  }

  private check(item: IngredientItem, data: DataToCheck): boolean {
    if (data.ids.includes(item.id)) return false;
    if (data.name.string && !new RegExp(data.name.string, 'i').test(item.name)) return false;
    return true;
  }

  private getIds(dishItem: DishItemEditor): string[] {
    const id = dishItem.original.ingredientItemId;
    const ids = dishItem.ingredients.editors.map(it => it.original.item.id);
    return id ? [...ids, id] : ids;
  }

}

export { IngredientItemList };