import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const CentralKitchensMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/central-kitchens" name="Central Kitchens" {...props} />
  );
};

export { CentralKitchensMenu };
