import React, { FC, useState, useRef, useEffect } from 'react';

import { OpenaiChatEditor } from 'models/entities/openai';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { CloseButton } from 'views/components/close-button';
import { ChatInput } from 'views/components/chat-input';
import { UserIcon } from 'views/components/icons';
import { OpenaiIcon } from 'views/components/openai-icon';
import { Root, Header, Conversation, Message, Role, Content, Footer } from './index.styled';

interface Props {
  target: OpenaiChatEditor;
  ready: boolean;
}

const CreateChatCompletion: FC<Props> = ({ target, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ editor: target }));
  const conversation = useRef<HTMLDivElement>(null);

  function onSend() {
    setModel(model.commit());
  }

  function onClose() {
    modal.close({ state: 'chatted', target: model.editor });
  }

  function scrollDown() {
    if (!conversation.current) return;
    conversation.current!.scroll({top: conversation.current.scrollHeight, left: 0, behavior: 'smooth'});
  }

  async function renderAssistant() {
    for await (const it of model.send()) {
      setModel(it);
    }
  }

  function watchTurn() {
    scrollDown();
    if (model.editor.original.turn === 'assistant') renderAssistant().catch(e => { throw new Error(e) });
  }

  function watchCompletion() {
    if (!model.editor.completion) return;
    scrollDown();
  }

  useEffect(watchTurn, [model.editor.original.turn]);
  useEffect(watchCompletion, [model.editor.completion]);

  return (
    <Root>
      <Header>
        <h1>ChatGPT</h1>
        <nav>
          <CloseButton onClose={onClose} />
        </nav>
      </Header>
      <Conversation ref={conversation}>
        <ol>
        {model.editor.original.conversation.map((it, i) => (
          <li key={i}>
            <Message>
              <Role>
                {it.role.id === 'user' ? <UserIcon /> : <OpenaiIcon />}
              </Role>
              <Content>{it.content}</Content>
            </Message>
          </li>
        ))}
        {model.editor.completion && (
          <li>
            <Message>
              <Role>
                <OpenaiIcon />
              </Role>
              <Content>{model.editor.completion.choices[0].delta.content}</Content>
            </Message>
          </li>
        )}
        </ol>
      </Conversation>
      <Footer>
        <ChatInput field={model.editor.content} onEdit={content => setModel(model.edit({ content }))} onSend={onSend} focused={ready} />
      </Footer>
    </Root>
  );

};

export { CreateChatCompletion };
