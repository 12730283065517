import React, { FC, useRef, useEffect, PropsWithChildren, ChangeEvent } from 'react';

import { Root } from './index.styled';

interface Props extends PropsWithChildren {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inFocus?: boolean;
  placeholder?: string;
}

const TextInput: FC<Props> = ({ value, onChange, children, inFocus, placeholder }) => {

  const input = useRef<HTMLInputElement>(null);

  function watchInFocus() {
    if (inFocus) input.current?.focus();
  }

  useEffect(watchInFocus, [inFocus]);

  return (
    <Root>
      <label>
        {children}
        <div>
          <input type="text" value={value} onChange={onChange} ref={input} placeholder={placeholder} />
        </div>
      </label>
    </Root>
  );

};

export { TextInput };