import { BooleanField } from 'models/value-objects/editor-field';

type Data = {
  field: BooleanField;
};

class Model {

  readonly field: BooleanField;
  readonly value: boolean;

  constructor(data: Data) {
    this.field = data.field;
    this.value = this.field.boolean;
  }

  change(value: boolean): this {
    const field = this.field.change(value);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value });
  }

  renew(field: BooleanField): this {
    const value = field.boolean;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value });
  }

}

export { Model };
