import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { textColor, lightPrimaryColor, darkPrimaryColor, lightTextColor, darkBaseColor, darkTextColor } from 'views/services/color-scheme';

const h2FontSize = baseFontSize;
const h2Margin = '8px';

export const Root = styled.div`
  display: block;
  & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
    font-family: ${baseFontFamily};
    font-size: calc(${h2FontSize} * 1);
    color: ${lightTextColor};
    margin-bottom: ${h2Margin};
    &.disabled {
      color: ${darkTextColor};
    }
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ToggleSwitch = styled.button`
  cursor: pointer;
  width: 64px;
  height: 40px;
  border: 2px solid ${lightPrimaryColor};
  border-radius: 20px;
  box-sizing: border-box;
  &:focus, &:hover {
    border-color: ${darkPrimaryColor};
  }
  transition: background-color 0.3s ease-in-out;
  &.off {
    background-color: ${darkBaseColor};
  }
  &.on {
    background-color: ${darkPrimaryColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${textColor};
    transition: transform 0.3s ease-in-out;
    &.off {
      transform: translateX(-12px);
    }
    &.on {
      transform: translateX(12px);
    }
  }
`;
