import { DishItem, DishItemData } from 'models/entities/dish-item';
import { IngredientCategory } from 'models/entities/ingredient-category';

import { IngredientItem, IngredientItemData } from '../ingredient-item';
import { calcGrossPrice, calcUnitPrice } from '../formula';

type Data = IngredientItemData & {
  dishItem: DishItemData | DishItem;
  unitValue?: number;
  unitSymbol?: string;
};

type DataToApply = {
  dishItem?: DishItem;
  category?: IngredientCategory;
  name?: string;
  unitValue?: number;
  unitSymbol?: string;
  yieldRate?: number;
};

class InHouseIngredientItem extends IngredientItem {

  readonly dishItem: DishItem;
  readonly name: string;
  readonly unitValue: number;
  readonly unitSymbol: string;
  readonly netPrice: number;
  readonly grossPrice?: number;
  readonly unitPrice?: number;
  readonly depth: number;

  constructor(data: Data) {
    super(data);
    this.dishItem = data.dishItem instanceof DishItem ? data.dishItem : new DishItem(data.dishItem);
    this.name = this.dishItem.name;
    this.unitValue = data.unitValue ?? 1;
    this.unitSymbol = data.unitSymbol ?? '';
    this.netPrice = this.dishItem.cost;
    this.grossPrice = calcGrossPrice(this.netPrice, this.yieldRate);
    this.unitPrice = calcUnitPrice(this.grossPrice, this.unitValue);
    this.depth = this.getDepth(this.dishItem);
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const netPrice = props.dishItem.cost;
    const grossPrice = calcGrossPrice(netPrice, props.yieldRate);
    const unitPrice = calcUnitPrice(grossPrice, props.unitValue);
    const depth = this.getDepth(props.dishItem);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, netPrice, grossPrice, unitPrice, depth });
  }

  toJSON(): Data {
    const data = super.toJSON();
    const { unitValue, unitSymbol } = this;
    const dishItem = this.dishItem.toJSON();
    return { ...data, dishItem, unitValue, unitSymbol };
  }

  private getDepth(dishItem: DishItem): number {
    return dishItem.depth + 1;
  }

}

export { InHouseIngredientItem };
export type { Data as InHouseIngredientItemData }
