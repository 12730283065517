import styled from 'styled-components';

export const Root = styled.div`
  display: block;
  text-align: center;
  font-size: 1em;
  line-height: 1em;
  width: 1em;
  height: 1em;
  color: inherit;
`;