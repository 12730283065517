import React, { FC } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Root } from './index.styled';

interface Props {
  icon: IconDefinition;
  className?: string;
}

const AbstractIcon: FC<Props> = ({ icon, className }) => {
  return (
    <Root className={className}>
      <FontAwesomeIcon icon={icon} />
    </Root>
  );
};

export { AbstractIcon };