export const UserFieldsQuery = `
  id
  parentId
  organizationId
  name
  email
  createdAt
  updatedAt
`;

export const UserCollectionQuery = `
  documents {
    ${UserFieldsQuery}
  }
  nextToken
`;

export const ReadUsersQuery = `
  readUsers(organizationId: $organizationId, nextToken: $nextToken) { 
    ${UserCollectionQuery}
  }
`;

export const UpdateUserQuery = `
  updateUser(id: $id, input: $input) { 
    ${UserFieldsQuery}
  }
`;

export * from './transaction';