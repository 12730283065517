type Data = {
  email: string;
  password: string;
};

class Credentials {

  readonly email: string;
  readonly password: string;

  constructor(data: Data) {
    this.email = data.email;
    this.password = data.password;
  }

}

export { Credentials };