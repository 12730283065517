import { Api } from 'models/services/api';
import { UpdateCentralKitchenQuery } from 'models/services/graphql';

import { CentralKitchenData, CentralKitchenDataToSave } from '../index';

type DataToUpdate = {
  id: string;
  input: CentralKitchenDataToSave;
};

const RequestQuery = `
  mutation UpdateCentralKitchen($id: ID!, $input: CentralKitchenInput!) {
    ${UpdateCentralKitchenQuery}
  }
`;

type ResponseData = {
  data: {
    updateCentralKitchen: CentralKitchenData
  }
}

class UpdateGql {

  readonly api: Api<ResponseData>;
  readonly document?: CentralKitchenData;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToUpdate): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const document = api.response.data.updateCentralKitchen;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, api, document });
  }

}

export { UpdateGql };