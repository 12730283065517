import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lineColor, darkPrimaryColor } from 'views/services/color-scheme';

export const Root = styled.div`
  border: 2px solid ${darkPrimaryColor};
`;

export const Footer = styled.footer`
  border-top: 1px solid ${lineColor};
  background-color: ${baseColor};
  height: 40px;
  font-size: calc(${baseFontSize} * 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
