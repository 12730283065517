import { DependingQuery } from './depending-query';

import { IngredientAnalysisCollectionFieldsQuery } from './ingredient-analysis-collection-fields-query';
import { IngredientAnalysisFieldsQuery } from './ingredient-analysis-fields-query';
import { DishItemAnalysisFieldsQuery } from './dish-item-analysis-fields-query';

import { IngredientCategoryFieldsQuery } from '../ingredient-category';

class IngredientItemAnalysisFieldsQuery extends DependingQuery {

  toString() {
    return `
      id
      dishItemId
      category {
        ${IngredientCategoryFieldsQuery}
      }
      name
      poundPrice
      unitValue
      unitSymbol
      netPrice
      yieldRate
      ingredients {
        ${this.query}
      }
    `;
  }

  static create() {
    const ingredientItemAnalysisFieldsQuery = new IngredientItemAnalysisFieldsQuery();
    const ingredientAnalysisCollectionFieldsQuery = new IngredientAnalysisCollectionFieldsQuery();
    const ingredientAnalysisFieldsQuery = new IngredientAnalysisFieldsQuery();
    const dishItemAnalysisFieldsQuery = new DishItemAnalysisFieldsQuery();
    ingredientItemAnalysisFieldsQuery.to(ingredientAnalysisCollectionFieldsQuery);
    ingredientAnalysisCollectionFieldsQuery.to(ingredientAnalysisFieldsQuery);
    ingredientAnalysisFieldsQuery.to(dishItemAnalysisFieldsQuery);
    dishItemAnalysisFieldsQuery.to(ingredientItemAnalysisFieldsQuery);
    return ingredientItemAnalysisFieldsQuery;
  }

}

export { IngredientItemAnalysisFieldsQuery };
