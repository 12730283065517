import { DishItemEditor, DishItemEditorDataToApply } from 'models/entities/dish-item';
import { IngredientEditor } from 'models/entities/ingredient';
import { IngredientItem, IngredientItemCollection, InHouseIngredientItem, IngredientItemFactory, InHouseIngredientItemEditor } from 'models/entities/ingredient-item';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';
import { Currency } from 'models/value-objects/currency';
import { StringField, NumberField } from 'models/value-objects/editor-field';

import { EditDishItemTarget } from '../../../models/entities/target';
import { ResultData, ResultState } from '../../../models/entities/result';

import { IngredientItemList } from './models/ingredient-item-list';
import { ReadGql } from './models/read-gql';

type Mode = 'initial' | 'editing' | 'done';

type Data = {
  target: EditDishItemTarget;
  ingredientItems: IngredientItemCollection;
  ingredientCategories: IngredientCategoryCollection;
};

type DataToApply = {
  dishItem?: DishItemEditor;
};

type DataToValidate = {
  dishItem?: DishItemEditor;
};

type DataToSearch = {
  name?: StringField;
};

class Model {

  readonly target: EditDishItemTarget;
  readonly ingredientItemList: IngredientItemList;
  readonly ingredientCategories: IngredientCategoryCollection;
  readonly unitSymbols: string[];
  readonly mode: Mode;

  readonly dishItem: DishItemEditor;
  readonly currency: Currency;

  readonly ok: boolean;

  constructor(data: Data) {
    this.target = data.target;
    this.ingredientCategories = data.ingredientCategories;
    this.unitSymbols = data.ingredientItems.getUnitSymbols();
    this.mode = 'initial';
    this.dishItem = this.target.dishItem;
    this.currency = this.target.currency;
    this.ok = this.validate(this);
    this.ingredientItemList = new IngredientItemList({ collection: data.ingredientItems, dishItem: this.dishItem });
  }

  toggle(mode: Mode): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, mode });
  }

  async readIngredientItem(): Promise<this> {
    if (!this.dishItem.original.ingredientItemId) throw new Error('invalid ingredientItemId');
    const gql = await new ReadGql().fetch({ id: this.dishItem.original.ingredientItemId, itemId: this.dishItem.original.ingredientItemId, limit: 1 });
    if (!gql.result) throw new Error('invalid result');
    const original = await IngredientItemFactory.create(gql.result.ingredientItem);
    if (!(original instanceof InHouseIngredientItem)) throw new Error('invalid ingredientItem');
    const ingredientItem = new InHouseIngredientItemEditor({ original, categories: this.ingredientCategories, unitSymbols: this.unitSymbols });
    const dishItem = gql.result.relationshipCollection.documents.length ? this.dishItem.connect(ingredientItem) : this.dishItem.connect(ingredientItem).init({ canDelete: true });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishItem });
  }

  edit(data: DishItemEditorDataToApply): this {
    const dishItem = this.dishItem.apply(data);
    return this.apply({ dishItem });
  }

  editUsage(usage: NumberField, ingredient: IngredientEditor): this {
    const dishItem = this.dishItem.applyIngredient(ingredient.apply({ usage }));
    return this.apply({ dishItem });
  }

  moveIngredient(ingredient: IngredientEditor, index: number): this {
    const dishItem = this.dishItem.moveIngredient(ingredient, index);
    return this.apply({ dishItem });
  }

  removeIngredient(ingredient: IngredientEditor): this {
    const dishItem = this.dishItem.removeIngredient(ingredient);
    return this.apply({ dishItem });
  }

  addIngredientItem(item: IngredientItem): this {
    const dishItem = this.dishItem.addIngredientItem(item);
    return this.apply({ dishItem });
  }

  insertIngredientItem(item: IngredientItem, index: number): this {
    const dishItem = this.dishItem.insertIngredientItem(item, index);
    return this.apply({ dishItem });
  }

  apply(data: DataToApply): this {
    const { dishItem } = { ...this, ...data };
    const ok = this.validate({ dishItem });
    const ingredientItemList = this.ingredientItemList.apply({ dishItem });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishItem, ok, ingredientItemList });
  }

  private validate(data: DataToValidate): boolean {
    const props = { ...this, ...data };
    if (!props.dishItem.ok) return false;
    return true;
  }

  async save(): Promise<this> {
    const dishItem = await this.dishItem.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishItem });
  }

  async delete(): Promise<this> {
    const dishItem = await this.dishItem.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishItem });
  }

  conclude(resultState?: ResultState): ResultData {
    const state = resultState || this.getResultState();
    const dishItem = this.dishItem;
    const target = this.target.apply({ dishItem });
    return { state, target };
  }

  private getResultState(): ResultState {
    if (!this.target.dishItem.original.id) return 'created';
    switch (true) {
      case !this.target.dishItem.original.ingredientItemId && !this.dishItem.original.ingredientItemId: return 'updated';
      case !this.target.dishItem.original.ingredientItemId && !!this.dishItem.original.ingredientItemId: return 'updated-created';
      case !!this.target.dishItem.original.ingredientItemId && !!this.dishItem.original.ingredientItemId: return 'updated-updated';
      case !!this.target.dishItem.original.ingredientItemId && !this.dishItem.original.ingredientItemId: return 'updated-deleted';
    }
    throw new Error('invalid ResultState');
  }

  search(data: DataToSearch = {}): this {
    const ingredientItemList = this.ingredientItemList.search(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItemList });
  }

}

export { Model };