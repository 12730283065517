import { Headquarters } from 'models/entities/headquarters';
import { Franchisor } from 'models/entities/franchisor';
import { Franchisee } from 'models/entities/franchisee';
import { Store } from 'models/entities/store';

import { Brand, BrandData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: BrandData[];
  nextToken: string;
};

class BrandCollection {

  readonly documents: Brand[];
  readonly nextToken: string;

  constructor(data: Data = { documents: [], nextToken: '' }) {
    this.documents = data.documents.map(it => new Brand(it));
    this.nextToken = data.nextToken;
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const gql = await new ReadCollectionGql().fetch({ nextToken: collection.nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new BrandCollection(gql.result));
    }
    return collection;
  }

  merge(collection: BrandCollection): this {
    const documents = Brand.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken });
  }

  add(brand: Brand): this {
    const documents = Brand.sort([...this.documents, brand]);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  replace(brand: Brand): this {
    const documents = Brand.sort(this.documents.map(it => it.id === brand.id ? brand : it));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  remove(brand: Brand): this {
    const documents = this.documents.filter(it => it.id !== brand.id);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents });
  }

  applyHeadquarters(headquarters: Headquarters): this {
    const brand = this.documents.find(it => it.id === headquarters.brandIdentity.id);
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.applyHeadquarters(headquarters));
  }

  removeHeadquarters(headquarters: Headquarters): this {
    const brand = this.documents.find(it => it.id === headquarters.brandIdentity.id);
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.removeHeadquarters(headquarters));
  }

  applyFranchisor(franchisor: Franchisor): this {
    const brand = this.documents.find(it => it.has(franchisor.organizationId));
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.applyFranchisor(franchisor));
  }

  removeFranchisor(franchisor: Franchisor): this {
    const brand = this.documents.find(it => it.has(franchisor.organizationId));
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.removeFranchisor(franchisor));
  }

  applyFranchisee(franchisee: Franchisee): this {
    const brand = this.documents.find(it => it.has(franchisee.organizationId));
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.applyFranchisee(franchisee));
  }

  removeFranchisee(franchisee: Franchisee): this {
    const brand = this.documents.find(it => it.has(franchisee.organizationId));
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.removeFranchisee(franchisee));
  }

  applyStore(store: Store): this {
    const brand = this.documents.find(it => it.has(store.organizationId));
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.applyStore(store));
  }

  removeStore(store: Store): this {
    const brand = this.documents.find(it => it.has(store.organizationId));
    if (!brand) throw new Error('brand is undefined');
    return this.replace(brand.removeStore(store));
  }

  getStores(): Store[] {
    return this.documents.map(it => it.getStores()).flat();
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  static async read(): Promise<BrandCollection> {
    const gql = await new ReadCollectionGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    return new BrandCollection(gql.result);
  }

}

export { BrandCollection };
export type { Data as BrandCollectionData };