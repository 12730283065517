import React, { FC, useState } from 'react';

import { SatelliteKitchen } from 'models/entities/satellite-kitchen';
import { Store } from 'models/entities/store';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { TextInput } from 'views/components/text-input';
import { StoreInput } from './views/store-input';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { Root, Footer, MoreActions } from './index.styled';

interface Props {
  target: SatelliteKitchen;
  stores: Store[];
  ready: boolean;
}

const EditSatelliteKitchen: FC<Props> = ({ target, stores, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, stores }));

  function save() {
    model.save().then(model => modal.close({ state: model.isNew ? 'created' : 'updated', target: model.result }));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (model.isNew) return;
    model.delete().then(model => modal.close({ state: 'deleted', target: model.result }));
  }

  return (
    <Root>
      <h1>Satellite Kitchen</h1>
      <fieldset>
        <StoreInput model={model} onUpdate={m => setModel(m)} inFocus={ready} />
        <TextInput value={model.name} onChange={e => setModel(model.change({ name: e.target.value }))}>
          <h2>Name</h2>
        </TextInput>
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.result}>Save</PrimaryButton>
      </Footer>
      {!model.isNew && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditSatelliteKitchen };
