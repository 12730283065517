import { times } from 'lodash';
import { Dayjs } from 'dayjs';

import { Day } from './day';

type Data = {
  first: Dayjs;
};

class Week {

  readonly first: Dayjs;
  readonly days: Day[];
  readonly start?: Day;
  readonly end?: Day;

  constructor(data: Data) {
    this.first = data.first;
    this.days = times(7, offset => new Day({ object: this.first.startOf('week').add(offset, 'day') }));
  }

  select(start: Day, end?: Day): this {
    if (this.canIgnore(start, end)) return this;
    const days = this.days.map(it => it.select(start, end));
    return Object.assign(Object.create(this.constructor.prototype), { ...this, days, start, end });
  }

  private canIgnore(start: Day, end?: Day): boolean {
    switch (true) {
      case !end && !this.start && start.object.isAfter(this.first, 'week'): return true;
      case !end && this.start && [this.start, start].every(it => it.object.isAfter(this.first, 'week')): return true;
      case end && !this.end && end.object.isBefore(this.first, 'week'): return true;
      case end && this.end && [this.end, end].every(it => it.object.isBefore(this.first, 'week')): return true;
      default: return false;
    }
  }

}

export { Week };