import React, { FC, PropsWithChildren, useState, useLayoutEffect, MouseEvent } from "react"

import { OpenaiAccount, OpenaiChatEditor } from 'models/entities/openai';

import { ModalName } from './models/services/modal-name';
import { Company, Brand, Headquarters, Franchisor, Franchisee, Store, CentralKitchen, SatelliteKitchen, BusinessCategory, PosSystem, Business, OriginalIngredientCategory, OriginalMenuCategory, OriginalDishCategory, MenuCategory, Supplier, IngredientItem, PosReport, DishItemReport, EditUserTarget, EditDishItemTarget, ImportDishItemsTarget, ExportDishItemsTarget, ExportReportTarget, ShowUsersTarget } from './models/services/target-object';
import { CompanyCollection, BusinessCategoryCollection, OriginalIngredientCategoryCollection, OriginalMenuCategoryCollection, IngredientCategoryCollection, DishCategoryCollection, PosSystemCollection, IngredientItemCollection, DishCategory } from './models/services/other-object';
import { OpenOptions } from './models/services/modal-interface';
import { EditPurchasedIngredientItemTarget, EditInHouseIngredientItemTarget } from './models/entities/target';
import { ResultData } from './models/entities/result';
import { Model } from './index.model';

import { ModalContext } from './views/services/modal-context';

import { SignIn } from './views/interfaces/sign-in';
import { EditPassword } from './views/interfaces/edit-password';
import { EditOpenaiAccount } from './views/interfaces/edit-openai-account';
import { EditCompany } from './views/interfaces/edit-company';
import { EditBrand } from './views/interfaces/edit-brand';
import { EditHeadquarters } from './views/interfaces/edit-headquarters';
import { EditFranchisor } from './views/interfaces/edit-franchisor';
import { EditFranchisee } from './views/interfaces/edit-franchisee';
import { EditStore } from './views/interfaces/edit-store';
import { EditCentralKitchen } from './views/interfaces/edit-central-kitchen';
import { EditSatelliteKitchen } from './views/interfaces/edit-satellite-kitchen';
import { EditUser } from './views/interfaces/edit-user';
import { EditBusinessCategory } from './views/interfaces/edit-business-category';
import { EditPosSystem } from './views/interfaces/edit-pos-system';
import { EditOriginalIngredientCategory } from './views/interfaces/edit-original-ingredient-category';
import { EditOriginalMenuCategory } from './views/interfaces/edit-original-menu-category';
import { EditOriginalDishCategory } from './views/interfaces/edit-original-dish-category';
import { EditBusiness } from './views/interfaces/edit-business';
import { EditIngredientCategories } from './views/interfaces/edit-ingredient-categories';
import { EditMenuCategories } from './views/interfaces/edit-menu-categories';
import { EditDishCategories } from './views/interfaces/edit-dish-categories';
import { EditSupplier } from './views/interfaces/edit-supplier';
import { EditPurchasedIngredientItem } from './views/interfaces/edit-purchased-ingredient-item';
import { EditInHouseIngredientItem } from './views/interfaces/edit-in-house-ingredient-item';
import { EditDishItem } from './views/interfaces/edit-dish-item';
import { ExportSuppliers } from './views/interfaces/export-suppliers';
import { ImportIngredientItems } from './views/interfaces/import-ingredient-items';
import { ExportIngredientItems } from './views/interfaces/export-ingredient-items';
import { ExportIngredientCategories } from './views/interfaces/export-ingredient-categories';
import { ImportDishItems } from './views/interfaces/import-dish-items';
import { ExportDishItems } from './views/interfaces/export-dish-items';
import { ExportDishCategories } from './views/interfaces/export-dish-categories';
import { ImportReport } from './views/interfaces/import-report';
import { ExportReport } from './views/interfaces/export-report';
import { ShowUsers } from './views/interfaces/show-users';
import { ShowDishItemReport } from './views/interfaces/show-dish-item-report';
import { CreateChatCompletion } from './views/interfaces/create-chat-completion';

import { Foreground } from './views/components/foreground';
import { Main, Background } from './index.styled';

const Modal: FC<PropsWithChildren> = ({ children }) => {

  const [model, setModel] = useState(new Model());

  function open(name: ModalName, options: OpenOptions = {}) {
    setModel(model.open(name, options));
  }

  function close(result?: ResultData) {
    setModel(model.close(result));
  }

  function watchBackgroundClick(e: MouseEvent<HTMLDivElement>) {
    if (e.currentTarget !== e.target) return;
    closeWithBackgroundClick();
  }

  function closeWithBackgroundClick() {
    model.canClose() && close();
  }

  function setWindowScroll() {
    window.scrollTo(0, model.scroll);
  }

  function restoreWindowScroll() {
    if (model.state === 'closed') setWindowScroll();
  }

  useLayoutEffect(restoreWindowScroll, [model.state]);

  return (
    <ModalContext.Provider value={{ open, close }}>
      <Main className={model.state} scroll={model.scroll}>
        {children}
      </Main>
      <Background className={model.state} onClick={e => watchBackgroundClick(e)}>
        {model.layers.map((it, i) => (
          <Foreground key={i} layer={it} onOpen={() => setModel(model.ready())} onClose={() => setModel(model.unmount())} onEscape={close} onClickBackground={closeWithBackgroundClick}>
            {(() => {
              switch (it.name) {
                case 'sign-in': return <SignIn />;
                case 'edit-password': return <EditPassword ready={it.ready} />;
                case 'edit-openai-account': return <EditOpenaiAccount target={it.target as OpenaiAccount} ready={it.ready} />;
                case 'edit-company': return <EditCompany target={it.target as Company} ready={it.ready} />;
                case 'edit-brand': return <EditBrand target={it.target as Brand} ready={it.ready} />;
                case 'edit-headquarters': return <EditHeadquarters target={it.target as Headquarters} companies={it.other!.companies as CompanyCollection} ready={it.ready} />;
                case 'edit-franchisor': return <EditFranchisor target={it.target as Franchisor} companies={it.other!.companies as CompanyCollection} ready={it.ready} />;
                case 'edit-franchisee': return <EditFranchisee target={it.target as Franchisee} companies={it.other!.companies as CompanyCollection} ready={it.ready} />;
                case 'edit-store': return <EditStore target={it.target as Store} ready={it.ready} />;
                case 'edit-central-kitchen': return <EditCentralKitchen target={it.target as CentralKitchen} companies={it.other!.companies as CompanyCollection} ready={it.ready} />;
                case 'edit-satellite-kitchen': return <EditSatelliteKitchen target={it.target as SatelliteKitchen} stores={it.other!.stores as Store[]} ready={it.ready} />;
                case 'edit-user': return <EditUser target={it.target as EditUserTarget} ready={it.ready} />;
                case 'edit-business-category': return <EditBusinessCategory target={it.target as BusinessCategory} ready={it.ready} />;
                case 'edit-pos-system': return <EditPosSystem target={it.target as PosSystem} ready={it.ready} />;
                case 'edit-business': return <EditBusiness target={it.target as Business} categories={it.other!.categories as BusinessCategoryCollection} posSystems={it.other!.posSystems as PosSystemCollection} ready={it.ready} />;
                case 'edit-original-ingredient-category': return <EditOriginalIngredientCategory target={it.target as OriginalIngredientCategory} ready={it.ready} />;
                case 'edit-original-menu-category': return <EditOriginalMenuCategory target={it.target as OriginalMenuCategory} ready={it.ready} />;
                case 'edit-original-dish-category': return <EditOriginalDishCategory target={it.target as OriginalDishCategory} menuCategory={it.other!.menuCategory as OriginalMenuCategory} ready={it.ready} />;
                case 'edit-ingredient-categories': return <EditIngredientCategories target={it.target as Business} originalCategories={it.other!.categories as OriginalIngredientCategoryCollection} />;
                case 'edit-menu-categories': return <EditMenuCategories target={it.target as Business} originalCategories={it.other!.categories as OriginalMenuCategoryCollection} />;
                case 'edit-dish-categories': return <EditDishCategories target={it.target as Business} />;
                case 'edit-supplier': return <EditSupplier target={it.target as Supplier} ready={it.ready} />;
                case 'edit-purchased-ingredient-item': return <EditPurchasedIngredientItem target={it.target as EditPurchasedIngredientItemTarget} ready={it.ready} />;
                case 'edit-in-house-ingredient-item': return <EditInHouseIngredientItem target={it.target as EditInHouseIngredientItemTarget} ready={it.ready} background={!it.foreground} finalize={it.finalize} />;
                case 'edit-dish-item': return <EditDishItem target={it.target as EditDishItemTarget} ingredientItems={it.other!.ingredientItems as IngredientItemCollection} business={it.other!.business as Business} ingredientCategories={it.other!.ingredientCategories as IngredientCategoryCollection} ready={it.ready} background={!it.foreground} />;
                case 'export-suppliers': return <ExportSuppliers target={it.target as Supplier[]} />;
                case 'import-ingredient-items': return <ImportIngredientItems target={it.target as IngredientItem[]} business={it.other!.business as Business} ready={it.ready} />;
                case 'export-ingredient-items': return <ExportIngredientItems target={it.target as IngredientItem[]} />;
                case 'export-ingredient-categories': return <ExportIngredientCategories target={it.target as IngredientCategoryCollection} />;
                case 'import-dish-items': return <ImportDishItems target={it.target as ImportDishItemsTarget} menuCategory={it.other!.menuCategory as MenuCategory} ready={it.ready} />;
                case 'export-dish-items': return <ExportDishItems target={it.target as ExportDishItemsTarget} />;
                case 'export-dish-categories': return <ExportDishCategories target={it.target as DishCategoryCollection} />;
                case 'import-report': return <ImportReport target={it.target as PosReport} ready={it.ready} />;
                case 'export-report': return <ExportReport target={it.target as ExportReportTarget} />;
                case 'show-users': return <ShowUsers target={it.target as ShowUsersTarget} background={!it.foreground} />;
                case 'show-dish-item-report': return <ShowDishItemReport target={it.target as DishItemReport} business={it.other!.business as Business} menuCategory={it.other!.menuCategory as MenuCategory} category={it.other!.category as DishCategory} />;
                case 'create-chat-completion': return <CreateChatCompletion target={it.target as OpenaiChatEditor} ready={it.ready} />;
              }
            })()}
          </Foreground>
        ))}
      </Background>
    </ModalContext.Provider>
  );

};

export { Modal };
export * from './views/services/use-modal';
export * from './models/entities/target';
export { Result as ModalResult } from './models/entities/result';
