import { IngredientItemEditor } from 'models/entities/ingredient-item';
import { Currency } from 'models/value-objects/currency';

type Data = {
  ingredientItem: IngredientItemEditor;
  currency: Currency;
};

type DataToApply = {
  ingredientItem: IngredientItemEditor;
};

class EditPurchasedIngredientItemTarget {

  readonly ingredientItem: IngredientItemEditor;
  readonly currency: Currency;

  constructor(data: Data) {
    this.ingredientItem = data.ingredientItem;
    this.currency = data.currency;
  }

  apply(data: DataToApply): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

}

export { EditPurchasedIngredientItemTarget };

