import dayjs, { Dayjs } from 'dayjs';

import { Organization } from 'models/entities/organization';
import { BrandIdentity, BrandIdentityData } from 'models/entities/brand-identity';
import { Company, CompanyData } from 'models/entities/company';
import { FranchisorCollection, FranchisorCollectionData, Franchisor } from 'models/entities/franchisor';
import { Franchisee } from 'models/entities/franchisee';
import { StoreCollection, StoreCollectionData, Store } from 'models/entities/store';

import { CreateGql, UpdateGql, DeleteGql } from './gql';

type Data = {
  id?: string;
  type?: string;
  parentId?: string;
  ownerId?: string;
  brandIdentity: BrandIdentityData | BrandIdentity;
  company?: CompanyData | Company;
  name?: string;
  franchisors?: FranchisorCollectionData;
  stores?: StoreCollectionData;
  userCount?: number;
  createdAt?: string;
  updatedAt?: string;
};

type DataToEdit = {
  company?: Company;
  name?: string;
};

type DataToSave = {
  type: string;
  brandId: string;
  companyId: string;
  name: string;
};

class Headquarters implements Organization {

  readonly id: string;
  readonly type: string;
  readonly parentId?: string;
  readonly ownerId?: string;
  readonly brandIdentity: BrandIdentity;
  readonly company?: Company;
  readonly name: string;
  readonly franchisors: FranchisorCollection;
  readonly stores: StoreCollection;
  readonly userCount: number;
  readonly createdAt?: Dayjs;
  readonly updatedAt?: Dayjs;
  readonly organizationIds: Set<string>;

  constructor(data: Data) {
    this.id = data.id ?? '';
    this.type = 'Headquarters';
    this.parentId = data.parentId ?? '';
    this.ownerId = data.ownerId ?? '';
    this.brandIdentity = data.brandIdentity instanceof BrandIdentity ? data.brandIdentity : new BrandIdentity(data.brandIdentity);
    this.company = data.company ? data.company instanceof Company ? data.company : new Company(data.company) : undefined;
    this.name = data.name ?? '';
    this.franchisors = new FranchisorCollection(this.id, data.franchisors);
    this.stores = new StoreCollection(this.id, data.stores);
    this.userCount = data.userCount ?? 0;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt) : undefined;
    this.organizationIds = this.getOrganizationIds();
  }

  edit(data: DataToEdit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  validate(): boolean {
    if (!this.company) return false;
    if (!this.name) return false;
    return true;
  }

  async save(): Promise<Headquarters> {
    if (!this.validate()) throw new Error('invalid cache object');
    return this.id ? this.update() : this.create();
  }

  private async create(): Promise<Headquarters> {
    const gql = await new CreateGql().fetch({ brandId: this.brandIdentity.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Headquarters(gql.document);
  }

  private async update(): Promise<Headquarters> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new UpdateGql().fetch({ id: this.id, input: this.getDataToSave() });
    if (!gql.document) throw new Error('invalid document');
    return new Headquarters(gql.document);
  }

  private getDataToSave(): DataToSave {
    const { type, brandIdentity, company, name } = this;
    return { type, brandId: brandIdentity.id, companyId: company!.id!, name };
  }

  async delete(): Promise<Headquarters> {
    if (!this.id) throw new Error('invalid relationship');
    const gql = await new DeleteGql().fetch({ id: this.id });
    if (!gql.document) throw new Error('invalid document');
    return new Headquarters(gql.document);
  }

  is(organizationId: string): boolean {
    return this.id === organizationId;
  }

  has(organizationId: string): boolean {
    return this.organizationIds.has(organizationId);
  }

  applyFranchisor(franchisor: Franchisor): this {
    if (!this.is(franchisor.organizationId)) return this;
    const franchisors = this.franchisors.apply(franchisor);
    const organizationIds = this.getOrganizationIds({ franchisors });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisors, organizationIds });
  }

  removeFranchisor(franchisor: Franchisor): this {
    if (!this.is(franchisor.organizationId)) return this;
    const franchisors = this.franchisors.remove(franchisor);
    const organizationIds = this.getOrganizationIds({ franchisors });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisors, organizationIds });
  }

  applyFranchisee(franchisee: Franchisee): this {
    const franchisors = this.franchisors.applyFranchisee(franchisee);
    const organizationIds = this.getOrganizationIds({ franchisors });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisors, organizationIds });
  }

  removeFranchisee(franchisee: Franchisee): this {
    const franchisors = this.franchisors.removeFranchisee(franchisee);
    const organizationIds = this.getOrganizationIds({ franchisors });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisors, organizationIds });
  }

  applyStore(store: Store): this {
    if (!this.has(store.organizationId)) return this;
    const franchisors = !this.is(store.organizationId) ? this.franchisors.applyStore(store) : this.franchisors;
    const stores = this.is(store.organizationId) ? this.stores.apply(store) : this.stores;
    const organizationIds = this.getOrganizationIds({ franchisors, stores });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisors, stores, organizationIds });
  }

  removeStore(store: Store): this {
    if (!this.has(store.organizationId)) return this;
    const franchisors = !this.is(store.organizationId) ? this.franchisors.removeStore(store) : this.franchisors;
    const stores = this.is(store.organizationId) ? this.stores.remove(store) : this.stores;
    const organizationIds = this.getOrganizationIds({ franchisors, stores });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, franchisors, stores, organizationIds });
  }

  getStores(): Store[] {
    return [...this.stores.documents, ...this.franchisors.getStores()];
  }

  setUserCount(userCount: number): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, userCount });
  }

  incrementUserCount(): this {
    return this.setUserCount(this.userCount + 1);
  }

  toJSON(): Data {
    const { id, name } = this;
    const brandIdentity = this.brandIdentity.toJSON();
    const company = this.company?.toJSON();
    const createdAt = this.createdAt?.toJSON();
    const updatedAt = this.updatedAt?.toJSON();
    return { id, brandIdentity, company, name, createdAt, updatedAt };
  }

  private getOrganizationIds(data: { franchisors?: FranchisorCollection, stores?: StoreCollection } = {}): Set<string> {
    const { franchisors, stores } = { ...this, ...data };
    const a = new Set(this.id ? [this.id] : []);
    const b = franchisors.organizationIds;
    const c = stores.organizationIds;
    return new Set([...a, ...b, ...c]);
  }

  static sort(businesses: Headquarters[]) {
    function orderByOrder(a: Headquarters, b: Headquarters) {
      if (a.name.toString() < b.name.toString()) return -1;
      else if (a.name.toString() > b.name.toString()) return 1;
      else return 0;
    }
    return businesses.sort(orderByOrder);
  }

}

export { Headquarters };
export type {
  Data as HeadquartersData,
  DataToSave as HeadquartersDataToSave,
};

export * from './collection';
export * from './company-collection';