export const CompanyFieldsQuery = `
  id
  type
  name
  userCount
  createdAt
  updatedAt
`;

export const CompanyCollectionQuery = `
  documents {
    ${CompanyFieldsQuery}
  }
  nextToken
`;

export const ReadCompaniesQuery = `
  readCompanies(nextToken: $nextToken) { 
    ${CompanyCollectionQuery}
  }
`;

export const CreateCompanyQuery = `
  createCompany(input: $input) { 
    ${CompanyFieldsQuery}
  }
`;

export const UpdateCompanyQuery = `
  updateCompany(id: $id, input: $input) { 
    ${CompanyFieldsQuery}
  }
`;

export const DeleteCompanyQuery = `
  deleteCompany(id: $id) { 
    ${CompanyFieldsQuery}
  }
`;
