import { Franchisee } from 'models/entities/franchisee';
import { Store } from 'models/entities/store';

import { Franchisor, FranchisorData } from './index';
import { ReadCollectionGql } from './gql';

type Data = {
  documents: FranchisorData[];
  nextToken: string;
};

class FranchisorCollection {

  readonly organizationId: string;
  readonly documents: Franchisor[];
  readonly nextToken: string;
  readonly organizationIds: Set<string>;

  constructor(organizationId: string = '', data: Data = { documents: [], nextToken: '' }) {
    this.organizationId = organizationId;
    this.documents = data.documents.map(it => new Franchisor(it));
    this.nextToken = data.nextToken;
    this.organizationIds = this.getOrganizationIds();
  }

  async readAll(): Promise<this> {
    let collection = this;
    while (collection.nextToken) {
      const { organizationId, nextToken } = collection;
      if (!organizationId) continue;
      const gql = await new ReadCollectionGql().fetch({ organizationId, nextToken });
      if (!gql.result) throw new Error('invalid result');
      collection = collection.merge(new FranchisorCollection(organizationId, gql.result));
    }
    return collection;
  }

  merge(collection: FranchisorCollection): this {
    const documents = Franchisor.sort([...this.documents, ...collection.documents]);
    const nextToken = collection.nextToken;
    const organizationIds = this.getOrganizationIds({ documents });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, nextToken, organizationIds });
  }

  apply(franchisor: Franchisor): this {
    return this.documents.some(it => it.id === franchisor.id) ? this.replace(franchisor) : this.add(franchisor);
  }

  add(franchisor: Franchisor): this {
    const documents = Franchisor.sort([...this.documents, franchisor]);
    const organizationIds = this.getOrganizationIds({ documents });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, organizationIds });
  }

  replace(franchisor: Franchisor): this {
    const documents = Franchisor.sort(this.documents.map(it => it.id === franchisor.id ? franchisor : it));
    const organizationIds = this.getOrganizationIds({ documents });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, organizationIds });
  }

  remove(franchisor: Franchisor): this {
    const documents = this.documents.filter(it => it.id !== franchisor.id);
    const organizationIds = this.getOrganizationIds({ documents });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, documents, organizationIds });
  }

  applyFranchisee(franchisee: Franchisee): this {
    const franchisor = this.documents.find(it => it.has(franchisee.organizationId));
    return franchisor ? this.replace(franchisor.applyFranchisee(franchisee)) : this;
  }

  removeFranchisee(franchisee: Franchisee): this {
    const franchisor = this.documents.find(it => it.has(franchisee.organizationId));
    return franchisor ? this.replace(franchisor.removeFranchisee(franchisee)) : this;
  }

  applyStore(store: Store): this {
    const franchisor = this.documents.find(it => it.has(store.organizationId));
    return franchisor ? this.replace(franchisor.applyStore(store)) : this;
  }

  removeStore(store: Store): this {
    const franchisor = this.documents.find(it => it.has(store.organizationId));
    return franchisor ? this.replace(franchisor.removeStore(store)) : this;
  }

  getStores(): Store[] {
    return this.documents.map(it => it.getStores()).flat();
  }

  toJSON(): Data {
    const { nextToken } = this;
    const documents = this.documents.map(it => it.toJSON());
    return { documents, nextToken };
  }

  private getOrganizationIds(data: { documents?: Franchisor[] } = {}): Set<string> {
    const documents = data.documents || this.documents;
    return new Set(documents.map(it => [...it.organizationIds]).flat());
  }

  static async read(organizationId: string): Promise<FranchisorCollection> {
    const gql = await new ReadCollectionGql().fetch({ organizationId });
    if (!gql.result) throw new Error('invalid result');
    return new FranchisorCollection(organizationId, gql.result);
  }

}

export { FranchisorCollection };
export type { Data as FranchisorCollectionData };