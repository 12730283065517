import { OriginalIngredientCategoryFieldsQuery } from '../original-ingredient-category';

export const IngredientCategoryFieldsQuery = `
  id
  businessId
  original {
    ${OriginalIngredientCategoryFieldsQuery}
  }
  order
  createdAt
  updatedAt
`;

export const IngredientCategoryCollectionQuery = `
  documents {
    ${IngredientCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadIngredientCategoriesQuery = `
  readIngredientCategories(businessId: $businessId, nextToken: $nextToken) {
    ${IngredientCategoryCollectionQuery}
  }
`;

export const CreateIngredientCategoryQuery = `
  createIngredientCategory(businessId: $businessId, input: $input) { 
    ${IngredientCategoryFieldsQuery}
  }
`;

export const UpdateIngredientCategoryQuery = `
  updateIngredientCategory(id: $id, input: $input) { 
    ${IngredientCategoryFieldsQuery}
  }
`;

export const DeleteIngredientCategoryQuery = `
  deleteIngredientCategory(id: $id) { 
    ${IngredientCategoryFieldsQuery}
  }
`;

