type ID = 'system' | 'user' | 'assistant';

type Data = {
  id?: ID;
};

type DataToSend = ID;

class Role {

  readonly id: ID;

  constructor(data: Data = {}) {
    this.id = data.id ?? 'system';
  }

}

export { Role };
export type {
  ID as RoleId,
  Data as RoleData,
  DataToSend as RoleDataToSend,
};