import { Supplier } from 'models/entities/supplier';
import { RelationshipCollection } from 'models/entities/relationship';

type Data = {
  target: Supplier;
}

type DataToChange = {
  name?: string;
  address?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  fax?: string;
  picName?: string;
  picPhone?: string;
  picEmail?: string;
  notes?: string;
};

class Model {

  readonly target: Supplier;
  readonly isNew: boolean;
  readonly canDelete: boolean;

  readonly name: string;
  readonly address: string;
  readonly city: string;
  readonly province: string;
  readonly country: string;
  readonly postalCode: string;
  readonly phone: string;
  readonly fax: string;
  readonly picName: string;
  readonly picPhone: string;
  readonly picEmail: string;
  readonly notes: string;

  readonly result?: Supplier;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.canDelete = false;
    this.name = this.target.name;
    this.address = this.target.address;
    this.city = this.target.city;
    this.province = this.target.province;
    this.country = this.target.country;
    this.postalCode = this.target.postalCode;
    this.phone = this.target.phone;
    this.fax = this.target.fax;
    this.picName = this.target.picName;
    this.picPhone = this.target.picPhone;
    this.picEmail = this.target.picEmail;
    this.notes = this.target.notes;
  }

  async readRelationships(supplierId: string): Promise<this> {
    const relationships = await RelationshipCollection.readBySupplierId(supplierId, 1);
    const canDelete = !relationships.documents.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, canDelete });
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.name) return false;
    return true;
  }

  private build(data: DataToChange): Supplier {
    const name = data.name;
    const address = data.address;
    const city = data.city;
    const province = data.province;
    const country = data.country;
    const postalCode = data.postalCode;
    const phone = data.phone;
    const fax = data.fax;
    const picName = data.picName;
    const picPhone = data.picPhone;
    const picEmail = data.picEmail;
    const notes = data.notes;
    return this.target.edit({ name, address, city, province, country, postalCode, phone, fax, picName, picPhone, picEmail, notes });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

}

export { Model };