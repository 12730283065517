import React, { FC, useState, useEffect } from 'react';

import { Business } from 'models/entities/business';
import { BusinessCategoryCollection } from 'models/entities/business-category';
import { PosSystemCollection } from 'models/entities/pos-system';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { TextInput } from 'views/components/text-input';
import { CategoryInput } from './views/category-input';
import { PosSystemInput } from './views/pos-system-input';
import { CurrencyInput } from './views/currency-input';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { Root, Footer, MoreActions } from './index.styled';

interface Props {
  target: Business;
  categories: BusinessCategoryCollection;
  posSystems: PosSystemCollection;
  ready: boolean;
}

const EditBusiness: FC<Props> = ({ target, categories, posSystems, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target, categories, posSystems }));

  function save() {
    model.save().then(model => modal.close({ state: model.isNew ? 'created' : 'updated', target: model.result }));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (model.isNew) return;
    model.delete().then(model => modal.close({ state: 'deleted', target: model.result }));
  }

  function watchTarget() {
    if (!model.target?.id) return;
    model.readRelationships(model.target.id).then(m => setModel(m));
  }

  useEffect(watchTarget, [model.target]);

  return (
    <Root>
      <h1>Business</h1>
      <fieldset>
        <CategoryInput model={model} onUpdate={m => setModel(m)} inFocus={ready} />
        <TextInput value={model.name} onChange={e => setModel(model.change({ name: e.target.value }))}>
          <h2>Name</h2>
        </TextInput>
        <TextInput value={model.location} onChange={e => setModel(model.change({ location: e.target.value }))}>
          <h2>Location</h2>
        </TextInput>
        <TextInput value={model.code} onChange={e => setModel(model.change({ code: e.target.value }))}>
          <h2>Code</h2>
        </TextInput>
        <CurrencyInput model={model} onUpdate={m => setModel(m)} />
        <PosSystemInput model={model} onUpdate={m => setModel(m)} />
      </fieldset>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.result}>Save</PrimaryButton>
      </Footer>
      {!model.isNew && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditBusiness };
