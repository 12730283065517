import { NumberField } from 'models/value-objects/editor-field';

type Data = {
  field: NumberField;
  alt?: number;
};

class Model {

  readonly field: NumberField;
  readonly value: string;
  readonly alt?: number;
  readonly isAlt: boolean;

  constructor(data: Data) {
    this.field = data.field;
    this.value = this.field.value;
    this.alt = data.alt;
    this.isAlt = this.getIsAlt(this.field);
  }

  change(value: string): this {
    const field = this.field.change(this.getAlt(value));
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  renew(field: NumberField): this {
    const value = field.value;
    const isAlt = this.getIsAlt(field);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, value, isAlt });
  }

  private getIsAlt(field: NumberField): boolean {
    return field.value === this.alt?.toString();
  }

  private getAlt(value: string): string {
    if (!value) return this.alt?.toString() ?? '';
    return value;
  }

}

export { Model };
