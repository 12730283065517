import React, { FC } from 'react';

import { AbstractMenuItem, Props } from '../abstract-menu-item';

const OriginalDishCategoriesMenu: FC<Props> = props => {
  return (
    <AbstractMenuItem page="/original-dish-categories" name="Original Dish Categories" actions={false} {...props} />
  );
};

export { OriginalDishCategoriesMenu };
