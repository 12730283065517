import { DependingQuery } from './depending-query';

import { IngredientItemFieldsQuery } from './ingredient-item-fields-query';
import { DishItemFieldsQuery } from './dish-item-fields-query';
import { IngredientCollectionQuery } from './ingredient-collection-query';

class IngredientFieldsQuery extends DependingQuery {

  toString() {
    return `
      id
      dishItemId
      item {
        ${this.query}
      }
      order
      usage
      createdAt
      updatedAt
    `;
  }

  static create() {
    const ingredientFieldsQuery = new IngredientFieldsQuery();
    const ingredientItemFieldsQuery = new IngredientItemFieldsQuery();
    const dishItemFieldsQuery = new DishItemFieldsQuery();
    const ingredientCollectionQuery = new IngredientCollectionQuery();
    ingredientFieldsQuery.to(ingredientItemFieldsQuery);
    ingredientItemFieldsQuery.to(dishItemFieldsQuery);
    dishItemFieldsQuery.to(ingredientCollectionQuery);
    ingredientCollectionQuery.to(ingredientFieldsQuery);
    return ingredientFieldsQuery;
  }

}

export { IngredientFieldsQuery };