import { Api } from 'models/services/api';
import { ReadBusinessesQuery } from 'models/services/graphql';

import { BusinessCollectionData } from '../collection';

type DataToRead = {
  nextToken?: string;
};

const RequestQuery = `
  query ReadBusinesses($nextToken: String) {
    ${ReadBusinessesQuery}
  }
`;

type ResponseData = {
  data: {
    readBusinesses: BusinessCollectionData;
  }
}

class ReadCollectionGql {

  readonly result?: BusinessCollectionData;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToRead = {}): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const result = api.response.data.readBusinesses;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadCollectionGql };
