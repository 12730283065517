import { Currency } from 'models/value-objects/currency';

export function showNumber(n?: number): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return Math.round(n).toLocaleString('en-US');
}

export function showInt(n?: number): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return Math.round(n).toLocaleString('en-US');
}

export function showFloat(n?: number): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return (Math.round(n * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 2 });
}

export function showPricePerPound(n?: number, currency: Currency = new Currency()): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return n > 0 ? `${showMoney(n, currency)} / lb` : '';
}

export function showUnit(n?: number, symbol: string = ''): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  if (n % 1 !== 0) return `${showFloat(n)}${symbol && ` (${symbol})`}`;
  return `${showInt(n)}${symbol && ` (${symbol})`}`;
}

export function showRate(n?: number): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return (Math.round(n * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function showPercentage(n?: number): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return (Math.round(n * 10) / 10).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
}

export function showMoney(n?: number, currency: Currency = new Currency()): string {
  if (n === undefined) return '---';
  if (!isFinite(n)) return '---';
  return currency.format(n);
}
