import { IngredientItem, IngredientItemData, IngredientItemEditor, IngredientItemEditorData } from './ingredient-item';
import { PoundBasedIngredientItem, PoundBasedIngredientItemData, PoundBasedIngredientItemEditor, PoundBasedIngredientItemEditorData } from './purchased/pound-based';
import { UnitBasedIngredientItem, UnitBasedIngredientItemData, UnitBasedIngredientItemEditor, UnitBasedIngredientItemEditorData } from './purchased/unit-based';
import { InHouseIngredientItem, InHouseIngredientItemData, InHouseIngredientItemEditor, InHouseIngredientItemEditorData } from './in-house';
import { ReadGql } from './gql';

class IngredientItemFactory {

  static create(data: IngredientItemData): IngredientItem {
    switch (true) {
      case !!data.dishItem: return new InHouseIngredientItem(data as InHouseIngredientItemData);
      case !!data.poundPrice: return new PoundBasedIngredientItem(data as PoundBasedIngredientItemData);
      default: return new UnitBasedIngredientItem(data as UnitBasedIngredientItemData);
    }
  }

  static createEditor(data: IngredientItemEditorData): IngredientItemEditor {
    switch (true) {
      case data.original instanceof InHouseIngredientItem: return new InHouseIngredientItemEditor(data as InHouseIngredientItemEditorData);
      case data.original instanceof PoundBasedIngredientItem: return new PoundBasedIngredientItemEditor(data as PoundBasedIngredientItemEditorData);
      default: return new UnitBasedIngredientItemEditor(data as UnitBasedIngredientItemEditorData);
    }
  }

  static async readById(id: string): Promise<IngredientItem> {
    const gql = await new ReadGql().fetch({ id });
    if (!gql.result) throw new Error('invalid result');
    return IngredientItemFactory.create(gql.result);

  }

}

export { IngredientItemFactory };