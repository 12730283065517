import { chunk } from 'lodash';

import { DishItem, DishItemEditorDataToCreate } from 'models/entities/dish-item';
import { MenuCategory } from 'models/entities/menu-category';
import { ImportDishItemsTarget } from '../../../models/entities/target';

import { CreateGql } from './models/create-gql';

type Data = {
  target: ImportDishItemsTarget;
  menuCategory: MenuCategory;
}

class Model {

  readonly target: ImportDishItemsTarget;
  readonly menuCategory: MenuCategory;
  readonly tsv: string;
  readonly data: DishItemEditorDataToCreate[];
  readonly valid: boolean;
  readonly dishItems?: DishItem[];

  constructor(data: Data) {
    this.target = data.target;
    this.menuCategory = data.menuCategory;
    this.tsv = ['categoryId', 'order', 'name', 'price', 'posItemId'].join('\t');
    this.data = [];
    this.valid = false;
  }

  input(tsv: string): this {
    const data = tsv.split('\n').slice(1).map(it => this.parse(it));
    const valid = data.every(it => !!it);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, tsv, data, valid });
  }

  private parse(row: string): DishItemEditorDataToCreate | undefined {
    let error = false;
    function logError(message: string) {
      error = true;
      console.error(new Error(message));
    }
    const data = row.split('\t');
    const categoryId = data[0];
    const order = data[1];
    const name = data[2];
    const price = parseFloat(data[3]); if (!isFinite(price)) logError('price must be a number');
    const posItemId = data[4];
    if (error) return undefined;
    return { categoryId, order, name, price, posItemId };
  }

  async import(): Promise<this> {
    const inputs = chunk(this.data, 25);
    const results = [];
    for (const input of inputs) results.push(new CreateGql().fetch({ menuCategoryId: this.menuCategory.id!, input }));
    const gqls = await Promise.all(results);
    const dishItems = gqls.map(gql => this.getDishItems(gql)).flat();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, dishItems });
  }

  conclude(): ImportDishItemsTarget {
    if (!this.dishItems) throw new Error('dishItems is undefined');
    return new ImportDishItemsTarget({ dishItems: this.dishItems });
  }

  private getDishItems(gql: CreateGql): DishItem[] {
    if (!gql.documents) throw new Error('invalid documents');
    return gql.documents.map(it => new DishItem(it));
  }

}

export { Model };