export const PosSystemFieldsQuery = `
  id
  code
  name
  createdAt
  updatedAt
`;

export const PosSystemCollectionQuery = `
  documents {
    ${PosSystemFieldsQuery}
  }
  nextToken
`;

export const ReadPosSystemsQuery = `
  readPosSystems(nextToken: $nextToken) {
    ${PosSystemCollectionQuery}
  }
`;

export const CreatePosSystemQuery = `
  createPosSystem(input: $input) { 
    ${PosSystemFieldsQuery}
  }
`;

export const UpdatePosSystemQuery = `
  updatePosSystem(id: $id, input: $input) { 
    ${PosSystemFieldsQuery}
  }
`;

export const DeletePosSystemQuery = `
  deletePosSystem(id: $id) { 
    ${PosSystemFieldsQuery}
  }
`;
