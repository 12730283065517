import { CreateIngredientsDataToFetch, UpdateIngredientsDataToFetch, DeleteIngredientsDataToFetch } from 'models/entities/ingredient';
import { CreateIngredientItemDataToFetch, UpdateIngredientItemDataToFetchForTransaction, DeleteIngredientItemDataToFetch } from 'models/entities/ingredient-item';

import { DishItemEditor } from '../editor';
import { CreateGqlDataToFetch, UpdateGqlDataToFetch, DeleteGqlDataToFetch } from '../gql';
import { CreateTransactionGql, UpdateTransactionGql, DeleteTransactionGql, GqlResult } from './gql';

type Data = {
  dishItem: DishItemEditor;
};

type Result = Data;

class DishItemTransaction {

  readonly dishItem: DishItemEditor;
  readonly result?: Result;

  constructor(data: Data) {
    this.dishItem = data.dishItem;
  }

  async save(): Promise<this> {
    return !this.dishItem.original.id ? await this.create() : await this.update();
  }

  private async create(): Promise<this> {
    const dishItem = this.getCreateDishItemParam();
    const ingredients = this.getCreateIngredientsParam();
    const ingredientItem = this.getCreateIngredientItemParam();
    const gql = await new CreateTransactionGql().fetch({ dishItem, ingredients, ingredientItem });
    if (!gql.result) throw new Error('invalid result');
    const result = this.identify(gql.result);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private identify(result: GqlResult): Result {
    const ingredients = result.ingredients ? this.dishItem.ingredients.identify(result.ingredients).original : undefined;
    const ingredientItemId = result.ingredientItem?.id;
    const dishItem = this.dishItem.identify(result.dishItem, Object.fromEntries(Object.entries({ ingredients, ingredientItemId }).filter(it => !!it[1])));
    const ingredientItem = result.ingredientItem ? this.dishItem.ingredientItem?.identify(result.ingredientItem, { dishItem: dishItem.original }) : undefined;
    return { dishItem: ingredientItem ? dishItem.connect(ingredientItem) : dishItem };
  }

  private async update(): Promise<this> {
    const dishItem = this.getUpdateDishItemParam();
    const ingredients = this.getUpdateIngredientsParam();
    const ingredientItem = this.getUpdateIngredientItemParam();
    const gql = await new UpdateTransactionGql().fetch({ dishItem, ingredients, ingredientItem });
    if (!gql.result) throw new Error('invalid result');
    const result = this.revise(gql.result);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private revise(result: GqlResult): Result {
    const ingredients = result.ingredients ? this.dishItem.ingredients.revise(result.ingredients).original : undefined;
    const ingredientItemId = result.ingredientItem?.id;
    const dishItem = this.dishItem.revise({}, Object.fromEntries(Object.entries({ ingredients, ingredientItemId }).filter(it => !!it[1])));
    const ingredientItem = result.ingredientItem ? this.dishItem.ingredientItem?.revise(result.ingredientItem, { dishItem: dishItem.original }) : undefined;
    return { dishItem: ingredientItem ? dishItem.connect(ingredientItem) : dishItem };
  }

  async delete(): Promise<this> {
    const dishItem = this.getDeleteDishItemParam();
    const ingredients = this.getDeleteIngredientsParam();
    const ingredientItem = this.getDeleteIngredientItemParam();
    const gql = await new DeleteTransactionGql().fetch({ dishItem, ingredients, ingredientItem });
    if (!gql.result) throw new Error('invalid result');
    const result = { dishItem: this.dishItem };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private getCreateDishItemParam(): CreateGqlDataToFetch {
    return {
      menuCategoryId: this.dishItem.original.menuCategoryId,
      input: this.dishItem.getDataToCreate(),
    };
  }

  private getUpdateDishItemParam(): UpdateGqlDataToFetch {
    return {
      id: this.dishItem.original.id!,
      input: this.dishItem.getDataToUpdate(),
    };
  }

  private getDeleteDishItemParam(): DeleteGqlDataToFetch {
    return {
      id: this.dishItem.original.id!,
    };
  }

  private getCreateIngredientsParam(): CreateIngredientsDataToFetch {
    return this.dishItem.ingredients.editors.map(it => ({
      input: it.getDataToCreate(),
    }));
  }

  private getUpdateIngredientsParam(): UpdateIngredientsDataToFetch {
    const editors = this.dishItem.ingredients.editors.filter(it => it.dirty).map(it => ({
      id: it.original.id,
      input: it.original.id ? it.getDataToUpdate() : it.getDataToCreate(),
    }));
    const deleted = this.dishItem.ingredients.deleted.map(it => ({
      id: it.original.id,
      input: {},
      deleted: true,
    }));
    return [...editors, ...deleted];
  }

  private getDeleteIngredientsParam(): DeleteIngredientsDataToFetch {
    return this.dishItem.ingredients.editors.map(it => ({
      id: it.original.id,
    }));
  }

  private getCreateIngredientItemParam(): CreateIngredientItemDataToFetch | undefined {
    if (!this.dishItem.ingredientItem) return undefined;
    return {
      businessId: this.dishItem.ingredientItem.original.businessId,
      input: this.dishItem.ingredientItem.getDataToCreate(),
    };
  }

  private getUpdateIngredientItemParam(): UpdateIngredientItemDataToFetchForTransaction | undefined {
    if (!this.dishItem.ingredientItem) return undefined;
    return {
      id: this.dishItem.ingredientItem.original.id,
      input: this.dishItem.ingredientItem.original.id ? this.dishItem.ingredientItem.getDataToUpdate() : this.dishItem.ingredientItem.getDataToCreate(),
      businessId: this.dishItem.ingredientItem.original.businessId,
      deleted: this.dishItem.ingredientItem.deleted,
    };
  }

  private getDeleteIngredientItemParam(): DeleteIngredientItemDataToFetch | undefined {
    if (!this.dishItem.original.ingredientItemId) return undefined;
    return {
      id: this.dishItem.original.ingredientItemId,
    };
  }

}

export { DishItemTransaction };
