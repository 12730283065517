import React, { useRef, MouseEvent } from 'react';

import { OpenaiAccount } from 'models/entities/openai';

import { useAuth } from 'views/services/auth';
import { useDropdown } from 'views/services/dropdown';
import { useModal, ModalResult } from 'views/services/modal';
import { UserIcon } from 'views/components/icons';
import { Root } from './index.styled';

const UserMenu = () => {

  const auth = useAuth();
  const dropdown = useDropdown();
  const modal = useModal();
  const button = useRef<HTMLButtonElement>(null);

  function onManageOpenai() {
    dropdown.close();
    const target = auth.user.openaiAccount ?? new OpenaiAccount();
    const tell = apply;
    modal.open('edit-openai-account', { target, strict: true, tell });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof OpenaiAccount)) return;
    const openaiAccount = result.target as OpenaiAccount;
    switch (result.state) {
      case 'created':
      case 'updated': auth.apply(auth.user.apply({ openaiAccount })); break;
      case 'deleted': auth.apply(auth.user.apply({ openaiAccount: undefined })); break;
    }
  }

  function onPassword() {
    dropdown.close();
    modal.open('edit-password', { strict: true });
  }

  function onSignOut() {
    auth.signOut();
  }

  function onClick(e: MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    const button = target.getBoundingClientRect();
    const x = button.left + button.width;
    const y = button.top + button.height;
    const props = { onManageOpenai, onPassword, onSignOut };
    dropdown.toggle('user-menu-actions', { target, point: { x, y }, props });
  }

  return (
    <Root>
      <button ref={button} onClick={onClick}>
        <UserIcon />
      </button>
    </Root>
  );

};

export { UserMenu };