import { SupplierCollection, SupplierArrayField } from 'models/entities/supplier';
import { IngredientCategoryCollection, IngredientCategoryArrayField } from 'models/entities/ingredient-category';
import { StringField, NumberField } from 'models/value-objects/editor-field';

import { IngredientItemEditor, IngredientItemDataToIdentify, IngredientItemDataToRevise } from '../../ingredient-item';
import { PoundBasedIngredientItem } from './pound-based';
import { UnitBasedIngredientItem, UnitBasedIngredientItemEditor } from '../unit-based';
import { calcNetPrice, calcGrossPrice } from '../../formula';
import { CreateGql, UpdateGql, DeleteGql } from '../../gql';

type Original = PoundBasedIngredientItem | UnitBasedIngredientItem;

type Data = {
  original: Original;
  suppliers: SupplierCollection;
  categories: IngredientCategoryCollection;
  unitSymbols: string[];
};

type DataToInit = {
  canDelete?: boolean;
};

type DataToApply = {
  supplier?: SupplierArrayField;
  category?: IngredientCategoryArrayField;
  name?: StringField;
  poundPrice?: NumberField;
  yieldRate?: NumberField;
};

type DataToValidate = {
  supplier?: SupplierArrayField;
  category?: IngredientCategoryArrayField;
  name?: StringField;
  poundPrice?: NumberField;
  yieldRate?: NumberField;
};

type DataToCreate = {
  supplierId: string;
  categoryId: string;
  name: string;
  poundPrice: number;
  yieldRate: number;
}

type DataToUpdate = {
  supplierId?: string;
  categoryId?: string;
  name?: string;
  poundPrice?: number;
  unitValue?: null;
  unitSymbol?: null;
  netPrice?: null;
  yieldRate?: number;
}

class PoundBasedIngredientItemEditor extends IngredientItemEditor {

  readonly original: Original;
  readonly suppliers: SupplierCollection;
  readonly categories: IngredientCategoryCollection;
  readonly unitSymbols: string[];
  readonly supplier: SupplierArrayField;
  readonly category: IngredientCategoryArrayField;
  readonly name: StringField;
  readonly poundPrice: NumberField;
  readonly unitValue: number;
  readonly unitSymbol: string;
  readonly netPrice?: number;
  readonly yieldRate: NumberField;
  readonly grossPrice?: number;
  readonly canDelete: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;

  constructor(data: Data) {
    super(data);
    this.original = data.original;
    this.suppliers = data.suppliers;
    this.categories = data.categories;
    this.unitSymbols = data.unitSymbols;
    this.supplier = new SupplierArrayField({ list: this.suppliers.documents, object: this.original.supplier });
    this.category = new IngredientCategoryArrayField({ list: this.categories.documents, object: this.original.category });
    this.name = new StringField({ string: this.original.name });
    this.poundPrice = new NumberField({ number: this.original.poundPrice });
    this.unitValue = 1000;
    this.unitSymbol = 'g';
    this.netPrice = calcNetPrice(this.poundPrice.number);
    this.yieldRate = new NumberField({ number: this.original.yieldRate });
    this.grossPrice = calcGrossPrice(this.netPrice, this.yieldRate.number);
    this.canDelete = false;
    this.dirty = false;
    this.ok = this.validate();
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const netPrice = calcNetPrice(props.poundPrice.number);
    const grossPrice = calcGrossPrice(netPrice, props.yieldRate.number);
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, netPrice, grossPrice, dirty, ok });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.supplier.ok) return false;
    if (!props.category.ok) return false;
    if (!props.name.ok) return false;
    if (!props.poundPrice.ok) return false;
    if (!props.yieldRate.ok) return false;
    return true;
  }

  async save(): Promise<PoundBasedIngredientItemEditor> {
    if (!this.validate()) throw new Error('invalid object');
    return this.original.id ? await this.update() : await this.create();
  }

  private async create(): Promise<PoundBasedIngredientItemEditor> {
    const gql = await new CreateGql().fetch({ businessId: this.original.businessId, input: this.getDataToCreate() });
    if (!gql.document) throw new Error('invalid document');
    return this.identify(gql.document);
  }

  private async update(): Promise<PoundBasedIngredientItemEditor> {
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new UpdateGql().fetch({ id: this.original.id, input: this.getDataToUpdate() });
    if (!gql.document) throw new Error('invalid document');
    return this.revise(gql.document);
  }

  getDataToCreate(): DataToCreate {
    const supplierId = this.supplier.object!.id;
    const categoryId = this.category.object!.id;
    const name = this.name.string!;
    const poundPrice = this.poundPrice.number!;
    const yieldRate = this.yieldRate.number!;
    return { supplierId, categoryId, name, poundPrice, yieldRate };
  }

  getDataToUpdate(): DataToUpdate {
    const supplierId = this.supplier.dirt?.id;
    const categoryId = this.category.dirt?.id;
    const name = this.name.dirt;
    const poundPrice = this.poundPrice.dirt;
    const unitValue = this.original instanceof UnitBasedIngredientItem ? null : undefined;
    const unitSymbol = this.original instanceof UnitBasedIngredientItem ? null : undefined;
    const netPrice = this.original instanceof UnitBasedIngredientItem ? null : undefined;
    const yieldRate = this.yieldRate.dirt;
    return { supplierId, categoryId, name, poundPrice, unitValue, unitSymbol, netPrice, yieldRate };
  }

  identify(data: IngredientItemDataToIdentify): PoundBasedIngredientItemEditor {
    const original = this.rebuild().identify(data);
    return new PoundBasedIngredientItemEditor({ original, suppliers: this.suppliers, categories: this.categories, unitSymbols: this.unitSymbols });
  }

  revise(data: IngredientItemDataToRevise = {}): PoundBasedIngredientItemEditor {
    const original = this.rebuild().revise(data);
    return new PoundBasedIngredientItemEditor({ original, suppliers: this.suppliers, categories: this.categories, unitSymbols: this.unitSymbols });
  }

  rebuild(): PoundBasedIngredientItem {
    if (!this.validate()) throw new Error('invalid object');
    const supplier = this.supplier.object!;
    const category = this.category.object!;
    const name = this.name.string!;
    const poundPrice = this.poundPrice.number!;
    const yieldRate = this.yieldRate.number!;
    const original = this.original instanceof UnitBasedIngredientItem ? this.original.toPoundBased() : this.original;
    return original.apply({ supplier, category, name, poundPrice, yieldRate });
  }

  async delete(): Promise<this> {
    if (!this.canDelete) throw new Error('cannot delete');
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new DeleteGql().fetch({ id: this.original.id });
    if (!gql.document) throw new Error('invalid document');
    return this;
  }

  asUnitBased(): UnitBasedIngredientItemEditor {
    const original = this.original;
    return new UnitBasedIngredientItemEditor({ original, categories: this.categories, suppliers: this.suppliers, unitSymbols: this.unitSymbols });
  }

}

export { PoundBasedIngredientItemEditor };
export type {
  Data as PoundBasedIngredientItemEditorData,
  DataToApply as PoundBasedIngredientItemEditorDataToApply,
};
