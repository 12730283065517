import { IngredientCategoryArrayField } from 'models/entities/ingredient-category';
import { StringArrayField } from 'models/value-objects/editor-field';

type Data = {
  field: IngredientCategoryArrayField;
};

class Model {

  readonly field: IngredientCategoryArrayField;
  readonly stringArrayField: StringArrayField;

  constructor(data: Data) {
    this.field = data.field;
    this.stringArrayField = new StringArrayField({ list: this.field.list.map(it => it.original.name), string: this.field.object?.original.name });
  }

  apply(stringArrayField: StringArrayField): this {
    const field = this.field.change(stringArrayField.index);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, field, stringArrayField });
  }

}

export { Model };
