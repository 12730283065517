import { OriginalDishCategoryCollectionQuery } from '../original-dish-category';

export const OriginalMenuCategoryFieldsQuery = `
  id
  order
  code
  name
  createdAt
  updatedAt
  dishCategories {
    ${OriginalDishCategoryCollectionQuery}
  }
`;

export const OriginalMenuCategoryCollectionQuery = `
  documents {
    ${OriginalMenuCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadOriginalMenuCategoriesQuery = `
  readOriginalMenuCategories(nextToken: $nextToken) {
    ${OriginalMenuCategoryCollectionQuery}
  }
`;

export const CreateOriginalMenuCategoryQuery = `
  createOriginalMenuCategory(input: $input) { 
    ${OriginalMenuCategoryFieldsQuery}
  }
`;

export const UpdateOriginalMenuCategoryQuery = `
  updateOriginalMenuCategory(id: $id, input: $input) { 
    ${OriginalMenuCategoryFieldsQuery}
  }
`;

export const DeleteOriginalMenuCategoryQuery = `
  deleteOriginalMenuCategory(id: $id) { 
    ${OriginalMenuCategoryFieldsQuery}
  }
`;