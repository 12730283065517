import React, { FC, useState, useEffect } from 'react';

import { Supplier } from 'models/entities/supplier';
import { Model } from './index.model';

import { useModal } from '../../services/use-modal';
import { TextInput } from 'views/components/text-input';
import { MultiTextInput } from 'views/components/multi-text-input';
import { PrimaryButton, SecondaryButton, SecondaryRoundButton } from 'views/components/buttons';
import { TrashIcon } from 'views/components/icons';
import { Root, Layout, Company, Pic, Other, Footer, MoreActions } from './index.styled';

interface Props {
  target: Supplier;
  ready: boolean;
}

const EditSupplier: FC<Props> = ({ target, ready }) => {

  const modal = useModal();
  const [model, setModel] = useState(new Model({ target }));

  function save() {
    model.save().then(model => modal.close({ state: model.isNew ? 'created' : 'updated', target: model.result }));
  }

  function cancel() {
    modal.close();
  }

  function remove() {
    if (model.isNew) return;
    model.delete().then(model => modal.close({ state: 'deleted', target: model.result }));
  }

  function watchTarget() {
    if (!model.target?.id) return;
    model.readRelationships(model.target.id).then(m => setModel(m));
  }

  useEffect(watchTarget, [model.target]);

  return (
    <Root>
      <h1>Supplier</h1>
      <Layout>
        <div>
          <Company>
            <h2>Company</h2>
            <fieldset>
              <TextInput value={model.name} onChange={e => setModel(model.change({ name: e.target.value }))} inFocus={ready}>
                <h3>Name</h3>
              </TextInput>
              <TextInput value={model.address} onChange={e => setModel(model.change({ address: e.target.value }))}>
                <h3>Address</h3>
              </TextInput>
              <TextInput value={model.city} onChange={e => setModel(model.change({ city: e.target.value }))}>
                <h3>City</h3>
              </TextInput>
              <TextInput value={model.province} onChange={e => setModel(model.change({ province: e.target.value }))}>
                <h3>Province</h3>
              </TextInput>
              <TextInput value={model.country} onChange={e => setModel(model.change({ country: e.target.value }))}>
                <h3>Country</h3>
              </TextInput>
              <TextInput value={model.postalCode} onChange={e => setModel(model.change({ postalCode: e.target.value }))}>
                <h3>Postal Code</h3>
              </TextInput>
              <TextInput value={model.phone} onChange={e => setModel(model.change({ phone: e.target.value }))}>
                <h3>Phone</h3>
              </TextInput>
              <TextInput value={model.fax} onChange={e => setModel(model.change({ fax: e.target.value }))}>
                <h3>Fax</h3>
              </TextInput>
            </fieldset>
          </Company>
        </div>
        <div>
          <Pic>
            <h2>Person in charge</h2>
            <fieldset>
              <TextInput value={model.picName} onChange={e => setModel(model.change({ picName: e.target.value }))}>
                <h3>Name</h3>
              </TextInput>
              <TextInput value={model.picPhone} onChange={e => setModel(model.change({ picPhone: e.target.value }))}>
                <h3>Phone</h3>
              </TextInput>
              <TextInput value={model.picEmail} onChange={e => setModel(model.change({ picEmail: e.target.value }))}>
                <h3>Email</h3>
              </TextInput>
            </fieldset>
          </Pic>
          <Other>
            <h2>Notes</h2>
            <fieldset>
              <MultiTextInput value={model.notes} onChange={e => setModel(model.change({ notes: e.target.value }))} height={'89px'} />
            </fieldset>
          </Other>
        </div>
      </Layout>
      <Footer>
        <SecondaryButton onClick={() => cancel()}>Cancel</SecondaryButton>
        <PrimaryButton onClick={() => save()} disabled={!model.result}>Save</PrimaryButton>
      </Footer>
      {!model.isNew && (
        <MoreActions>
          <div>
            <SecondaryRoundButton onClick={() => remove()} disabled={!model.canDelete} tabIndex={-1}><TrashIcon /></SecondaryRoundButton>
          </div>
        </MoreActions>
      )}
    </Root>
  );

};

export { EditSupplier };
