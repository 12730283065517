import { OpenaiAccount } from 'models/entities/openai';

type Data = {
  target: OpenaiAccount;
}

type DataToChange = {
  apiKey?: string;
  organizationId?: string;
};

class Model {

  readonly target: OpenaiAccount;
  readonly isNew: boolean;
  readonly currentApiKey: string;
  readonly currentOrganizationId: string;

  readonly apiKey: string;
  readonly organizationId: string;

  readonly result?: OpenaiAccount;

  constructor(data: Data) {
    this.target = data.target;
    this.isNew = !data.target.id;
    this.currentApiKey = this.mask(this.target.apiKey);
    this.currentOrganizationId = this.mask(this.target.organizationId);
    this.apiKey = '';
    this.organizationId = '';
  }

  change(data: DataToChange): this {
    const props = { ...this, ...data };
    const result = this.validate(props) ? this.build(props) : undefined;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, result });
  }

  private validate(data: DataToChange): boolean {
    if (!data.apiKey) return false;
    if (!data.organizationId) return false;
    return true;
  }

  private build(data: DataToChange): OpenaiAccount {
    const apiKey = data.apiKey;
    const organizationId = data.organizationId;
    return this.target.edit({ apiKey, organizationId });
  }

  async save(): Promise<this> {
    if (!this.result) throw new Error('invalid result');
    const result = await this.result.save();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  async delete(): Promise<this> {
    const result = await this.target.delete();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result });
  }

  private mask(string: string): string {
    if (string.length < 16) return '*'.repeat(string.length);
    return '*'.repeat(string.length - 4) + string.slice(-4);
  }

}

export { Model };