export const OriginalIngredientCategoryFieldsQuery = `
  id
  order
  name
  createdAt
  updatedAt
`;

export const OriginalIngredientCategoryCollectionQuery = `
  documents {
    ${OriginalIngredientCategoryFieldsQuery}
  }
  nextToken
`;

export const ReadOriginalIngredientCategoriesQuery = `
  readOriginalIngredientCategories(nextToken: $nextToken) {
    ${OriginalIngredientCategoryCollectionQuery}
  }
`;

export const CreateOriginalIngredientCategoryQuery = `
  createOriginalIngredientCategory(input: $input) { 
    ${OriginalIngredientCategoryFieldsQuery}
  }
`;

export const UpdateOriginalIngredientCategoryQuery = `
  updateOriginalIngredientCategory(id: $id, input: $input) { 
    ${OriginalIngredientCategoryFieldsQuery}
  }
`;

export const DeleteOriginalIngredientCategoryQuery = `
  deleteOriginalIngredientCategory(id: $id) { 
    ${OriginalIngredientCategoryFieldsQuery}
  }
`;

