import { Amplify, API } from 'aws-amplify';

import { Config } from './config';

Amplify.configure(Config);

type OptionsToFetch = {
  variables?: object;
};

class Api<ResponseData> {

  readonly request: string;
  readonly response?: ResponseData;

  constructor(request: string) {
    this.request = request;
  }

  async fetch({ variables }: OptionsToFetch = {}): Promise<this> {
    const response = await API.graphql({ query: this.request, variables });
    return Object.assign(Object.create(this.constructor.prototype), { ...this, response });
  }

}

export { Api };
