import { EmailField, PasswordField } from 'models/value-objects/editor-field';
import { Credentials } from 'models/value-objects/credentials';

type DataToEdit = {
  email?: EmailField;
  password?: PasswordField;
};

type DataToValidate = {
  email?: EmailField;
  password?: PasswordField;
};

class Model {

  readonly email: EmailField;
  readonly password: PasswordField;
  readonly ok: boolean;

  constructor() {
    this.email = new EmailField();
    this.password = new PasswordField();
    this.ok = this.validate(this);
  }

  edit(data: DataToEdit = {}): this {
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data, ok });
  }

  issueCredentials(): Credentials {
    const email = this.email.string || '';
    const password = this.password.string || '';
    return new Credentials({ email, password });
  }

  private validate(data: DataToValidate): boolean {
    const props = { ...this, ...data };
    if (!props.email.ok) return false;
    if (!props.password.ok) return false;
    return true;
  }

}

export { Model };
